import React from "react";
import { makeStyles, Typography } from "@material-ui/core";

export const useStyles = makeStyles({
  brandHit: {
    display: "block",
    width: "25%",
    margin: "75px 0 25px 0",
  },
  brandHitLogo: {
    display: "block",
    width: "100%",
  },
});

const ConfigErrorMessage = () => {
  return (
    <div>
      <Typography variant="h4">
        Oops! Looks like you have stumbled upon an unusual configuration issue.
      </Typography>
      <Typography>
        But no worries, we got you! Please reach out to our support at
        exchange@proficienthealth.com.
      </Typography>
    </div>
  );
};

const AppError = ({ type }) => {
  const classes = useStyles();

  const ErrorSwitch = ({ errorType }) => {
    switch (errorType) {
      case "config":
        return <ConfigErrorMessage />;
      default:
        return <ConfigErrorMessage />;
    }
  };

  return (
    <>
      <div id="brand-logo-error" className={classes.brandHit}>
        <img
          className={classes.brandHitLogo}
          src={`${
            process.env.REACT_APP_BASENAME === "/"
              ? "/exchange"
              : process.env.REACT_APP_BASENAME
          }/branding_hit.png`}
          alt="Proficient Health"
        />
      </div>
      <ErrorSwitch errorType={type} />
    </>
  );
};

export default AppError;
