import { Routes, styles } from "./Routes";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { withOptimizely } from "@optimizely/react-sdk";

function mapStateToProps(state) {
  return {
    authenticated: state.auth.authenticated,
  };
}

export default withOptimizely(
  withRouter(connect(mapStateToProps)(withStyles(styles)(Routes)))
);
