const SET_APP_ERROR = "hermes/errors/SET_APP_ERROR";

const initialState = {
  appError: false,
  errorType: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_APP_ERROR:
      return {
        ...state,
        appError: true,
        errorType: action.errorType,
      };
    default:
      return state;
  }
}

export function setAppError(errorType) {
  return {
    type: SET_APP_ERROR,
    errorType,
  };
}
