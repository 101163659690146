import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    header: {
      display: "inline",
      fontWeight: 500,
      fontSize: "0.875rem",
      lineHeight: "1.57",
      color: theme.palette.gray.gray3,
    },
    require: {
      display: "inline",
      fontWeight: 700,
      fontSize: "11px",
      lineHeight: "14px",
      color: theme.palette.require,
      marginLeft: "3px",
    },
    primary: {
      background: (props) =>
        !!props.value ? theme.palette.primary.light : theme.palette.white.main,
      borderColor: theme.palette.primary.light,
      borderRadius: "4px",
      color: theme.palette.white.main,
      width: "100%",
      "& input.Mui-disabled": {
        color: (props) => (props.value ? theme.palette.white.main : "unset"),
      },
    },
    disabled: {
      borderRadius: "4px",
      width: "100%",
    },
    hintText: {
      fontSize: "12px",
      lineHeight: "12px",
      fontWeight: "500px",
      marginTop: "2px",
      color: theme.palette.gray.gray2,
    },
  };
});

export { useStyles };
