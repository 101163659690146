import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useQuery } from "@apollo/client";

import { APP_SETTING } from "../queries/context.query";
import { GET_NETWORK_DASHBOARD_LIST } from "../queries/dashboards";
import jwtDecode from "jwt-decode";
import { formatAppsetting } from "./helper";

export const AppSettingContext = createContext();

export const AppSettingProvider = ({ children }) => {
  const clientId = useSelector((state) => state.auth.clientId);
  const [token, setToken] = useState(null);
  const [isNetworkDashboardCallSkipped, setIsNetworkDashboardCallSkipped] =
    useState(true);

  const [exchangeTokenInfo, setExchangeTokenInfo] = useState(null);
  useEffect(() => {
    if (token) {
      setExchangeTokenInfo(jwtDecode(token));
    }
  }, [token]);

  const { data, error, loading } = useQuery(APP_SETTING, {
    variables: {
      clientId,
      userId: `${exchangeTokenInfo?.userId}`,
    },
    skip: !clientId || !exchangeTokenInfo,
    fetchPolicy: "cache-first",
  });

  const { data: networkDashboards } = useQuery(GET_NETWORK_DASHBOARD_LIST, {
    variables: { networkId: data?.directoryProps?.primaryNetworkId[0] },
    skip:
      !data?.directoryProps?.primaryNetworkId ||
      data?.directoryProps?.primaryNetworkId?.length === 0,
    fetchPolicy: "cache-first",
    onCompleted: () => {
      setIsNetworkDashboardCallSkipped(false);
    },
  });

  const value = useMemo(
    () =>
      formatAppsetting({
        data,
        error,
        loading,
        exchangeTokenInfo,
        networkDashboards,
        setToken,
        isNetworkDashboardCallSkipped,
      }),
    [
      data,
      error,
      loading,
      exchangeTokenInfo,
      networkDashboards,
      isNetworkDashboardCallSkipped,
    ]
  );

  return (
    <AppSettingContext.Provider value={value}>
      {children}
    </AppSettingContext.Provider>
  );
};

export const useAppSetting = () => {
  const context = useContext(AppSettingContext);
  if (context === undefined) {
    return new Error("Export config context is undefined");
  }
  return context;
};
