import React, { useEffect } from "react";
import { useFormContext, Controller } from "react-hook-form";
// @mui
import { Box, Typography } from "@material-ui/core";
import FormHelperText from "@mui/material/FormHelperText";
//
import Editor from "../../components/Editor";
import { useStyles } from "../styles";
import PropTypes from "prop-types";

// ----------------------------------------------------------------------

const RichTextField = React.forwardRef(
  (
    {
      name,
      label,
      isrequired = false,
      helperText,
      // Remove the ref prop here
      ...other
    },
    forwardedRef
  ) => {
    const classes = useStyles();
    const {
      control,
      watch,
      setValue,
      formState: { isSubmitSuccessful },
    } = useFormContext();

    const values = watch();

    useEffect(() => {
      if (values[name] === "<p><br></p>") {
        setValue(name, "", {
          shouldValidate: !isSubmitSuccessful,
        });
      }
    }, [isSubmitSuccessful, name, setValue, values]);

    return (
      <Box>
        {/* Label Section */}
        {label && (
          <Box>
            <Typography className={classes.header}>{label}</Typography>
            {isrequired && (
              <Typography className={classes.require}>*</Typography>
            )}
          </Box>
        )}

        {/* Editor Field with Controller */}
        <Controller
          name={name}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Editor
              id={name}
              ref={forwardedRef}
              value={field.value}
              onChange={(e) => {
                field.onChange(e);
              }}
              error={!!error}
              helperText={
                (!!error || helperText) && (
                  <FormHelperText error={!!error} sx={{ px: 2 }}>
                    {error ? error?.message : helperText}
                  </FormHelperText>
                )
              }
              {...other}
            />
          )}
        />
      </Box>
    );
  }
);

export default RichTextField;

RichTextField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  isrequired: PropTypes.bool,
  helperText: PropTypes.string,
  other: PropTypes.object,
};
