import { put, call, takeEvery } from "redux-saga/effects";
import { showProcessingSpinner, hideProcessingSpinner } from "./form";
import {
  updatePatient,
  fetchPatient,
  createPatientInPool,
} from "../../services/PatientService";
import { getAuthToken, getClientId } from "./auth";
import { enqueueSnackbar } from "./notifiers";
import _ from "lodash";
import { format, parseISO } from "date-fns";
import { getReceivedReferral } from "./referralsReceived";
import { getSentReferral, UPDATE_SENT_REFERRAL_PATIENT } from "./referralsSent";
import { patientMaybeChanged } from "./patientSearch";
import { setAppError } from "./errors";

const NEW_CREATE_PATIENT = "hermes/patients/NEW_CREATE_PATIENT";
const NEW_CREATE_PATIENT_SUCCESS = "hermes/patients/NEW_CREATE_PATIENT_SUCCESS";
const UPDATE_PATIENT = "hermes/patients/UPDATE_PATIENT";
const UPDATE_PATIENT_SUCCESS = "hermes/patients/UPDATE_PATIENT_SUCCESS";
const UPDATE_PATIENT_FAILURE = "hermes/patients/UPDATE_PATIENT_FAILURE";
const HYDRATE_PATIENT = "hermes/patients/HYDRATE_PATIENT";
const HYDRATE_PATIENT_SUCCESS = "hermes/patients/HYDRATE_PATIENT_SUCCESS";
const HYDRATE_PATIENT_FAILURE = "hermes/patients/HYDRATE_PATIENT_FAILURE";
const CLEAR_HYDRATE_PATIENT = "hermes/patients/CLEAR_HYDRATE_PATIENT";
const GET_PATIENTS = "hermes/patients/GET_PATIENTS";
const GET_PATIENTS_SUCCESS = "hermes/patients/GET_PATIENTS_SUCCESS";
const GET_PATIENTS_FAILURE = "hermes/patients/GET_PATIENTS_FAILURE";
const GET_CURRENT_PATIENT_SUCCESS =
  "hermes/patients/GET_CURRENT_PATIENT_SUCCESS";
const GET_CURRENT_PATIENT_FAILURE =
  "hermes/patients/GET_CURRENT_PATIENT_FAILURE";
const REMOVE_CURRENT_PATIENT = "hermes/patients/REMOVE_CURRENT_PATIENT";
const UPDATE_CURRENT_PATIENT = "hermes/patients/UPDATE_CURRENT_PATIENT";
const initialState = {
  list: [],
  currentPatient: {},
  selectList: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case NEW_CREATE_PATIENT_SUCCESS:
      let newCreatePlist = [
        action.newPatient,
        ...state.list.filter((patient) => patient.id !== action.newPatient.id),
      ];
      return {
        ...state,
        list: newCreatePlist,
        selectList: newCreatePlist.map((patient) => {
          return {
            id: patient.id,
            value: patient,
            label: JSON.stringify({
              primary: `${patient.firstName} ${patient.lastName}`,
              secondary: `${patient.mrn ? `MRN: ${patient.mrn}` : ""} ${
                patient.dob
                  ? `DOB: ${format(parseISO(patient.dob), "MM/dd/yyyy")}`
                  : ""
              }`,
            }),
          };
        }),
      };
    case UPDATE_PATIENT_SUCCESS:
      let updatePlist = [
        action.newPatient,
        ...state.list.filter((patient) => patient.id !== action.newPatient.id),
      ];
      return {
        ...state,
        list: updatePlist,
        selectList: updatePlist.map((patient) => {
          return {
            id: patient.id,
            value: patient,
            label: JSON.stringify({
              primary: `${patient.firstName} ${patient.lastName}`,
              secondary: `${patient.mrn ? `MRN: ${patient.mrn}` : ""} ${
                patient.dob
                  ? `DOB: ${format(parseISO(patient.dob), "MM/dd/yyyy")}`
                  : ""
              }`,
            }),
          };
        }),
      };
    case HYDRATE_PATIENT_SUCCESS:
      let hydratePlist = [
        action.newPatient,
        ...state.list.filter((patient) => patient.id !== action.newPatient.id),
      ];
      return {
        ...state,
        hydratedPatient: action.newPatient,
        list: hydratePlist,
        selectList: hydratePlist.map((patient) => {
          return {
            id: patient.id,
            value: patient,
            label: JSON.stringify({
              primary: `${patient.firstName} ${patient.lastName}`,
              secondary: `${patient.mrn ? `MRN: ${patient.mrn}` : ""} ${
                patient.dob
                  ? `DOB: ${format(parseISO(patient.dob), "MM/dd/yyyy")}`
                  : ""
              }`,
            }),
          };
        }),
      };
    case CLEAR_HYDRATE_PATIENT:
      return {
        ...state,
        hydratedPatient: undefined,
      };
    case GET_PATIENTS_SUCCESS:
      let getPlist = action.patients;
      return {
        ...state,
        list: getPlist,
        selectList: getPlist.map((patient) => {
          return {
            id: patient.id,
            value: patient,
            label: JSON.stringify({
              primary: `${patient.firstName} ${patient.lastName}`,
              secondary: `${patient.mrn ? `MRN: ${patient.mrn}` : ""} ${
                patient.dob
                  ? `DOB: ${format(parseISO(patient.dob), "MM/dd/yyyy")}`
                  : ""
              }`,
            }),
          };
        }),
      };
    case GET_CURRENT_PATIENT_SUCCESS:
      return {
        ...state,
        currentPatient: action.currentPatient,
      };
    case GET_CURRENT_PATIENT_FAILURE:
      return state;
    case GET_PATIENTS_FAILURE:
      return state;
    case REMOVE_CURRENT_PATIENT:
      return {
        ...state,
        currentPatient: {},
      };
    case UPDATE_CURRENT_PATIENT:
      return {
        ...state,
        currentPatient: action.currentPatient,
      };
    default:
      return state;
  }
}

export function updatePatientInfo(
  updatedPatientInfo,
  { referralId, referralType, patientsPoolID } = {}
) {
  return {
    type: UPDATE_PATIENT,
    updatedPatientInfo,
    referralId,
    referralType,
    patientsPoolID,
  };
}

export function createPatientInfo(newPatientInfo, { patientsPoolID } = {}) {
  return {
    type: NEW_CREATE_PATIENT,
    newPatientInfo,
    patientsPoolID,
  };
}

export function createPatientInfoSuccess(newPatient) {
  return {
    type: NEW_CREATE_PATIENT_SUCCESS,
    newPatient,
  };
}

export function hydratePatientInfo(patientId) {
  return {
    type: HYDRATE_PATIENT,
    patientId,
  };
}

export function clearHydratedPatient() {
  return {
    type: CLEAR_HYDRATE_PATIENT,
  };
}

export function getExistingPatients(searchTerm) {
  return {
    type: GET_PATIENTS,
    searchTerm,
  };
}

export function searchPatientResult(patients = []) {
  return {
    type: GET_PATIENTS_SUCCESS,
    patients: patients.slice(0, 20),
  };
}

function* handleUpdatePatient({
  updatedPatientInfo,
  referralId,
  referralType,
}) {
  try {
    yield put(showProcessingSpinner());
    let authToken = yield call(getAuthToken);
    let clientId = yield call(getClientId);

    let response = yield call(
      updatePatient,
      authToken,
      clientId,
      updatedPatientInfo
    );
    yield put({
      newPatient: { ...response },
      type: UPDATE_PATIENT_SUCCESS,
    });
    yield put({
      patient: { ...response },
      type: UPDATE_SENT_REFERRAL_PATIENT,
    });

    /***
     TODO: this refactor should be handled in EX-3709 after EX-3643
     */
    if (referralId) {
      switch (referralType) {
        case "RECEIVED":
          yield put(getReceivedReferral(referralId));
          break;
        case "SENT":
          yield put(getSentReferral(referralId));
          break;
        default:
          break;
      }
    }
    yield put(patientMaybeChanged(response));
    yield put(hideProcessingSpinner());
    yield put(
      enqueueSnackbar({
        message: `Patient updated!`,
        options: {
          variant: "success",
        },
      })
    );
  } catch (e) {
    let errorLogging = {};
    if (!_.includes(e.message, "401")) {
      errorLogging = { error: true, payload: e };
    }
    if (_.includes(e.message, "404")) {
      yield put(setAppError("config"));
    }
    yield put({ type: UPDATE_PATIENT_FAILURE, ...errorLogging });
    yield put(hideProcessingSpinner());
    yield put(
      enqueueSnackbar({
        message: `Patient not updated.`,
        options: {
          variant: "error",
        },
      })
    );
  }
}
function* handleNewCreatePatient({ newPatientInfo, patientsPoolID, meta }) {
  try {
    let clientId = yield call(getClientId);

    // use clientId from patient in case it has
    clientId = newPatientInfo?.clientId ?? clientId;
    delete newPatientInfo?.clientId;

    yield put(showProcessingSpinner());
    let authToken = yield call(getAuthToken);

    let response = yield call(
      createPatientInPool,
      authToken,
      clientId,
      newPatientInfo,
      patientsPoolID
    );
    if (response.isError) {
      yield put(
        enqueueSnackbar({
          message: `${response.errorMessage}`,
          options: {
            variant: "error",
          },
        })
      );
      yield put({ type: UPDATE_PATIENT_FAILURE });
      yield put(hideProcessingSpinner());
    } else {
      yield put({
        newPatient: { ...response },
        type: NEW_CREATE_PATIENT_SUCCESS,
      });
      yield put(hideProcessingSpinner());
      yield put(
        enqueueSnackbar({
          message: `Patient created!`,
          options: {
            variant: "success",
          },
        })
      );
      if (meta) {
        yield call(meta.resolve, response);
      }
    }
  } catch (e) {
    let errorLogging = {};
    if (!_.includes(e.message, "401") && !_.includes(e.message, "409")) {
      errorLogging = { error: true, payload: e };
    }
    if (_.includes(e.message, "404")) {
      yield put(setAppError("config"));
    }
    yield put(
      enqueueSnackbar({
        message: `Patient not created.`,
        options: {
          variant: "error",
        },
      })
    );
    yield put({ type: UPDATE_PATIENT_FAILURE, ...errorLogging });
    yield put(hideProcessingSpinner());
    if (meta) {
      yield call(meta.reject, e);
    }
  }
}

function* handleHydratePatient({ patientId }) {
  try {
    yield put(showProcessingSpinner());
    let authToken = yield call(getAuthToken);
    let clientId = yield call(getClientId);

    let response = yield call(fetchPatient, authToken, clientId, patientId);
    yield put({
      newPatient: { ...response },
      type: HYDRATE_PATIENT_SUCCESS,
    });

    yield put(hideProcessingSpinner());
  } catch (e) {
    let errorLogging = {};
    if (!_.includes(e.message, "401")) {
      errorLogging = { error: true, payload: e };
    }
    if (_.includes(e.message, "404")) {
      yield put(setAppError("config"));
    }
    yield put({ type: HYDRATE_PATIENT_FAILURE, ...errorLogging });
    yield put(hideProcessingSpinner());
  }
}

export function removeCurrentPatient() {
  return {
    type: REMOVE_CURRENT_PATIENT,
  };
}

export function* updatePatientSaga() {
  yield takeEvery(UPDATE_PATIENT, handleUpdatePatient);
}
export function* newCreatePatientSaga() {
  yield takeEvery(NEW_CREATE_PATIENT, handleNewCreatePatient);
}
export function* hydratePatientSaga() {
  yield takeEvery(HYDRATE_PATIENT, handleHydratePatient);
}
