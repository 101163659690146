import { gql } from "@apollo/client";

export const GET_ALL_TENANT_USERS = gql`
  query GetUsers($clientId: Int!) {
    users(clientId: $clientId) {
      id
      clientId
      firstName
      lastName
      multiTenant
      tenantadmin
      email
      inactive
    }
  }
`;

export const userQuery = gql`
  query GetUserName($userId: String) {
    user(userId: $userId) {
      firstName
      lastName
    }
  }
`;

export const GET_USER_VIEWS = gql`
  query getUserViews {
    userViewTimes {
      receivedReferrals
      searchReferrals
      sentReferrals
    }
  }
`;

export const GET_USER_PREFERENCE_QUERY = gql`
  query UserPreferences {
    userPreferences {
      mainTabs {
        documentTriage {
          savedFilters {
            filterValue
          }
        }
      }
    }
  }
`;
