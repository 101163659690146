export const getOrderingProviderName = (provider) => {
  if (!provider) {
    return "";
  }
  const { fields } = provider;
  if (!fields) {
    return "";
  }
  const { firstName, lastName, orgName, faxPhone } = fields;
  const city = fields.address?.city ?? "";
  const state = fields.address?.state ?? "";
  const zip = fields.address?.zip ?? "";
  return `${firstName} ${lastName} - ${orgName} - ${city}, ${state} ${zip} - Fax ${faxPhone}`;
};
