import { Container, styles } from "./Container";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import {
  signIn,
  signInFailure,
  determineIfSignedIn,
  signOut,
} from "../../state/ducks/auth";
import { checkAppVersion } from "../../state/ducks/version";
import { withOptimizely } from "@optimizely/react-sdk";
import { initApiHost } from "../../state/ducks/api";
import { enqueueSnackbar } from "../../state/ducks/notifiers";
import { setAppError } from "../../state/ducks/errors";

function mapStateToProps(state) {
  return {
    authStateDetermined: state.auth.authStateDetermined,
    createBtn: state.sidemenu.createBtn,
    hasExtraDrawer: state.sidemenu.hasExtraDrawer,
    userId: state.auth.userId,
    clientId: state.auth.clientId,
    environment: state.auth.environment,
    appError: state.errors.appError,
    errorType: state.errors.errorType,
  };
}

export default withOptimizely(
  withRouter(
    connect(mapStateToProps, {
      signIn,
      signOut,
      determineIfSignedIn,
      signInFailure,
      checkAppVersion,
      initApiHost,
      enqueueSnackbar,
      setAppError,
    })(withStyles(styles, { withTheme: true })(Container))
  )
);
