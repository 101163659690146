import React from "react";
import classnames from "classnames";
import {
  Avatar,
  Card,
  CardContent,
  Grid,
  Typography,
  Tooltip,
} from "@material-ui/core";
import {
  ArrowUpward,
  ArrowDownward,
  Flag,
  CloudQueue,
  CloudOff,
  WifiTethering,
  PortableWifiOff,
  Warning,
} from "@material-ui/icons";
import {
  red,
  lightGreen,
  grey,
  blueGrey,
  brown,
  deepOrange,
  orange,
  amber,
  green,
  teal,
  cyan,
  lightBlue,
  blue,
  indigo,
  deepPurple,
  purple,
  pink,
} from "@material-ui/core/colors";
import { Draggable } from "react-beautiful-dnd";
import { Link } from "react-router-dom";
import { format, parseISO, isValid } from "date-fns";
import {
  determineIfOnline,
  newDetermineIfOnline,
  newDetermineIfInNetwork,
} from "../../state/ducks/directory";
import { AppSettingContext } from "../../context/appSettingContext.js";
import { ReactComponent as PrintDisabled } from "../../../assets/icons/PrintDisabled.svg";

export const styles = {
  card: {
    maxWidth: 430,
    minHeight: 120,
    padding: 8,
    paddingBottom: 4,
    marginBottom: 8,
    transitionDuration: ".2s",
    "&:hover": {
      backgroundColor: grey[300],
    },
  },
  cardFailed: {
    maxWidth: 430,
    minHeight: 120,
    padding: 8,
    paddingBottom: 4,
    marginBottom: 8,
    borderRadius: "5px",
    border: "3px solid",
    borderColor: "#EB5757",
    transitionDuration: ".2s",
    "&:hover": {
      backgroundColor: grey[300],
    },
  },
  cardContainer: {
    minHeight: 108,
  },
  cardHeader: {
    padding: 0,
  },
  cardContent: {
    padding: 0,
    "&:last-child": {
      paddingBottom: 0,
    },
  },
  cardContentFont: {
    fontWeight: 400,
  },
  flag: {
    color: red[800],
  },
  printDisabled: {
    color: "#EB5757",
  },
  urgencyIndicator: {
    width: "auto",
    margin: 1,
  },
  urgent: {
    color: red[700],
    width: ".85em",
    height: ".85em",
  },
  wrapper: {
    display: "flex",
    justifyContent: "center",
    aligItems: "center",
  },
  routine: {
    color: lightGreen[700],
    width: ".85em",
    height: ".85em",
  },
  draggable: {
    userSelect: "none",
    borderRadius: 5,
    marginBottom: 8,
    "&:last-child": {
      marginBottom: 0,
    },
  },
  dragging: {
    background: "lightblue",
  },
  topRightButton: {
    padding: 4,
    marginRight: 12,
  },
  nameHyphen: {
    padding: "0 4px",
  },
  refLink: {
    textDecoration: "none",
    color: "inherit",
  },
  thnInNetwork: {
    backgroundColor: "rgba(36, 170, 224, 0.1)",
  },
  thnOutOfNetwork: {
    backgroundColor: "rgba(207, 198, 0, 0.1)",
  },
  avatar: {
    margin: 2,
  },

  loggedInUser: {
    margin: 2,
    color: "#fff",
    backgroundColor: "#304ffe",
    width: "1.1em",
    height: "1.1em",
  },

  notLoggedUser: {
    margin: 2,
    color: "#",
    backgroundColor: "#bdbdbd",
    width: "1.1em",
    height: "1.1em",
  },

  avatarText: {
    fontSize: 12,
  },
};

export function _typeColorFromType(type, typeOptions) {
  const optionIndex = typeOptions.findIndex((option) => option.label === type);

  const params = {
    selection: optionIndex % 16,
    variance: _findOptionFromVariance(Math.ceil(optionIndex / 16)),
  };
  switch (params.selection) {
    case 0:
      return {
        color: red[params.variance],
      };
    case 1:
      return {
        color: pink[params.variance],
      };
    case 2:
      return {
        color: purple[params.variance],
      };
    case 3:
      return {
        color: deepPurple[params.variance],
      };
    case 4:
      return {
        color: indigo[params.variance],
      };
    case 5:
      return {
        color: blue[params.variance],
      };
    case 6:
      return {
        color: lightBlue[params.variance],
      };
    case 7:
      return {
        color: cyan[params.variance],
      };
    case 8:
      return {
        color: teal[params.variance],
      };
    case 9:
      return {
        color: green[params.variance],
      };
    case 10:
      return {
        color: lightGreen[params.variance],
      };
    case 11:
      return {
        color: amber[params.variance],
      };
    case 12:
      return {
        color: orange[params.variance],
      };
    case 13:
      return {
        color: deepOrange[params.variance],
      };
    case 14:
      return {
        color: brown[params.variance],
      };
    case 15:
      return {
        color: blueGrey[params.variance],
      };
    default:
      return {
        color: "inherit",
      };
  }
}

function _findOptionFromVariance(variance) {
  switch (variance) {
    case 1:
      return 300;
    case 2:
      return 400;
    case 3:
      return 500;
    case 4:
      return 600;
    case 5:
      return 700;
    case 6:
      return 800;
    case 7:
      return 900;
    default:
      return 500;
  }
}

function generateCardContent(referral, typeOptions, classes) {
  return (
    <CardContent className={classes.cardContent}>
      {_generateInternalCardContent(referral, typeOptions, classes)}
    </CardContent>
  );
}

export function _generateInternalCardContent(referral, typeOptions, classes) {
  const {
    consultDate,
    senderPractice = "",
    senderName = "",
    referralRecipientPhysician = "",
    recipientPracticeOrgName,
    recipientPractice,
  } = referral;

  return (
    <Grid>
      <Grid container direction="row" wrap="nowrap">
        {referral.type === "SENT" && (
          <React.Fragment>
            <Typography
              variant="body2"
              noWrap={true}
              className={classes.cardContentFont}
            >
              {recipientPracticeOrgName || recipientPractice?.orgName}
            </Typography>
            {referralRecipientPhysician && (
              <Typography
                className={classnames(
                  classes.nameHyphen,
                  classes.cardContentFont
                )}
                noWrap={true}
                variant="body2"
              >
                {"-"}
              </Typography>
            )}
            <Typography
              variant="body2"
              noWrap={true}
              className={classes.cardContentFont}
            >
              {referralRecipientPhysician}
            </Typography>
          </React.Fragment>
        )}
        {referral.type === "RECEIVED" && (
          <React.Fragment>
            <Typography
              variant="body2"
              noWrap={true}
              className={classes.cardContentFont}
            >
              {senderPractice}
            </Typography>
            {senderName && (
              <Typography
                className={classnames(
                  classes.nameHyphen,
                  classes.cardContentFont
                )}
                noWrap={true}
                variant="body2"
              >
                {"-"}
              </Typography>
            )}
            <Typography
              variant="body2"
              noWrap={true}
              className={classes.cardContentFont}
            >
              {senderName}
            </Typography>
          </React.Fragment>
        )}
      </Grid>
      {consultDate && isValid(new Date(consultDate)) && (
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={0}
        >
          <Typography variant="body2" noWrap={true}>
            Consult date: {`${format(new Date(consultDate), "MM/dd/yyyy")}`}
          </Typography>
        </Grid>
      )}
      <Grid container direction="row">
        <Typography
          variant="button"
          style={_typeColorFromType(referral.specialty, typeOptions)}
        >
          {referral.specialty}
        </Typography>
      </Grid>
    </Grid>
  );
}

export function generateCardActions(
  referral,
  classes,
  refLink,
  userId,
  clientNetwork
) {
  let refProps = {};
  let initials;
  const referenceUrns =
    referral?.recipientPracticeProviderReferenceUrns ||
    referral?.recipientPractice?.referenceUrns;

  const senderProviderNetworks =
    referral?.senderProviderNetworks || referral?.senderProvider?.networks;

  const receivedReferenceUrn =
    referral.recipientPracticeProviderReferenceUrns ||
    referral?.recipientPractice?.referenceUrns;

  if (referral.assigneeUserId || referral?.assignee) {
    const name = `${
      referral.assigneeFirstName || referral?.assignee?.firstName
    } ${referral.assigneeLastName || referral?.assignee?.lastName}`;
    initials = name.match(/\b\w/g) || [];
    initials = (
      (initials.shift() || "") + (initials.pop() || "")
    ).toUpperCase();
  }

  if (refLink) {
    refProps = {
      component: Link,
      to: `/referrals/${referral.type.toLowerCase()}/${referral.id}`,
      onClick: _cancelParentEvent,
      onContextMenu: _cancelParentEvent,
    };
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="flex-end"
      wrap="nowrap"
      spacing={0}
    >
      <Grid container direction="row">
        {referral.priority === "Stat" && (
          <Grid
            container
            item
            direction="row"
            alignItems="flex-end"
            wrap="nowrap"
            className={classes.urgencyIndicator}
          >
            <Tooltip title="Stat">
              <div id="urgent_wrapper" className={classes.wrapper}>
                <Warning id="stat" className={classes.urgent}></Warning>
              </div>
            </Tooltip>
          </Grid>
        )}
        {referral.priority === "Urgent" && (
          <Grid
            container
            item
            direction="row"
            alignItems="flex-end"
            wrap="nowrap"
            className={classes.urgencyIndicator}
          >
            <Tooltip title="Urgent">
              <div id="urgent_wrapper" className={classes.wrapper}>
                <ArrowUpward
                  id="urgent"
                  className={classes.urgent}
                ></ArrowUpward>
              </div>
            </Tooltip>
          </Grid>
        )}
        {(!referral.priority || referral.priority === "Routine") && (
          <Grid
            container
            item
            direction="row"
            alignItems="flex-end"
            wrap="nowrap"
            className={classes.urgencyIndicator}
          >
            <Tooltip title="Routine">
              <div id="routine_wrapper" className={classes.wrapper}>
                <ArrowDownward
                  id="routine"
                  className={classes.routine}
                ></ArrowDownward>
              </div>
            </Tooltip>
          </Grid>
        )}
        {referral.type === "SENT" && referenceUrns ? (
          newDetermineIfOnline(referenceUrns) ? (
            <Grid
              container
              item
              direction="row"
              alignItems="flex-end"
              wrap="nowrap"
              className={classes.urgencyIndicator}
            >
              <Tooltip title="Online">
                <div id="online_wrapper" className={classes.wrapper}>
                  <CloudQueue
                    id="online"
                    className={classes.routine}
                  ></CloudQueue>
                </div>
              </Tooltip>
            </Grid>
          ) : (
            <Grid
              container
              item
              direction="row"
              alignItems="flex-end"
              wrap="nowrap"
              className={classes.urgencyIndicator}
            >
              <Tooltip title="Offline">
                <div id="offline_wrapper" className={classes.wrapper}>
                  <CloudOff id="offline" className={classes.urgent}></CloudOff>
                </div>
              </Tooltip>
            </Grid>
          )
        ) : null}
        {referral.type === "SENT" && senderProviderNetworks ? (
          newDetermineIfInNetwork(senderProviderNetworks, clientNetwork) ? (
            <Grid
              container
              item
              direction="row"
              alignItems="flex-end"
              wrap="nowrap"
              className={classes.urgencyIndicator}
            >
              <Tooltip title="In Network">
                <div id="in_network_wrapper" className={classes.wrapper}>
                  <WifiTethering id="in_network" className={classes.routine} />
                </div>
              </Tooltip>
            </Grid>
          ) : (
            <Grid
              container
              item
              direction="row"
              alignItems="flex-end"
              wrap="nowrap"
              className={classes.urgencyIndicator}
            >
              <Tooltip title="Out of Network">
                <div id="out_of_network_wrapper" className={classes.wrapper}>
                  <PortableWifiOff
                    id="out_of_network"
                    className={classes.urgent}
                  ></PortableWifiOff>
                </div>
              </Tooltip>
            </Grid>
          )
        ) : null}
        {referral.type === "RECEIVED" ? (
          newDetermineIfOnline(receivedReferenceUrn) ? (
            <Grid
              container
              item
              direction="row"
              alignItems="flex-end"
              wrap="nowrap"
              className={classes.urgencyIndicator}
            >
              <Tooltip title="Online">
                <div id="online_wrapper" className={classes.wrapper}>
                  <CloudQueue
                    id="online"
                    className={classes.routine}
                  ></CloudQueue>
                </div>
              </Tooltip>
            </Grid>
          ) : (
            <Grid
              container
              item
              direction="row"
              alignItems="flex-end"
              wrap="nowrap"
              className={classes.urgencyIndicator}
            >
              <Tooltip title="Offline">
                <div id="offline_wrapper" className={classes.wrapper}>
                  <CloudOff id="offline" className={classes.urgent}></CloudOff>
                </div>
              </Tooltip>
            </Grid>
          )
        ) : null}
        {referral.type === "RECEIVED" ? (
          newDetermineIfInNetwork(senderProviderNetworks, clientNetwork) ? (
            <Grid
              container
              item
              direction="row"
              alignItems="flex-end"
              wrap="nowrap"
              className={classes.urgencyIndicator}
            >
              <Tooltip title="In Network">
                <div id="in_network_wrapper" className={classes.wrapper}>
                  <WifiTethering
                    id="in_network"
                    className={classes.routine}
                  ></WifiTethering>
                </div>
              </Tooltip>
            </Grid>
          ) : (
            <Grid
              container
              item
              direction="row"
              alignItems="flex-end"
              wrap="nowrap"
              className={classes.urgencyIndicator}
            >
              <Tooltip title="Out of Network">
                <div id="out_of_network_wrapper" className={classes.wrapper}>
                  <PortableWifiOff
                    id="out_of_network"
                    className={classes.urgent}
                  ></PortableWifiOff>
                </div>
              </Tooltip>
            </Grid>
          )
        ) : null}
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        wrap="nowrap"
        spacing={0}
      >
        <Typography className={classes.refLink} {...refProps} variant="caption">
          {`REF-${referral?.displayableId}`}
        </Typography>
        {userId !== null && (referral.assigneeUserId || referral?.assignee) && (
          <Tooltip
            title={`Assignee: ${
              referral.assigneeFirstName || referral?.assignee?.firstName
            } ${referral.assigneeLastName || referral?.assignee?.lastName}`}
          >
            <Avatar
              className={
                referral?.assignee?.userId === userId
                  ? classes.loggedInUser
                  : classes.notLoggedUser
              }
            >
              <span className={classes.avatarText}>{initials}</span>
            </Avatar>
          </Tooltip>
        )}
      </Grid>
    </Grid>
  );
}

export function generatePatientDisplayName(patient = {}) {
  if (patient.lastName && patient.firstName) {
    return `${patient.lastName}, ${patient.firstName}`;
  } else if (patient.lastName) {
    return patient.lastName;
  } else if (patient.firstName) {
    return patient.firstName;
  }
  return "Patient Not Selected";
}

export function generateCardHeader(referral, classes) {
  const { startTime, flagged, status, patient, patientSnapshot } = referral;

  const getCardDate = (referralDate) => {
    const today = {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      day: new Date().getDate(),
    };

    const referral = {
      year: new Date(referralDate).getFullYear(),
      month: new Date(referralDate).getMonth() + 1,
      day: new Date(referralDate).getDate(),
    };

    const referralTime = `${format(new Date(referralDate), "h:mm a")}`;

    const referralFormattedDate = `${format(new Date(referralDate), "M/d/y")}`;

    if (today.year === referral.year) {
      if (today.month === referral.month) {
        if (today.day === referral.day) {
          return `at ${referralTime}`;
        }
      }
    }
    return `on ${referralFormattedDate}`;
  };

  const lastName = patient?.lastName || patientSnapshot?.lastName;
  const firstName = patient?.firstName || patientSnapshot?.firstName;
  const patientDob = patient?.dob || patientSnapshot?.dob;

  return (
    <Grid container direction="column" alignItems="flex-start" spacing={0}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        wrap="nowrap"
        alignItems="center"
        spacing={0}
      >
        <Typography variant="subtitle2" noWrap={true}>
          {lastName}, {firstName}
        </Typography>
        <Typography variant="caption">
          {isValid(parseISO(patientDob))
            ? format(parseISO(patientDob), "MM/dd/yyyy")
            : patientDob}
        </Typography>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={0}
      >
        <Typography variant="caption">{`Created ${getCardDate(
          startTime
        )}`}</Typography>
        {flagged && (
          <div id="flagged_wrapper" className={classes.wrapper}>
            <Flag id="flagged" className={classes.flag}></Flag>
          </div>
        )}
        {status === "Send Failed" && (
          <Grid
            container
            item
            direction="row"
            alignItems="flex-end"
            wrap="nowrap"
            className={classes.urgencyIndicator}
          >
            <Tooltip title="Fax Failed">
              <div id="printdisabled_wrapper" className={classes.wrapper}>
                <PrintDisabled
                  id="printdisabled"
                  className={classes.printDisabled}
                />
              </div>
            </Tooltip>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

function _cancelParentEvent(event) {
  event.stopPropagation();
}

export class TaskCard extends React.Component {
  static contextType = AppSettingContext;
  render() {
    const {
      referral,
      typeOptions,
      openCardMenu,
      index,
      classes,
      userId,
      removeInspectedReferral,
    } = this.props;

    const { primaryNetworkId } = this.context;

    const refProps = {
      to: `/referrals/${referral.type.toLowerCase()}/${referral.id}`,
      onClick: (event) => {
        _cancelParentEvent(event);
        removeInspectedReferral();
      },
      onContextMenu: _cancelParentEvent,
    };

    return (
      <Draggable
        id={`draggable_${referral.displayableId}`}
        key={referral.id}
        draggableId={referral.id}
        index={index}
        isDragDisabled={
          !referral.actionable && determineIfOnline(referral.recipientPractice)
        }
      >
        {(provided, snapshot) => (
          <Link
            id={`wrapper_${referral.displayableId}`}
            ref={provided.innerRef}
            {...refProps}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={classes.draggable}
            onContextMenu={(e) => {
              e.preventDefault();
              openCardMenu(
                e.currentTarget,
                referral.id,
                referral.displayableId,
                referral.flagged,
                referral.type
              );
            }}
          >
            <Card
              id={`card_${referral.displayablId}`}
              data-testid={"card-wrapper"}
              className={classnames(
                referral.status !== "Send Failed"
                  ? classes.card
                  : classes.cardFailed,
                {
                  [classes.dragging]: snapshot.isDragging,
                }
              )}
            >
              <Grid
                container
                direction="column"
                justifyContent="space-between"
                className={classes.cardContainer}
              >
                {generateCardHeader(referral, classes)}
                {generateCardContent(referral, typeOptions, classes)}
                {generateCardActions(
                  referral,
                  classes,
                  true,
                  userId,
                  primaryNetworkId
                )}
              </Grid>
            </Card>
          </Link>
        )}
      </Draggable>
    );
  }
}
