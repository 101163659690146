import { applyMiddleware, compose, createStore } from "redux";
import createRootReducer from "./reducer";
import createRootSaga from "./sagas";
import createSagaMiddleware from "redux-saga";
import rollbar from "rollbar";
import rollbarMiddleware from "rollbar-redux-middleware";
import { OPEN } from "./ducks/cardMenu";

const sagaMiddleware = createSagaMiddleware();

const Rollbar = new rollbar({
  accessToken: "fcdd7674a6cf4423a39dd31656f2483d",
  captureUncaught: true,
  captureUnhandledRejections: true,
  logLevel: "info",
});
window.Rollbar = Rollbar;

const itemsToRedactFromRollbar = [
  "board",
  "createDialog.createReferralTemplate",
  "createPatientDialog.createPatientTemplate",
  "form.formValues.patient",
  "patients",
  "quickFilterPopover.typeOptions",
  "quickFilterPopover.documentTypeOptions",
  "referralsReceived.list.patient",
  "referralsSent.list.patient",
];

const rollbarRedux = rollbarMiddleware(Rollbar, itemsToRedactFromRollbar);

export default function configureStore(preloadedState) {
  const actionSanitizer = (action) =>
    action.type === OPEN && action.cardMenu
      ? { ...action, cardMenu: `<<${action.cardMenu.id}>>` }
      : action;
  const stateSanitizer = (state) =>
    state.cardMenu && state.cardMenu.cardMenu
      ? {
          ...state,
          cardMenu: {
            ...state.cardMenu,
            cardMenu: `<<${state.cardMenu.cardMenu.id}>>`,
          },
        }
      : state;

  const composeEnhancers =
    process.env.REACT_APP_BASENAME === "/" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          actionSanitizer,
          stateSanitizer,
          trace: true,
          traceLimit: 25,
        })
      : compose;
  const store = createStore(
    createRootReducer(),
    preloadedState,
    composeEnhancers(applyMiddleware(sagaMiddleware, rollbarRedux))
  );

  sagaMiddleware.run(createRootSaga);

  return store;
}
