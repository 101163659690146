import { useLazyQuery } from "@apollo/client";
import { GET_REFERRAL } from "../queries/referral";
import { getSentReferral } from "../state/ducks/referralsSent";
import { getReceivedReferral } from "../state/ducks/referralsReceived";
import { useDispatch, useSelector } from "react-redux";

export const useFetchReferral = () => {
  const clientId = useSelector((state) => state.auth.clientId);
  const [fetchReferral, { loading: loadingReferral }] =
    useLazyQuery(GET_REFERRAL);
  const dispatch = useDispatch();

  const handleFetchReferral = async (
    referralId,
    options = { inspectReferral: true }
  ) => {
    const { data: referralData } = await fetchReferral({
      variables: {
        clientId,
        processInstanceId: referralId,
      },
      skip: !referralId,
    });

    if (referralData?.referral?.id) {
      if (referralData?.referral?.type === "SENT") {
        dispatch(getSentReferral(referralData?.referral, options));
      } else {
        dispatch(getReceivedReferral(referralData?.referral, options));
      }
    }
  };

  return { handleFetchReferral, loadingReferral };
};

export default useFetchReferral;
