import { TaskCard, styles } from "./TaskCard";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { openCardMenu } from "../../state/ducks/cardMenu";
import { openCardDetailModal } from "../../state/ducks/cardDetailModal";
import { removeInspectedReferral } from "../../state/ducks/referralInspect";

export function _findSpecialtyOptions(createReferralTemplate) {
  const specialtyField = createReferralTemplate.fields.find(
    (field) => field.name === "Specialty"
  );

  if (specialtyField) {
    return specialtyField.selectOptions;
  }

  return null;
}

function mapStateToProps(state) {
  return {
    typeOptions:
      _findSpecialtyOptions(state.createDialog.createReferralTemplate) || [],
    userId: state.auth.userId,
  };
}

export default connect(mapStateToProps, {
  openCardMenu,
  openCardDetailModal,
  removeInspectedReferral,
})(withStyles(styles)(TaskCard));
