// Actions
const NO_OP = "hermes/docSubscriptions/NO_OP";
const UPDATE_DOC_SUBS = "hermes/docSubscriptions/UPDATE_DOC_SUBS";
const RESUBSCRIBE = "hermes/docSubscriptions/RESUBSCRIBE";

const initialState = {
  subList: [],
  refreshTime: new Date().getTime().toString(),
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_DOC_SUBS:
      return {
        ...state,
        subList: [...action.subList],
      };
    case NO_OP:
      return {
        ...state,
      };
    case RESUBSCRIBE:
      return {
        ...state,
        refreshTime: action.refreshTime,
      };
    default:
      return state;
  }
}

export function updateSubscription(subData, currentList) {
  if (subData.subscriptionType === "DOCUMENT_CHANGED") {
    if (subData.action === "subscribed") {
      const subbedDoc = currentList.find(
        (item) => subData.subscribedId === item.document
      );
      if (!subbedDoc) {
        currentList.push({
          document: subData.subscribedId,
          users: [subData.userId],
        });
      } else {
        const index = currentList.findIndex(
          (item) => item.document === subbedDoc.document
        );
        if (index > -1) {
          currentList[index] = {
            document: subbedDoc.document,
            users: [...currentList[index].users, subData.userId],
          };
        }
      }
      return {
        type: UPDATE_DOC_SUBS,
        subList: currentList,
      };
    } else if (subData.action === "unsubscribed") {
      const subbedDoc = currentList.find(
        (item) => subData.subscribedId === item.document
      );
      if (subbedDoc) {
        const index = currentList.findIndex(
          (item) => item.document === subbedDoc.document
        );
        if (subbedDoc.users.length > 1) {
          const userIndex = subbedDoc.users.findIndex(
            (user) => user === subData.userId
          );
          currentList[index].users.splice(userIndex, 1);
        } else {
          currentList.splice(index, 1);
        }
      }
      return {
        type: UPDATE_DOC_SUBS,
        subList: currentList,
      };
    }
  }

  if (subData.subscriptionType === "SERVICE_REQUEST_CHANGED") {
    if (subData.action === "subscribed") {
      const subbedServiceRequest = currentList.find(
        (item) => subData.subscribedId === item.serviceRequest
      );
      if (!subbedServiceRequest) {
        currentList.push({
          serviceRequest: subData.subscribedId,
          users: [subData.userId],
        });
      } else {
        const index = currentList.findIndex(
          (item) => item.serviceRequest === subbedServiceRequest.serviceRequest
        );
        if (index > -1) {
          currentList[index] = {
            serviceRequest: subbedServiceRequest.serviceRequest,
            users: [...currentList[index].users, subData.userId],
          };
        }
      }
      return {
        type: UPDATE_DOC_SUBS,
        subList: currentList,
      };
    }

    if (subData.action === "unsubscribed") {
      const subbedServiceRequest = currentList.find(
        (item) => subData.subscribedId === item.serviceRequest
      );
      if (subbedServiceRequest) {
        const index = currentList.findIndex(
          (item) => item.serviceRequest === subbedServiceRequest.serviceRequest
        );
        if (subbedServiceRequest.users.length > 1) {
          const userIndex = subbedServiceRequest.users.findIndex(
            (user) => user === subData.userId
          );
          currentList[index].users.splice(userIndex, 1);
        } else {
          currentList.splice(index, 1);
        }
      }
      return {
        type: UPDATE_DOC_SUBS,
        subList: currentList,
      };
    }
  }

  if (
    subData.subscriptionType === "USER_SUBSCRIPTIONS" &&
    subData.action === "subscribed"
  ) {
    return {
      type: RESUBSCRIBE,
      refreshTime: new Date().getTime().toString(),
    };
  }

  return {
    type: NO_OP,
  };
}
