import { gql } from "@apollo/client";

const USERS_SUBSCRIPTION = gql`
  subscription UserSubscribed($clientId: Int) {
    userSubscriptions(clientId: $clientId) {
      userId
      subscriptionType
      subscribedId
      action
    }
  }
`;

export default USERS_SUBSCRIPTION;
