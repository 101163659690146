// Actions
const OPEN = "hermes/m-detail/OPEN";
const OPEN_DOCUMENT_DETAIL = "hermes/m-detail/OPEN_DOCUMENT_DETAIL";
const CLOSE = "hermes/m-detail/CLOSE";
const CLOSE_DOCUMENT_DETAIL = "hermes/m-detail/CLOSE_DOCUMENT_DETAIL";

const initialState = {
  open: false,
  readOnlyMode: false,
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case OPEN:
      return {
        ...state,
        open: true,
        readOnlyMode: action.readOnlyMode,
      };
    case CLOSE:
      return {
        ...state,
        open: false,
        readOnlyMode: false,
      };
    case OPEN_DOCUMENT_DETAIL:
      return {
        ...state,
        openDocumentDetail: true,
      };
    case CLOSE_DOCUMENT_DETAIL:
      return {
        ...state,
        openDocumentDetail: false,
      };
    default:
      return state;
  }
}

// Action Creators
export function openDetail(readOnlyMode = false) {
  return {
    type: OPEN,
    readOnlyMode,
  };
}

export function openDocumentDetail() {
  return {
    type: OPEN_DOCUMENT_DETAIL,
  };
}

export function closeDetail() {
  return {
    type: CLOSE,
  };
}

export function closeDocumentDetail() {
  return {
    type: CLOSE_DOCUMENT_DETAIL,
  };
}
