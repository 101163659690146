import { gql } from "@apollo/client";

export const providerFieldsFragment = gql`
  fragment ProviderFields on Provider {
    id
    networkId
    networks
    type
    referenceId
    referenceUrns
    orgName
    prefixName
    firstName
    lastName
    workPhone
    faxPhone
    address {
      street
      city
      state
      zip
    }
    specialties
  }
`;
