import { call, put, takeEvery } from "redux-saga/effects";
import { getTenantServices } from "../../services/TenantServicesService";
import { getAuthToken } from "./auth";

// Actions
const GET_TENANT_SERVICES = "hermes/services/GET_TENANT_SERVICES";
const GET_TENANT_SERVICES_SUCCESS =
  "hermes/services/GET_TENANT_SERVICES_SUCCESS";
const GET_TENANT_SERVICES_FAILURE =
  "hermes/services/GET_TENANT_SERVICES_FAILURE";

const initialState = {
  loading: false,
  services: [],
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_TENANT_SERVICES:
      return {
        ...state,
        loading: true,
      };
    case GET_TENANT_SERVICES_SUCCESS:
      return {
        ...state,
        loading: false,
        services: action.services,
      };
    case GET_TENANT_SERVICES_FAILURE:
      return {
        ...state,
        loading: false,
        services: [],
      };
    default:
      return state;
  }
}

// Action Creators
export function getServices(clientId, searchTerm) {
  return {
    type: GET_TENANT_SERVICES,
    clientId,
    searchTerm,
  };
}

export function clearServices() {
  return {
    type: GET_TENANT_SERVICES_SUCCESS,
    services: [],
  };
}

// Sagas
function* handleGetServices({ clientId, searchTerm }) {
  try {
    const authToken = yield call(getAuthToken);
    const response = yield call(
      getTenantServices,
      authToken,
      clientId,
      searchTerm
    );
    yield put({ type: GET_TENANT_SERVICES_SUCCESS, services: response.data });
  } catch (e) {
    console.log(e);
    yield put({ type: GET_TENANT_SERVICES_FAILURE });
  }
}

export function* handleGetServicesSaga() {
  yield takeEvery(GET_TENANT_SERVICES, handleGetServices);
}
