import React from "react";
import { Grid, Typography, withTheme } from "@material-ui/core";
import WorkList from "../../components/WorkList";
import HomeActivityStream from "../../components/HomeActivityStreamer/HomeActivityStreamer";
import { AppSettingContext } from "../../context";
import { ClientMode } from "../../constants";

export const styles = {};

class Home extends React.Component {
  static contextType = AppSettingContext;

  componentDidMount() {
    this.props.setCreateButton(null);
    const logoutCallback = localStorage.getItem("logout_callback");
    localStorage.removeItem("logout_callback");
    if (logoutCallback) {
      window.location.assign(logoutCallback);
    }
  }
  render() {
    const { userFirstName, userId, authenticated, errorMessage } = this.props;
    const { clientMode } = this.context;
    const captureMode = clientMode === ClientMode.CAPTURE;
    return (
      <div>
        <Grid container direction="row" wrap="nowrap">
          <Grid container direction="column">
            {!userFirstName && (
              <>
                <Typography
                  variant="h4"
                  id="main_title"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Proficient Health Referral Management
                </Typography>
                <Typography
                  variant="h6"
                  id="login_message"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 100,
                  }}
                >
                  Please click Login button below to proceed.
                </Typography>
              </>
            )}
            {userFirstName && (
              <Typography
                variant="h4"
                color="primary"
                id="referral_dashboard_title"
              >
                {userFirstName}'s Referral Dashboard
              </Typography>
            )}
            <Grid container justifyContent="space-between" direction="row">
              {!captureMode && authenticated && (
                <>
                  <WorkList userId={userId} />
                  <HomeActivityStream />
                </>
              )}
              {captureMode && authenticated && <HomeActivityStream />}
              {!authenticated && errorMessage && (
                <Typography variant="h5" color="error" id="main_error">
                  {errorMessage}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const themedHome = withTheme(Home);
export { themedHome as Home };
