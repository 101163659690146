import React from "react";
import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import App from "./js/App";
import dotenv from "dotenv";
import "core-js";
import { unregister } from "./serviceWorker";

dotenv.config();

unregister();

render(
  <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <App />
    </MuiPickersUtilsProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
