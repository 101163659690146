import { getAuthToken, getClientId } from "./auth";
import { put, call, takeLatest } from "redux-saga/effects";
import { enqueueSnackbar } from "./notifiers";
import { searchTenantDidService } from "../../services/TenantDidService";
import { getDirectAddressesForClient } from "../../services/DirectAddressService.js";

// Actions
const SETTINGS_DIALOG_OPEN = "hermes/settings-dialog/SETTINGS_DIALOG_OPEN";
const SET_ABOUT_DIALOG_OPEN = "hermes/settings-dialog/ABOUT_DIALOG_OPEN";
const SET_ABOUT_DIALOG_CLOSE = "hermes/settings-dialog/ABOUT_DIALOG_CLOSE";
const SETTINGS_DIALOG_OPEN_SUCCESS =
  "hermes/settings-dialog/SETTINGS_DIALOG_OPEN_SUCCESS";
const SETTINGS_DIALOG_CLOSE = "hermes/settings-dialog/SETTINGS_DIALOG_CLOSE";

const initialState = {
  settingsDialogOpen: false,
  settingsDialog: null,
  inboundFaxNumberList: [],
  directAddressList: [],
  aboutDialogOpen: false,
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SETTINGS_DIALOG_OPEN:
      return {
        ...state,
        settingsDialogOpen: true,
      };
    case SETTINGS_DIALOG_OPEN_SUCCESS:
      return {
        ...state,
        settingsDialogOpen: true,
        user: action.user,
        inboundFaxNumberList: action.inboundFaxNumberList,
        directAddressList: action.directAddressList,
      };
    case SETTINGS_DIALOG_CLOSE:
      return {
        ...state,
        settingsDialogOpen: false,
        settingsDialog: null,
      };
    case SET_ABOUT_DIALOG_OPEN:
      return {
        ...state,
        aboutDialogOpen: true,
      };
    case SET_ABOUT_DIALOG_CLOSE:
      return {
        ...state,
        aboutDialogOpen: false,
      };
    default:
      return state;
  }
}

// Action Creators
export function openSettingsDialog() {
  return {
    type: SETTINGS_DIALOG_OPEN,
  };
}

export function closeSettingsDialog() {
  return {
    type: SETTINGS_DIALOG_CLOSE,
  };
}

export function openAboutDialog() {
  return {
    type: SET_ABOUT_DIALOG_OPEN,
  };
}

export function closeAboutDialog() {
  return {
    type: SET_ABOUT_DIALOG_CLOSE,
  };
}

// Saga handlers
function* handleOpenSettingsDialog(action) {
  try {
    const authToken = yield call(getAuthToken);
    const clientId = yield call(getClientId);
    const inboundFaxNumberList = yield call(
      getInboundFaxNumbers,
      authToken,
      clientId
    );
    const directAddressList = yield call(
      getDirectAddresses,
      authToken,
      clientId
    );
    yield put({
      inboundFaxNumberList,
      directAddressList,
      type: SETTINGS_DIALOG_OPEN_SUCCESS,
    });
  } catch (e) {
    yield put(
      enqueueSnackbar({
        message: `Unable to fetch settings information`,
        options: {
          variant: "error",
        },
      })
    );
  }
}

function* getInboundFaxNumbers(authToken, clientId) {
  let response = [];
  try {
    const unmappedOnly = false;
    response = yield call(
      searchTenantDidService,
      authToken,
      clientId,
      unmappedOnly
    );
  } catch (e) {
    console.error("settingsDialog getInboundFaxNumbers caught exception", e);
  }
  return response;
}

function* getDirectAddresses(authToken, clientId) {
  let response = [];
  try {
    response = yield call(getDirectAddressesForClient, authToken, clientId);
  } catch (e) {
    console.error("settingsDialog getDirectAddresses caught exception", e);
  }
  return response;
}

// Sagas
export function* openSettingsDialogSaga() {
  yield takeLatest(SETTINGS_DIALOG_OPEN, handleOpenSettingsDialog);
}
