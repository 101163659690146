import React from "react";
import {
  ListItem,
  ListItemAvatar,
  Avatar,
  Grid,
  Typography,
  Link,
} from "@mui/material";
import { format } from "date-fns";
import { indigo } from "@material-ui/core/colors";
import {
  activityUserLabel,
  avatarUserName,
  getReferralUrl,
  isSelfUser,
} from "../../../utils";

const CommentActivityItem = ({ activity, selfId }) => (
  <ListItem key={activity.id}>
    <ListItemAvatar>
      <Avatar
        sx={
          isSelfUser ? { color: "#fff", backgroundColor: indigo["A700"] } : {}
        }
      >
        {avatarUserName(activity)}
      </Avatar>
    </ListItemAvatar>
    <Grid container>
      <Grid item xs={12}>
        <Typography>{`"${activity?.fields?.description || ""}"`}</Typography>
        <Typography variant="body2" color="textSecondary">
          {`${activityUserLabel(activity, selfId)} 
          said on ${format(
            new Date(activity?.fields?.eventTime),
            "MMM d, y h:mm a"
          )}`}
          {activity?.fields?.objectItem && (
            <>
              {" on "}
              <Link
                role={"link"}
                to={`${getReferralUrl(activity?.fields?.objectItem)}`}
              >
                {activity?.fields?.objectItem.name || ""}
              </Link>
            </>
          )}
        </Typography>
      </Grid>
    </Grid>
  </ListItem>
);

export default CommentActivityItem;
