import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    formControl: {
      width: "100%",
      "& .MuiSelect-select:focus": {
        backgroundColor: "transparent",
      },
    },
    select: {
      height: "38px",
    },
    inputLabel: {
      color: "#828282",
    },
  };
});

export { useStyles };
