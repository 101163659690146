// Actions
const SERVICE_REQUEST_DOCUMENT_EXPORTED =
  "hermes/reports/SERVICE_REQUEST_DOCUMENT_EXPORTED";

const SET_SELECTED_SERVICE_REQUEST_DOCUMENTS =
  "hermes/reports/SET_SELECTED_SERVICE_REQUEST_DOCUMENTS";

const COMPLETE_SERVICE_REQUEST_DOCUMENT_EXPORTED =
  "hermes/reports/COMPLETE_SERVICE_REQUEST_DOCUMENT_EXPORTED";

const SET_SELECTED_DOCUMENT_ID = "hermes/reports/SET_SELECTED_DOCUMENT_ID";

const UPDATE_SERVICE_REQUEST_DOCUMENT_FILE =
  "hermes/reports/UPDATE_SERVICE_REQUEST_DOCUMENT_FILE";

const initialState = {
  exportedDocuments: [],
  serviceRequestDocumentFiles: [],
  selectedDocumentId: null,
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SERVICE_REQUEST_DOCUMENT_EXPORTED: {
      let { uuid } = action;
      let exportedDocuments = [...state.exportedDocuments];
      if (!exportedDocuments.includes(uuid)) {
        exportedDocuments.push(uuid);
      }
      return {
        ...state,
        exportedDocuments,
      };
    }
    case COMPLETE_SERVICE_REQUEST_DOCUMENT_EXPORTED:
      return {
        ...state,
        exportedDocuments: state?.exportedDocuments.filter(
          (item) => item !== action?.uuid
        ),
      };
    case SET_SELECTED_SERVICE_REQUEST_DOCUMENTS:
      return {
        ...state,
        serviceRequestDocumentFiles: action.serviceRequestDocumentFiles,
      };
    case SET_SELECTED_DOCUMENT_ID:
      return {
        ...state,
        selectedDocumentId: action.selectedDocumentId,
      };
    case UPDATE_SERVICE_REQUEST_DOCUMENT_FILE: {
      let files = [...state.serviceRequestDocumentFiles];
      let targetDocumentIndex = files.findIndex(
        (doc) => doc.uuid === action.documentFile?.uuid
      );
      files[targetDocumentIndex] = action.documentFile;

      return {
        ...state,
        serviceRequestDocumentFiles: files,
      };
    }
    default:
      return state;
  }
}

// Action Creators

export function documentExported(uuid) {
  return {
    type: SERVICE_REQUEST_DOCUMENT_EXPORTED,
    uuid,
  };
}

export function completeDocumentExported(uuid) {
  return {
    type: COMPLETE_SERVICE_REQUEST_DOCUMENT_EXPORTED,
    uuid,
  };
}

export function setSelectedServiceRequestDocuments(
  serviceRequestDocumentFiles
) {
  return {
    type: SET_SELECTED_SERVICE_REQUEST_DOCUMENTS,
    serviceRequestDocumentFiles,
  };
}

export function setSelectedDocumentId(selectedDocumentId) {
  return {
    type: SET_SELECTED_DOCUMENT_ID,
    selectedDocumentId,
  };
}

export function updateServiceRequestDocumentFiles(documentFile) {
  return {
    type: UPDATE_SERVICE_REQUEST_DOCUMENT_FILE,
    documentFile,
  };
}
