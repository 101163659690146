import React from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";

import { useStyles } from "./styles";
import clsx from "clsx";

const RadioButton = ({
  required,
  label,
  options,
  className,
  onChange,
  radioOption,
  horizontalAlign,
}) => {
  const classes = useStyles();

  return (
    <FormControl component="fieldset" className={clsx(className)}>
      <FormLabel component="legend">
        {label && <span className={classes.formLabelText}>{label}</span>}
        {required && <span className={classes.formLabelRequired}>*</span>}
      </FormLabel>
      <RadioGroup
        required
        aria-label="option"
        name="closeOptions"
        value={radioOption}
        onChange={onChange}
        className={`${
          horizontalAlign ? classes.horizontalAlign : classes.radioGroupMargins
        }`}
      >
        {options.map((option, key) => {
          return (
            <FormControlLabel
              key={key}
              classes={{ label: classes.radioLabel }}
              value={option.id}
              control={
                <Radio
                  className={classes.radioMinPadding}
                  color="primary"
                  id={option.buttonId}
                />
              }
              label={option.label}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioButton;
