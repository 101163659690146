import { put, call, takeEvery } from "redux-saga/effects";
import { searchProviders } from "../../services/DirectoryService";
import { getAuthToken, getClientId } from "./auth";
import _ from "lodash";

const GET_CLIENT_STAFF = "hermes/staff/GET_CLIENT_STAFF";
const GET_PROVIDER_STAFF = "hermes/staff/GET_PROVIDER_STAFF";
const GET_SCHEDULING_PROVIDER_STAFF =
  "hermes/staff/GET_SCHEDULING_PROVIDER_STAFF";
const GET_SCHEDULING_PROVIDER_STAFF_SUCCESS =
  "hermes/staff/GET_SCHEDULING_PROVIDER_STAFF_SUCCESS";
const GET_SCHEDULING_PROVIDER_STAFF_FAILURE =
  "hermes/staff/GET_SCHEDULING_PROVIDER_STAFF_FAILURE";
const CLEAR_PROVIDER_STAFF = "hermes/staff/CLEAR_PROVIDER_STAFF";
const GET_CLIENT_STAFF_SUCCESS = "hermes/staff/GET_CLIENT_STAFF_SUCCESS";
const GET_CLIENT_STAFF_NEW_SUCCESS =
  "hermes/staff/GET_CLIENT_STAFF_NEW_SUCCESS";
const GET_CLIENT_STAFF_FAILURE = "hermes/staff/GET_CLIENT_STAFF_FAILURE";
const GET_PROVIDER_STAFF_SUCCESS = "hermes/staff/GET_PROVIDER_STAFF_SUCCESS";
const GET_PROVIDER_STAFF_FULL_OBJ_SUCCESS =
  "hermes/staff/GET_PROVIDER_STAFF_FULL_OBJ_SUCCESS";
const GET_SCHEDULING_PROVIDER_STAFF_FULL_OBJ_SUCCESS =
  "hermes/staff/GET_SCHEDULING_PROVIDER_STAFF_FULL_OBJ_SUCCESS";
const GET_PROVIDER_STAFF_FAILURE = "hermes/staff/GET_PROVIDER_STAFF_FAILURE";

// Reducer
export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case GET_CLIENT_STAFF_SUCCESS:
      return {
        ...state,
        senderStaff: action.staff,
      };
    case GET_CLIENT_STAFF_NEW_SUCCESS:
      return {
        ...state,
        senderStaffNew: action.staff,
      };
    case GET_PROVIDER_STAFF_SUCCESS:
      return {
        ...state,
        providerStaff: action.providerStaff,
      };
    case GET_PROVIDER_STAFF_FULL_OBJ_SUCCESS:
      return {
        ...state,
        providerStaffFullObj: action.providerStaffFullObj,
      };
    case GET_SCHEDULING_PROVIDER_STAFF_SUCCESS:
      return {
        ...state,
        schedulingProviderStaff: action.providerStaff,
      };
    case GET_SCHEDULING_PROVIDER_STAFF_FULL_OBJ_SUCCESS:
      return {
        ...state,
        schedulingProviderStaffFullObj: action.providerStaffFullObj,
      };
    case CLEAR_PROVIDER_STAFF:
      return {
        ...state,
        providerStaff: undefined,
      };
    default:
      return state;
  }
}

// Action Creators
export function retrieveClientStaff(
  staffId,
  networkIds,
  specialty,
  searchTerm,
  orgName,
  urn
) {
  return {
    type: GET_CLIENT_STAFF,
    staffId,
    networkIds,
    searchTerm,
    orgName,
    urn,
  };
}

export function retrieveProviderStaff(
  staffId,
  networkIds,
  specialty,
  searchTerm,
  orgName,
  urn
) {
  return {
    type: GET_PROVIDER_STAFF,
    staffId,
    networkIds,
    searchTerm,
    orgName,
    specialty,
    urn,
  };
}
export function retrieveSchedulingProviderStaff(
  staffId,
  networkIds,
  specialty,
  searchTerm,
  orgName,
  urn
) {
  return {
    type: GET_SCHEDULING_PROVIDER_STAFF,
    staffId,
    networkIds,
    searchTerm,
    orgName,
    specialty,
    urn,
  };
}

export function clearProviderStaff() {
  return {
    type: CLEAR_PROVIDER_STAFF,
  };
}

export function setProviderStaff(staff) {
  return {
    type: GET_CLIENT_STAFF_NEW_SUCCESS,
    staff: staff,
  };
}

// Sagas
function* handleGetClientStaff(action) {
  try {
    let bearerToken = yield call(getAuthToken);
    let clientId = yield call(getClientId);

    let response = "";
    const providers = yield call(
      searchProviders,
      bearerToken,
      clientId,
      action.networkIds,
      undefined,
      action.searchTerm,
      "Provider",
      action.orgName,
      null,
      null,
      action.urn
    );
    let staff = [];
    let staff2 = [];
    if (providers) {
      staff = providers.map((p) =>
        `${p.prefixName} ${p.firstName} ${p.lastName}`.replace(/undefined/g, "")
      );
      staff2 = providers.map((p) => {
        return {
          label: `${p.prefixName} ${p.firstName} ${p.lastName}`.replace(
            /undefined/g,
            ""
          ),
          obj: p,
        };
      });
      response = staff.map((s) => {
        return { value: s, label: s };
      });
    }
    yield put({ staff: staff2, type: GET_CLIENT_STAFF_NEW_SUCCESS });
    yield put({ staff: response, type: GET_CLIENT_STAFF_SUCCESS });
  } catch (e) {
    let errorLogging = {};
    if (!_.includes(e.message, "401")) {
      errorLogging = { error: true, payload: e };
    }
    yield put({ type: GET_CLIENT_STAFF_FAILURE, ...errorLogging });
  }
}

// Sagas
function* handleGetProviderStaff(action) {
  try {
    let bearerToken = yield call(getAuthToken);
    let clientId = yield call(getClientId);

    let response = "";
    let response2 = "";
    const providers = yield call(
      searchProviders,
      bearerToken,
      clientId,
      action.networkIds,
      action.specialty,
      action.searchTerm,
      "Provider",
      action.orgName,
      null,
      null,
      action.urn
    );
    if (providers) {
      //Currently with 2 separate forms it's necessary to save both the full provider object and an object with just the label.
      //Duplicated to minimize changes outside of the feature flag.
      const staff = providers.map((p) =>
        `${p.prefixName} ${p.firstName} ${p.lastName}`.replace(/undefined/g, "")
      );
      const staffFullObj = providers.map((p) => ({
        ...p,
        name: `${p.prefixName} ${p.firstName} ${p.lastName}`.replace(
          /undefined/g,
          ""
        ),
      }));

      response = staff.map((s) => {
        return { value: s, label: s };
      });
      response2 = staffFullObj.map((s) => {
        return { value: s, label: s.name };
      });
    }

    yield put({ providerStaff: response, type: GET_PROVIDER_STAFF_SUCCESS });
    yield put({
      providerStaffFullObj: response2,
      type: GET_PROVIDER_STAFF_FULL_OBJ_SUCCESS,
    });
  } catch (e) {
    let errorLogging = {};
    if (!_.includes(e.message, "401")) {
      errorLogging = { error: true, payload: e };
    }
    yield put({ type: GET_PROVIDER_STAFF_FAILURE, ...errorLogging });
  }
}

function* handleGetSchedulingProviderStaff(action) {
  try {
    let bearerToken = yield call(getAuthToken);
    let clientId = yield call(getClientId);

    let response = "";
    let response2 = "";
    const providers = yield call(
      searchProviders,
      bearerToken,
      clientId,
      action.networkIds,
      action.specialty,
      action.searchTerm,
      "Provider",
      action.orgName,
      null,
      null,
      action.urn
    );
    if (providers) {
      //Currently with 2 separate forms it's necessary to save both the full provider object and an object with just the label.
      //Duplicated to minimize changes outside of the feature flag.
      const staff = providers.map((p) =>
        `${p.prefixName} ${p.firstName} ${p.lastName}`.replace(/undefined/g, "")
      );
      const staffFullObj = providers.map((p) => ({
        ...p,
        name: `${p.prefixName} ${p.firstName} ${p.lastName}`.replace(
          /undefined/g,
          ""
        ),
      }));

      response = staff.map((s) => {
        return { value: s, label: s };
      });
      response2 = staffFullObj.map((s) => {
        return { value: s, label: s.name };
      });
    }

    yield put({
      providerStaff: response,
      type: GET_SCHEDULING_PROVIDER_STAFF_SUCCESS,
    });
    yield put({
      providerStaffFullObj: response2,
      type: GET_SCHEDULING_PROVIDER_STAFF_FULL_OBJ_SUCCESS,
    });
  } catch (e) {
    let errorLogging = {};
    if (!_.includes(e.message, "401")) {
      errorLogging = { error: true, payload: e };
    }
    yield put({ type: GET_SCHEDULING_PROVIDER_STAFF_FAILURE, ...errorLogging });
  }
}

export function* getClientStaffSaga() {
  yield takeEvery(GET_CLIENT_STAFF, handleGetClientStaff);
}

export function* getProviderStaffSaga() {
  yield takeEvery(GET_PROVIDER_STAFF, handleGetProviderStaff);
}
export function* getSchedulingProviderStaffSaga() {
  yield takeEvery(
    GET_SCHEDULING_PROVIDER_STAFF,
    handleGetSchedulingProviderStaff
  );
}
