import { gql } from "@apollo/client";

export const UPDATE_CLIENT_NOTIFICATION = gql`
  mutation Mutation($clientId: Int!, $clientConfig: ClientConfigInput!) {
    updateClientConfig(clientId: $clientId, clientConfig: $clientConfig) {
      uiConfig {
        notifications {
          referrals {
            comments
            documentsAdded
            flagged
            unflagged
          }
        }
      }
    }
  }
`;

export const UPDATE_USER_VIEW = gql`
  mutation setUserView($view: UserViewEnum!) {
    updateUserViewTime(view: $view)
  }
`;

export const MARK_READ = gql`
  mutation markRead($clientId: Int!, $activitystreamId: String!) {
    markNotificationRead(
      clientId: $clientId
      activitystreamId: $activitystreamId
    ) {
      activitystreamId
      readTime
      userId
    }
  }
`;

export const MARK_UNREAD = gql`
  mutation markUnread($clientId: Int!, $activitystreamId: String!) {
    markNotificationUnread(
      clientId: $clientId
      activitystreamId: $activitystreamId
    )
  }
`;
