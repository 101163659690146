// Actions
const SET_REFERRAL_INSPECT = "hermes/referral-inspect/SET_REFERRAL_INSPECT";
const REMOVE_REFERRAL_INSPECT =
  "hermes/referral-inspect/REMOVE_REFERRAL_INSPECT";

const initialState = {
  target: null,
  loaded: false,
};
// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_REFERRAL_INSPECT:
      return {
        ...state,
        target: action.newState
          ? { ...action.referralInspect, status: action.newState }
          : action.referralInspect,
        loaded: true,
      };
    case REMOVE_REFERRAL_INSPECT:
      return {
        ...state,
        target: null,
        loaded: false,
      };
    default:
      return state;
  }
}

// Action Creators
export function setInspectedReferral(referralInspect, newState) {
  return {
    type: SET_REFERRAL_INSPECT,
    referralInspect,
    newState,
  };
}

export function removeInspectedReferral() {
  return {
    type: REMOVE_REFERRAL_INSPECT,
  };
}
