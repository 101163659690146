const GET_PATIENT_POOLS_SUCCESS = "hermes/users/GET_PATIENT_POOLS_SUCCESS";
const GET_PATIENT_POOLS_FAILURE = "hermes/users/GET_PATIENT_POOLS_FAILURE";
const SET_CURRENT_PATIENT_POOL = "hermes/users/SET_CURRENT_PATIENT_POOL";

const initialState = {
  patientPools: [],
  currentPatientsPool: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_PATIENT_POOLS_SUCCESS:
      let pools = action.patientPools;
      return {
        ...state,
        patientPools: pools,
      };
    case SET_CURRENT_PATIENT_POOL:
      return {
        ...state,
        currentPatientsPool: action.currentPatientsPool,
      };
    case GET_PATIENT_POOLS_FAILURE:
      return state;
    default:
      return state;
  }
}

export function setPatientPools(patientPools = []) {
  return {
    type: GET_PATIENT_POOLS_SUCCESS,
    patientPools: patientPools.slice(0, 20),
  };
}

export function setCurrentPatientsPool(patientsPool) {
  return {
    type: SET_CURRENT_PATIENT_POOL,
    currentPatientsPool: patientsPool,
  };
}
