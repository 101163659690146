import React from "react";
import { Button as MaterialButton } from "@material-ui/core";
import { useStyles } from "./styles";

const Button = ({
  id,
  testid,
  buttonLabel,
  type,
  variant,
  icon,
  onClick,
  disabled,
  classname = "",
  ...props
}) => {
  const classes = useStyles();

  const element = {
    primary: (
      <MaterialButton
        id={id}
        data-testid={testid}
        className={`${classes.primary} ${classname}`}
        onClick={onClick}
        type={type}
        disabled={disabled}
        disableRipple={true}
        {...props}
      >
        {buttonLabel} {icon}
      </MaterialButton>
    ),
    secondary: (
      <MaterialButton
        id={id}
        data-testid={testid}
        className={`${classes.secondary} ${classname}`}
        onClick={onClick}
        type={type}
        disabled={disabled}
        {...props}
      >
        {buttonLabel} {icon}
      </MaterialButton>
    ),
  };
  return element[variant];
};

export default Button;
