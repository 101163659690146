import { put, call, takeEvery } from "redux-saga/effects";
import { getAuthToken, getClientId } from "./auth";
import { enqueueSnackbar } from "./notifiers";
import { resendFax } from "../../services/ReferralService";
import { sendFaxWithPages } from "../../services/FaxService";

const RETRY_FAX = "hermes/fax/RETRY_FAX";
const SEND_FAX_DOCUMENT = "hermes/fax/SEND_FAX_DOCUMENT";
const OPEN_FAX_MODAL = "hermes/fax/OPEN_FAX_MODAL";
const CLOSE_FAX_MODAL = "hermes/fax/CLOSE_FAX_MODAL";

const ENABLE_SEND_BUTTON = "hermes/fax/ENABLE_SEND_BUTTON";
const DISABLE_SEND_BUTTON = "hermes/fax/DISABLE_SEND_BUTTON";

const initialState = {
  faxModalOpen: false,
  disabledSendButton: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case OPEN_FAX_MODAL:
      return {
        ...state,
        faxModalOpen: true,
      };
    case CLOSE_FAX_MODAL:
      return {
        ...state,
        faxModalOpen: false,
      };
    case ENABLE_SEND_BUTTON:
      return {
        ...state,
        disabledSendButton: false,
      };
    case DISABLE_SEND_BUTTON:
      return {
        ...state,
        disabledSendButton: true,
      };
    case RETRY_FAX:
      return state;
    default:
      return state;
  }
}

export function openSendFaxModal() {
  return {
    type: OPEN_FAX_MODAL,
  };
}

export function closeSendFaxModal() {
  return {
    type: CLOSE_FAX_MODAL,
  };
}

export function enableSendButton() {
  return {
    type: ENABLE_SEND_BUTTON,
  };
}

export function disableSendButton() {
  return {
    type: DISABLE_SEND_BUTTON,
  };
}

// Action Creators
export function retryFax(processInstanceId) {
  return {
    type: RETRY_FAX,
    processInstanceId,
  };
}

export function sendFaxDocument(docId, sendFaxParams, sendFaxDocumentResource) {
  return {
    type: SEND_FAX_DOCUMENT,
    docId,
    sendFaxParams,
    sendFaxDocumentResource,
  };
}

function* handleRetryFax(action) {
  try {
    let authToken = yield call(getAuthToken);
    let clientId = yield call(getClientId);

    yield call(resendFax, authToken, clientId, action.processInstanceId);

    yield put(
      enqueueSnackbar({
        message: `Fax successfully sent!`,
        options: {
          variant: "success",
        },
      })
    );
    yield put({
      type: CLOSE_FAX_MODAL,
    });
  } catch (e) {
    yield put(
      enqueueSnackbar({
        message: `Failed to resend fax`,
        options: {
          variant: "error",
        },
      })
    );
  }
  yield put({
    type: ENABLE_SEND_BUTTON,
  });
}

function* handleSendFaxDocument({
  docId,
  sendFaxParams,
  sendFaxDocumentResource,
}) {
  try {
    let authToken = yield call(getAuthToken);
    let clientId = yield call(getClientId);

    yield call(
      sendFaxWithPages,
      clientId,
      authToken,
      docId,
      sendFaxParams,
      sendFaxDocumentResource
    );

    yield put(
      enqueueSnackbar({
        message: `Fax successfully sent!`,
        options: {
          variant: "success",
        },
      })
    );
    yield put({
      type: CLOSE_FAX_MODAL,
    });
  } catch (e) {
    yield put(
      enqueueSnackbar({
        message: `Failed to send fax`,
        options: {
          variant: "error",
        },
      })
    );
  }
  yield put({
    type: ENABLE_SEND_BUTTON,
  });
}

export function* retryFaxSaga() {
  yield takeEvery(RETRY_FAX, handleRetryFax);
}

export function* sendFaxDocumentSaga() {
  yield takeEvery(SEND_FAX_DOCUMENT, handleSendFaxDocument);
}
