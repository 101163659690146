import axios from "axios";

const reflect = (p) =>
  p.then(
    (v) => ({ v, status: "fulfilled" }),
    (e) => ({ v: {}, e, status: "rejected" })
  );

export async function getLatestAppVersionNumber() {
  const response = await reflect(
    axios.get(
      `${
        process.env.REACT_APP_BASENAME !== "/"
          ? process.env.REACT_APP_BASENAME
          : ""
      }/version.txt`,
      {
        headers: {
          "Cache-Control": "no-cache",
        },
      }
    )
  );

  if (response.status === "fulfilled" && typeof response.v.data === "string") {
    return response.v.data.trim();
  }
  console.warn(
    "Unable to detect latest version number. We'll assume we're up-to-date."
  );
  return process.env.REACT_APP_VERSION;
}
export async function getCurrentEnvironment() {
  const response = await reflect(
    axios.get(
      `${
        process.env.REACT_APP_BASENAME !== "/"
          ? process.env.REACT_APP_BASENAME
          : ""
      }/environment.txt`,
      {
        headers: {
          "Cache-Control": "no-cache",
        },
      }
    )
  );

  if (response.status === "fulfilled" && typeof response.v.data === "string") {
    return response.v.data.trim();
  }
  console.warn("Unable to detect environment. We'll assume we're local.");
  return "local";
}
