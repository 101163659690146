import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import { Person, Settings } from "@material-ui/icons";
import SettingsDialog from "../SettingsDialog";
import AboutDialog from "../AboutDialog";
import { Link, Redirect, useLocation } from "react-router-dom";
import { withApollo } from "@apollo/client/react/hoc";
import {
  openSettingsDialog,
  openAboutDialog,
} from "../../state/ducks/settingsDialog";
import { signOut } from "../../state/ducks/auth";

const AuthenticatedMenu = (props) => {
  const { pathname } = useLocation();
  const [logout, setLogout] = useState(false);
  const [userPopover, setUserPopover] = useState(null);
  const [callback, setCallback] = useState(null);

  const dispatch = useDispatch();
  const { authenticated, local } = useSelector((state) => state.auth);
  const { settingsDialogOpen } = useSelector((state) => state.settingsDialog);
  useEffect(() => {
    if (pathname === "/" && logout) {
      dispatch(signOut(props.client, callback));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, logout]);

  const open = Boolean(userPopover);
  return logout ? (
    <Redirect push to="/" />
  ) : (
    authenticated && (
      <>
        <Box
          position="absolute"
          bottom="10px"
          left="12px"
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
        >
          <Tooltip title="Settings" arrow placement="top-end">
            <IconButton
              id="navbar_button_settings"
              color="inherit"
              onClick={(event) => {
                dispatch(openSettingsDialog());
              }}
            >
              <Settings />
            </IconButton>
          </Tooltip>
          <IconButton
            aria-owns={open ? "menu-appbar" : undefined}
            aria-haspopup="true"
            id="navbar_button_profile"
            onClick={(event) => {
              setUserPopover(event.currentTarget);
            }}
            color="inherit"
          >
            <Person />
          </IconButton>
        </Box>
        <Menu
          id="menu-appbar"
          anchorEl={userPopover}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={open}
          onClose={() => setUserPopover(null)}
        >
          <MenuItem
            sx={{ backgroundColor: "white", color: "black" }}
            onClick={() => {
              setUserPopover(null);
              setLogout(true);
              setCallback(window.location.origin);
            }}
          >
            Logout
          </MenuItem>
          <MenuItem onClick={() => dispatch(openAboutDialog())}>About</MenuItem>
          {local ? (
            <MenuItem
              component={Link}
              to="/configure-multifactor-login"
              onClick={() => {
                setUserPopover(null);
              }}
            >
              Multi-factor Authentication
            </MenuItem>
          ) : null}
        </Menu>
        {settingsDialogOpen && <SettingsDialog />}
        <AboutDialog />
      </>
    )
  );
};

export default withApollo(AuthenticatedMenu);
