import { combineReducers } from "redux";
import activity from "./ducks/activity";
import auth from "./ducks/auth";
import board from "./ducks/board";
import cardDetailModal from "./ducks/cardDetailModal";
import cardMenu from "./ducks/cardMenu";
import createDialog from "./ducks/createDialog";
import dialogs from "./ducks/dialogs";
import docserver from "./ducks/docserver";
import editDialog from "./ducks/editDialog";
import fax from "./ducks/fax";
import form from "./ducks/form";
import insurancePlans from "./ducks/insurancePlans";
import mDetail from "./ducks/mDetail";
import notifiers from "./ducks/notifiers";
import patients from "./ducks/patients";
import patientSearch from "./ducks/patientSearch";
import pdfDisplay from "./ducks/pdfDisplay";
import quickFilterPopover from "./ducks/quickFilterPopover";
import referralSearch from "./ducks/referralSearch";
import referralInspect from "./ducks/referralInspect";
import referralsReceived from "./ducks/referralsReceived";
import referralsSent from "./ducks/referralsSent";
import serviceRequestsDocs from "./ducks/serviceRequestsDocs";
import sidemenu from "./ducks/sidemenu";
import staff from "./ducks/staff";
import userPopover from "./ducks/userPopover";
import users from "./ducks/users";
import version from "./ducks/version";
import settingsDialog from "./ducks/settingsDialog";
import tenant from "./ducks/tenant";
import totp from "./ducks/totp";
import host from "./ducks/api";
import services from "./ducks/tenantServices";
import errors from "./ducks/errors";
import docSubscriptions from "./ducks/docSubscriptions";

const rootReducer = () =>
  combineReducers({
    activity,
    auth,
    board,
    cardDetailModal,
    cardMenu,
    createDialog,
    dialogs,
    docserver,
    docSubscriptions,
    editDialog,
    fax,
    form,
    insurancePlans,
    mDetail,
    notifiers,
    patients,
    patientSearch,
    pdfDisplay,
    quickFilterPopover,
    referralInspect,
    referralSearch,
    referralsReceived,
    referralsSent,
    serviceRequestsDocs,
    sidemenu,
    totp,
    staff,
    userPopover,
    users,
    version,
    settingsDialog,
    host,
    services,
    errors,
    tenant,
  });

export default rootReducer;
