import {
  getUserTotpConfig,
  getUserTotpSecret,
  verifyUserTotp,
  deleteUserTotp,
} from "../../services/TotpService";
import { getCognitoToken } from "./auth";
import { put, call, takeLatest } from "redux-saga/effects";
import { includes } from "lodash";
import { enqueueSnackbar } from "./notifiers";

// Actions
const GET_TOTP_CONFIG = "hermes/totp/GET_TOTP_CONFIG";
const GET_TOTP_CONFIG_SUCCESS = "hermes/totp/GET_TOTP_CONFIG_SUCCESS";
const GET_TOTP_CONFIG_FAILURE = "hermes/totp/GET_TOTP_CONFIG_FAILURE";
const GET_TOTP_SECRET = "hermes/totp/GET_TOTP_SECRET";
const GET_TOTP_SECRET_SUCCESS = "hermes/totp/GET_TOTP_SECRET_SUCCESS";
const GET_TOTP_SECRET_FAILURE = "hermes/totp/GET_TOTP_SECRET_FAILURE";
const VERIFY_USER_TOTP = "hermes/totp/VERIFY_USER_TOTP";
const VERIFY_USER_TOTP_SUCCESS = "hermes/totp/VERIFY_USER_TOTP_SUCCESS";
const VERIFY_USER_TOTP_FAILURE = "hermes/totp/VERIFY_USER_TOTP_FAILURE";
const DELETE_TOTP = "hermes/totp/DELETE_TOTP";
const DELETE_TOTP_SUCCESS = "hermes/totp/DELETE_TOTP_SUCCESS";
const DELETE_TOTP_FAILURE = "hermes/totp/DELETE_TOTP_FAILURE";
const START_LOAD = "hermes/totp/START_LOAD";

// Reducer
export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case GET_TOTP_CONFIG:
      return {
        ...state,
        reloadConfig: false,
      };
    case GET_TOTP_CONFIG_SUCCESS:
      return {
        ...state,
        config: action.config,
        loading: false,
      };
    case GET_TOTP_SECRET_SUCCESS:
      return {
        ...state,
        secret: action.secret,
        loading: false,
      };
    case VERIFY_USER_TOTP_SUCCESS:
      return {
        ...state,
        reloadConfig: true,
        loading: false,
      };
    case DELETE_TOTP_SUCCESS:
      return {
        ...state,
        secret: undefined,
        reloadConfig: true,
        loading: false,
      };
    case GET_TOTP_CONFIG_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case GET_TOTP_SECRET_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case VERIFY_USER_TOTP_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case DELETE_TOTP_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case START_LOAD:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}

// Action Creators
export function fetchTotpConfig() {
  return {
    type: GET_TOTP_CONFIG,
  };
}

export function fetchTotpSecret() {
  return {
    type: GET_TOTP_SECRET,
  };
}

export function verifyTotp(code) {
  return {
    type: VERIFY_USER_TOTP,
    code,
  };
}

export function deleteTotp(code) {
  return {
    type: DELETE_TOTP,
    code,
  };
}

// Sagas

function* handleGetTotpConfig() {
  try {
    let cognitoToken = yield call(getCognitoToken);
    const config = yield call(getUserTotpConfig, cognitoToken);
    yield put({ type: GET_TOTP_CONFIG_SUCCESS, config });
  } catch (e) {
    let errorLogging = {};
    if (!includes(e.message, "401")) {
      errorLogging = { error: true, payload: e };
    }
    yield put({ type: GET_TOTP_CONFIG_FAILURE, ...errorLogging });
  }
}

function* handleGetTotpSecret() {
  yield put({ type: START_LOAD });
  try {
    let cognitoToken = yield call(getCognitoToken);
    const secret = yield call(getUserTotpSecret, cognitoToken);
    yield put({ type: GET_TOTP_SECRET_SUCCESS, secret });
  } catch (e) {
    let errorLogging = {};
    if (!includes(e.message, "401")) {
      errorLogging = { error: true, payload: e };
    }
    yield put({ type: GET_TOTP_SECRET_FAILURE, ...errorLogging });
  }
}

function* handleVerifyTotp({ code }) {
  yield put({ type: START_LOAD });
  try {
    let cognitoToken = yield call(getCognitoToken);
    yield call(verifyUserTotp, cognitoToken, code);
    yield put({ type: VERIFY_USER_TOTP_SUCCESS });
    yield put(
      enqueueSnackbar({
        message: `MFA enabled!`,
        options: {
          variant: "success",
        },
      })
    );
  } catch (e) {
    let errorLogging = {};
    if (!includes(e.message, "401") || !includes(e.message, "400")) {
      errorLogging = { error: true, payload: e };
    }
    if (includes(e.message, "400")) {
      yield put(
        enqueueSnackbar({
          message: `MFA Code is incorrect. Please try again.`,
          options: {
            variant: "error",
          },
        })
      );
    }
    yield put({ type: VERIFY_USER_TOTP_FAILURE, ...errorLogging });
  }
}

function* handleDeleteTotp({ code }) {
  yield put({ type: START_LOAD });
  try {
    let cognitoToken = yield call(getCognitoToken);
    yield call(verifyUserTotp, cognitoToken, code);
    yield call(deleteUserTotp, cognitoToken);
    yield put({ type: DELETE_TOTP_SUCCESS });
  } catch (e) {
    let errorLogging = {};
    if (!includes(e.message, "401") || !includes(e.message, "400")) {
      errorLogging = { error: true, payload: e };
    }
    if (includes(e.message, "400")) {
      yield put(
        enqueueSnackbar({
          message: `MFA Code is incorrect. Please try again.`,
          options: {
            variant: "error",
          },
        })
      );
    }
    yield put({ type: DELETE_TOTP_FAILURE, ...errorLogging });
  }
}

export function* getTotpConfigSaga() {
  yield takeLatest(GET_TOTP_CONFIG, handleGetTotpConfig);
}

export function* getTotpSecretSaga() {
  yield takeLatest(GET_TOTP_SECRET, handleGetTotpSecret);
}

export function* verifyTotpSaga() {
  yield takeLatest(VERIFY_USER_TOTP, handleVerifyTotp);
}

export function* deleteTotpSaga() {
  yield takeLatest(DELETE_TOTP, handleDeleteTotp);
}
