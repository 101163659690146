// Actions
const SET_MAX_PAGES = "hermes/pdf-display/SET_MAX_PAGES";
const CHANGE_CURRENT_PAGE = "hermes/pdf-display/CHANGE_CURRENT_PAGE";
const SHOW_CONTROLS = "hermes/pdf-display/SHOW_CONTROLS";
const HIDE_CONTROLS = "hermes/pdf-display/HIDE_CONTROLS";
const RESET = "hermes/pdf-display/RESET";
const SET_PDF_FN = "hermes/pdf-display/SET_PDF_FN";
const SET_PDF_SPLIT_FN = "hermes/pdf-display/SET_PDF_SPLIT_FN";
const SET_PDF_SPLIT_PAGE_COUNT = "hermes/pdf-display/SET_PDF_SPLIT_PAGE_COUNT";

const initialState = {
  maxPages: 1,
  currentPage: 1,
  showControls: false,
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SHOW_CONTROLS:
      return {
        ...state,
        showControls: true,
      };
    case HIDE_CONTROLS:
      return {
        ...state,
        showControls: false,
      };
    case SET_MAX_PAGES:
      return {
        ...state,
        maxPages: action.numPages,
      };
    case CHANGE_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.newPage,
      };
    case SET_PDF_FN:
      return {
        ...state,
        pdfTronFn: action.pdfTronFn,
      };
    case SET_PDF_SPLIT_FN:
      return {
        ...state,
        pdfTronSplitFn: action.pdfTronSplitFn,
      };
    case SET_PDF_SPLIT_PAGE_COUNT:
      return {
        ...state,
        pdfTronPageCountFn: action.pdfTronPageCountFn,
      };
    case RESET:
      return initialState;
    default:
      return state;
  }
}

// Action Creators
export function setMaxPages({ numPages }) {
  return {
    type: SET_MAX_PAGES,
    numPages,
  };
}

export function changeCurrentPage(newPage) {
  return {
    type: CHANGE_CURRENT_PAGE,
    newPage,
  };
}

export function showPdfControls() {
  return {
    type: SHOW_CONTROLS,
  };
}

export function hidePdfControls() {
  return {
    type: HIDE_CONTROLS,
  };
}

export function resetPdfDisplayer() {
  return {
    type: RESET,
  };
}

export function setGetPdfTronDocumentFn(pdfTronFn) {
  return {
    type: SET_PDF_FN,
    pdfTronFn,
  };
}

export function setGetPdfTronSplitDocumentFn(pdfTronSplitFn) {
  return {
    type: SET_PDF_SPLIT_FN,
    pdfTronSplitFn,
  };
}

export function setGetPdfTronSplitPageCountFn(pdfTronPageCountFn) {
  return {
    type: SET_PDF_SPLIT_PAGE_COUNT,
    pdfTronPageCountFn,
  };
}
