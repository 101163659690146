import { call, put, takeEvery } from "redux-saga/effects";
import { addDocumentsToReferral } from "../../services/ReferralService";
import {
  updateDocument,
  createPdfFromReferral,
  createReceivedReferralPDF,
} from "../../services/DocServerService";
import { removeDocumentFromReferral } from "../../services/ReferralService";
import { sendDirectMessage } from "../../services/DirectService";
import { getAuthToken, getClientId } from "./auth";
import { addDocuments } from "./form";
import { enqueueSnackbar } from "./notifiers";
import { getSentReferral } from "./referralsSent";
import { getReceivedReferral } from "./referralsReceived";
import { hydrateDialogReferral } from "./editDialog";
import _ from "lodash";
import { getClientDocumentUrl } from "../../components/ActivityStreamer/utils";

// Actions
const SHOW_USER_DOCS = "hermes/docserver/SHOW_USER_DOCS";
const UPLOAD_LOCAL_DOC = "hermes/docserver/UPLOAD_LOCAL_DOC";
const REFRESHED = "hermes/docserver/REFRESHED";
const DOCUMENTS_MOVED_TO_PERM = "hermes/docserver/DOCUMENTS_MOVED_TO_PERM";
const ADD_TO_REFERRAL = "hermes/docserver/ADD_TO_REFERRAL";
const ADD_TO_REFERRAL_SUCCESS = "hermes/docserver/ADD_TO_REFERRAL_SUCCESS";
const ADD_TO_REFERRAL_FAILURE = "hermes/docserver/ADD_TO_REFERRAL_FAILURE";
const REMOVE_FROM_REFERRAL = "hermes/docserver/REMOVE_FROM_REFERRAL";
const SEND_DIRECT_MESSAGE = "hermes/docserver/SEND_DIRECT_MESSAGE";
const SEND_DIRECT_MESSAGE_SUCCESS =
  "hermes/docserver/SEND_DIRECT_MESSAGE_SUCCESS";
const SEND_DIRECT_MESSAGE_FAILURE =
  "hermes/docserver/SEND_DIRECT_MESSAGE_FAILURE";
const OPEN_SPLIT_DIALOG = "hermes/docserver/OPEN_SPLIT_DIALOG";
const OPEN_SPLIT_DIALOG_SUCCESS = "hermes/docserver/OPEN_SPLIT_DIALOG_SUCCESS";
const CLOSE_SPLIT_DIALOG = "hermes/docserver/CLOSE_SPLIT_DIALOG";
const UPDATE_DOCUMENT_META = "hermes/docserver/UPDATE_DOCUMENT_META";
const CREATE_REFERRAL_PDF = "hermes/docserver/CREATE_REFERRAL_PDF";
const CREATE_REFERRAL_PDF_START = "hermes/docserver/CREATE_REFERRAL_PDF_START";
const CREATE_REFERRAL_PDF_FINISH =
  "hermes/docserver/CREATE_REFERRAL_PDF_FINISH";
const CREATE_RECEIVED_REFERRAL_PDF =
  "hermes/docserver/CREATE_RECEIVED_REFERRAL_PDF";
const UPDATE_REFRESH_STATUS = "hermes/docserver/UPDATE_REFRESH_STATUS";
const UPDATE_REFRESH_DOCUMENT_TRIAGE_STATUS =
  "hermes/docserver/UPDATE_REFRESH_DOCUMENT_TRIAGE_STATUS";

export const initialState = {
  loaded: false,
  uploadMode: false,
  openSplitDialog: false,
  refreshNeeded: false,
  error: false,
  docs: [],
  pdfLoading: false,
  refreshNewDocList: false,
  refreshDocumentTriage: false,
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SHOW_USER_DOCS:
      return {
        ...state,
        uploadMode: false,
      };
    case SEND_DIRECT_MESSAGE:
      return {
        ...state,
        processing: true,
      };
    case SEND_DIRECT_MESSAGE_SUCCESS:
      return {
        ...state,
        processing: false,
      };
    case SEND_DIRECT_MESSAGE_FAILURE:
      return {
        ...state,
        processing: false,
      };
    case UPLOAD_LOCAL_DOC:
      return {
        ...state,
        uploadMode: true,
      };
    case ADD_TO_REFERRAL_SUCCESS:
      return {
        ...state,
        refreshNeeded: true,
      };
    case REFRESHED:
      return {
        ...state,
        refreshNeeded: false,
      };
    case OPEN_SPLIT_DIALOG_SUCCESS:
      return {
        ...state,
        openSplitDialog: true,
      };
    case CLOSE_SPLIT_DIALOG:
      return {
        ...state,
        openSplitDialog: false,
      };
    case DOCUMENTS_MOVED_TO_PERM:
      return {
        ...state,
        docs: state.docs.filter(
          (stateDoc) =>
            !action.docList.find(
              (docListDoc) => docListDoc.uuid === stateDoc.uuid
            )
        ),
      };
    case CREATE_REFERRAL_PDF_START:
      return {
        ...state,
        pdfLoading: true,
      };
    case CREATE_REFERRAL_PDF_FINISH:
      return {
        ...state,
        pdfLoading: false,
      };
    case UPDATE_REFRESH_STATUS:
      return {
        ...state,
        refreshNewDocList: action.refreshNewDocList,
      };
    case UPDATE_REFRESH_DOCUMENT_TRIAGE_STATUS:
      return {
        ...state,
        refreshDocumentTriage: action.refreshDocumentTriage,
      };
    default:
      return state;
  }
}

let lastFilters;
// Action Creators
export function getClientDocs(filters = lastFilters) {
  lastFilters = filters;
  return {
    type: SHOW_USER_DOCS,
    filters,
  };
}

export function sentDocViaDirect(doc) {
  return {
    type: SEND_DIRECT_MESSAGE,
    doc,
  };
}

export function addDocsToReferral(
  event,
  docList,
  referral,
  fromDialog,
  refreshNewDocList
) {
  return {
    type: ADD_TO_REFERRAL,
    docList,
    referral,
    fromDialog,
    refreshNewDocList,
  };
}

/*
 * "Save AS" pdftron feature, to save a new document to an existing referral.
 */

export function removeDocFromReferral(doc, referral) {
  return {
    type: REMOVE_FROM_REFERRAL,
    doc,
    referral,
  };
}

export function removeDocumentsFromList(docList) {
  return {
    type: DOCUMENTS_MOVED_TO_PERM,
    docList,
  };
}

export function updateDocumentMeta(doc) {
  return {
    type: UPDATE_DOCUMENT_META,
    doc,
  };
}

export function createPDFReceivedReferral(referral) {
  return {
    type: CREATE_RECEIVED_REFERRAL_PDF,
    referral,
  };
}
export function openSplitDialog(pdfTronOpenFn) {
  return {
    type: OPEN_SPLIT_DIALOG,
    pdfTronOpenFn,
  };
}

export function closeSplitDialog(pdfTronOpenFn) {
  return {
    type: CLOSE_SPLIT_DIALOG,
    pdfTronOpenFn,
  };
}

export function createReferralPdf(referral, sig) {
  return {
    type: CREATE_REFERRAL_PDF,
    referral,
    sig,
  };
}

export function refreshedDocList() {
  return {
    type: REFRESHED,
  };
}

export function newDocListShouldRefresh(shouldRefresh) {
  return {
    type: UPDATE_REFRESH_STATUS,
    refreshNewDocList: shouldRefresh,
  };
}

export function documentTriageShouldRefresh(shouldRefresh) {
  return {
    type: UPDATE_REFRESH_DOCUMENT_TRIAGE_STATUS,
    refreshDocumentTriage: shouldRefresh,
  };
}

// Sagas

function* handleCreateReceivedReferralPDF({ referral }) {
  yield put({ type: CREATE_REFERRAL_PDF_START });
  let response = null;
  try {
    const authToken = yield call(getAuthToken);
    const clientId = yield call(getClientId);
    response = yield call(
      createReceivedReferralPDF,
      authToken,
      clientId,
      referral?.id
    );
  } catch (e) {
    console.log(e);
  }
  if (response) {
    window.location = getClientDocumentUrl(response);
  }
}

function* handleReferralPdf({ referral, sig }) {
  yield put({ type: CREATE_REFERRAL_PDF_START });
  let response;
  try {
    const authToken = yield call(getAuthToken);
    const clientId = yield call(getClientId);
    response = yield call(
      createPdfFromReferral,
      authToken,
      clientId,
      referral?.id,
      sig
    );
  } catch (e) {
    console.log(e);
  }
  if (response) {
    yield put({
      type: ADD_TO_REFERRAL,
      docList: [response],
      referral,
      signingReferral: true,
    });
  }
  yield put({ type: CREATE_REFERRAL_PDF_FINISH });
}

function* handleSendDirectMessage(action) {
  try {
    const authToken = yield call(getAuthToken);
    const clientId = yield call(getClientId);
    yield call(sendDirectMessage, authToken, clientId, action.doc.uuid);
    yield put(
      enqueueSnackbar({
        message: `Sent document ${action.doc.filename} via direct message.`,
        options: {
          variant: "success",
        },
      })
    );
    yield put({ type: SEND_DIRECT_MESSAGE_SUCCESS });
  } catch (e) {
    let errorLogging = {};
    if (!_.includes(e.message, "401")) {
      errorLogging = { error: true, payload: e };
    }
    yield put({ type: SEND_DIRECT_MESSAGE_FAILURE, ...errorLogging });
    yield put(
      enqueueSnackbar({
        message: `Failed to send document ${action.doc.filename} via direct message.`,
        options: {
          variant: "error",
        },
      })
    );
  }
}

// Sagas

function* handleUpdateDocumentMeta({ doc }) {
  try {
    const authToken = yield call(getAuthToken);
    const clientId = yield call(getClientId);
    yield call(updateDocument, authToken, clientId, doc?.uuid, doc);
    yield put(getClientDocs());
    yield put(
      enqueueSnackbar({
        message: `Updated document ${doc?.filename}`,
        options: {
          variant: "success",
        },
      })
    );
  } catch (e) {
    console.log(e);
  }
}

/**
 * The executing function that handles the saga call to DocServerService
 * @param  {Object} action Object defined to access the documentList/document that needs to be uploaded
 */

function* handleAddToReferral(action) {
  try {
    const authToken = yield call(getAuthToken);
    const clientId = yield call(getClientId);

    yield put(removeDocumentsFromList(action.docList));
    yield put(getClientDocs());
    if (action?.referral?.id) {
      yield call(
        addDocumentsToReferral,
        authToken,
        clientId,
        action.referral.id,
        action.docList
      );
    }
    yield put(
      addDocuments(action.docList, action.referral?.id || "newreferral")
    );
    if (action?.referral?.displayableId) {
      yield put(
        enqueueSnackbar({
          message: `Added ${action.docList.length} file${
            action.docList.length > 1 ? "s" : ""
          } to REF-${action.referral.displayableId}!`,
          options: {
            variant: "success",
          },
        })
      );
    }
    /***
      TODO: this code refactor should be handled in EX-3653 after EX-3643
    */
    if (action?.referral?.id) {
      yield put(
        action.referral?.type === "SENT"
          ? getSentReferral(action?.referral?.id, {
              inspectReferral: true,
              cardDetail: action?.fromDialog ? false : !action?.signingReferral,
            })
          : getReceivedReferral(action?.referral?.id, {
              inspectReferral: true,
              cardDetail: action?.fromDialog ? false : !action?.signingReferral,
            })
      );
      yield put(
        hydrateDialogReferral(action?.referral?.id, action?.referral?.type)
      );
    }
    yield put({ type: ADD_TO_REFERRAL_SUCCESS });
    if (action.refreshNewDocList) {
      yield put({ type: UPDATE_REFRESH_STATUS, refreshNewDocList: true });
    }
  } catch (e) {
    yield put(
      enqueueSnackbar({
        message: `Failed to attach file to REF-${action.referral.displayableId}!`,
        options: {
          variant: "error",
        },
      })
    );
    let errorLogging = {};
    if (!_.includes(e.message, "401")) {
      errorLogging = { error: true, payload: e };
    }

    yield put({ type: ADD_TO_REFERRAL_FAILURE, ...errorLogging });
  }
}

function* handleRemoveFromReferral({ doc, referral }) {
  try {
    const authToken = yield call(getAuthToken);
    const clientId = yield call(getClientId);
    yield call(
      removeDocumentFromReferral,
      authToken,
      clientId,
      referral.id,
      doc.uuid
    );
    /***
     TODO: this code refactor should be handled in EX-3669 after EX-3643
     */
    if (referral.type === "SENT") {
      yield put(
        getSentReferral(referral.id, {
          inspectReferral: true,
          cardDetail: false,
        })
      );
    } else if (referral.type === "RECEIVED") {
      yield put(
        getReceivedReferral(referral.id, {
          inspectReferral: true,
          cardDetail: false,
        })
      );
    }
    yield put(hydrateDialogReferral(referral.id, referral.type));
    yield put(
      enqueueSnackbar({
        message: `Removed document ${doc?.filename} from REF-${referral.displayableId}`,
        options: {
          variant: "success",
        },
      })
    );
  } catch (e) {}
}

function* handleOpenSplitDialog(action) {
  try {
    const shouldOpenSplitDialog = yield action.pdfTronOpenFn();
    if (shouldOpenSplitDialog) {
      yield put({ type: OPEN_SPLIT_DIALOG_SUCCESS });
    } else {
      yield put(
        enqueueSnackbar({
          message: `Please select the page(s) first by pressing ctrl-click for each page you would like to split out on the thumbnails view.`,
          options: {
            variant: "error",
          },
        })
      );
    }
  } catch (e) {
    // Should never occur.
    yield put(
      enqueueSnackbar({
        message: `An error occurred while attempting to split.`,
        options: {
          variant: "error",
        },
      })
    );
  }
}

export function* handleReferralPdfSaga() {
  yield takeEvery(CREATE_REFERRAL_PDF, handleReferralPdf);
}

/**
 * The generator function to execute and
 * get the response from the handled function
 * and action.
 *
 */

export function* addToReferralSaga() {
  yield takeEvery(ADD_TO_REFERRAL, handleAddToReferral);
}
export function* removeFromReferralSaga() {
  yield takeEvery(REMOVE_FROM_REFERRAL, handleRemoveFromReferral);
}

export function* sendDirectMessageSaga() {
  yield takeEvery(SEND_DIRECT_MESSAGE, handleSendDirectMessage);
}

export function* openSplitDialogSaga() {
  yield takeEvery(OPEN_SPLIT_DIALOG, handleOpenSplitDialog);
}

export function* handleUpdateDocumentMetaSaga() {
  yield takeEvery(UPDATE_DOCUMENT_META, handleUpdateDocumentMeta);
}

export function* handleCreateReceivedReferralPDFSaga() {
  yield takeEvery(
    CREATE_RECEIVED_REFERRAL_PDF,
    handleCreateReceivedReferralPDF
  );
}
