import { getClientUsers } from "../../services/UserService";
import { getAuthToken, getClientId } from "./auth";
import { put, call, takeLatest } from "redux-saga/effects";
import _ from "lodash";

// Actions
const GET_ALL_USERS = "hermes/users/GET_ALL_USERS";
const GET_ALL_USERS_SUCCESS = "hermes/users/GET_ALL_USERS_SUCCESS";
const GET_ALL_USERS_FAILURE = "hermes/users/GET_ALL_USERS_FAILURE";

// Reducer
export default function reducer(state = { list: [] }, action = {}) {
  switch (action.type) {
    case GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        list: action.users,
      };
    default:
      return state;
  }
}

// Action Creators
export function getAllClientUsers() {
  return {
    type: GET_ALL_USERS,
  };
}

// Sagas

function* handleGetAllClientUsers() {
  try {
    let authToken = yield call(getAuthToken);
    let clientId = yield call(getClientId);
    const users = yield call(getClientUsers, authToken, clientId);
    yield put({ type: GET_ALL_USERS_SUCCESS, users });
  } catch (e) {
    let errorLogging = {};
    if (!_.includes(e.message, "401")) {
      errorLogging = { error: true, payload: e };
    }
    yield put({ type: GET_ALL_USERS_FAILURE, ...errorLogging });
  }
}

export function* getAllClientUsersSaga() {
  yield takeLatest(GET_ALL_USERS, handleGetAllClientUsers);
}
