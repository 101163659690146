import { gql } from "@apollo/client";

export const enableUserMutation = gql`
  mutation EnableUser($clientId: Int!, $user: EnableUserInput!) {
    enableUser(clientId: $clientId, user: $user) {
      userName
    }
  }
`;

export const enableUserBatchMutation = (tenantId, users) => {
  if (users.length <= 0) {
    return enableUserMutation;
  }

  const mutations = users
    .map(
      (user) => `
    user_${user.id}: enableUser(clientId: ${tenantId}, user: {
      firstName: "${user.firstName}",
      lastName: "${user.lastName}",
      email: "${user.email}",
    }) {
      userName
    }
  `
    )
    .join("\n");

  return gql`
    mutation EnableUserBatch {
      ${mutations}
    }
  `;
};

export const disableUserMutation = gql`
  mutation DisableUser($clientId: Int!, $email: String!) {
    disableUser(clientId: $clientId, email: $email) {
      userName
    }
  }
`;

export const disableUserBatchMutation = (tenantId, users) => {
  if (users.length <= 0) {
    return disableUserMutation;
  }

  const mutations = users
    .map(
      (user) => `
    user_${user.id}: disableUser(clientId: ${tenantId}, email: "${user.email}") {
      userName
    }
  `
    )
    .join("\n");

  return gql`
    mutation DisableUserBatch {
      ${mutations}
    }
  `;
};

export const updateUserPreference = gql`
  mutation updateUserPreferences($userPreferences: UserPreferencesInput!) {
    updateUserPreferences(userPreferences: $userPreferences) {
      mainTabs {
        documentTriage {
          savedFilters {
            filterValue
          }
        }
      }
    }
  }
`;
