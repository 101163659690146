import React from "react";
import {
  Avatar,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import { indigo } from "@material-ui/core/colors";
import {
  activityUserLabel,
  avatarUserName,
  getCustomizedSummary,
  getDocumentUrl,
  getPatientName,
  getReferralUrl,
  getRelatedReferral,
  isSelfUser,
  isValidToDisplay,
} from "../../../utils";
import { ACTIVITY_TYPE_NAME } from "../../../constants";
import { Link } from "react-router-dom";
import { format } from "date-fns";

const DefaultActivityItem = ({ activity, selfId }) => (
  <React.Fragment key={`fragment_${activity.id}`}>
    <ListItem key={activity.id}>
      <ListItemAvatar>
        <Avatar
          sx={
            isSelfUser(activity, selfId)
              ? {
                  color: "#fff !important",
                  backgroundColor: `${indigo["A700"]} !important`,
                }
              : {}
          }
        >
          {avatarUserName(activity)}
        </Avatar>
      </ListItemAvatar>
      <Grid container>
        <Grid item xs={12}>
          {Boolean(activity?.fields?.objectItem) && (
            <Typography>
              {`${activityUserLabel(activity, selfId)} ${getCustomizedSummary(
                activity
              )}`}{" "}
              {(activity?.fields?.objectItem?.typeName ===
                ACTIVITY_TYPE_NAME.DOCSERVER_DOCUMENT ||
                activity?.fields?.objectItem?.typeName ===
                  ACTIVITY_TYPE_NAME.RECEIVED_FAX_DOCUMENT) && (
                <Link
                  to={`${getDocumentUrl(
                    activity?.fields?.objectItem,
                    activity?.fields?.clientId
                  )}`}
                  target="_blank"
                  rel="opener"
                >
                  {activity?.fields?.objectItem?.name || ""}
                </Link>
              )}
              {activity?.fields?.objectItem?.typeName ===
                ACTIVITY_TYPE_NAME.SERVICE_REQUEST && (
                <Link
                  to={`/service-requests/${activity?.fields?.clientId}/${activity?.fields?.objectItem?.key}`}
                  target="_blank"
                  rel="opener"
                >
                  {activity?.fields?.objectItem.name || ""}
                </Link>
              )}
              {(activity?.fields?.objectItem?.typeName ===
                ACTIVITY_TYPE_NAME.SENTREFERRAL ||
                activity?.fields?.objectItem?.typeName ===
                  ACTIVITY_TYPE_NAME.RCVDREFERRAL) && (
                <Link to={`${getReferralUrl(activity?.fields?.objectItem)}`}>
                  {activity?.fields?.objectItem.name || ""}{" "}
                </Link>
              )}
              {activity?.fields?.relatedItems &&
              activity?.fields?.relatedItems.length > 0
                ? getRelatedReferral(
                    activity?.fields?.relatedItems,
                    activity?.fields?.clientId
                  )
                : null}
            </Typography>
          )}
          {!activity?.fields?.objectItem && (
            <Typography>{`${activityUserLabel(
              activity,
              selfId
            )} ${activity?.fields?.summary.toLowerCase()}`}</Typography>
          )}
        </Grid>
        <Grid item xs={6}>
          <Typography
            sx={{
              fontSize: "0.875rem",
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              fontWeight: "500",
              lineHeight: "1.57",
              letterSpacing: "0.00714em",
            }}
            variant="subtitle2"
          >
            {getPatientName(activity?.fields)}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            sx={{
              fontSize: "0.875rem",
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              fontWeight: "500",
              lineHeight: "1.57",
              letterSpacing: "0.00714em",
            }}
            color="textSecondary"
            variant="subtitle2"
          >
            {format(new Date(activity?.fields?.eventTime), "MMM d, y h:mm a")}
          </Typography>
        </Grid>
      </Grid>
    </ListItem>
    {activity?.fields?.changedValues && (
      <List key={`changelist_${activity.id}`} component="div" disablePadding>
        {" "}
        {activity?.fields?.changedValues.map((change) => {
          const displayOptions = isValidToDisplay(change.fieldName);
          if (displayOptions) {
            const displayFrom = displayOptions.displayFn
              ? displayOptions.displayFn(change.changedFrom)
              : change.changedFrom;
            const displayTo = displayOptions.displayFn
              ? displayOptions.displayFn(change.changedTo)
              : change.changedTo;
            if (
              (!displayFrom || displayFrom === "") &&
              (!displayTo || displayTo === "")
            ) {
              return null;
            }
            return (
              <ListItem
                id={`${activity.id}_change_${change.fieldName}`}
                key={change.fieldName}
              >
                {displayFrom &&
                  displayFrom !== "" &&
                  displayTo &&
                  displayTo !== "" && (
                    <ListItemText
                      primary={`${displayOptions.displayName} changed from "${displayFrom}" to "${displayTo}".`}
                    ></ListItemText>
                  )}
                {displayFrom &&
                  displayFrom !== "" &&
                  (!displayTo || displayTo === "") && (
                    <ListItemText
                      primary={`${displayOptions.displayName} "${displayFrom}" was removed.`}
                    ></ListItemText>
                  )}
                {(!displayFrom || displayFrom === "") &&
                  displayTo &&
                  displayTo !== "" && (
                    <ListItemText
                      primary={`${displayOptions.displayName} was set to "${displayTo}".`}
                    ></ListItemText>
                  )}
              </ListItem>
            );
          }
          return null;
        })}
      </List>
    )}
  </React.Fragment>
);

export default DefaultActivityItem;
