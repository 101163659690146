import { getAllClientUsers } from "./users";
import jwtDecode from "jwt-decode";
import {
  getClientOrganizationName,
  identifyUser,
} from "../../services/UserService";
import { getCurrentEnvironment } from "../../services/VersionService";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import delay from "@redux-saga/delay-p";
import _ from "lodash";
import queryString from "query-string";
import { logout } from "../../components/Container/auth";

// Actions
const SIGN_IN = "hermes/auth/SIGN_IN";
const SIGN_IN_SUCCESS = "hermes/auth/SIGN_IN_SUCCESS";
const SIGN_IN_FAILURE = "hermes/auth/SIGN_IN_FAILURE";
const SIGN_OUT = "hermes/auth/SIGN_OUT";
const SIGN_OUT_SUCCESS = "hermes/auth/SIGN_OUT_SUCCESS";
const SIGN_OUT_FAILURE = "hermes/auth/SIGN_OUT_FAILURE";
const DETERMINE_AUTH_STATE = "hermes/auth/DETERMINE_AUTH_STATE";
const DETERMINE_AUTH_STATE_SUCCESS = "hermes/auth/DETERMINE_AUTH_STATE_SUCCESS";
const DETERMINE_AUTH_STATE_FAILURE = "hermes/auth/DETERMINE_AUTH_STATE_FAILURE";
const CHECK_AUTH_STATE = "hermes/auth/CHECK_AUTH_STATE";
const GET_CURRENT_ENVIRONMENT = "hermes/auth/GET_CURRENT_ENVIRONMENT";

const initialState = {
  authenticated: false,
  authStateDetermined: false,
};

function setAuthToken(token) {
  sessionStorage.setItem("hermesUIBearerToken", token);
}

function removeAuthToken() {
  sessionStorage.removeItem("hermesUIBearerToken");
}

export async function getAuthToken() {
  return await sessionStorage.getItem("hermesUIBearerToken");
}

export function setCognitoToken(token) {
  sessionStorage.setItem("hermesUICognitoToken", token);
}

export async function getCognitoToken() {
  return await sessionStorage.getItem("hermesUICognitoToken");
}

function setClientId(clientId) {
  sessionStorage.setItem("hermesUIClientId", clientId);
}

function removeClientId() {
  sessionStorage.removeItem("hermesUIClientId");
}

export async function getClientId() {
  return await sessionStorage.getItem("hermesUIClientId");
}

function setUserId(userId) {
  sessionStorage.setItem("hermesUIUserId", userId);
}

function removeUserId() {
  sessionStorage.removeItem("hermesUIUserId");
}

export async function getUserId() {
  return await sessionStorage.getItem("hermesUIUserId");
}

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SIGN_IN_SUCCESS:
      return {
        ...state,
        ...action.userInfo,
        userInitials:
          action.userInfo.firstName &&
          action.userInfo.lastName &&
          `${action.userInfo.firstName.slice(
            0,
            1
          )}${action.userInfo.lastName.slice(0, 1)}`,
        authenticated: true,
        errorMessage: undefined,
        authStateDetermined: true,
      };
    case SIGN_IN_FAILURE:
      return {
        ...state,
        authenticated: false,
        errorMessage: action.errorMessage || "Invalid username or password",
        authStateDetermined: true,
      };
    case SIGN_OUT_SUCCESS:
      return {
        authenticated: false,
        authStateDetermined: true,
      };
    case GET_CURRENT_ENVIRONMENT:
      return {
        ...state,
        environment: action.environment,
      };
    default:
      return state;
  }
}

// Action Creators
export function signIn(bearerToken, optimizely) {
  return {
    type: SIGN_IN,
    bearerToken,
    optimizely,
  };
}

export function signInFailure(errorMessage) {
  return {
    type: SIGN_IN_FAILURE,
    errorMessage,
  };
}

export function signOut(apolloClient, callback) {
  return {
    type: SIGN_OUT,
    apolloClient,
    callback,
  };
}

export function determineIfSignedIn(optimizely) {
  return {
    type: DETERMINE_AUTH_STATE,
    optimizely,
  };
}

export function checkAuthState() {
  return {
    type: CHECK_AUTH_STATE,
  };
}

// Sagas
function* handleSignIn(action) {
  if (action.bearerToken) {
    try {
      yield call(setAuthToken, action.bearerToken);
      let response = yield call(identifyUser, action.bearerToken);
      const exchangeTokenInfo = jwtDecode(action.bearerToken);
      const associatedTenantUsers =
        exchangeTokenInfo?.associatedTenantUsers?.map((tenantUser) => ({
          ...tenantUser,
          ...{
            tenantId: tenantUser?.t ?? tenantUser?.tenantId,
            exchangeUserId: tenantUser?.u ?? tenantUser?.exchangeUserId,
          },
        })) || [];
      let orgName = yield call(
        getClientOrganizationName,
        action.bearerToken,
        response.clientId
      );

      // TODO: Replace this with actual ACL

      yield put({
        userInfo: { ...response, orgName, associatedTenantUsers },
        type: SIGN_IN_SUCCESS,
      });
      yield call(setClientId, response.clientId);
      yield call(setUserId, response.userId);
      const environment = yield call(getCurrentEnvironment);
      yield put({ environment, type: GET_CURRENT_ENVIRONMENT });
      window.Rollbar.configure({
        payload: {
          person: {
            id: response.userId,
            username: response.username,
            clientId: response.clientId,
            userId: response.userId,
            width: window.innerWidth,
            height: window.innerHeight,
          },
          environment,
        },
      });
      action.optimizely &&
        action.optimizely.setUser &&
        action.optimizely.setUser({
          id: response.userId.toString(),
          attributes: {
            username: response.username,
            clientId: response.clientId,
            userId: response.userId,
          },
        });

      yield put(getAllClientUsers());
    } catch (e) {
      let errorLogging = {};
      if (!_.includes(e.message, "401")) {
        errorLogging = { error: true, payload: e };
      }
      yield put({ type: SIGN_IN_FAILURE, ...errorLogging });
      yield call(removeAuthToken);
    }
  } else {
    yield put({ type: SIGN_OUT_SUCCESS });
  }
}

function redirectToHost(logoutUrl) {
  const queryParams = queryString.parse(logoutUrl);
  if (queryParams?.state) {
    const nonce = JSON.parse(window.localStorage.getItem("nonce"));
    const nonceId = queryParams.state.split(",")[0];
    const redirectUrl =
      nonce[nonceId].hostname === "localhost"
        ? "http://localhost:3000/exchange123123/"
        : `https://${nonce[nonceId].hostname}/exchange/`;
    window.location.replace(redirectUrl);
  }
  return undefined;
}

function assignUrl(url) {
  window.location.assign(url);
  return undefined;
}

function* handleSignOut({ apolloClient, callback }) {
  try {
    yield call(removeAuthToken);
    yield call(removeClientId);
    yield call(removeUserId);
    apolloClient?.clearStore();
    window.Rollbar.configure({
      payload: {
        person: {},
      },
    });
    const logoutUrl = yield call(logout, callback);
    yield call(assignUrl(logoutUrl));
    yield delay(50);
    yield put({ type: SIGN_OUT_SUCCESS });
    yield call(redirectToHost(logoutUrl));
  } catch (e) {
    let errorLogging = {};
    if (!_.includes(e.message, "401")) {
      errorLogging = { error: true, payload: e };
    }
    yield put({ type: SIGN_OUT_FAILURE, ...errorLogging });
  }
}

function* handleDetermineAuthState(action) {
  try {
    const authToken = yield call(getAuthToken);

    const environment = yield call(getCurrentEnvironment);
    yield put({ environment, type: GET_CURRENT_ENVIRONMENT });
    yield put(signIn(authToken, action.optimizely));
    yield put({ type: DETERMINE_AUTH_STATE_SUCCESS });
  } catch (e) {
    let errorLogging = {};
    if (!_.includes(e.message, "401")) {
      errorLogging = { error: true, payload: e };
    }
    yield put({ type: DETERMINE_AUTH_STATE_FAILURE, ...errorLogging });
  }
}

function* handleCheckAuthState(action) {
  try {
    let authToken = yield call(getAuthToken);
    yield call(identifyUser, authToken);
  } catch (e) {
    yield put(signOut());
  }
}

export function* signInSaga() {
  yield takeEvery(SIGN_IN, handleSignIn);
}

export function* signOutSaga() {
  yield takeEvery(SIGN_OUT, handleSignOut);
}

export function* determineAuthStateSaga() {
  yield takeEvery(DETERMINE_AUTH_STATE, handleDetermineAuthState);
}

export function* checkAuthStateSaga() {
  yield takeLatest(CHECK_AUTH_STATE, handleCheckAuthState);
}
