// Actions
const OPEN_DIALOG = "hermes/dialogs/OPEN_DIALOG";
const CLOSE_DIALOG = "hermes/dialogs/CLOSE_DIALOG";

export const initialState = {
  scheduling: false,
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case OPEN_DIALOG:
      return {
        ...state,
        [action.dialogToOpen]: {
          open: true,
          referral: action.referral,
          action: action.action,
        },
      };
    case CLOSE_DIALOG:
      return {
        ...state,
        [action.dialogToClose]: false,
      };
    default:
      return state;
  }
}

// Action Creators
export function openDialog(dialogToOpen, referral, action) {
  return {
    type: OPEN_DIALOG,
    dialogToOpen,
    referral,
    action,
  };
}

export function closeDialog(dialogToClose) {
  return {
    type: CLOSE_DIALOG,
    dialogToClose,
  };
}
