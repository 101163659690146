import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useCallback,
} from "react";
import { useSelector } from "react-redux";
import { GET_CLIENT_NOTFICATION_CONFIG } from "../queries/clientConfig";
import { GET_USER_VIEWS } from "../queries/users";
import { useQuery } from "@apollo/client";

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const clientId = useSelector((state) => state.auth.clientId);

  const { data } = useQuery(GET_CLIENT_NOTFICATION_CONFIG, {
    variables: { clientId },
    skip: !clientId,
    fetchPolicy: "cache-and-network",
  });

  const { data: userView, refetch: userViewRefetch } = useQuery(
    GET_USER_VIEWS,
    {
      fetchPolicy: "cache-and-network",
      skip: !clientId,
    }
  );

  const isNotficationEnabled = useMemo(
    () =>
      data?.clientConfig?.uiConfig?.notifications?.referrals?.comments ||
      data?.clientConfig?.uiConfig?.notifications?.referrals?.flagged ||
      data?.clientConfig?.uiConfig?.notifications?.referrals?.unflagged ||
      data?.clientConfig?.uiConfig?.notifications?.referrals?.documentsAdded,
    [data]
  );

  const notificationLastViewed = useMemo(() => {
    if (!isNotficationEnabled || !userView) return undefined;
    return userView.userViewTimes;
  }, [userView, isNotficationEnabled]);

  const handlePanelOpen = useCallback(() => {
    setIsPanelOpen(true);
  }, [setIsPanelOpen]);

  const handlePanelClose = useCallback(() => {
    setIsPanelOpen(false);
  }, [setIsPanelOpen]);

  return (
    <NotificationContext.Provider
      value={{
        isPanelOpen,
        handlePanelClose,
        handlePanelOpen,
        isNotficationEnabled,
        notificationLastViewed,
        userViewRefetch,
        notficationSetting:
          data?.clientConfig?.uiConfig?.notifications?.referrals,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (context === undefined) {
    return new Error("Export config context is undefined");
  }
  return context;
};
