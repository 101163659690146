import { format, endOfDay } from "date-fns";
import moment from "moment";

export function getSpecificDateRange(
  daysAgo = 30,
  identifierName = "createDate"
) {
  const today = endOfDay(new Date());
  let monthAgo = new Date().setDate(today.getDate() - daysAgo);
  monthAgo = new Date(monthAgo);
  monthAgo.setHours(0, 0, 0, 0);
  return {
    identifier: identifierName,
    dateMin: monthAgo.toISOString(),
    dateMax: today.toISOString(),
  };
}

export function formatDate(date) {
  return date
    ? new Date(date).toLocaleDateString("en-US", { timeZone: "UTC" })
    : "";
}

export function formatDateTime(date) {
  return date
    ? new Date(date).toLocaleString("en-US", { timeZone: "UTC" })
    : "";
}

export function formatDateSecondary(date) {
  const utcDate = new Date(date).toLocaleDateString("en-US", {
    timeZone: "UTC",
  });
  return date ? format(new Date(utcDate), "yyyy-MM-dd") : null;
}

export function isDateError(date) {
  const minDate = new Date("1900-1-1");
  const maxDate = new Date("2099-12-31");
  const parsedDate = new Date(date);

  if (isNaN(parsedDate.getTime())) {
    return "Date is not valid";
  } else if (parsedDate < minDate) {
    return "Date should not be before minimal date";
  } else if (parsedDate > maxDate) {
    return "Date should not be after maximum date";
  } else {
    return false;
  }
}

// Default date filter - Past 90 days
export function getCurrentDateDefaultFilter(days = 90) {
  const today = new Date();
  let monthAgo = new Date().setDate(today.getDate() - days);
  monthAgo = new Date(monthAgo);
  monthAgo.setHours(0, 0, 0, 0);

  return {
    dateMin: new Date(
      moment(monthAgo).clone().startOf("day").format("YYYY-MM-DDTHH:mm:ss")
    ).toISOString(),
    dateMax: new Date(
      moment(today).clone().endOf("day").format("YYYY-MM-DDTHH:mm:ss")
    ).toISOString(),
  };
}

export function setDateToUTCNoon(strDate) {
  return new Date(new Date(strDate).setUTCHours(12, 0, 0, 0)).toISOString();
}
