// Actions
const OPEN = "hermes/quick-filter-popover/OPEN";
const APPLY_FILTER = "hermes/quick-filter-popover/APPLY_FILTER";
const APPLY_FILTERS_FROM_SENT_URL =
  "hermes/quick-filter-popover/APPLY_FILTERS_FROM_SENT_URL";
const APPLY_FILTERS_FROM_RECEIVED_URL =
  "hermes/quick-filter-popover/APPLY_FILTERS_FROM_RECEIVED_URL";
const SAVE_SENT_ROUTE_WITH_FILTERS =
  "hermes/quick-filter-popover/SAVE_SENT_ROUTE_WITH_FILTERS";
const SAVE_RECEIVED_ROUTE_WITH_FILTERS =
  "hermes/quick-filter-popover/SAVE_RECEIVED_ROUTE_WITH_FILTERS";
const REMOVE_FILTER = "hermes/quick-filter-popover/REMOVE_FILTER";
const APPLY_DOCUMENT_FILTER =
  "hermes/quick-filter-popover/APPLY_DOCUMENT_FILTER";
const REMOVE_DOCUMENT_FILTER =
  "hermes/quick-filter-popover/REMOVE_DOCUMENT_FILTER";
const CLOSE = "hermes/quick-filter-popover/CLOSE";
const CLEAR_ALL = "hermes/quick-filter-popover/CLEAR_ALL";
const CLEAR_ALL_BOARD = "hermes/quick-filter-popover/CLEAR_ALL_BOARD";
const CLEAR_ALL_DOCUMENTS = "hermes/quick-filter-popover/CLEAR_ALL_DOCUMENTS";
const APPLY_ASSIGNEE_FILTER =
  "hermes/quick-filter-popover/APPLY_ASSIGNEE_FILTER";
const REMOVE_ASSIGNEE_FILTER =
  "hermes/quick-filter-popover/REMOVE_ASSIGNEE_FILTER";
const APPLY_ORDER_FILTER = "hermes/quick-filter-popover/APPLY_ORDER_FILTER";
const APPLY_REF_ASSOCIATION_FILTER =
  "hermes/quick-filter-popover/APPLY_REF_ASSOCIATION_FILTER";
const APPLY_PRIORITY_FILTER =
  "hermes/quick-filter-popover/APPLY_PRIORITY_FILTER";
const APPLY_LOCATION_FILTER =
  "hermes/quick-filter-popover/APPLY_LOCATION_FILTER";
const APPLY_REQUESTED_LOCATION_FILTER =
  "hermes/quick-filter-popover/APPLY_REQUESTED_LOCATION_FILTER";
const APPLY_SCHEDULED_LOCATION_FILTER =
  "hermes/quick-filter-popover/APPLY_SCHEDULED_LOCATION_FILTER";
const REMOVE_ORDER_FILTER = "hermes/quick-filter-popover/REMOVE_ORDER_FILTER";
const APPLY_SOURCE_CHANNEL_FILTER =
  "hermes/quick-filter-popover/APPLY_SOURCE_CHANNEL_FILTER";
const REMOVE_SOURCE_CHANNEL_FILTER =
  "hermes/quick-filter-popover/REMOVE_SOURCE_CHANNEL_FILTER";
const APPLY_CREATE_DATE_FILTER =
  "hermes/quick-filter-popover/APPLY_CREATE_DATE_FILTER";
const REMOVE_CREATE_DATE_FILTER =
  "hermes/quick-filter-popover/REMOVE_CREATE_DATE_FILTER";
const APPLY_ASSIGNEE_TAG_DOCUMENT =
  "hermes/quick-filter-popover/APPLY_ASSIGNEE_TAG_DOCUMENT";
const REMOVE_ASSIGNEE_TAG_DOCUMENT =
  "hermes/quick-filter-popover/REMOVE_ASSIGNEE_TAG_DOCUMENT";

const APPLY_CONSULT_DATE_FILTER =
  "hermes/quick-filter-popover/APPLY_CONSULT_DATE_FILTER";

const initialState = {
  quickFilterPopover: null,
  activeFilters: [],
  ordersFilters: [
    {
      orderId: "desc",
      filterLabel: "Most Recent",
    },
    {
      orderId: "asc",
      filterLabel: "Least Recent",
    },
  ],
  typeOptions: [
    {
      name: "Flagged",
      filterLabel: "Flagged Only",
    },
    {
      name: "Online",
      filterLabel: "Online Only",
    },
    {
      name: "Offline",
      filterLabel: "Offline Only",
    },
  ],
  activeDocumentFilters: [],
  documentTypeOptions: [
    {
      name: "My documents",
      filterLabel: "My documents",
    },
  ],
  orderId: "desc",
  sourceChannel: "",
  createDateFilter: null,
  assigneeId: NaN,
  referralAssociation: "all",
  priority: "any",
  location: "any",
  requestedLocation: "any",
  scheduledLocation: "any",
  consultDate: "any",
  sentRoute: undefined,
  receivedRoute: undefined,
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case OPEN:
      return {
        ...state,
        quickFilterPopover: action.quickFilterPopover,
      };
    case APPLY_FILTER:
      return {
        ...state,
        activeFilters: [...state.activeFilters, action.filterName],
      };
    case REMOVE_FILTER:
      return {
        ...state,
        activeFilters: state.activeFilters.filter(
          (filter) => filter !== action.filterName
        ),
      };
    case APPLY_FILTERS_FROM_SENT_URL:
      return {
        ...state,
        assigneeId: action.assigneeId,
        orderId: action.orderId,
        referralAssociation: action.referralAssociation,
        priority: action.priority,
        location: action.location,
        consultDate: action.consultDate,
        activeFilters: [...action.activeFilters],
      };
    case APPLY_FILTERS_FROM_RECEIVED_URL:
      return {
        ...state,
        assigneeId: action.assigneeId,
        orderId: action.orderId,
        referralAssociation: action.referralAssociation,
        priority: action.priority,
        requestedLocation: action.requestedLocation,
        scheduledLocation: action.scheduledLocation,
        consultDate: action.consultDate,
        activeFilters: [...action.activeFilters],
      };
    case SAVE_SENT_ROUTE_WITH_FILTERS:
      return {
        ...state,
        sentRoute: action.route,
      };
    case SAVE_RECEIVED_ROUTE_WITH_FILTERS:
      return {
        ...state,
        receivedRoute: action.route,
      };
    case CLEAR_ALL_BOARD:
      return {
        ...state,
        assigneeId: NaN,
        priority: "any",
        referralAssociation: "all",
        location: "any",
        requestedLocation: "any",
        scheduledLocation: "any",
        consultDate: "any",
      };
    case APPLY_ASSIGNEE_FILTER:
      return {
        ...state,
        assigneeId: action.assigneeId,
      };
    case REMOVE_ASSIGNEE_FILTER:
      return {
        ...state,
        assigneeId: undefined,
      };
    case APPLY_ASSIGNEE_TAG_DOCUMENT:
      return {
        ...state,
        assigneTag: action.assigneTag,
      };
    case REMOVE_ASSIGNEE_TAG_DOCUMENT:
      return {
        ...state,
        assigneTag: undefined,
      };

    case APPLY_REF_ASSOCIATION_FILTER:
      return {
        ...state,
        referralAssociation: action.referralAssociation,
      };

    case APPLY_CONSULT_DATE_FILTER:
      return {
        ...state,
        consultDate: action.consultDate,
      };
    case APPLY_PRIORITY_FILTER:
      return {
        ...state,
        priority: action.priority,
      };
    case APPLY_LOCATION_FILTER:
      return {
        ...state,
        location: action.location,
      };
    case APPLY_REQUESTED_LOCATION_FILTER:
      return {
        ...state,
        requestedLocation: action.location,
      };
    case APPLY_SCHEDULED_LOCATION_FILTER:
      return {
        ...state,
        scheduledLocation: action.location,
      };
    case APPLY_ORDER_FILTER:
      return {
        ...state,
        orderId: action.orderId,
      };
    case REMOVE_ORDER_FILTER:
      return {
        ...state,
        orderId: "desc",
      };
    case APPLY_DOCUMENT_FILTER:
      return {
        ...state,
        activeDocumentFilters: [
          ...state.activeDocumentFilters,
          action.filterName,
        ],
      };
    case REMOVE_DOCUMENT_FILTER:
      return {
        ...state,
        activeDocumentFilters: state.activeDocumentFilters.filter(
          (filter) => filter !== action.filterName
        ),
      };
    case CLEAR_ALL:
      return {
        ...state,
        activeFilters: [],
        quickFilterPopover: null,
      };
    case CLEAR_ALL_DOCUMENTS:
      return {
        ...state,
        activeDocumentFilters: [],
        quickFilterPopover: null,
      };
    case CLOSE:
      return {
        ...state,
        quickFilterPopover: null,
      };
    case APPLY_SOURCE_CHANNEL_FILTER:
      return {
        ...state,
        sourceChannel: action.sourceChannel,
      };
    case REMOVE_SOURCE_CHANNEL_FILTER:
      return {
        ...state,
        sourceChannel: "",
      };
    case APPLY_CREATE_DATE_FILTER:
      return {
        ...state,
        createDateFilter: action.createdAfter,
        createDateFilterBefore: action.createdBefore,
      };
    case REMOVE_CREATE_DATE_FILTER:
      return {
        ...state,
        createDateFilter: "",
      };
    default:
      return state;
  }
}

// Action Creators
export function openQuickFilterPopover(quickFilterPopover) {
  return {
    type: OPEN,
    quickFilterPopover: quickFilterPopover,
  };
}

export function applyFilter(filterName) {
  return {
    type: APPLY_FILTER,
    filterName,
  };
}

export function removeFilter(filterName) {
  return {
    type: REMOVE_FILTER,
    filterName,
  };
}

export function applyFiltersFromSentUrl(
  assigneeId,
  orderId,
  referralAssociation,
  priority,
  location,
  consultDate,
  activeFilters
) {
  return {
    type: APPLY_FILTERS_FROM_SENT_URL,
    assigneeId,
    orderId,
    referralAssociation,
    priority,
    location,
    consultDate,
    activeFilters,
  };
}

export function applyFiltersFromReceivedUrl(
  assigneeId,
  orderId,
  referralAssociation,
  priority,
  requestedLocation,
  scheduledLocation,
  consultDate,
  activeFilters
) {
  return {
    type: APPLY_FILTERS_FROM_RECEIVED_URL,
    assigneeId,
    orderId,
    referralAssociation,
    priority,
    requestedLocation,
    scheduledLocation,
    consultDate,
    activeFilters,
  };
}

export function saveSentRouteWithFilters(route) {
  return {
    type: SAVE_SENT_ROUTE_WITH_FILTERS,
    route,
  };
}

export function saveReceivedRouteWithFilters(route) {
  return {
    type: SAVE_RECEIVED_ROUTE_WITH_FILTERS,
    route,
  };
}

export function clearBoardFilters() {
  return {
    type: CLEAR_ALL_BOARD,
  };
}

export function applyDocumentFilter(filterName) {
  return {
    type: APPLY_DOCUMENT_FILTER,
    filterName,
  };
}

export function removeDocumentFilter(filterName) {
  return {
    type: REMOVE_DOCUMENT_FILTER,
    filterName,
  };
}
export function clearAllFilters() {
  return {
    type: CLEAR_ALL,
  };
}
export function clearAllDocumentFilters() {
  return {
    type: CLEAR_ALL_DOCUMENTS,
  };
}

export function closeQuickFilterPopover() {
  return {
    type: CLOSE,
  };
}

export function setAssigneeIdForFilter(assigneeId) {
  return {
    type: APPLY_ASSIGNEE_FILTER,
    assigneeId,
  };
}

export function setAssigneeFilter(assigneeId) {
  return {
    type: APPLY_ASSIGNEE_FILTER,
    assigneeId,
  };
}

export function removeAssigneeFilter() {
  return {
    type: REMOVE_ASSIGNEE_FILTER,
  };
}

export function setOrderForFilter(orderId) {
  return {
    type: APPLY_ORDER_FILTER,
    orderId,
  };
}

export function setOrderFilter(orderId) {
  return {
    type: APPLY_ORDER_FILTER,
    orderId,
  };
}

export function setRefAssociationFilter(referralAssociation) {
  return {
    type: APPLY_REF_ASSOCIATION_FILTER,
    referralAssociation,
  };
}
export function setPriorityFilter(priority) {
  return {
    type: APPLY_PRIORITY_FILTER,
    priority,
  };
}
export function setLocationFilter(location) {
  return {
    type: APPLY_LOCATION_FILTER,
    location,
  };
}
export function setRequestedLocationFilter(location) {
  return {
    type: APPLY_REQUESTED_LOCATION_FILTER,
    location,
  };
}
export function setScheduledLocationFilter(location) {
  return {
    type: APPLY_SCHEDULED_LOCATION_FILTER,
    location,
  };
}

export function removeOrderFilter() {
  return {
    type: REMOVE_ORDER_FILTER,
  };
}

export function setCreateDateFilter({ createdAfter, createdBefore }) {
  return {
    type: APPLY_CREATE_DATE_FILTER,
    createdAfter,
    createdBefore,
  };
}

export function removeCreateDateFilter() {
  return {
    type: REMOVE_CREATE_DATE_FILTER,
  };
}

export function setSourceChannelFilter(sourceChannel) {
  return {
    type: APPLY_SOURCE_CHANNEL_FILTER,
    sourceChannel,
  };
}

export function removeSourceChannelFilter() {
  return {
    type: REMOVE_SOURCE_CHANNEL_FILTER,
  };
}

export function setAssigneeTagDocument(assigneTag) {
  return {
    type: APPLY_ASSIGNEE_TAG_DOCUMENT,
    assigneTag,
  };
}

export function removeAssigneeTagDocument() {
  return {
    type: REMOVE_ASSIGNEE_TAG_DOCUMENT,
  };
}

export function setConsultDateFilter(consultDate) {
  return {
    type: APPLY_CONSULT_DATE_FILTER,
    consultDate,
  };
}
