import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  Button,
  TextField,
  Autocomplete,
} from "@mui/material";
import { generatePatientDisplayName } from "../TaskCard/TaskCard";
import { closeDialog } from "../../state/ducks/dialogs";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { enqueueSnackbar } from "../../state/ducks/notifiers";
import { toggleSentReferralFlag } from "../../state/ducks/referralsSent";
import { toggleReceivedReferralFlag } from "../../state/ducks/referralsReceived";

const flagReasons = [
  "Documentation",
  "Patient Information",
  "Referral Status",
  "Scheduling Information",
  "Other",
];
const unflagReasons = ["Resolved", "Unresolved"];

export default function FlagDialog() {
  const dispatch = useDispatch();
  const flagDialogIsOpen = useSelector((state) => state.dialogs.flag?.open);
  const referral = useSelector((state) => state.dialogs.flag?.referral);
  const isFlagged = referral?.flagged;
  const { handleSubmit, setValue, register, unregister } = useForm({
    defaultValues: {
      flagReason: null,
      flagComment: "",
    },
  });
  const [flagReason, setFlagReason] = useState(null);
  const [flagComment, setFlagComment] = useState("");

  useEffect(() => {
    register("flagReason", { required: true });
    register("flagComment", {
      validate: (value) => value === "" || (value && value.trim().length >= 1),
    });

    return () => {
      if (unregister) {
        unregister("flagReason");
        unregister("flagComment");
      }
      setFlagReason(null);
      setFlagComment("");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register]);

  const generateFlagComment = ({ flagReason, flagComment }) => {
    let comment = flagReason;
    if (flagComment) {
      return comment.concat(" - ", flagComment);
    }
    return comment;
  };

  const onSubmit = (formValues) => {
    const comment = generateFlagComment(formValues);
    if (referral.type === "SENT") {
      dispatch(
        toggleSentReferralFlag(
          referral.id,
          referral.displayableId,
          isFlagged,
          comment,
          referral.onDetail
        )
      );
    } else if (referral.type === "RECEIVED") {
      dispatch(
        toggleReceivedReferralFlag(
          referral.id,
          referral.displayableId,
          isFlagged,
          comment,
          referral.onDetail
        )
      );
    }
    closeFlagDialogAndReinit();
  };
  const onError = (error) => {
    dispatch(
      enqueueSnackbar({
        message: `Please complete all required form fields`,
        options: {
          variant: "error",
        },
      })
    );
  };

  const setFlagReasonSelect = (data) => {
    setValue("flagReason", data);
    setFlagReason(data);
  };
  const setFlagCommentForm = (data) => {
    setValue("flagComment", data);
    setFlagComment(data);
  };

  const closeFlagDialogAndReinit = () => {
    dispatch(closeDialog("flag"));
    setValue("flagReason", null);
    setValue("flagComment", "");
    setFlagReason(null);
    setFlagComment("");
  };

  return flagDialogIsOpen ? (
    <Dialog
      key={`${referral.id}_${isFlagged ? "unflag" : "flag"}_dialog`}
      open={true}
      onClose={closeFlagDialogAndReinit}
      aria-labelledby="form-dialog-title"
      scroll="body"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle id="form-dialog-title" disableTypography>
        <Typography component="h2" variant="h6">
          {isFlagged ? "Unflag" : "Flag"}
        </Typography>
        <Typography variant="subtitle1">{`${generatePatientDisplayName(
          referral.patient || referral.patientSnapshot
        )} (REF-${referral.displayableId})`}</Typography>
      </DialogTitle>
      <DialogContent>
        <form
          id="flag-action-dialog"
          onSubmit={handleSubmit(onSubmit, onError)}
        >
          <Autocomplete
            autoHighlight
            id="flagReason"
            options={isFlagged ? unflagReasons : flagReasons}
            name={"flagReason"}
            style={{ width: "100%" }}
            value={flagReason}
            onChange={(e, val) => setFlagReasonSelect(val)}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                label={`${isFlagged ? "Unf" : "F"}lag Reason *`}
                variant="standard"
              />
            )}
          />
          <TextField
            id="flagComment"
            name={flagComment}
            key="flagComment"
            value={flagComment || ""}
            onChange={(e) => setFlagCommentForm(e?.target?.value)}
            required={flagReason === "Other" || flagReason === "Unresolved"}
            label={"Description"}
            fullWidth
            multiline={true}
            rows={2}
            variant="standard"
            margin="normal"
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          id="cancel"
          key={`cancel_${isFlagged ? "unflag" : "flag"}`}
          type="cancel"
          color="primary"
          onClick={closeFlagDialogAndReinit}
        >
          Cancel
        </Button>
        <Button
          id="success"
          key={`button_${isFlagged ? "unflag" : "flag"}`}
          type="submit"
          form="flag-action-dialog"
          color="primary"
        >
          {isFlagged ? "Unflag" : "Flag"}
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;
}
