import React from "react";
import { Button } from "@material-ui/core";
import { enqueueSnackbar } from "./notifiers";
import { put, call, takeLatest } from "redux-saga/effects";
import { getLatestAppVersionNumber } from "../../services/VersionService";
import delay from "@redux-saga/delay-p";

// Actions
const BEGIN_VERSION_CHECK = "hermes/version/BEGIN_VERSION_CHECK";

// Reducer
export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    default:
      return state;
  }
}

// Action Creators
export function checkAppVersion() {
  return {
    type: BEGIN_VERSION_CHECK,
  };
}

const versionAction = (
  <React.Fragment>
    <Button
      style={{ color: "white" }}
      onClick={() => window.location.reload(true)}
    >
      Reload
    </Button>
  </React.Fragment>
);

// Sagas

function* handleCheckAppVersion(action) {
  yield delay(5000);
  let repeatVersionCheck = true;

  while (repeatVersionCheck) {
    const appVersionNumber = yield call(getLatestAppVersionNumber);
    if (appVersionNumber === process.env.REACT_APP_VERSION) {
      yield delay(300000);
    } else {
      yield put(
        enqueueSnackbar({
          message: `There is a new version available.`,
          options: {
            action: versionAction,
            variant: "warning",
            persist: true,
          },
        })
      );
      repeatVersionCheck = false;
    }
  }
}

export function* checkAppVersionSaga() {
  yield takeLatest(BEGIN_VERSION_CHECK, handleCheckAppVersion);
}
