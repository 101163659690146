import axios from "axios";
import { getApiHost } from "../state/ducks/api";
import _ from "lodash";

const reflect = (p) =>
  p.then(
    (v) => ({ v, status: "fulfilled" }),
    (e) => ({ v: {}, e, status: "rejected" })
  );

const userList = [];

export async function identifyUser(bearerToken) {
  const apiHost = await getApiHost();
  const response = await axios.get(`${apiHost}/api/caller-profile`, {
    headers: {
      Authorization: `Bearer ${bearerToken}`,
    },
  });
  return { ...response.data, id: response.data.userId };
}

export async function getUserName(bearerToken, clientId, userId) {
  if (_.isNil(userId) || userId === "null" || userId === "undefined") {
    return {};
  }
  const requestedUser = userList.find(
    (user) => user.userId === parseInt(userId)
  );
  if (requestedUser) {
    return requestedUser;
  }
  const apiHost = await getApiHost();
  const response = await reflect(
    axios.get(`${apiHost}/api/clients/${clientId}/users/${userId}`, {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
      },
    })
  );
  const user = response.v;
  userList.push({
    ...user.data,
    ...(user.data && { username: user.data.userName }),
    userId: parseInt(userId),
  });
  return {
    ...user.data,
    username: user.data.userName,
    userId: parseInt(userId),
  };
}

export async function getClientOrganizationName(bearerToken, clientId) {
  const apiHost = await getApiHost();
  const response = await axios.get(`${apiHost}/api/clients/${clientId}`, {
    headers: {
      Authorization: `Bearer ${bearerToken}`,
    },
  });

  return await response.data.name;
}

export async function getClientUsers(bearerToken, clientId) {
  const apiHost = await getApiHost();
  const response = await axios.get(`${apiHost}/api/clients/${clientId}/users`, {
    headers: {
      Authorization: `Bearer ${bearerToken}`,
    },
    params: {
      inactive: false,
    },
  });

  return (await response.data) || [];
}
