import React from "react";
import {
  ListItem,
  ListItemAvatar,
  Avatar,
  Grid,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import { indigo } from "@material-ui/core/colors";
import {
  isSelfUser,
  avatarUserName,
  activityUserLabel,
  formatFaxNumber,
} from "../../../utils";
import PropTypes from "prop-types";

/**
 * Render a TRANSFER_DOCUMENT_OUT activity
 */
const TransferActivityItem = ({ activity, selfId }) => {
  const userInitials = avatarUserName(activity);
  const isSelf = isSelfUser(activity, selfId);
  const eventTime = format(
    new Date(activity?.fields?.eventTime),
    "MMM d, y h:mm a"
  );
  const userName = activityUserLabel(activity, selfId);
  const relatedItems = activity?.fields?.relatedItems || [];

  let receiverTenantName = "";
  let faxNumber = "";
  const documentName = activity?.fields?.objectItem?.name || "";

  relatedItems.forEach((item) => {
    if (item.typeName === "CLIENT") {
      receiverTenantName = item.name;
    }
    if (item.typeName === "RECEIVER_FAX_NUMBER") {
      faxNumber = formatFaxNumber(item.key);
    }
  });

  let message = "";

  const action = activity.fields.summary;

  if (receiverTenantName) {
    // Multi-tenant?
    if (faxNumber) {
      // Case: Multi-tenant FAXED DOCUMENT
      message = `${userName} ${action} to ${receiverTenantName} ${faxNumber} ${documentName}`;
    } else {
      // Case: Multi-tenant UPLOAD DOCUMENT
      message = `${userName} ${action} to ${receiverTenantName} ${documentName}`;
    }
  } else {
    // Case: Single-tenant FAXED DOCUMENT
    message = `${userName} ${action} to ${faxNumber} ${documentName}`;
  }

  return (
    <ListItem key={activity.id}>
      <ListItemAvatar>
        <Avatar
          sx={
            isSelf
              ? {
                  color: "#fff !important",
                  backgroundColor: `${indigo["A700"]} !important`,
                }
              : {}
          }
        >
          {userInitials}
        </Avatar>
      </ListItemAvatar>
      <Grid container>
        <Grid item xs={12}>
          <Typography>{message}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            sx={{
              fontSize: "0.875rem",
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              fontWeight: "500",
              lineHeight: "1.57",
              letterSpacing: "0.00714em",
            }}
            color="textSecondary"
            variant="subtitle2"
          >
            {eventTime}
          </Typography>
        </Grid>
      </Grid>
    </ListItem>
  );
};

TransferActivityItem.propTypes = {
  activity: PropTypes.object.isRequired,
  selfId: PropTypes.number.isRequired,
};

export default TransferActivityItem;
