import React from "react";
import {
  Box,
  Typography,
  TextField as MaterialTextField,
} from "@material-ui/core";
import { useStyles } from "./styles";

const TextField = React.forwardRef(
  (
    {
      id,
      ref,
      testid,
      name,
      label,
      className,
      type,
      placeholder,
      isrequired,
      rowcount,
      disabled,
      register,
      value,
      error,
      onChange,
      onFocus,
      hintText = "",
      InputProps = {},
      inputProps = {},
    },
    forwardedRef
  ) => {
    const classes = useStyles();
    return (
      <Box className={className}>
        <Box>
          <Typography className={classes.header}>{label}</Typography>
          {isrequired ? (
            <Typography className={classes.require}>*</Typography>
          ) : (
            ""
          )}
        </Box>
        <MaterialTextField
          id={id}
          ref={forwardedRef}
          data-testid={testid}
          disabled={disabled}
          size="small"
          onFocus={onFocus}
          className={disabled ? classes.primary : classes.disabled}
          placeholder={placeholder}
          multiline={rowcount ? true : false}
          rows={rowcount ?? 1}
          type={type}
          error={error}
          variant="outlined"
          value={value}
          onChange={onChange}
          InputProps={InputProps}
          inputProps={inputProps}
          {...(register && register(name))}
        />

        {hintText && (
          <div className={classes.hintText}>
            <span>{hintText}</span>
          </div>
        )}
      </Box>
    );
  }
);

export default TextField;
