// Actions

const OPEN = "hermes/create-dialog/OPEN_CREATE_REFERRAL";
const CLOSE = "hermes/create-dialog/CLOSE_CREATE_REFERRAL";
const ADD_DOCS_TO_FORM = "hermes/create-dialog/ADD_DOCS_TO_FORM";
const RESET_FORM_TEMPLATE = "hermes/create-dialog/RESET_FORM_TEMPLATE";

const initialState = {
  createReferralDialogOpen: false,
  addDocsActive: false,
  createReferralTemplate: {
    header: {
      text: "Please provide the following information:",
    },
    fields: [
      {
        name: "Patient",
        type: "select",
        required: true,
        selectOptionProp: "patients",
        liveSearchFocus: "patient",
        liveSearchFnName: "getExistingPatients",
        objectId: "patient",
        createable: true,
        createTransition: "openPatientCreateDialog",
        editTransition: "openPatientEditDialog",
      },
      {
        name: "Patient Snapshot",
        type: "select",
        required: true,
        objectId: "patientSnapshot",
      },
      {
        name: "Reason for Referral",
        type: "textarea",
        objectId: "description",
        required: true,
      },
      {
        name: "Refer to",
        type: "select",
        required: true,
        selectOptionProp: "practices",
        liveSearchFocus: "directory",
        liveSearchFnName: "fetchNetworkProviders",
        dependsOnField: "specialty",
        objectId: "recipientPractice",
        createable: true,
        createTransition: "openOrganizationCreateDialog",
        editTransition: "openOrganizationEditDialog",
        fullWidth: true,
      },
      {
        name: "Preferred provider",
        type: "select",
        objectId: "referralRecipientPhysician",
        selectOptionProp: "providerStaff",
        liveSearchFnName: "retrieveProviderStaff",
        createable: true,
        filterSelectOptions: true,
        createTransition: "openProviderCreateDialog",
        liveSearchFocus: "provider",
        dependsOnField: "recipientPractice",
      },
      {
        name: "Specialty",
        type: "select",
        required: true,
        filterSelectOptions: true,
        selectOptions: [],
        objectId: "specialty",
      },
      {
        name: "Referral Practice",
        type: "text",
        objectId: "senderPractice",
        readOnly: true,
        required: true,
      },
      {
        name: "Referred by",
        type: "select",
        objectId: "senderName",
        selectOptionProp: "senderStaff",
        liveSearchFnName: "retrieveClientStaff",
        liveSearchFocus: "referredBy",
        dependsOnField: "senderPractice",
        createTransition: "openProviderCreateDialog",
        createable: true,
        required: true,
      },
      {
        name: "Seen by",
        type: "select",
        objectId: "referralRecipientPhysicianActual",
        hidden: false,
        readOnly: false,
        selectOptionProp: "providerStaff",
        liveSearchFnName: "retrieveProviderStaff",
        liveSearchFocus: "provider",
        dependsOnField: "recipientPractice",
      },
      {
        name: "Priority",
        type: "select",
        objectId: "priority",
        required: true,
        selectOptions: [
          {
            value: "Routine",
            label: "Routine",
          },
          {
            value: "Urgent",
            label: "Urgent",
          },
        ],
      },
      {
        name: "Consult date",
        type: "date",
        objectId: "consultDate",
        allowWritesFn: (type, state) => {
          return type === "RECEIVED" && state === "Scheduled";
        },
      },
      {
        name: "Patient Visit Number",
        type: "text",
        objectId: "visitNumber",
        readOnly: true,
        hidden: true,
      },
      {
        name: "External Referral ID",
        type: "text",
        objectId: "externalId",
      },
      {
        name: "OON Reason",
        type: "select",
        objectId: "oonReason",
        oonOnly: true,
        required: true,
        selectOptions: [
          "Existing Care Relationship",
          "Geographic Location",
          "Patient Preference",
          "Provider Availability",
          "Provider Request/Preference",
          "Service Not Available In-Network",
        ].map((option) => {
          return { value: option, label: option };
        }),
      },
      {
        name: "Attached documents",
        type: "documentdisplay",
        objectId: "documents",
      },
    ],
    actions: [
      {
        name: "Cancel",
        function: "cancel",
      },
      {
        nameFn: (type) => {
          if (type === "CREATE") {
            return "Create";
          }
          return "Update";
        },
        function: "persist",
      },
    ],
  },
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case OPEN:
      return {
        ...state,
        createReferralDialogOpen: true,
      };
    case CLOSE:
      return {
        ...state,
        createReferralDialogOpen: false,
      };
    case ADD_DOCS_TO_FORM:
      return {
        ...state,
        addDocsActive: true,
        createReferralTemplate: {
          ...state.createReferralTemplate,
          fields: [
            ...state.createReferralTemplate.fields,
            {
              name: "Add documents",
              type: "fileupload",
              objectId: "documents",
            },
          ],
        },
      };
    case RESET_FORM_TEMPLATE:
      return {
        ...state,
        addDocsActive: false,
        createReferralTemplate: initialState.createReferralTemplate,
      };
    default:
      return state;
  }
}

// Action Creators
export function openDialog() {
  return { type: OPEN };
}

export function closeDialog() {
  return { type: CLOSE };
}

export function openEditFormDocuments() {
  return { type: ADD_DOCS_TO_FORM };
}

export function resetReferralForm() {
  return { type: RESET_FORM_TEMPLATE };
}
