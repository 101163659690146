export const ACTIVITY_EVENT_TYPE = {
  NOTIFICATION_READ: "NOTIFICATION_READ",
  NOTIFICATION_UNREAD: "NOTIFICATION_UNREAD",
};

export const NOTIFICATION_TYPE = {
  REFERRAL_FLAGGED: "REFERRAL_FLAGGED",
  REFERRAL_UNFLAGGED: "REFERRAL_UNFLAGGED",
  DOCUMENT_ADDED_TO_PROCESS: "DOCUMENT_ADDED_TO_PROCESS",
  ADD_COMMENT: "ADD_COMMENT",
};

export const ACTIVITY_TYPE_NAME = {
  DOCSERVER_DOCUMENT: "DOCSERVER_DOCUMENT",
  RECEIVED_FAX_DOCUMENT: "RECEIVED_FAX_DOCUMENT",
  SERVICE_REQUEST: "SERVICE_REQUEST",
  SENTREFERRAL: "SENTREFERRAL",
  RCVDREFERRAL: "RCVDREFERRAL",
};
