import { gql } from "@apollo/client";

export const patientFieldsFragment = gql`
  fragment PatientFields on Patient {
    id
    uuid
    mrn
    lastName
    address
    city
    country
    zip
    state
    firstName
    dob
    gender
    middleName
    homePhoneNumber
    workPhoneNumber
    cellPhoneNumber
    insurance {
      insuranceCompanyId
      insuranceCompanyName
      groupNumber
      policyNumber
      priority
    }
  }
`;
