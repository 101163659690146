import axios from "axios";
import { getApiHost } from "../state/ducks/api";

export async function getTenantServices(bearerToken, clientId, searchTerm) {
  try {
    const apiHost = await getApiHost();
    return await axios.get(`${apiHost}/api/clients/${clientId}/services`, {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
      },
      params: {
        searchTerm,
      },
    });
  } catch (e) {
    console.log(e);
  }
}
