import axios from "axios";
import { getApiHost } from "../state/ducks/api";

export async function getDirectAddressesForClient(bearerToken, clientId) {
  const apiHost = await getApiHost();
  const response = await axios.get(
    `${apiHost}/api/clients/${clientId}/inbound-configs`,
    {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
      },
      params: {
        sort: "address",
      },
    }
  );
  return response.data ? response.data : [];
}
