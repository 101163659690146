import axios from "axios";
import _ from "lodash";
import { getApiHost } from "../state/ducks/api";

export async function sendDirectMessage(
  auth,
  clientId,
  uuids,
  exportConfig = {}
) {
  const apiHost = await getApiHost();
  const response = await axios.post(
    `${apiHost}/api/clients/${clientId}/export`,
    {
      ..._.omit(exportConfig, ["enabled"]),
      documents: [uuids],
    },
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    }
  );

  return response.data;
}

export async function getExportConfig(bearerToken, clientId) {
  const apiHost = await getApiHost();
  const response = await axios.get(
    `${apiHost}/api/clients/${clientId}/export-config`,
    {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
      },
    }
  );

  return response?.data;
}
