import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";

import {
  useSearchkit,
  withSearchkit,
  withSearchkitRouting,
  useSearchkitVariables,
} from "@searchkit/client";
import { Pagination, FacetsList } from "@searchkit/elastic-ui";
import "@elastic/eui/dist/eui_theme_light.css";
import { List, Tooltip, Box } from "@mui/material";
import { format } from "date-fns";
import { getSpecificDateRange, processModifiedData } from "../../utils";
import {
  OverridableListFacet,
  OverridableDateRangeFacet,
} from "../CustomFacets";
import { OverridableValueFilter } from "../CustomFilters/OverridableValueFilter";
import { PHSelectedFilters } from "../PHSelectedFilters/PHSelectedFilters";
import { useSelector, useDispatch } from "react-redux";
import { saveVariables, persistVariables } from "../../state/ducks/activity";
import { ACTIVITIES_QUERY } from "../../queries/activities";
import PropTypes from "prop-types";
import activityComponentMapping from "./activityComponentMapping";
import DefaultActivityItem from "./ActivityTypes/DefaultActivityItem";

//For Redirect in case of GraphQL authentication error
const host =
  window.location.hostname === "localhost"
    ? "phealth-dev"
    : window.location.hostname.split(".")[1];

const redirectUrl =
  window.location.hostname === "localhost"
    ? "http://localhost:3000/exchange/"
    : `https://phealth.${host}.net/exchange/`;

const HomeActivityStream = ({ selfId, newForm }) => {
  const dispatch = useDispatch();

  const savedVariables = useSelector(
    (state) => state.activity.savedSearchVariables
  );
  const [useSaved, setUseSaved] = useState(
    useSelector((state) => state.activity.useSaved) || false
  );
  let variables = useSearchkitVariables();
  const api = useSearchkit();
  const [dateSet, setDefaultDate] = useState(false);

  if (
    !variables?.filters?.find((item) => item.value === "user authentication")
  ) {
    variables.filters.push({
      identifier: "excludeCategories",
      value: "user authentication",
    });
  }

  const {
    previousData,
    data = previousData,
    loading,
    error,
  } = useQuery(ACTIVITIES_QUERY, {
    variables,
  });

  const Facets = FacetsList([OverridableListFacet, OverridableDateRangeFacet]);

  const modifiedData = processModifiedData(data);

  useEffect(() => {
    if (!useSaved) {
      dispatch(saveVariables(variables));
    }
  }, [dispatch, useSaved, variables]);

  useEffect(() => {
    if (data?.activities?.results && useSaved) {
      api.setQuery(savedVariables?.query);
      savedVariables?.filters.forEach((filter) => api.addFilter(filter));
      api.setSortBy(savedVariables?.sortBy);
      api.setPage(savedVariables?.page);
      setUseSaved(false);
      setDefaultDate(true);
      api.search();
    }
  }, [
    api,
    data,
    savedVariables?.filters,
    savedVariables?.page,
    savedVariables?.query,
    savedVariables?.sortBy,
    useSaved,
  ]);

  useEffect(() => {
    return () => {
      dispatch(persistVariables());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleDefaultFilter = () => {
    const defaultRange = getSpecificDateRange(undefined, "eventTime");
    api.toggleFilter(defaultRange);
    api.search();
  };

  useEffect(() => {
    if (
      data?.activities?.results?.summary?.appliedFilters &&
      !dateSet &&
      !useSaved
    ) {
      const dateFilters = api.getFiltersByIdentifier("eventTime");
      if (dateFilters.length === 0) {
        toggleDefaultFilter();
      }
      setDefaultDate(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    api,
    data?.activities?.results?.summary?.appliedFilters,
    dateSet,
    useSaved,
  ]);

  if (error) {
    const errors = error.networkError?.result?.errors;
    if (errors?.length) {
      errors.forEach((error) => {
        const code = error.extensions.code;
        if (code) {
          window.location.href = redirectUrl;
        }
      });
    }
  }

  let activityList = [];

  if (modifiedData?.activities?.results?.hits?.items?.length) {
    activityList = modifiedData.activities.results.hits.items.map(
      (activity) => {
        const eventType = activity.fields?.eventType;

        // Get component based on eventType
        const ActivityComponent =
          activityComponentMapping[eventType] || DefaultActivityItem;

        return (
          <ActivityComponent
            key={activity.id}
            activity={activity}
            selfId={selfId}
          />
        );
      }
    );
  }

  return (
    <div
      style={{
        width: "70%",
        // eslint-disable-next-line
        ...(format == "full" && { marginLeft: 24, marginRight: 24 }),
        // eslint-disable-next-line
        ...(format == "compact" && {
          width: "90%",
          marginTop: 24,
          marginBottom: 24,
        }),
        ...(newForm && {
          width: "calc(100% - 24px)",
          margin: "24px 8px 24px 16px",
        }),
      }}
    >
      <Tooltip title={"List of specified actions that have occurred recently"}>
        <div
          style={{
            color: "#ffffff",
            backgroundColor: "#516794",
            flexBasis: "45%",
            flexShrink: 0,
            padding: "2px 0px 2px 5px",
            borderRadius: 2,
          }}
        >
          {"ACTIVITY STREAM"}
        </div>
      </Tooltip>
      <Box
        id="page-activity-stream"
        sx={{
          padding: "16px",
          display: "flex",
        }}
      >
        <Box
          sx={{
            minWidth: "192px",
            marginRight: "16px",
            flex: "0 1",
          }}
        >
          <hr
            style={{
              border: "none",
              height: "1px",
              backgroundColor: "#d3dae6",
              width: "100%",
              margin: "16px 0",
            }}
          />
          <Facets data={modifiedData?.activities?.results} loading={loading} />
        </Box>
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Box
            sx={{
              marginBottom: "16px",
            }}
          >
            <PHSelectedFilters
              data={modifiedData?.activities?.results}
              loading={loading}
              customFilterComponents={{
                OverriddenListFilter: OverridableValueFilter,
              }}
            />
          </Box>
          <Box
            id="activity-results"
            style={{
              backgroundColor: "#fff",
              borderRadius: "6px",
              boxShadow:
                "0 0.9px 4px -1px rgba(0,0,0,.08), 0 2.6px 8px -1px rgba(0,0,0,.06), 0 5.7px 12px -1px rgba(0,0,0,.05), 0 15px 15px -1px rgba(0,0,0,.04)",
              padding: "24px",
              flexGrow: "1",
              height: "100%",
            }}
          >
            <List key="activityList">{activityList}</List>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Pagination data={data?.activities?.results} />
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default withSearchkit(withSearchkitRouting(HomeActivityStream));

HomeActivityStream.propTypes = {
  selfId: PropTypes.number,
  newForm: PropTypes.any,
};
