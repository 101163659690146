import { format, isValid } from "date-fns";
import { ACTIVITY_EVENT_TYPE, NOTIFICATION_TYPE } from "../constants";
import { Link } from "react-router-dom";
import React from "react";

export function isComment(activity) {
  return activity.summary === "Added a comment";
}

export function isValidToDisplay(fieldName) {
  const validChangeFields = [
    {
      name: "patient",
      displayName: "Patient",
      displayFn: (patient) => {
        try {
          const jsonPatient = JSON.parse(patient);
          if (jsonPatient) {
            return `${jsonPatient.firstName} ${jsonPatient.lastName}`;
          }
        } catch (e) {
          // no-op
        }
      },
    },
    {
      name: "recipientPractice",
      displayName: "Refer To Practice",
      displayFn: (recipientPractice) => {
        try {
          const jsonRecipientPractice = JSON.parse(recipientPractice);
          if (jsonRecipientPractice) {
            return `${jsonRecipientPractice.orgName}`;
          }
        } catch (e) {
          // no-op
        }
      },
    },
    {
      name: "recipient",
      displayName: "Refer To Practice",
      displayFn: (recipientPractice) => {
        try {
          const jsonRecipientPractice = JSON.parse(recipientPractice);
          if (jsonRecipientPractice) {
            return `${jsonRecipientPractice.orgName}`;
          }
        } catch (e) {
          // no-op
        }
      },
    },
    {
      name: "description",
      displayName: "Description",
    },
    {
      name: "specialty",
      displayName: "Specialty",
    },
    {
      name: "referralRecipientPhysician",
      displayName: "Preferred Provider",
    },
    {
      name: "referralRecipientPhysicianActual",
      displayName: "Seen By",
    },
    {
      name: "senderPractice",
      displayName: "Referring Practice",
    },
    {
      name: "senderName",
      displayName: "Referring Physician",
    },
    {
      name: "consultDate",
      displayName: "Consult Date",
      displayFn: (consultDate) => {
        if (isValid(new Date(consultDate))) {
          return format(new Date(consultDate), "MM/dd/yyyy");
        }
      },
    },
    {
      name: "oonReason",
      displayName: "Out of Network Reason",
    },
    {
      name: "priority",
      displayName: "Priority",
    },
  ];
  return validChangeFields.find((name) => name.name === fieldName);
}

export function getReferralUrl(ref) {
  return `/referrals/${
    ref.typeName.toLowerCase().startsWith("se") ? "sent" : "received"
  }/${ref.key}`;
}

export function getDocumentUrl(doc, clientId) {
  return `/referral-documents/${clientId}/${doc.key}`;
}

export function getPatientName({ objectItem }) {
  if (objectItem?.typeName === "PATIENT_RECORD") {
    return objectItem?.name;
  }
  return "";
}

function getNotificationType(notificatinType) {
  switch (notificatinType) {
    case NOTIFICATION_TYPE.REFERRAL_FLAGGED:
      return "flagged";
    case NOTIFICATION_TYPE.REFERRAL_UNFLAGGED:
      return "unflagged";
    case NOTIFICATION_TYPE.DOCUMENT_ADDED_TO_PROCESS:
      return "document attached";
    case NOTIFICATION_TYPE.ADD_COMMENT:
      return "comments";
    default:
      return "";
  }
}

export function getCustomizedSummary(activity) {
  if (activity?.fields?.eventType === ACTIVITY_EVENT_TYPE.NOTIFICATION_READ) {
    return `marked the ${getNotificationType(
      activity?.fields?.objectItem?.name
    )} notification as read`;
  }
  if (activity?.fields?.eventType === ACTIVITY_EVENT_TYPE.NOTIFICATION_UNREAD) {
    return `marked the ${getNotificationType(
      activity?.fields?.objectItem?.name
    )} notification as unread`;
  }
  return activity?.fields?.summary.toLowerCase();
}

export const isSelfUser = (activity, selfId) => {
  return parseInt(activity?.fields?.userId) === selfId;
};

export const avatarUserName = (activity) => {
  return `${activity.fields?.user?.firstName} ${activity.fields?.user?.lastName}`
    .match(/\b\w/g)
    .join("")
    .toUpperCase();
};

export const activityUserLabel = (activity, selfId) => {
  return `${
    isSelfUser(activity, selfId)
      ? "You"
      : `${activity.fields?.user?.firstName || ""} ${
          activity.fields?.user?.lastName || ""
        }`
  }`;
};

export const formatLabel = (label) => {
  if (!label) return "";
  return label
    .replace(/_/g, " ")
    .toLowerCase()
    .split(" ")
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(" ");
};

export const processModifiedData = (data) => {
  if (!data?.activities?.results) return data;

  const entries = data.activities.results.facets
    .find((facet) => facet.identifier === "eventType")
    ?.entries.map((entry) => ({
      ...entry,
      overrideLabel: formatLabel(entry.label),
    }));

  return {
    ...data,
    activities: {
      ...data.activities,
      results: {
        ...data.activities.results,
        summary: {
          ...data.activities.results.summary,
          appliedFilters: [
            ...(data.activities.results.summary.appliedFilters || []).filter(
              (filter) =>
                filter.identifier !== "excludeCategories" &&
                filter.identifier !== "eventOrigin" &&
                filter.identifier !== "eventType"
            ),
            ...(data.activities.results.summary.appliedFilters || [])
              .filter((filter) => filter.identifier === "eventOrigin")
              .map((filter) => {
                const entry = data.activities.results.facets
                  .find((facet) => facet.identifier === "eventOrigin")
                  ?.entries.find((entry) => entry.value === filter.value);
                return {
                  ...filter,
                  overrideValue: entry?.label,
                  display: "OverriddenListFilter",
                };
              }),
            ...(data.activities.results.summary.appliedFilters || [])
              .filter((filter) => filter.identifier === "eventType")
              .map((filter) => {
                return {
                  ...filter,
                  overrideValue: formatLabel(filter.value),
                  display: "OverriddenListFilter",
                };
              }),
          ],
        },
        facets: [
          {
            ...data.activities.results.facets.find(
              (facet) => facet.identifier === "eventOrigin"
            ),
            display: "OverridableListFacet",
            label: "Filter By Initiator",
          },
          {
            ...data.activities.results.facets.find(
              (facet) => facet.identifier === "eventTime"
            ),
            display: "OverridableDateRangeFacet",
            label: "Filter By Activity Date",
          },
          {
            ...data.activities.results.facets.find(
              (facet) => facet.identifier === "eventType"
            ),
            display: "OverridableListFacet",
            entries,
            label: "Filter By Event Type",
          },
        ],
      },
    },
  };
};

export const getRelatedReferral = (items, clientId) => {
  return items.map((item) =>
    item.typeName === "SENTREFERRAL" || item.typeName === "RCVDREFERRAL" ? (
      <Link key={`related-${item?.key}`} to={`${getReferralUrl(item)}`}>
        {item.name || ""}
      </Link>
    ) : item.typeName === "DOCSERVER_DOCUMENT" ? (
      <Link
        key={`related-${item?.key}`}
        to={`${getDocumentUrl(item, clientId)}`}
        target="_blank"
        rel="opener"
      >
        {item.name || ""}
      </Link>
    ) : null
  );
};

export const formatFaxNumber = (faxNumber) => {
  if (!faxNumber) return faxNumber;

  const cleanedNumber = faxNumber.replace(/\D/g, "");

  if (cleanedNumber.length === 11) {
    return cleanedNumber.replace(/(\d)(\d{3})(\d{3})(\d{4})/, "$1-$2-$3-$4");
  } else if (cleanedNumber.length === 10) {
    return cleanedNumber.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
  } else {
    return faxNumber;
  }
};
