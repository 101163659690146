import { gql } from "@apollo/client";

export const ADD_TAGS = gql`
  mutation AddTags(
    $clientId: Int
    $documentId: String
    $tagsetName: String
    $owner: String
    $tags: [TagInput]
    $isMultiValue: Boolean
  ) {
    addTags(
      clientId: $clientId
      documentId: $documentId
      tagsetName: $tagsetName
      owner: $owner
      tags: $tags
      isMultiValue: $isMultiValue
    ) {
      uuid
    }
  }
`;

export const REMOVE_TAGS = gql`
  mutation RemoveTags(
    $clientId: Int
    $documentId: String
    $tagsetName: String
    $owner: String
    $tags: [TagInput]
  ) {
    removeTags(
      clientId: $clientId
      documentId: $documentId
      tagsetName: $tagsetName
      owner: $owner
      tags: $tags
    ) {
      uuid
    }
  }
`;
