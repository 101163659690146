import { put, call, takeEvery } from "redux-saga/effects";
import { getAuthToken } from "./auth";
import { getInsurancePlansFn } from "../../services/InsurancePlanService";
import { enqueueSnackbar } from "./notifiers";

const GET_INSURANCE_PLANS = "hermes/insurancePlans/GET_INSURANCE_PLANS";
const GET_INSURANCE_PLANS_SUCCESS =
  "hermes/insurancePlans/GET_INSURANCE_PLANS_SUCCESS";

const initialState = {
  insurancePlans: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_INSURANCE_PLANS:
      return state;
    case GET_INSURANCE_PLANS_SUCCESS:
      return {
        ...state,
        insurancePlans: action.insuranceList,
      };
    default:
      return state;
  }
}

// Action Creators
export function getInsurancePlans() {
  return {
    type: GET_INSURANCE_PLANS,
  };
}

function* handleGetInsurancePlans(action) {
  try {
    let authToken = yield call(getAuthToken);
    const insuranceList = yield call(getInsurancePlansFn, authToken);
    yield put({
      type: GET_INSURANCE_PLANS_SUCCESS,
      insuranceList,
    });
  } catch (e) {
    yield put(
      enqueueSnackbar({
        message: `Failed to load insurance Plans`,
        options: {
          variant: "error",
        },
      })
    );
  }
}

export function* getInsurancePlansSaga() {
  yield takeEvery(GET_INSURANCE_PLANS, handleGetInsurancePlans);
}
