import React from "react";
import { EuiFacetGroup, EuiTitle, EuiFacetButton } from "@elastic/eui";
import { useSearchkit } from "@searchkit/client";

export const OverridableListFacet = ({ facet, loading }) => {
  const api = useSearchkit();

  const entries = facet.entries?.map((entry) => (
    <EuiFacetButton
      style={{ height: "28px", marginTop: 0, marginBottom: 0 }}
      key={entry.value}
      quantity={entry.count}
      isSelected={api.isFilterSelected({
        identifier: facet.identifier,
        value: entry.value,
      })}
      onClick={() => {
        api.toggleFilter({
          identifier: facet.identifier,
          value: entry.value,
        });
        api.setPage({ from: 0 });
        api.search();
      }}
      isLoading={loading}
    >
      {entry.overrideLabel || entry.label}
    </EuiFacetButton>
  ));

  return (
    <>
      <EuiTitle size="xxs">
        <h3>{facet.label}</h3>
      </EuiTitle>
      <EuiFacetGroup>{entries}</EuiFacetGroup>
    </>
  );
};

OverridableListFacet.DISPLAY = "OverridableListFacet";
