import { createTheme } from "@material-ui/core/styles";
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  spacing: 8,
  palette: {
    primary: {
      main: "#516794",
      light: "#97A4BF",
      highlighted: "#53C0A6",
      dark: "#465065",
    },
    text: {
      disabled: "#828282",
    },
    secondary: {
      main: "#F2994A",
      light: "#C6DD5B",
      dark: "#9CB42B",
    },
    accent: {
      main: "53C0A6",
      light: "#87D3C1",
      dark: "#47A38D",
    },
    // info: "#2F80ED",
    // warning: "#E2B93B",
    // success: "#27AE60",
    // error: "#EB5757",
    // black: "#000000",
    // white: "#FFFFFF",
    require: "#EB5757",
    gray: {
      main: "#333333",
      gray2: "#4F4F4F",
      gray3: "#828282",
      gray4: "#BDBDBD",
      gray5: "#E0E0E0",
      gray6: "#F2F2F2",
    },
    black: {
      main: `#4F4F4F`,
    },
    white: {
      main: "white",
    },
    red: {
      red1: "#C23F38",
      red2: "#FF0000",
    },
  },
});

export default theme;
