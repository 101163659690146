export const ClientMode = {
  CAPTURE: "capture",
  FULL: "full",
  EXCHANGE: "exchange",
  NETWORK_REPORTS_ONLY: "networkReportsOnly",
};

export const SAVED_FILTERS = "exchange_saved_filters";
export const TRIAGE_METADATA_VIEW = "exchange_doc_triage_metadata";
export const TRANSFER_DOCS_OPTION = "exchange_transfer_documents";
export const SHOW_ADDITIONAL_PROVIDERS_AND_ORDER_ID =
  "exchange_additional_providers_and_order_id";
export const PATIENT_SERVICE_REQUEST_SORT_ORDER =
  "exchange_service_requests_sort_order";
