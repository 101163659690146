import React, { useState, useRef, useEffect } from "react";
import { useStyles } from "../styles";
import { Modal, Grid, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { RadioButton } from "../../../UILibrary";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";

const MultiTenantFileUploadModal = ({
  open,
  handleClose,
  tenantList,
  uploading,
  uploadDocs,
}) => {
  const classes = useStyles();
  const fileInput = useRef(null);
  const [radioOption, setRadioOption] = useState("");

  const handleRadioOptionChange = (event) => {
    setRadioOption(event.target.value);
  };

  const handleContinue = () => {
    fileInput.current.click();
  };

  useEffect(() => {
    if (!open) {
      setRadioOption("");
    }
  }, [open]);

  return (
    <Modal open={open} onClose={handleClose}>
      <div className={classes.multiTenantFileUploadModal}>
        <Grid container spacing={0}>
          <Grid item xs={11}>
            <Typography
              level="h6"
              className={`${classes.multiTenantFileUploadModalHeader} ${classes.multiTenantFileUploadModalLeftMargin}`}
            >
              Choose Upload Location
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              color="primary"
              aria-label="close button"
              component="span"
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <Typography
              level="small"
              className={`${classes.multiTenantFileUploadModalInfoText} ${classes.multiTenantFileUploadModalLeftMargin}`}
            >
              You have access to more than one location. Please choose the
              location you wish to upload to.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            className={classes.multiTenantFileUploadModalLeftMargin}
          >
            <RadioButton
              label={""}
              options={tenantList.map((tenant) => {
                return {
                  buttonId: `${tenant.id}-${tenant.name}`,
                  id: `${tenant.id}`,
                  label: tenant.name,
                };
              })}
              onChange={handleRadioOptionChange}
              radioOption={radioOption}
            />
          </Grid>
          <Grid item xs={4} />
          <Grid item xs={4} style={{ textAlign: "center" }}>
            <FormControl style={{ marginTop: "40px" }}>
              <Button
                data-testid="btn-continue"
                variant="contained"
                color="primary"
                disabled={!radioOption}
                style={{ width: "160px" }}
                onClick={handleContinue}
              >
                Continue
              </Button>
              <Button
                variant="outlined"
                color="primary"
                style={{
                  marginBottom: "24px",
                  marginTop: "10px",
                  width: "160px",
                }}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </FormControl>
          </Grid>
          <Grid item xs={4} />
        </Grid>
        <input
          data-testid="upload-multitenantfile"
          accept="image/*,application/pdf"
          disabled={uploading}
          ref={fileInput}
          className={classes.input}
          id="contained-button-file"
          multiple
          type="file"
          onChange={(event) => {
            uploadDocs(event.target.files, +radioOption);
            event.target.value = null;
          }}
        />
      </div>
    </Modal>
  );
};

export default MultiTenantFileUploadModal;

MultiTenantFileUploadModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  tenantList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  uploading: PropTypes.bool.isRequired,
  uploadDocs: PropTypes.func.isRequired,
};
