import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    h1: {
      fontWeight: 400,
      fontSize: "52px",
      lineHeight: "1.1",
      "& div": {
        display: "inline",
      },
    },
    h2: {
      fontWeight: "400",
      fontSize: "46px",
      lineHeight: "1.1",
      "& div": {
        display: "inline",
      },
    },
    h3: {
      fontWeight: "400",
      fontSize: "40px",
      lineHeight: "1.1",
      "& div": {
        display: "inline",
      },
    },
    h4: {
      fontWeight: "400",
      fontSize: "28px",
      lineHeight: "1.45",
      "& div": {
        display: "inline",
      },
    },
    h5: {
      fontWeight: "500",
      fontSize: "24px",
      lineHeight: "1.45",
      "& div": {
        display: "inline",
      },
    },
    h6: {
      fontWeight: "700",
      fontSize: "20px",
      lineHeight: "1.45",
      "& div": {
        display: "inline",
      },
    },
    body: {
      fontWeight: "normal",
      fontSize: "12px",
      lineHeight: "1.45",
      "& div": {
        display: "inline",
      },
    },
    large: {
      fontWeight: "normal",
      fontSize: "18px",
      lineHeight: "1.45",
      "& div": {
        display: "inline",
      },
    },
    medium: {
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "1.5",
      "& div": {
        display: "inline",
      },
    },
    small: {
      fontWeight: "normal",
      fontSize: "10px",
      lineHeight: "1.45",
      "& div": {
        display: "inline",
      },
    },
    underlined: {
      textDecoration: "underline",
    },
  };
});

export { useStyles };
