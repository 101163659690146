import { useSubscription } from "@apollo/client";
import USERS_SUBSCRIPTION from "../../subscriptions/users";
import { useDispatch, useSelector } from "react-redux";
import { updateSubscription } from "../../state/ducks/docSubscriptions";
import { useMemo } from "react";

const UserSubs = ({ clientId, userId }) => {
  const subscriptions = useSelector((state) => state.docSubscriptions.subList);
  const dispatch = useDispatch();
  const config = useMemo(
    () => ({
      variables: { clientId },
      shouldResubscribe: true,
      onSubscriptionData: (result) => {
        dispatch(
          updateSubscription(
            result.subscriptionData.data.userSubscriptions,
            subscriptions
          )
        );
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clientId, dispatch, subscriptions, userId]
  );
  useSubscription(USERS_SUBSCRIPTION, config);
  return null;
};

export default UserSubs;
