import { gql } from "@apollo/client";

export const GET_CLIENT_NOTFICATION_CONFIG = gql`
  query ClientConfig($clientId: Int!) {
    clientConfig(clientId: $clientId) {
      uiConfig {
        notifications {
          referrals {
            comments
            documentsAdded
            flagged
            unflagged
          }
        }
      }
    }
  }
`;
