import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Collapse,
  List,
  ListItem,
  ListSubheader,
  ListItemText,
  Box,
} from "@mui/material";
import { ChevronRightRounded } from "@material-ui/icons";
import { Link, NavLink, useLocation } from "react-router-dom";

import { determineSideMenuOptions } from "./determineSideMenuOptions.js";
import { clearVariables } from "../../state/ducks/activity";
import { setDateFlag } from "../../state/ducks/referralSearch";
import { getAuthToken } from "../../state/ducks/auth";
import { useAppSetting } from "../../context/appSettingContext.js";
import { ClientMode } from "../../constants/optimizely.js";

const MenuItem = ({ children, hasSubMenu, ...props }) => {
  const {
    fontWeight,
    mt = 0,
    borderTop = "none",
    fontSize = "16px",
    pt,
  } = props;
  return (
    <ListItem
      sx={{
        mt,
        paddingTop: `${pt} !important`,
        borderTop,
        display: hasSubMenu ? "-webkit-box" : "flex",
        padding: `0 0 0 8px`,
        color: "white !important",
        "& :hover": {
          cursor: "pointer",
          fontWeight: 500,
        },
        "& span": {
          fontSize: fontSize,
          fontWeight,
        },
      }}
      {...props}
    >
      {children}
    </ListItem>
  );
};

export default function SidebarMenu() {
  const dispatch = useDispatch();
  const [openReportDropdown, setOpenReportDropdown] = useState();
  const [openAdminDropdown, setOpenAdminDropdown] = useState();
  const authenticated = useSelector((state) => state.auth.authenticated);
  const { pathname } = useLocation();

  const clientId = useSelector((state) => state.auth.clientId);
  const { clientMode, roles, setToken, dashboardLists } = useAppSetting();

  useEffect(() => {
    (async () => {
      if (clientId) {
        const token = await getAuthToken();
        setToken(token);
      }
    })();
  }, [clientId]); // eslint-disable-line

  const captureMode = clientMode === ClientMode.CAPTURE;
  const exchangeMode = clientMode === ClientMode.EXCHANGE;
  const networkDashboardOnlyMode =
    clientMode === ClientMode.NETWORK_REPORTS_ONLY;
  const options = determineSideMenuOptions(
    authenticated,
    roles,
    { openReportDropdown, setOpenReportDropdown },
    { openAdminDropdown, setOpenAdminDropdown },
    captureMode,
    exchangeMode,
    dashboardLists,
    networkDashboardOnlyMode
  );

  return (
    <List sx={{ marginTop: "16px" }}>
      {options.map((item) => {
        if (item.nested) {
          return (
            <React.Fragment key={item.text + "_frag"}>
              <MenuItem
                key={item.text}
                fontWeight={item.stateVariable ? 500 : 300}
                borderTop={
                  item.text === "Analytics" ? "white 1px solid" : "none"
                }
                mt={item.text === "Analytics" ? 2 : 0}
                pt={item.text === "Analytics" ? "16px" : 0}
                fontSize="14px"
                onClick={() => item.onClickFn(!item.stateVariable)}
                hasSubMenu
              >
                <ListItemText
                  primary={item.text}
                  sx={{
                    color: item.stateVariable ? "white" : "#B9C2D4",
                  }}
                />
                <Box mt="4px">
                  <ChevronRightRounded />
                </Box>
              </MenuItem>

              <Collapse in={item.stateVariable} timeout="auto" unmountOnExit>
                <List component="div" style={{ zindex: 1000 }} disablePadding>
                  {item.list.map((nItem, nIndex) =>
                    nItem.type === "subheader" ? (
                      <ListSubheader key={nItem.text} color="inherit">
                        {nItem.text}
                      </ListSubheader>
                    ) : (
                      <ListItem
                        sx={{
                          padding: "0 0 0 8px",
                          color: pathname === nItem.route ? "#FFF" : "#B9C2D4",
                        }}
                        id={nItem.id}
                        key={nItem.text}
                        component={Link}
                        to={nItem.route}
                      >
                        <ListItemText
                          primary={nItem.text}
                          sx={{
                            "& span": {
                              fontSize: "12px",
                              fontWeight: pathname === nItem.route ? 400 : 300,
                            },
                          }}
                        />
                      </ListItem>
                    )
                  )}
                </List>
              </Collapse>
            </React.Fragment>
          );
        }
        return (
          <>
            <MenuItem
              id={item.id}
              key={item.route}
              component={NavLink}
              exact={true}
              to={item.route}
              fontWeight={pathname === item.route ? 500 : 300}
              onClick={() => {
                if (item.id === "menu_home") {
                  dispatch(clearVariables());
                } else if (item.id === "menu_search_referrals_facets") {
                  dispatch(setDateFlag(false));
                }
              }}
            >
              <ListItemText id={item.id} primary={item.text} />
              {pathname === item.route && (
                <Box
                  sx={{
                    width: 0,
                    height: 0,
                    border: "0 solid transparent",
                    borderTopWidth: "14px",
                    borderBottomWidth: "14px",
                    borderRight: "20px solid white",
                  }}
                />
              )}
            </MenuItem>
          </>
        );
      })}
    </List>
  );
}
