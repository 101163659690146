import { takeEvery, put } from "redux-saga/effects";
import _ from "lodash";
import { format } from "date-fns";

// Actions
const DRAG_ITEM = "hermes/board/DRAG_ITEM";
const SEARCH_BOARD = "hermes/board/SEARCH_BOARD";
const SEARCH_DOCS = "hermes/board/SEARCH_DOCS";
const FETCH_REFERRAL_STATES = "hermes/board/FETCH_REFERRAL_STATES";
const FETCH_REFERRAL_STATES_SUCCESS =
  "hermes/board/FETCH_REFERRAL_STATES_SUCCESS";
const FETCH_REFERRAL_STATES_FAILURE =
  "hermes/board/FETCH_REFERRAL_STATES_FAILURE";
const FETCH_REFER_TO_STATES = "hermes/board/FETCH_REFER_TO_STATES";
const FETCH_REFER_TO_STATES_SUCCESS =
  "hermes/board/FETCH_REFER_TO_STATES_SUCCESS";
const FETCH_REFER_TO_STATES_FAILURE =
  "hermes/board/FETCH_REFER_TO_STATES_FAILURE";
const GET_LAST_REFERRAL_BOARD_REFRESH_TIME =
  "hermes/board/GET_LAST_REFERRAL_BOARD_REFRESH_TIME";

export const initialState = {
  lastReferralBoardRefreshTime: "",
  referralStates: [
    {
      name: "Send Failed",
      displayOnBoard: false,
      actionable: true,
      actions: [
        {
          name: "Send",
          targetState: "Sent",
          availableOnline: true,
        },
      ],
    },
    {
      name: "New",
      displayOnBoard: true,
      actionable: true,
      actions: [
        {
          name: "Send",
          targetState: "Sent",
          availableOnline: true,
        },
      ],
    },
    {
      name: "Sent",
      displayOnBoard: true,
      actionable: false,
      actions: [
        {
          name: "Review",
          targetState: "In review",
          availableOnline: false,
        },
      ],
    },
    {
      name: "In review",
      displayOnBoard: true,
      actionable: false,
      actions: [
        {
          name: "Schedule",
          targetState: "Scheduled",
          availableOnline: false,
        },
      ],
    },
    {
      name: "Scheduled",
      displayOnBoard: true,
      actionable: false,
      actions: [
        {
          name: "Consult",
          targetState: "Consulted",
          availableOnline: false,
        },
        {
          name: "Reverse",
          targetState: "In review",
          availableOnline: false,
        },
      ],
    },
    {
      name: "Consulted",
      displayOnBoard: true,
      actionable: false,
      actions: [
        {
          name: "Complete",
          targetState: "Done",
          availableOnline: false,
        },
        {
          name: "Reverse",
          targetState: "Scheduled",
          availableOnline: false,
        },
      ],
    },
    {
      name: "Declined",
      displayOnBoard: true,
      actionable: true,
      actions: [
        {
          name: "Close",
          targetState: "Closed",
          availableOnline: true,
        },
      ],
    },
    {
      name: "Done",
      displayOnBoard: true,
      actionable: true,
      actions: [
        {
          name: "Close",
          targetState: "Closed",
          availableOnline: true,
        },
        {
          name: "Reverse",
          targetState: "Consulted",
          availableOnline: false,
        },
      ],
    },
    {
      name: "Closed",
      displayOnBoard: false,
      actionable: false,
    },
  ],
  referToStates: [
    {
      name: "New",
      displayOnBoard: true,
      actionable: true,
      actions: [
        {
          name: "Review",
          targetState: "In review",
          availableOnline: true,
        },
      ],
    },
    {
      name: "In review",
      displayOnBoard: true,
      actionable: true,
      actions: [
        {
          name: "Accept",
          targetState: "Scheduling",
          availableOnline: true,
        },
        {
          name: "Reject",
          targetState: "Done",
          availableOnline: true,
          specialDialog: "reject",
        },
      ],
    },
    {
      name: "Scheduling",
      displayOnBoard: true,
      actionable: true,
      actions: [
        {
          name: "Schedule",
          targetState: "Scheduled",
          availableOnline: true,
          specialDialog: "scheduling",
        },
        {
          name: "Reverse",
          targetState: "In review",
          availableOnline: true,
        },
        {
          name: "Reject",
          targetState: "Done",
          availableOnline: true,
          specialDialog: "reject",
        },
      ],
    },
    {
      name: "Scheduled",
      displayOnBoard: true,
      actionable: true,
      actions: [
        {
          name: "Move to consult",
          targetState: "Consulted",
          availableOnline: true,
        },
        {
          name: "Reverse",
          targetState: "Scheduling",
          availableOnline: true,
        },
        {
          name: "Reject",
          targetState: "Done",
          availableOnline: true,
          specialDialog: "reject",
        },
      ],
    },
    {
      name: "Consulted",
      displayOnBoard: true,
      actionable: true,
      actions: [
        {
          name: "Add notes",
          targetState: "Done",
          availableOnline: true,
          formTemplate: {
            header: {
              text: "Please attach the clinical documentation from the appointment.",
            },
            fields: [
              {
                name: "Attached documents",
                type: "documentdisplay",
                objectId: "documents",
              },
              {
                name: "Upload documents",
                type: "fileupload",
                addNotesModal: true,
                objectId: "clinicalFiles",
              },
              {
                name: "Additional comments",
                type: "textarea",
                objectId: "newComment",
              },
            ],
            actions: [
              {
                name: "Cancel",
                function: "cancel",
              },
              {
                name: "Save",
                function: "persist",
              },
            ],
          },
        },
        {
          name: "Reverse",
          targetState: "Scheduled",
          availableOnline: true,
        },
      ],
    },
    {
      name: "Done",
      displayOnBoard: true,
      actionable: true,
      actions: [
        {
          name: "Close",
          targetState: "Closed",
          availableOnline: true,
        },
        {
          name: "Reverse",
          targetState: "Consulted",
          availableOnline: true,
        },
      ],
    },
    {
      name: "Closed",
      displayOnBoard: false,
      actionable: false,
    },
  ],
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case DRAG_ITEM:
      return {
        ...state,
        dragId: action.dragId,
      };
    case SEARCH_BOARD:
      return {
        ...state,
        searchValue: action.searchValue,
      };
    case SEARCH_DOCS:
      return {
        ...state,
        docSearchValue: action.searchValue,
      };
    case GET_LAST_REFERRAL_BOARD_REFRESH_TIME:
      return {
        ...state,
        lastReferralBoardRefreshTime: `${format(new Date(), "HH:mm:ss")}`,
      };
    default:
      return state;
  }
}

export function getLastReferralBoardRefreshTime() {
  return {
    type: GET_LAST_REFERRAL_BOARD_REFRESH_TIME,
  };
}

// Action Creators
export function defineDraggable(referral) {
  return {
    type: DRAG_ITEM,
    dragId: referral ? referral.draggableId : undefined,
  };
}

export function searchBoard(value) {
  return {
    type: SEARCH_BOARD,
    searchValue: value,
  };
}

export function searchDocs(event) {
  return {
    type: SEARCH_DOCS,
    searchValue: event.target.value,
  };
}

export function nullifySearchValue() {
  return {
    type: SEARCH_BOARD,
    searchValue: undefined,
  };
}

export function nullifyDocSearchValue() {
  return {
    type: SEARCH_DOCS,
    searchValue: undefined,
  };
}

export function fetchReferralStates() {
  return {
    type: FETCH_REFERRAL_STATES,
  };
}

export function fetchReferToStates() {
  return {
    type: FETCH_REFER_TO_STATES,
  };
}

// Sagas
function* handleFetchReferralStates(action) {
  try {
    yield put({ type: FETCH_REFERRAL_STATES_SUCCESS, ...action });
  } catch (e) {
    let errorLogging = {};
    if (!_.includes(e.message, "401")) {
      errorLogging = { error: true, payload: e };
    }
    yield put({ type: FETCH_REFERRAL_STATES_FAILURE, ...errorLogging });
  }
}

function* handleFetchReferToStates(action) {
  try {
    yield put({ type: FETCH_REFER_TO_STATES_SUCCESS, ...action });
  } catch (e) {
    let errorLogging = {};
    if (!_.includes(e.message, "401")) {
      errorLogging = { error: true, payload: e };
    }
    yield put({ type: FETCH_REFER_TO_STATES_FAILURE, ...errorLogging });
  }
}

export function* fetchReferralStatesSaga() {
  yield takeEvery(FETCH_REFERRAL_STATES, handleFetchReferralStates);
}

export function* fetchReferToStatesSaga() {
  yield takeEvery(FETCH_REFER_TO_STATES, handleFetchReferToStates);
}
