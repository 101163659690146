import React from "react";
import { Button, Tooltip } from "@material-ui/core";
import { ReactComponent as Upload } from "../../../assets/icons/upload.svg";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  customTooltip: {},
  sidebarButton: {
    width: "calc(100% - 54px)",
    padding: "0 0 0 0",
    margin: "16px 16px 0 24px",
    color: theme.palette.primary.main,
    textTransform: "none !important",
  },
  disabledButton: {
    cursor: "not-allowed !important",
    opacity: 0.5,
    pointerEvents: "auto !important",
    backgroundColor: `#e0e0e0 !important`,
  },
  input: {
    display: "none",
  },
}));

const MainUploadButton = ({
  multiTenant,
  associatedTenantsIds,
  isReadOnlyUser,
  uploading,
  uploadDocs,
  fetchAssociatedTenantsInfo,
}) => {
  const classes = useStyles();
  const noAssociatedTenants = associatedTenantsIds?.length === 0;
  const hasMoreThanOneAssociatedTenant = associatedTenantsIds.length > 1;

  if ((multiTenant && noAssociatedTenants) || isReadOnlyUser) {
    return (
      <Tooltip
        title={
          isReadOnlyUser
            ? "You are not allowed to perform this action"
            : "You can upload to specific facilities only"
        }
        placement="right"
        classes={{ tooltip: classes.customTooltip }}
      >
        <Button
          disabled
          className={classes.sidebarButton}
          classes={{ disabled: classes.disabledButton }}
          variant="contained"
          color="default"
          component="span"
          data-testid="upload-icon-multi"
          startIcon={<Upload fill="#516794" />}
        >
          Upload
        </Button>
      </Tooltip>
    );
  }

  if (!(multiTenant && hasMoreThanOneAssociatedTenant)) {
    return (
      <>
        <input
          accept="image/*,application/pdf"
          disabled={uploading}
          className={classes.input}
          id="contained-button-file"
          data-testid={"upload-input"}
          multiple
          type="file"
          onChange={(event) => {
            uploadDocs(event.target.files);
            event.target.value = null;
          }}
        />
        <label htmlFor="contained-button-file">
          <Button
            className={classes.sidebarButton}
            variant="contained"
            color="default"
            component="span"
            data-testid="upload-icon-single"
            startIcon={<Upload />}
          >
            Upload
          </Button>
        </label>
      </>
    );
  }

  return (
    <Button
      onClick={() => {
        fetchAssociatedTenantsInfo();
      }}
      className={classes.sidebarButton}
      variant="contained"
      color="default"
      component="span"
      data-testid="upload-icon-default"
      startIcon={<Upload fill="#516794" />}
    >
      Upload
    </Button>
  );
};

export default MainUploadButton;

MainUploadButton.propTypes = {
  uploadDocs: PropTypes.func.isRequired,
  uploading: PropTypes.bool.isRequired,
  fetchAssociatedTenantsInfo: PropTypes.func.isRequired,
  associatedTenantsIds: PropTypes.arrayOf(
    PropTypes.shape({
      tenantId: PropTypes.string.isRequired,
    })
  ).isRequired,
  isReadOnlyUser: PropTypes.bool.isRequired,
  multiTenant: PropTypes.bool.isRequired,
};
