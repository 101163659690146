import React, { useEffect, useState, useMemo } from "react";
import { useForm } from "react-hook-form";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import NewDateInput from "../Inputs/NewDateInput";
import { useQuery } from "@apollo/client";
import { determineIfOnline } from "../../../state/ducks/directory";
import { useDispatch, useSelector } from "react-redux";
import { retrieveSchedulingProviderStaff } from "../../../state/ducks/staff";
import { Autocomplete } from "@material-ui/lab";
import { useAppSetting } from "../../../context";
import { getQueryVariable } from "../../../utils";
import { SEARCH_PROVIDERS_PRACTICES_FOR_REFERRAL } from "../../../queries/practiceProvider";

const canEditRecipientLocation = ({ type, status }) => {
  if (type === "RECEIVED") {
    return true;
  }
  return false;
};

export default function SchedulingSection({
  classes,
  cardDetail,
  validElements,
  setSchedulingInfo,
  initialize,
}) {
  const { seenAtProvider, referralRecipientPhysicianActual, consultDate } =
    cardDetail;
  const [consultDateValue, setConsultDateValue] = useState(consultDate);
  const [seenByValue, setSeenByValue] = useState(
    seenAtProvider && seenAtProvider?.firstName && seenAtProvider?.lastName
      ? {
          label: seenAtProvider?.prefixName
            ? `${seenAtProvider?.prefixName} ${seenAtProvider?.firstName} ${seenAtProvider?.lastName}`
            : `${seenAtProvider?.firstName} ${seenAtProvider?.lastName}`,
          value: seenAtProvider?.prefixName
            ? `${seenAtProvider?.prefixName} ${seenAtProvider?.firstName} ${seenAtProvider?.lastName}`
            : `${seenAtProvider?.firstName} ${seenAtProvider?.lastName}`,
        }
      : referralRecipientPhysicianActual
      ? {
          label: referralRecipientPhysicianActual,
          value: referralRecipientPhysicianActual,
        }
      : null
  );
  const [tenantLocations, setTenantLocations] = useState([]);
  const providerStaff = useSelector(
    (state) => state.staff.schedulingProviderStaffFullObj || []
  );

  const { tenantNetworks } = useAppSetting();
  const networkIds = tenantNetworks?.map((net) => net.id) || [];

  const dispatch = useDispatch();
  const { register, unregister, setValue, errors, getValues } = useForm({
    defaultValues: {
      consultDate,
    },
  });
  const clientId = useSelector((state) => state.auth.clientId);

  const [locationInput, setLocationInput] = useState(seenAtProvider || {});

  const practiceQuery = useMemo(() => {
    return getQueryVariable({
      urn: [`urn:phealth:clientId:${clientId}`],
      networkIds,
      practiceType: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data } = useQuery(SEARCH_PROVIDERS_PRACTICES_FOR_REFERRAL, {
    variables: practiceQuery,
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (data?.providers?.results?.hits?.items) {
      const formattedData = data.providers.results.hits.items.map(
        (locations) => locations.fields
      );
      setTenantLocations(formattedData);
    }
  }, [data]);

  const [autoSelectTenantLocation, setAutoselectTenantLocation] =
    useState(false);

  useEffect(() => {
    dispatch(
      retrieveSchedulingProviderStaff(
        null,
        networkIds,
        null,
        "",
        locationInput?.orgName,
        `urn:phealth:clientId:${clientId}`
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, locationInput, clientId]);

  useEffect(() => {
    if (
      seenByValue?.value?.orgName &&
      locationInput?.orgName !== seenByValue?.value?.orgName
    ) {
      const prac = tenantLocations.find(
        (p) => p.orgName === seenByValue?.value?.orgName
      );
      setLocationInput(prac || null);
      onChangeReceiverLocation(prac || null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seenByValue?.value?.orgName]);

  useEffect(() => {
    if (tenantLocations?.length === 1) {
      setAutoselectTenantLocation(true);
    } else {
      setAutoselectTenantLocation(false);
    }
  }, [tenantLocations]);

  useEffect(() => {
    if (
      autoSelectTenantLocation &&
      canEditRecipientLocation(cardDetail) &&
      !seenAtProvider
    ) {
      setLocationInput(tenantLocations[0]);
      onChangeReceiverLocation(tenantLocations[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoSelectTenantLocation, cardDetail]);

  useEffect(() => {
    register("seenAtProvider");
    register("consultDate");
    register("referralRecipientPhysicianActual");
    // Set initialValue for edits
    setValue("seenAtProvider", seenAtProvider || {});
    setValue("consultDate", consultDate);
    setValue(
      "referralRecipientPhysicianActual",
      referralRecipientPhysicianActual
    );
    // Set initialValue for inputs
    setLocationInput(seenAtProvider || {});
    setConsultDateValue(consultDate);
    setSeenByValue(
      seenAtProvider && seenAtProvider?.firstName && seenAtProvider?.lastName
        ? {
            label: seenAtProvider?.prefixName
              ? `${seenAtProvider?.prefixName} ${seenAtProvider?.firstName} ${seenAtProvider?.lastName}`
              : `${seenAtProvider?.firstName} ${seenAtProvider?.lastName}`,
            value: seenAtProvider?.prefixName
              ? `${seenAtProvider?.prefixName} ${seenAtProvider?.firstName} ${seenAtProvider?.lastName}`
              : `${seenAtProvider?.firstName} ${seenAtProvider?.lastName}`,
          }
        : referralRecipientPhysicianActual
        ? {
            label: referralRecipientPhysicianActual,
            value: referralRecipientPhysicianActual,
          }
        : null
    );

    return () => {
      if (unregister) {
        unregister("consultDate");
        unregister("referralRecipientPhysicianActual");
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register, seenAtProvider, consultDate, referralRecipientPhysicianActual]);

  useEffect(() => {
    if (initialize) {
      validElements(getValues());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialize, validElements]);

  const onSubmitConsult = (data) => {
    validElements({ consultDate: data?.consultDate || "" });
    if (setSchedulingInfo) {
      setSchedulingInfo(data?.consultDate);
    }
  };

  const onSubmitSeenBy = (data) => {
    validElements({
      referralRecipientPhysicianActual:
        data?.referralRecipientPhysicianActual || "",
    });
  };

  const onSubmitLocation = (seenAtProvider) => {
    validElements({
      seenAtProvider,
    });
    if (setSchedulingInfo) {
      setSchedulingInfo(seenAtProvider);
    }
  };

  const onChangeReceiverLocation = (data) => {
    setValue("seenAtProvider", data);
    setLocationInput(data);
    onSubmitLocation(data);
  };

  const onChangeConsult = (data) => {
    setValue("consultDate", data);
    setConsultDateValue(data);
    onSubmitConsult({ consultDate: data });
  };

  const onChangeSeenBy = (data) => {
    setValue("referralRecipientPhysicianActual", data?.value?.name);
    setSeenByValue(data);
    onSubmitSeenBy({ referralRecipientPhysicianActual: data?.value?.name });
  };

  return (
    <Card className={classes.card}>
      <CardHeader
        title="Scheduling Information"
        classes={{
          root: classes.cardHeaderRoot,
          content: classes.cardHeader,
          action: classes.cardHeaderAction,
        }}
      />
      <CardContent>
        <Grid container>
          <Grid item xs={2}>
            <Typography className={classes.fieldLabel} variant="caption">
              Scheduled Location
            </Typography>
          </Grid>
          <Grid item xs={10}>
            {cardDetail?.type === "RECEIVED" &&
            canEditRecipientLocation(cardDetail) ? (
              <Autocomplete
                id="seen_at_provider"
                autoHighlight
                disabled={autoSelectTenantLocation}
                options={tenantLocations}
                getOptionLabel={(option) => option?.orgName || ""}
                getOptionSelected={(option, selected) =>
                  option.id === selected.id
                }
                style={{ marginLeft: 10, marginTop: 10 }}
                name={"seenAtProvider"}
                value={locationInput}
                onChange={(e, val) => onChangeReceiverLocation(val)}
                renderInput={(params) => (
                  <TextField required {...params} label="Scheduled Location" />
                )}
              />
            ) : (
              <Grid>
                <Typography className={classes.fieldValue}>
                  {seenAtProvider?.orgName}
                </Typography>
              </Grid>
            )}
          </Grid>
          <Grid item xs={2}>
            <Typography className={classes.fieldLabel} variant="caption">
              Consult Date
            </Typography>
          </Grid>
          <Grid item xs={8} style={{ marginLeft: 10, marginTop: 10 }}>
            <NewDateInput
              field={{
                name: "Consult date",
                type: "datetime",
                objectId: "consultDate",
                readOnly:
                  cardDetail?.type === "SENT" &&
                  determineIfOnline(cardDetail?.recipientPractice),
              }}
              classes={classes}
              errors={errors}
              register={register}
              unregister={unregister}
              setValue={setValue}
              value={consultDateValue}
              onChange={onChangeConsult}
            />
          </Grid>
        </Grid>
        <br />
        <br />
        <Grid container>
          <Grid item xs={2}>
            <Typography className={classes.fieldLabel} variant="caption">
              Seen By
            </Typography>
          </Grid>
          <Grid item xs={10}>
            {cardDetail?.type === "RECEIVED" ? (
              <Autocomplete
                id="referral_recipient_physician_actual"
                autoHighlight
                options={providerStaff}
                disabled={cardDetail?.type !== "RECEIVED"}
                getOptionLabel={(option) => option?.label || ""}
                getOptionSelected={(option, selected) =>
                  option?.label === selected?.label
                }
                style={{ marginLeft: 10, marginTop: 10 }}
                name={"referralRecipientPhysicianActual"}
                value={seenByValue}
                onChange={(e, val) => onChangeSeenBy(val)}
                onInputChange={(e, searchTerm, reason) =>
                  dispatch(
                    retrieveSchedulingProviderStaff(
                      null,
                      networkIds,
                      null,
                      searchTerm,
                      locationInput?.orgName,
                      `urn:phealth:clientId:${clientId}`
                    )
                  )
                }
                renderInput={(params) => (
                  <TextField {...params} label="Seen by" />
                )}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Grid container direction="row" alignItems="center">
                      <Grid item xs={11}>
                        <Typography>{option.label}</Typography>
                        <Typography variant="body2">
                          {option?.value?.orgName}
                        </Typography>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                )}
              />
            ) : (
              <Typography className={classes.fieldValue}>
                {seenByValue?.label}
              </Typography>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
