import React, { useState, useEffect } from "react";
import {
  KeyboardDateTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core";
import { format, isValid, parseISO } from "date-fns";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  datePicker: {
    margin: theme.spacing(0),
    marginTop: 8,
  },
  datePickerLabel: {
    marginTop: -4,
  },
  fullWidthMode: {
    margin: theme.spacing(1),
    display: "block",
  },
  fixedWidth: {
    width: "160px",
  },
}));

export default function NewDateInput(props) {
  const {
    field,
    value,
    processing,
    onChange,
    errors,
    onBlur,
    fullWidth = false,
    required = false,
    fixedWidth,
  } = props;
  const [selectedDate, setSelectedDate] = useState(value);
  const styles = useStyles();

  useEffect(() => {
    setSelectedDate(value);
  }, [value]);

  switch (field.type) {
    case "datetime":
      const changeValueTime = () => {
        if (isValid(selectedDate)) {
          onChange(selectedDate);
          onBlur && onBlur(format(selectedDate, "yyyy-MM-dd hh:mm a"));
        }
      };
      return (
        <KeyboardDateTimePicker
          id={`datetime_${field.name}`}
          required={required}
          variant="inline"
          label={field.name}
          format="MM/dd/yyyy hh:mm a"
          value={Boolean(selectedDate) ? new Date(selectedDate) : null}
          onChange={setSelectedDate}
          onClose={changeValueTime}
          onBlur={changeValueTime}
          className={clsx(styles.datePicker, {
            [styles.fullWidthMode]: fullWidth,
          })}
          disabled={Boolean(field.readOnly || processing)}
          InputLabelProps={{
            className: styles.datePickerLabel,
          }}
          KeyboardButtonProps={{
            id: "date_time_icon",
          }}
        />
      );
    case "date":
      const changeValue = () => {
        if (isValid(selectedDate)) {
          onChange(selectedDate);
          onBlur && onBlur(format(selectedDate, "yyyy-MM-dd"));
        }
      };
      return (
        <KeyboardDatePicker
          id={`date_${field.name}`}
          required={required}
          variant="inline"
          label={field.name}
          format="MM/dd/yyyy"
          value={
            isValid(selectedDate)
              ? selectedDate
              : isValid(parseISO(selectedDate))
              ? parseISO(selectedDate)
              : null
          }
          onChange={setSelectedDate}
          onClose={changeValue}
          onBlur={changeValue}
          className={clsx(styles.datePicker, {
            [styles.fullWidthMode]: fullWidth,
            [styles.fixedWidth]: !fullWidth,
            [styles.fixedWidth]: fixedWidth,
          })}
          error={
            selectedDate &&
            (errors ||
              (!isValid(selectedDate) && !isValid(parseISO(selectedDate))))
          }
          disabled={Boolean(field.readOnly || processing)}
          InputLabelProps={{
            className: styles.datePickerLabel,
          }}
          KeyboardButtonProps={{
            id: "date_time_icon",
          }}
        />
      );
    default:
      break;
  }
}
