import { format, isValid } from "date-fns";

export const validChangeFields = [
  {
    name: "patient",
    displayName: "Patient",
    displayFn: (patient) => {
      try {
        const jsonPatient = JSON.parse(patient);
        if (jsonPatient) {
          return `${jsonPatient.firstName} ${jsonPatient.lastName}`;
        }
      } catch (e) {
        // no-op
      }
    },
  },
  {
    name: "recipientPractice",
    displayName: "Refer To Practice",
    displayFn: (recipientPractice) => {
      try {
        const jsonRecipientPractice = JSON.parse(recipientPractice);
        if (jsonRecipientPractice) {
          return `${jsonRecipientPractice.orgName}`;
        }
      } catch (e) {
        // no-op
      }
    },
  },
  {
    name: "recipient",
    displayName: "Refer To Practice",
    displayFn: (recipientPractice) => {
      try {
        const jsonRecipientPractice = JSON.parse(recipientPractice);
        if (jsonRecipientPractice) {
          return `${jsonRecipientPractice.orgName}`;
        }
      } catch (e) {
        // no-op
      }
    },
  },
  {
    name: "description",
    displayName: "Description",
  },
  {
    name: "specialty",
    displayName: "Specialty",
  },
  {
    name: "referralRecipientPhysician",
    displayName: "Preferred Provider",
  },
  {
    name: "referralRecipientPhysicianActual",
    displayName: "Seen By",
  },
  {
    name: "senderPractice",
    displayName: "Referring Practice",
  },
  {
    name: "senderName",
    displayName: "Referring Physician",
  },
  {
    name: "consultDate",
    displayName: "Consult Date",
    displayFn: (consultDate) => {
      if (isValid(new Date(consultDate))) {
        return format(new Date(consultDate), "MM/dd/yyyy");
      }
    },
  },
  {
    name: "oonReason",
    displayName: "Out of Network Reason",
  },
  {
    name: "priority",
    displayName: "Priority",
  },
];

export const isComment = (activity) => {
  return activity?.summary === "Added a comment";
};

export const isValidToDisplay = (fieldName) => {
  return validChangeFields.find((name) => name.name === fieldName);
};

export const getReferralUrl = (ref) => {
  return `/referrals/${
    ref.typeName.toLowerCase().startsWith("se") ? "sent" : "received"
  }/${ref?.key}`;
};

export const getDocumentUrl = (doc) => {
  return `/referral-documents/${doc?.key}`;
};

export const getClientDocumentUrl = (doc, version) => {
  return !version
    ? `/referral-documents/${doc?.clientId}/${doc?.uuid}`
    : `/referral-documents/${doc?.clientId}/${doc?.uuid}?versionId=${version}`;
};

export const getPatientName = ({ objectItem, relatedItems }) => {
  if (relatedItems?.length) {
    const patient = relatedItems.find((r) => r.typeName === "PatientInfo");

    if (patient) {
      return `${patient.name}`;
    }
  }

  if (objectItem?.typeName === "PATIENT_RECORD") {
    return objectItem?.name;
  }

  return "";
};
