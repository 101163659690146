import React, { createContext, useContext, useMemo } from "react";
import { useQuery } from "@apollo/client";
import { useSelector } from "react-redux";

import { GET_ALL_TENANT_USERS } from "../queries/users";

const UsersContext = createContext();
export const UsersProvider = ({ children }) => {
  const clientId = useSelector((state) => state.auth.clientId);
  const { data, loading, error } = useQuery(GET_ALL_TENANT_USERS, {
    variables: {
      clientId,
    },
    skip: !clientId,
    fetchPolicy: "cache-and-network",
  });

  const tenantUsers = useMemo(
    () => ({ tenantUsers: data ?? [], loading, error }),
    [data, loading, error]
  );
  return (
    <UsersContext.Provider value={tenantUsers}>
      {children}
    </UsersContext.Provider>
  );
};

export const useTenantUsers = () => {
  const context = useContext(UsersContext);
  if (context === undefined) {
    return new Error("Users context is undefined");
  }
  return context;
};
