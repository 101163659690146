import React from "react";
import Button from "@material-ui/core/Button";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  List,
  ListItem,
  Box,
} from "@material-ui/core";
import { resetForm } from "../../state/ducks/form";
import { closeSettingsDialog } from "../../state/ducks/settingsDialog";
import { useSelector, useDispatch } from "react-redux";
import { useAppSetting } from "../../context/appSettingContext.js";

export const determineExportMethod = (exportConfig) => {
  if (!exportConfig) {
    return "Not Configured";
  }

  const { exportType = null, exportValue = null } = exportConfig;

  if (exportType === "HL7 ORU") {
    return "ORU";
  }

  return exportType === "Fax"
    ? `Fax: ${exportValue}`
    : `Direct Address: ${exportValue}`;
};

const SettingsDialog = () => {
  const clientId = useSelector((state) => state.auth.clientId);
  const {
    settingsDialog: { inboundFaxNumberList = [], directAddressList = [] },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { exportConfig = null, clientName: orgName } = useAppSetting();
  return (
    <Dialog
      open={true}
      maxWidth="lg"
      onClose={() => {
        closeSettingsDialog();
        resetForm();
      }}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">Settings</DialogTitle>
      <DialogContent>
        <DialogContentText>{orgName}</DialogContentText>
        <Box>
          <Typography variant="body1">TenantId:</Typography>
          <List>
            <ListItem>{clientId}</ListItem>
          </List>
          <Typography variant="body1">Inbound Fax Numbers:</Typography>
          <List>
            {inboundFaxNumberList.length > 0 ? (
              inboundFaxNumberList.map((fax) => (
                <ListItem key={fax.did}>{fax.did}</ListItem>
              ))
            ) : (
              <ListItem key="fax_no_data">No inbound fax numbers</ListItem>
            )}
          </List>
          <Typography variant="body1">Import Direct Addresses:</Typography>
          <List>
            {directAddressList.length > 0 ? (
              directAddressList.map((address) => (
                <ListItem key={address.address}>{address.address}</ListItem>
              ))
            ) : (
              <ListItem key="fax_no_data">No direct addresses</ListItem>
            )}
          </List>
          <Typography variant="body1">My Export Method:</Typography>
          <List>
            <ListItem>{determineExportMethod(exportConfig)}</ListItem>
          </List>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            dispatch(closeSettingsDialog());
          }}
          color="primary"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SettingsDialog;
