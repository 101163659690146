import { makeStyles } from "@material-ui/core/styles";
import { red, lightGreen } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  halfScreen: {
    width: "48%",
  },
  center: {
    marginLeft: "calc(50% - 30px)",
    marginTop: "calc(15% - 30px)",
  },
  urgencyIndicator: {
    width: "auto",
  },
  urgent: {
    color: red[700],
    width: ".85em",
    height: ".85em",
  },
  wrapper: {
    display: "flex",
    justifyContent: "center",
    aligItems: "center",
  },
  routine: {
    color: lightGreen[700],
    width: ".85em",
    height: ".85em",
  },
  refLink: {
    textDecoration: "none",
    color: "inherit",
  },
  listContainer: {
    marginLeft: 12,
  },
}));

export { useStyles };
