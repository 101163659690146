export const PRIORITY_OPTIONS = [
  { label: "Routine", value: "Routine" },
  { label: "Urgent", value: "Urgent" },
  { label: "Stat", value: "Stat" },
];
export const DEFAULT_PRIORITY = PRIORITY_OPTIONS[0]; // ROUTINE

export const MAX_SELECTED_SERVICE_CODES = 4;

export const DOC_TYPE_TAG_URN =
  "urn:tag:{{clientId}}:CaptureDocumentTags:documentType";
export const DOC_DEPARTMENTS_TAG_URN =
  "urn:tag:{{clientId}}:CaptureDocumentTags:departments";
export const DOC_DESCRIPTION_TAG_URN =
  "urn:tag:{{clientId}}:CaptureDocumentTags:documentDescription";

export const SERVICE_REQUEST_TYPE = {
  DEFAULT: "default",
  ABSTRACT: "abstractEncounter",
};
