import {
  determineIfInNetwork,
  determineIfOnline,
  findProviderClientId,
} from "../state/ducks/directory";

export const canEditSenderLocation = ({ type, status }) => {
  if (type === "SENT") {
    if (!status || status === "New") {
      return true;
    }
  }
  return false;
};

export const generatePracticeAddress = (
  address = { street: "", city: "", state: "", zip: "" }
) => {
  if (!address) return "No address supplied";
  const { street, city, state, zip } = address;
  if (street && city && state && zip) {
    return `${street}, ${city}, ${state} ${zip}`;
  }
  if (city && (state || zip)) {
    return `${city}, ${state} ${zip}`;
  }
  if (state || zip) {
    return `${state} ${zip}`;
  }
  return "No address supplied";
};

export const getPracticeOptions = ({ practices, networkId = [] }) => {
  if (!practices) {
    return [];
  }
  const allNewPractices = practices?.providers.results.hits.items.map(
    (item) => {
      return {
        ...item.fields,
        inNetwork: determineIfInNetwork(item.fields, networkId),
        online: determineIfOnline(item.fields),
        insurancePlans: item.fields.insurancePlans || [],
      };
    }
  );
  return allNewPractices;
};

export const getSpecialitiesOptions = ({
  recipientPracticeValue,
  specialtyInputValue,
  allSpecialties,
  tenantLocations,
}) => {
  const allSpecialtiesOptions = allSpecialties?.map((item) => {
    return { name: item.name, value: item.name };
  });

  if (specialtyInputValue) {
    return allSpecialtiesOptions?.filter((item) => {
      return (
        item.name.toLowerCase().indexOf(specialtyInputValue.toLowerCase()) >= 0
      );
    });
  }
  if (recipientPracticeValue) {
    const specialityValue = [...new Set(recipientPracticeValue?.specialties)];
    return specialityValue.map((item) => {
      return { name: item, value: item };
    });
  } else if (tenantLocations?.length > 0) {
    return Array.from(
      new Set(
        tenantLocations
          .reduce((prev, cur) => {
            prev = [...prev, ...cur.specialties];
            return prev;
          }, [])
          .map((item) => ({ label: item, value: item }))
      )
    );
  } else {
    return allSpecialtiesOptions || [];
  }
};

export const getProviderOptions = ({ providersData }) => {
  if (!providersData) {
    return [];
  }
  const allProviders = providersData?.providers.results.hits.items.map(
    (item) => {
      return {
        label: `${item.fields.prefixName} ${item.fields.firstName} ${item.fields.lastName}`,
        value: item.fields,
      };
    }
  );
  return allProviders;
};

const getLocationUrn = (urn) => {
  return urn.find((item) => item.includes("locationId"));
};

export const getQueryVariable = ({
  query,
  urn,
  specialty,
  networkIds,
  practiceType,
}) => {
  let locationUrn = "";
  if (urn?.length === 1) {
    locationUrn = urn[0];
  }
  if (urn?.length > 1) {
    locationUrn = getLocationUrn(urn) || "";
  }

  // Check if we really need this filter, currently is not working because should be 'networks' as plural
  const networkIdFilters = networkIds?.map((item) => ({
    identifier: "provider.network",
    value: item,
  }));
  const typeFilter = practiceType
    ? { identifier: "provider.type", value: "Practice" }
    : { identifier: "provider.type", value: "Provider" };

  const variable = {
    query: query,
    filters: [...networkIdFilters, typeFilter],
    page: {
      size: 100,
    },
    sortBy: "relevance",
  };

  if (locationUrn) {
    return {
      ...variable,
      filters: [
        ...variable.filters,
        {
          identifier: "provider.referenceUrns",
          value: locationUrn,
        },
      ],
    };
  }

  return specialty
    ? {
        ...variable,
        filters: [
          ...variable.filters,
          {
            identifier: "provider.specialties",
            value: specialty.value,
          },
        ],
      }
    : variable;
};

export const recipientIsEditable = ({ recipientPractice, status = "" }) =>
  recipientPractice
    ? Boolean(status === "New" || status === "Send Failed" || status === "")
    : true;

export const getSortedPractices = ({
  practices,
  networkId,
  primaryInsurance = {},
}) => {
  if (!practices) {
    return [];
  }

  return practices.sort((left, right) => {
    return (
      right.networks?.some((network) => networkId.includes(network)) -
      left.networks?.some((network) => networkId.includes(network))
    );
  });
};

export const hydrateReferralVariables = (data) => {
  const { recipientPractice, patient, senderProvider } = data;
  return {
    senderPractice: data?.senderPractice,
    recipientPractice: {
      id: recipientPractice?.id,
      type: recipientPractice?.type,
      orgName: recipientPractice?.orgName,
      prefixName: recipientPractice?.prefixName,
      firstName: recipientPractice?.firstName,
      lastName: recipientPractice?.lastName,
      address: {
        city: recipientPractice?.address?.city,
        state: recipientPractice?.address?.state,
        zip: recipientPractice?.address?.zip,
        street: recipientPractice?.address?.street,
      },
      faxPhone: recipientPractice?.faxPhone,
      workPhone: recipientPractice?.workPhone,
      networks: recipientPractice?.networks,
      referenceUrns: recipientPractice?.referenceUrns,
      specialties: recipientPractice?.specialties,
      referenceId: recipientPractice?.referenceId,
      networkId: recipientPractice?.networkId,
      contactType: recipientPractice?.contactType,
      npi: recipientPractice?.npi,
      insurancePlans: recipientPractice?.insurancePlans?.map((plan) => ({
        id: plan.id,
        name: plan.name,
      })),
      emrId: recipientPractice?.emrId,
    },
    referralRecipientPhysician: data?.referralRecipientPhysician,
    priority: data?.priority,
    patient: {
      id: patient?.id,
      mrn: patient?.mrn,
      lastName: patient?.lastName,
      address: patient?.address,
      city: patient?.city,
      country: patient?.country,
      zip: patient?.zip,
      state: patient?.state,
      firstName: patient?.firstName,
      dob: patient?.dob,
      gender: patient?.gender,
      middleName: patient?.middleName,
      homePhoneNumber: patient?.homePhoneNumber,
    },
    description: data?.description,
    specialty: data?.specialty,
    senderProvider: {
      id: senderProvider?.id,
      type: senderProvider?.type,
      orgName: senderProvider?.orgName,
      prefixName: senderProvider?.prefixName,
      firstName: senderProvider?.firstName,
      lastName: senderProvider?.lastName,
      address: {
        city: senderProvider?.city,
        state: senderProvider?.state,
        zip: senderProvider?.zip,
        street: senderProvider?.street,
      },
      faxPhone: senderProvider?.faxPhone,
      workPhone: senderProvider?.workPhone,
      networks: senderProvider?.networks,
      referenceUrns: senderProvider?.referenceUrns,
      specialties: senderProvider?.specialties,
      referenceId: senderProvider?.referenceId,
      networkId: senderProvider?.networkId,
      contactType: senderProvider?.contactType,
      npi: senderProvider?.npi,
      insurancePlans:
        senderProvider?.insurancePlans?.map((plan) => ({
          id: plan.id,
          name: plan.name,
        })) || [],
      emrId: senderProvider.emrId,
    },
    senderName: data?.senderName,
    oonReason: data?.oonReason,
    userId: data?.userId,
    recipientContactType: data?.recipientContactType,
    recipientContactNumber: data?.recipientContactNumber,
    patientId: patient?.id,
    networkId: data?.networkId,
    sentReferralNetworkId: data?.sentReferralNetworkId,
    assigneeId: data?.userId,
    processDefinitionKey: data?.processDefinitionKey,
    processType: data?.processType,
    requestedServices: data?.requestedServices || [],
  };
};

export const prepReferralForSubmission = (data, referralType) => {
  let recipientContactType, recipientContactNumber;
  if (data.recipientPractice && determineIfOnline(data.recipientPractice)) {
    recipientContactType = "online";
    recipientContactNumber = findProviderClientId(data.recipientPractice);
  } else if (data.recipientPractice) {
    recipientContactType = "offline";
    recipientContactNumber = data.recipientPractice.faxPhone;
  }

  let formValues = structuredClone({
    ...data,
    recipientContactType,
    recipientContactNumber,
    processDefinitionKey:
      referralType === "Received" ? "referralReceiver" : "referralSender",
    processType: "referralProcess",
  });
  delete formValues?.type;

  if (data.patient) {
    delete formValues?.patient?.insurance;
    delete formValues?.patient?.workPhoneNumber;
    delete formValues?.patient?.uuid;
    delete formValues?.patient?.displayName;
  }

  if (data.seenAtProvider) {
    delete formValues?.seenAtProvider?.__typename;
    delete formValues?.seenAtProvider?.address?.__typename;
    formValues?.seenAtProvider?.insurancePlans?.forEach((insurancePlan) => {
      delete insurancePlan?.__typename;
    });
  }

  if (data.recipientPractice) {
    delete formValues?.recipientPractice?.__typename;
    delete formValues?.recipientPractice?.address?.__typename;
    delete formValues?.recipientPractice?.inNetwork;
    delete formValues?.recipientPractice?.online;
    formValues.recipientPractice.insurancePlans =
      data?.recipientPractice?.insurancePlans?.map((plan) => ({
        id: plan.id,
        name: plan.name,
      }));

    // If not multi-network set recipientPractice.networkId as default
    if (!formValues.sentReferralNetworkId)
      formValues.networkId = data.recipientPractice.networkId;
  }

  return formValues;
};
