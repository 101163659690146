import { useSearchkit } from "@searchkit/client";
import { EuiButton, EuiFlexGroup, EuiFlexItem } from "@elastic/eui";
import React from "react";
import PropTypes from "prop-types";

const NumericRangeFilter = ({ filter, loading }) => {
  const api = useSearchkit();

  return (
    <EuiFlexItem grow={false}>
      <EuiButton
        onClick={() => {
          api.removeFilter(filter);
          api.search();
        }}
        iconSide="right"
        iconType="cross"
        isLoading={loading}
      >
        {filter.label}: {filter.min} - {filter.max}
      </EuiButton>
    </EuiFlexItem>
  );
};

const DateRangeFilter = ({ filter, loading }) => {
  const api = useSearchkit();

  return (
    <EuiFlexItem grow={false}>
      <EuiButton
        onClick={() => {
          api.removeFilter(filter);
          api.search();
        }}
        iconSide="right"
        iconType="cross"
        isLoading={loading}
      >
        {filter.label}: {new Date(filter.dateMin).toDateString()} -{" "}
        {new Date(filter.dateMax).toDateString()}
      </EuiButton>
    </EuiFlexItem>
  );
};

export const PHSelectedFilters = ({
  loading,
  data,
  customFilterComponents = {},
}) => {
  const filterComponentMap = {
    RangeSliderFacet: NumericRangeFilter,
    DateRangeFacet: DateRangeFilter,
    ...customFilterComponents,
  };
  return (
    <EuiFlexGroup
      gutterSize="s"
      alignItems="center"
      style={{ flexWrap: "wrap" }}
    >
      {data?.summary?.appliedFilters.map((filter) => {
        const FilterComponent = filterComponentMap[filter.display];
        if (!filterComponentMap[filter.display])
          throw new Error(
            `could not display selected filters for ${filter.identifier} facet. Use customFilterComponents prop to pass a component to handle this filter for ${filter.display} display`
          );
        return (
          <FilterComponent filter={filter} loading={loading} key={filter.id} />
        );
      })}
    </EuiFlexGroup>
  );
};

NumericRangeFilter.propTypes = {
  filter: PropTypes.shape({
    label: PropTypes.string,
    min: PropTypes.number,
    max: PropTypes.number,
  }),
  loading: PropTypes.bool,
};

DateRangeFilter.propTypes = {
  filter: PropTypes.shape({
    label: PropTypes.string,
    dateMin: PropTypes.instanceOf(Date),
    dateMax: PropTypes.instanceOf(Date),
  }),
  loading: PropTypes.bool,
};

PHSelectedFilters.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.shape({
    summary: PropTypes.shape({
      appliedFilters: PropTypes.arrayOf(
        PropTypes.shape({
          display: PropTypes.string,
          identifier: PropTypes.string,
          id: PropTypes.string,
        })
      ),
    }),
  }),
  customFilterComponents: PropTypes.object,
};
