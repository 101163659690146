import { gql } from "@apollo/client";

export const ACTIVITIES_QUERY = gql`
  query activityQuery(
    $query: String
    $filters: [SKFiltersSet]
    $page: SKPageInput
  ) {
    activities {
      results(query: $query, filters: $filters) {
        summary {
          appliedFilters {
            id
            identifier
            display
            label
            ... on DateRangeSelectedFilter {
              dateMin
              dateMax
            }

            ... on NumericRangeSelectedFilter {
              min
              max
            }

            ... on ValueSelectedFilter {
              value
            }
          }
          query
        }
        hits(page: $page) {
          page {
            total
            totalPages
            pageNumber
            from
            size
            __typename
          }
          items {
            ... on ActivityResultHit {
              id
              fields {
                clientId
                eventTime
                eventType
                userId
                read
                user {
                  firstName
                  lastName
                }
                description
                summary
                objectItem {
                  key
                  name
                  typeName
                }
                relatedItems {
                  id
                  key
                  name
                  typeName
                }
              }
            }
          }
        }
        facets {
          identifier
          type
          label
          display
          entries {
            value
            label
            count
            __typename
          }
          __typename
        }
      }
    }
  }
`;

export const REFERRAL_ACTIVITIES_QUERY = gql`
  query referralActivityQuery(
    $query: String
    $filters: [SKFiltersSet]
    $page: SKPageInput
  ) {
    activities {
      results(query: $query, filters: $filters) {
        hits(page: $page) {
          page {
            total
            totalPages
            pageNumber
            from
            size
            __typename
          }
          items {
            ... on ActivityResultHit {
              fields {
                changedValues {
                  changedFrom
                  changedTo
                  fieldName
                }
                eventTime
                eventSource
                eventType
                category
                userId
                user {
                  firstName
                  lastName
                  __typename
                }
                clientId
                description
                summary
                objectItem {
                  id
                  key
                  typeName
                  __typename
                }
                relatedItems {
                  id
                  key
                  name
                  typeName
                  __typename
                }
                __typename
              }
              __typename
            }
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
