// Actions
const OPEN = "hermes/user-popover/OPEN";
const CLOSE = "hermes/user-popover/CLOSE";

const initialState = {
  userPopover: null,
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case OPEN:
      return {
        ...state,
        userPopover: action.userPopover,
      };
    case CLOSE:
      return {
        ...state,
        userPopover: null,
      };
    default:
      return state;
  }
}

// Action Creators
export function openUserPopover(userPopover) {
  return {
    type: OPEN,
    userPopover: userPopover,
  };
}

export function closeUserPopover() {
  return {
    type: CLOSE,
  };
}
