/**
 * @Date:   2019-10-21T15:06:21+05:30
 * @Last modified time: 2019-10-21T15:24:33+05:30
 */

import { put, takeEvery, takeLatest, call } from "redux-saga/effects";
import delay from "@redux-saga/delay-p";
import {
  showProcessingSpinner,
  hideProcessingSpinner,
  resetSavedStates,
  prepopulateReferralForm,
  resetFormCache,
} from "./form";
import {
  reverseState,
  fetchReceivedReferral,
  acceptReferral,
  rejectReferral,
  flagReferral,
  updateReferral,
  addDocumentsToReceivedReferral,
  moveToInReview,
  moveToScheduled,
  moveToConsulted,
  moveToDone,
  moveToClosed,
  clearConsultDateReferral,
} from "../../services/ReferralService";
import { setInspectedReferral } from "./referralInspect";
import { modifyFlagOnSearch } from "./referralSearch";
import { removeDocumentsFromList } from "./docserver";
import { upgradeDocsToPerm } from "../../services/DocServerService";
import { determineIfOnline } from "./directory";
import { getAuthToken, getClientId, checkAuthState, getUserId } from "./auth";
import { closeCardDetailModal, openCardDetailModal } from "./cardDetailModal";
import { enqueueSnackbar } from "./notifiers";
import { closeDialog as closeEditDialog } from "./editDialog";
import { addCommentToReferral } from "./activity";
import _ from "lodash";

// Actions
const CHANGE_REFERRAL_STATE = "hermes/referrals-received/CHANGE_REFERRAL_STATE";
const CHANGE_REFERRAL_STATE_SUCCESS =
  "hermes/referrals-received/CHANGE_REFERRAL_STATE_SUCCESS";
const CHANGE_REFERRAL_STATE_FAILURE =
  "hermes/referrals-received/CHANGE_REFERRAL_STATE_FAILURE";
const TOGGLE_REFERRAL_FLAG = "hermes/referrals-received/TOGGLE_REFERRAL_FLAG";
const TOGGLE_REFERRAL_FLAG_SUCCESS =
  "hermes/referrals-received/TOGGLE_REFERRAL_FLAG_SUCCESS";
const TOGGLE_REFERRAL_FLAG_FAILURE =
  "hermes/referrals-received/TOGGLE_REFERRAL_FLAG_FAILURE";
const TOGGLE_REFERRAL_FLAG_RESET =
  "hermes/referrals-received/TOGGLE_REFERRAL_FLAG_RESET";
const EDIT_REFERRAL = "hermes/referrals-received/EDIT_REFERRAL";
export const EDIT_REFERRAL_SUCCESS =
  "hermes/referrals-received/EDIT_REFERRAL_SUCCESS";
const EDIT_REFERRAL_FAILURE = "hermes/referrals-received/EDIT_REFERRAL_FAILURE";
const GET_RECEIVED_REFERRALS =
  "hermes/referrals-received/GET_RECEIVED_REFERRALS";
const GET_RECEIVED_REFERRALS_SUCCESS =
  "hermes/referrals-received/GET_RECEIVED_REFERRALS_SUCCESS";
const GET_RECEIVED_REFERRAL = "hermes/referrals-received/GET_RECEIVED_REFERRAL";
const GET_RECEIVED_REFERRAL_SUCCESS =
  "hermes/referrals-received/GET_RECEIVED_REFERRAL_SUCCESS";
const SELECT_ASSIGNEE = "hermes/referrals-received/SELECT_ASSIGNEE";
const SELECT_ASSIGNEE_SUCCESS =
  "hermes/referrals-received/SELECT_ASSIGNEE_SUCCESS";
const SELECT_ASSIGNEE_FAILURE =
  "hermes/referrals-received/SELECT_ASSIGNEE_FAILURE";
const BEGIN_PERIODIC_FETCH = "hermes/referrals-received/BEGIN_PERIODIC_FETCH";
const LOADING_START = "hermes/referrals-received/LOADING_START";
const LOADING_FINISH = "hermes/referrals-received/LOADING_FINISH";
const CLEAR_REFERRAL_ACTION_COMMENT =
  "hermes/referrals-received/CLEAR_REFERRAL_ACTION_COMMENT";
const RESET_REFRESH_FLAG = "hermes/referrals-sent/RESET_REFRESH_FLAG";

const initialState = {
  list: [],
  details: [],
  loaded: false,
  referralFlagToggledId: null,
  referralActionComment: "",
  referralStatus: "",
  refreshReferralSearch: false,
  loading: false,
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_RECEIVED_REFERRALS_SUCCESS:
      return {
        ...state,
        list: action.response,
        loaded: true,
      };
    case GET_RECEIVED_REFERRAL_SUCCESS:
      const getReceivedDetails = _.cloneDeep(state.details);
      const detailExists = state.details.findIndex(
        (referral) =>
          action.processInstanceId === referral.id ||
          action.response?.id === referral.id
      );
      if (detailExists !== -1) {
        getReceivedDetails[detailExists] = action.response;
      } else {
        getReceivedDetails.push(action.response);
      }
      return {
        ...state,
        details: getReceivedDetails,
      };
    case CHANGE_REFERRAL_STATE:
      const changeStateOptDetail = _.cloneDeep(state.details);
      const changeStateOptDetailExists = state.details.findIndex(
        (referral) =>
          action.processInstanceId === referral.id ||
          action.referral?.id === referral.id
      );
      if (changeStateOptDetailExists !== -1) {
        changeStateOptDetail[changeStateOptDetailExists] = {
          ...changeStateOptDetail[changeStateOptDetailExists],
          status: action.newState,
        };
      }
      return {
        ...state,
        list: [
          ...state.list.map((referral) => {
            if (action.referralId === referral.id) {
              return {
                ...referral,
                status: action.newState,
              };
            }
            return referral;
          }),
        ],
        details: changeStateOptDetail,
      };
    case CHANGE_REFERRAL_STATE_SUCCESS:
      let referralActionComment = "";
      const changeStateDetail = _.cloneDeep(state.details);
      const changeStateDetailExists = state.details.findIndex(
        (referral) =>
          action.processInstanceId === referral.id ||
          action.referral?.id === referral.id
      );
      if (changeStateDetailExists !== -1) {
        changeStateDetail[changeStateDetailExists] = {
          ...changeStateDetail[changeStateDetailExists],
          status: action.referral.status,
        };
      } else {
        changeStateDetail.push(action.referral);
      }

      if (action.referral.status === "Done" && action.referral.comment) {
        referralActionComment = action.referral.comment;
      }
      return {
        ...state,
        referralActionComment,
        referralStatus: action.referral.status,
        list: [
          ...state.list.map((referral) => {
            if (action.referral.id === referral.id) {
              return action.referral;
            }
            return referral;
          }),
        ],
        details: changeStateDetail,
        refreshReferralSearch: true,
      };
    case CLEAR_REFERRAL_ACTION_COMMENT:
      return {
        ...state,
        referralActionComment: "",
      };
    case CHANGE_REFERRAL_STATE_FAILURE:
      const changeStateFailDetail = _.cloneDeep(state.details);
      const changeStateFailDetailExists = state.details.findIndex(
        (referral) =>
          action.processInstanceId === referral.id ||
          action.referral?.id === referral.id
      );
      if (changeStateFailDetailExists !== -1) {
        changeStateFailDetail[changeStateFailDetailExists] = action.referral;
      }
      return {
        ...state,
        list: [
          ...state.list.map((referral) => {
            if (action.referral.id === referral.id) {
              return action.referral;
            }
            return referral;
          }),
        ],
        details: changeStateFailDetail,
      };
    case EDIT_REFERRAL_SUCCESS:
      const editDetail = _.cloneDeep(state.details);
      const editDetailExists = state.details.findIndex(
        (referral) =>
          action.processInstanceId === referral.id ||
          action.referral?.id === referral.id
      );
      if (editDetailExists !== -1) {
        editDetail[editDetailExists] = {
          ...(editDetail[editDetailExists].patientId ===
            action.referral.patientId && {
            patient: editDetail[editDetailExists].patient,
          }),
          ...action.referral,
        };
      }
      return {
        ...state,
        list: state.list.map((ref) => {
          if (ref.id === action.referral.id) {
            return {
              ...(ref.patientId === action.referral.patientId && {
                patient: ref.patient,
              }),
              ...action.referral,
            };
          }
          return ref;
        }),
        details: editDetail,
      };
    case TOGGLE_REFERRAL_FLAG_SUCCESS:
      return {
        ...state,
        referralFlagToggledId: action.referralId,
        referralActionComment: action.comment,
        list: state.list.map((ref) => {
          if (ref.id === action.id) {
            return { ...ref, flagged: !ref.flagged };
          }
          return ref;
        }),
      };
    case TOGGLE_REFERRAL_FLAG_RESET:
      return {
        ...state,
        referralFlagToggledId: null,
      };
    case LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case LOADING_FINISH:
      return {
        ...state,
        loading: false,
      };
    case RESET_REFRESH_FLAG:
      return {
        ...state,
        refreshReferralSearch: false,
      };
    default:
      return state;
  }
}

// Action Creators
export function changeReferralState(
  referralId,
  displayableId,
  taskId,
  intendedState,
  userSelection,
  formValues,
  documents,
  updateTarget,
  username
) {
  return {
    type: CHANGE_REFERRAL_STATE,
    referralId,
    taskId,
    newState: intendedState,
    userSelection,
    displayableId,
    formValues,
    documents,
    updateTarget,
    username,
  };
}

export function resetReceivedRefresh() {
  return { type: RESET_REFRESH_FLAG };
}

export function clearReferralActionComment() {
  return {
    type: CLEAR_REFERRAL_ACTION_COMMENT,
  };
}

export function getReceivedReferrals(referralId) {
  return {
    type: GET_RECEIVED_REFERRALS,
    referralId,
  };
}

export function getReceivedReferralsApollo(data) {
  return {
    type: GET_RECEIVED_REFERRALS_SUCCESS,
    response: data,
  };
}

export function startApolloLoading() {
  return { type: LOADING_START };
}

export function finishApolloLoading() {
  return { type: LOADING_FINISH };
}

export function getReceivedReferral(referral, options = {}) {
  const { inspectReferral, cardDetail } = options;
  return {
    type: GET_RECEIVED_REFERRAL,
    referral,
    inspectReferral,
    cardDetail,
  };
}

export function toggleReceivedReferralFlag(
  referralId,
  displayableId,
  isFlagged,
  comment,
  fullScreen
) {
  return {
    type: TOGGLE_REFERRAL_FLAG,
    referralId,
    displayableId,
    isFlagged,
    comment,
    fullScreen,
  };
}

export function resetReceiveReferralFlag() {
  return {
    type: TOGGLE_REFERRAL_FLAG_RESET,
  };
}

export function editReceivedReferral(
  referralId,
  displayableId,
  formValues,
  documents,
  skipPrepopulate,
  updateTarget
) {
  return {
    type: EDIT_REFERRAL,
    referralId,
    displayableId,
    formValues,
    documents,
    skipPrepopulate,
    updateTarget,
  };
}

export function selectAssignee(referralId, displayableId, assignee) {
  return {
    type: SELECT_ASSIGNEE,
    referralId,
    displayableId,
    assignee,
  };
}

export function fetchReceivedReferralsPeriodically() {
  return {
    type: BEGIN_PERIODIC_FETCH,
  };
}

// Sagas
function* handleReferralStateChange(action) {
  let authToken = yield call(getAuthToken);
  let clientId = yield call(getClientId);
  let userId = yield call(getUserId);
  const referral = yield call(
    fetchReceivedReferral,
    authToken,
    clientId,
    action.referralId
  );

  if (action.newState && action.newState === "Scheduled") {
    yield put(showProcessingSpinner());
  }
  try {
    let comment;
    if (action.formValues && Object.keys(action.formValues).length) {
      if (action.formValues["consultNotes"]) {
        yield put(
          addCommentToReferral(
            {
              id: action.referralId,
            },
            action.formValues["consultNotes"],
            false,
            action.username
          )
        );
      }
      if (action.formValues["newComment"]) {
        comment = action.formValues["newComment"];
        if (action.userSelection !== "Reject") {
          yield put(
            addCommentToReferral(
              {
                id: action.referralId,
              },
              action.formValues["newComment"],
              false,
              action.username
            )
          );
        }
        delete action.formValues["newComment"];
      }
      if (action.formValues["rejectionReason"]) {
        comment = `Rejection Reason - ${action.formValues.rejectionReason}${
          (action.formValues.rejectionComment &&
            ` - ${action.formValues.rejectionComment}`) ||
          ""
        }`;
        if (action.userSelection !== "Reject") {
          yield put(
            addCommentToReferral(
              {
                id: action.referralId,
              },
              `Rejection Reason - ${action.formValues.rejectionReason}${
                action.formValues.rejectionComment &&
                ` - ${action.formValues.rejectionComment}`
              }`
            )
          );
        }
        delete action.formValues.rejectionReason;
        delete action.formValues.rejectionComment;
      }
    }

    if (action.documents && action.documents.length > 0) {
      let docList = action.documents;
      if (
        docList &&
        docList.length > 0 &&
        action.documents.filter((doc) => doc.temporary).length > 0
      ) {
        docList = yield call(
          upgradeDocsToPerm,
          authToken,
          clientId,
          action.documents.filter((doc) => doc.temporary)
        );

        docList = [
          ...action.documents.filter((doc) => !doc.temporary),
          ...docList,
        ];
      }

      yield put(removeDocumentsFromList(docList));
      yield call(
        addDocumentsToReceivedReferral,
        authToken,
        clientId,
        action.referralId,
        docList
      );
    }

    const getMessage = {
      "In review": moveToInReview,
      Accept: acceptReferral,
      Scheduled: moveToScheduled,
      Consulted: moveToConsulted,
      Done: moveToDone,
      Closed: moveToClosed,
      Reject: rejectReferral,
    };

    if (action.userSelection === "Reject" || action.newState === "Reject") {
      yield call(
        getMessage[action.userSelection] || getMessage[action.newState],
        authToken,
        clientId,
        action.referralId,
        { comment, userId }
      );
    } else {
      if (action.userSelection === "Reverse") {
        yield call(
          reverseState,
          authToken,
          action.referralId,
          action.newState,
          clientId
        );

        if (action.newState === "Scheduling") {
          yield call(
            clearConsultDateReferral,
            authToken,
            clientId,
            action.referralId
          );
        }
      } else {
        yield call(
          getMessage[action.userSelection] || getMessage[action.newState],
          authToken,
          action.referralId,
          clientId
        );
      }
    }

    yield put(closeEditDialog());
    yield put(closeCardDetailModal());
    if (
      action.userSelection === "Reverse" &&
      action.newState === "Scheduling"
    ) {
      yield put({
        referral: {
          ...referral,
          status: action.newState || action.userSelection,
          consultDate: null,
          comment,
        },
        type: CHANGE_REFERRAL_STATE_SUCCESS,
      });
    } else {
      yield put({
        referral: {
          ...referral,
          status: action.newState || action.userSelection,
          consultDate: action.formValues.consultDate,
          comment,
        },
        type: CHANGE_REFERRAL_STATE_SUCCESS,
      });
    }

    if (action.updateTarget) {
      yield put(setInspectedReferral(referral, action.newState));
    }
    yield put(resetSavedStates());
    if (action.newState && action.newState === "Scheduled") {
      yield put(hideProcessingSpinner());
    }
    yield put(
      enqueueSnackbar({
        message: `Referral ${action.displayableId} moved to ${action.newState}!`,
        options: {
          variant: "success",
        },
      })
    );

    yield delay(5000);
    yield put(getReceivedReferrals());
  } catch (e) {
    let clientId = yield call(getClientId);
    let referral = yield call(
      fetchReceivedReferral,
      authToken,
      clientId,
      action.referralId
    );
    let errorLogging = {};
    if (!_.includes(e.message, "401")) {
      errorLogging = {
        error: true,
        payload: e,
      };
    }
    yield put({
      type: CHANGE_REFERRAL_STATE_FAILURE,
      referral,
      ...errorLogging,
    });
    yield put(checkAuthState());
    if (action.newState && action.newState === "Scheduled") {
      yield put(hideProcessingSpinner());
    }

    if (_.includes(e.message, "400")) {
      yield put(
        enqueueSnackbar({
          message: `Movement prevented because the other practice has Closed this referral and is not able to receive any updates..`,
          options: {
            variant: "error",
          },
        })
      );
    } else {
      console.log(e.message);
      yield put(
        enqueueSnackbar({
          message: `Error moving referral.`,
          options: {
            variant: "error",
          },
        })
      );
    }
  }
}
// Temporal function to support non refactored methods
function* handleGetReceivedReferralTmp(referralId) {
  let authToken = yield call(getAuthToken);
  let clientId = yield call(getClientId);
  return yield call(fetchReceivedReferral, authToken, clientId, referralId);
}

function* handleGetReceivedReferral(action) {
  try {
    let response = action?.referral;

    // Temporal condition to support non refactored methods
    /*
     * TODO: Remove this condition when EX-3669, EX-3653
     * */
    if (typeof response !== "object") {
      response = yield call(handleGetReceivedReferralTmp, response);
    }
    response = { ...response };
    response.inNetwork = null;
    yield put({
      response,
      type: GET_RECEIVED_REFERRAL_SUCCESS,
    });
    if (action.inspectReferral) {
      yield put(setInspectedReferral(response));
    }
    if (action.cardDetail) {
      yield put(openCardDetailModal(response));
    }
  } catch (e) {
    if (action.inspectReferral) {
      yield put(setInspectedReferral(null));
    }
    yield put(
      enqueueSnackbar({
        message: `Unable to fetch referral.`,
        options: {
          variant: "error",
        },
      })
    );
  }
}

function* handleToggleReceivedReferralFlag(action) {
  try {
    let authToken = yield call(getAuthToken);
    let clientId = yield call(getClientId);
    let userId = yield call(getUserId);
    let response = yield call(
      flagReferral,
      authToken,
      clientId,
      action.referralId,
      { comment: action.comment, userId }
    );
    yield put({
      ...response,
      type: TOGGLE_REFERRAL_FLAG_SUCCESS,
      referralId: `${action.referralId}`,
      comment: action.comment,
    });
    yield put(resetFormCache("flagComment"));
    yield put(modifyFlagOnSearch(action.referralId));

    if (action.fullScreen) {
      yield put(
        getReceivedReferral(action.referralId, { inspectReferral: true })
      );
    }

    yield put(
      enqueueSnackbar({
        message: `Referral ${action.displayableId} ${
          action.isFlagged ? "un" : ""
        }flagged!`,
        options: {
          variant: "success",
        },
      })
    );
    yield delay(5000);
    yield put(getReceivedReferrals());
  } catch (e) {
    let errorLogging = {};
    if (!_.includes(e.message, "401")) {
      errorLogging = {
        error: true,
        payload: e,
      };
    }
    yield put(
      enqueueSnackbar({
        message: `Error flagging referral.`,
        options: {
          variant: "error",
        },
      })
    );
    yield put({
      ...action,
      type: TOGGLE_REFERRAL_FLAG_FAILURE,
      ...errorLogging,
    });
  }
}

function* handleEditReceivedReferral(action) {
  try {
    yield put({ type: LOADING_START });
    yield put(showProcessingSpinner());
    let authToken = yield call(getAuthToken);
    let clientId = yield call(getClientId);
    let recipientContactType, recipientContactNumber;
    if (
      action.formValues.recipientPractice &&
      determineIfOnline(action.formValues.recipientPractice)
    ) {
      recipientContactType = "online";
      recipientContactNumber = action.formValues.recipientPractice.clientId;
    } else if (action.formValues.recipientPractice) {
      recipientContactType = "offline";
      recipientContactNumber = action.formValues.recipientPractice.faxPhone;
    }

    let docList = action.documents;
    if (docList && docList.length > 0) {
      docList = yield call(
        upgradeDocsToPerm,
        authToken,
        clientId,
        action.documents.filter((doc) => doc.temporary)
      );
      docList = [
        ...action.documents.filter(
          (doc) => !doc.temporary && Boolean(doc.uuid)
        ),
        ...docList,
      ];
      yield put(
        removeDocumentsFromList(action.documents.filter((doc) => doc.temporary))
      );
    }

    let patientId = action.formValues.patient && action.formValues.patient.id;

    let formValues = {
      ...action.formValues,
      documents: docList,
      recipientContactType,
      recipientContactNumber,
      patientId,
    };
    delete formValues.assignee;
    delete formValues.type;
    let response = yield call(
      updateReferral,
      authToken,
      clientId,
      action.referralId,
      formValues
    );
    yield put({
      referral: response,
      type: EDIT_REFERRAL_SUCCESS,
    });
    yield put(
      enqueueSnackbar({
        message: `Referral ${action.displayableId} saved!`,
        options: {
          variant: "success",
        },
      })
    );
    yield put(hideProcessingSpinner());
    if (action.updateTarget) {
      yield put(setInspectedReferral(response));
    }
    if (!action.skipPrepopulate) {
      yield put(prepopulateReferralForm(response));
    }
    yield put({ type: LOADING_FINISH });
  } catch (e) {
    let errorLogging = {};
    if (!_.includes(e.message, "401")) {
      errorLogging = {
        error: true,
        payload: e,
      };
    }
    yield put(
      enqueueSnackbar({
        message: `Error updating referral.`,
        options: {
          variant: "error",
        },
      })
    );

    yield put(hideProcessingSpinner());
    yield put({
      ...action,
      type: EDIT_REFERRAL_FAILURE,
      ...errorLogging,
    });
    yield put({ type: LOADING_FINISH });
  }
}

function* handleSelectAssignee(action) {
  let authToken = yield call(getAuthToken);
  let clientId = yield call(getClientId);
  yield put({ type: LOADING_START });

  try {
    let response = yield call(
      updateReferral,
      authToken,
      clientId,
      action.referralId,
      {
        assignee: {
          ...action.assignee,
          userId: action.assignee.userId || action.assignee.id,
        },
      }
    );
    yield put({
      ...response,
      type: SELECT_ASSIGNEE_SUCCESS,
    });
    yield put(
      enqueueSnackbar({
        message: `${
          action.assignee &&
          action.assignee.firstName &&
          action.assignee.lastName
            ? `${action.assignee.firstName} ${action.assignee.lastName}`
            : "Unknown user"
        } assigned to ${action.displayableId}!`,
        options: {
          variant: "success",
        },
      })
    );
    yield put(
      getReceivedReferral(action.referralId, { inspectReferral: true })
    );
    yield delay(5000);
    yield put(getReceivedReferrals());
    yield put({ type: LOADING_FINISH });
  } catch (e) {
    let errorLogging = {};
    if (!_.includes(e.message, "401")) {
      errorLogging = {
        error: true,
        payload: e,
      };
    }
    yield put({
      ...action,
      type: SELECT_ASSIGNEE_FAILURE,
      ...errorLogging,
    });
    yield put(checkAuthState());
    yield put(
      enqueueSnackbar({
        message: `Error assigning referral.`,
        options: {
          variant: "error",
        },
      })
    );
    yield put({ type: LOADING_FINISH });
  }
}

function* handleFetchReceivedReferralsPeriodically() {
  yield delay(60000);

  while (true) {
    yield put(getReceivedReferrals());
    yield delay(30000);
  }
}

export function* changeReceivedReferralSaga() {
  yield takeEvery(CHANGE_REFERRAL_STATE, handleReferralStateChange);
}

export function* toggleReceivedReferralFlagSaga() {
  yield takeEvery(TOGGLE_REFERRAL_FLAG, handleToggleReceivedReferralFlag);
}

export function* editReceivedReferralSaga() {
  yield takeEvery(EDIT_REFERRAL, handleEditReceivedReferral);
}

export function* getReceivedReferralSaga() {
  yield takeLatest(GET_RECEIVED_REFERRAL, handleGetReceivedReferral);
}

export function* fetchReceivedReferralsPeriodicallySaga() {
  yield takeEvery(
    BEGIN_PERIODIC_FETCH,
    handleFetchReceivedReferralsPeriodically
  );
}

export function* selectReceivedReferralAssigneeSaga() {
  yield takeEvery(SELECT_ASSIGNEE, handleSelectAssignee);
}
