import { all } from "redux-saga/effects";
import { hydrateSearchReferralSaga } from "./ducks/referralSearch";
import {
  changeSentReferralSaga,
  createSentReferralSaga,
  cancelSentReferralSaga,
  cloneSentReferralSaga,
  toggleSentReferralFlagSaga,
  editSentReferralSaga,
  getSentReferralSaga,
  fetchSentReferralsPeriodicallySaga,
  selectSentReferralAssigneeSaga,
  patchReferralSaga,
} from "./ducks/referralsSent";
import {
  changeReceivedReferralSaga,
  toggleReceivedReferralFlagSaga,
  editReceivedReferralSaga,
  getReceivedReferralSaga,
  fetchReceivedReferralsPeriodicallySaga,
  selectReceivedReferralAssigneeSaga,
} from "./ducks/referralsReceived";
import { fetchReferralStatesSaga, fetchReferToStatesSaga } from "./ducks/board";
import {
  determineAuthStateSaga,
  signInSaga,
  signOutSaga,
  checkAuthStateSaga,
} from "./ducks/auth";
import {
  getUserActivitySaga,
  getReferralActivitySaga,
  addCommentToReferralSaga,
} from "./ducks/activity";
import {
  updatePatientSaga,
  hydratePatientSaga,
  newCreatePatientSaga,
} from "./ducks/patients";
import {
  handleUpdateDocumentMetaSaga,
  addToReferralSaga,
  removeFromReferralSaga,
  sendDirectMessageSaga,
  openSplitDialogSaga,
  handleReferralPdfSaga,
  handleCreateReceivedReferralPDFSaga,
} from "./ducks/docserver";
import {
  getClientStaffSaga,
  getProviderStaffSaga,
  getSchedulingProviderStaffSaga,
} from "./ducks/staff";
import { getAllClientUsersSaga } from "./ducks/users";
import { checkAppVersionSaga } from "./ducks/version";
import { retryFaxSaga, sendFaxDocumentSaga } from "./ducks/fax";
import { openSettingsDialogSaga } from "./ducks/settingsDialog";
import { getApiHostSaga } from "./ducks/api";
import {
  verifyTotpSaga,
  deleteTotpSaga,
  getTotpConfigSaga,
  getTotpSecretSaga,
} from "./ducks/totp";
import { handleGetServicesSaga } from "./ducks/tenantServices";
import { hydrateDialogReferralSaga } from "./ducks/editDialog";

import { getInsurancePlansSaga } from "./ducks/insurancePlans";

export default function* rootSaga() {
  yield all([
    changeSentReferralSaga(),
    createSentReferralSaga(),
    cancelSentReferralSaga(),
    cloneSentReferralSaga(),
    changeReceivedReferralSaga(),
    determineAuthStateSaga(),
    editReceivedReferralSaga(),
    editSentReferralSaga(),
    fetchReferralStatesSaga(),
    fetchReferToStatesSaga(),
    fetchReceivedReferralsPeriodicallySaga(),
    fetchSentReferralsPeriodicallySaga(),
    getReceivedReferralSaga(),
    getSentReferralSaga(),
    getReferralActivitySaga(),
    getUserActivitySaga(),
    signInSaga(),
    signOutSaga(),
    toggleReceivedReferralFlagSaga(),
    toggleSentReferralFlagSaga(),
    addToReferralSaga(),
    removeFromReferralSaga(),
    getClientStaffSaga(),
    getProviderStaffSaga(),
    checkAuthStateSaga(),
    addCommentToReferralSaga(),
    getAllClientUsersSaga(),
    selectSentReferralAssigneeSaga(),
    selectReceivedReferralAssigneeSaga(),
    sendDirectMessageSaga(),
    checkAppVersionSaga(),
    retryFaxSaga(),
    openSettingsDialogSaga(),
    openSplitDialogSaga(),
    handleUpdateDocumentMetaSaga(),
    getApiHostSaga(),
    patchReferralSaga(),
    updatePatientSaga(),
    hydratePatientSaga(),
    newCreatePatientSaga(),
    sendFaxDocumentSaga(),
    getSchedulingProviderStaffSaga(),
    getTotpConfigSaga(),
    getTotpSecretSaga(),
    verifyTotpSaga(),
    deleteTotpSaga(),
    handleReferralPdfSaga(),
    handleGetServicesSaga(),
    handleCreateReceivedReferralPDFSaga(),
    hydrateSearchReferralSaga(),
    hydrateDialogReferralSaga(),
    getInsurancePlansSaga(),
  ]);
}
