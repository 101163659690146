import React from "react";
import { useSelector } from "react-redux";
import { Box, Button } from "@mui/material";
import { getLoginUrl } from "../Container/auth";

async function sendToAuthScreen() {
  getLoginUrl().then((loginUrl) => {
    window.location.replace(loginUrl);
  });
}

const UnauthenticatedMenu = () => {
  const { authenticated } = useSelector((state) => state.auth);
  return (
    !authenticated && (
      <Box
        flexGrow={1}
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        mb={2}
      >
        <Button
          fullWidth
          onClick={() => sendToAuthScreen()}
          sx={{ fontSize: "14px", color: "white !important" }}
        >
          Login
        </Button>
      </Box>
    )
  );
};

export default UnauthenticatedMenu;
