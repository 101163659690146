/**
 * @Date:   2019-10-21T16:01:15+05:30
 * @Last modified time: 2019-10-24T20:25:03+05:30
 */

import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import delay from "@redux-saga/delay-p";
import {
  hideProcessingSpinner,
  prepopulateReferralForm,
  resetForm,
  resetFormCache,
  resetSavedStates,
  showProcessingSpinner,
} from "./form";
import {
  cancelSentReferral,
  cloneSentReferral,
  fetchSentReferral,
  flagReferral,
  moveToClosed,
  moveToConsulted,
  moveToDeclined,
  moveToDone,
  moveToInReview,
  moveToScheduled,
  moveToSent,
  reverseState,
  updateReferral,
} from "../../services/ReferralService";
import { checkAuthState, getAuthToken, getClientId, getUserId } from "./auth";
import { modifyFlagOnSearch, removeReferralFromSearch } from "./referralSearch";
import { setInspectedReferral } from "./referralInspect";
import { retrieveProviderStaff } from "./staff";
import { enqueueSnackbar } from "./notifiers";
import { closeCardDetailModal, openCardDetailModal } from "./cardDetailModal";
import { closeDialog, resetReferralForm } from "./createDialog";
import {
  determineIfInNetwork,
  determineIfOnline,
  findProviderClientId,
} from "./directory";
import { closeDialog as closeEditDialog } from "./editDialog";
import { removeDocumentsFromList } from "./docserver";
import { upgradeDocsToPerm } from "../../services/DocServerService";
import _ from "lodash";
import {
  EDIT_REFERRAL_SUCCESS as EDIT_RECEIVED_REFERRAL_SUCCESS,
  getReceivedReferrals,
} from "./referralsReceived";
import { closeDetail } from "./mDetail";

// Actions
const CHANGE_REFERRAL_STATE = "hermes/referrals-sent/CHANGE_REFERRAL_STATE";
const CHANGE_REFERRAL_STATE_SUCCESS =
  "hermes/referrals-sent/CHANGE_REFERRAL_STATE_SUCCESS";
const CHANGE_REFERRAL_STATE_FAILURE =
  "hermes/referrals-sent/CHANGE_REFERRAL_STATE_FAILURE";
const CREATE_REFERRAL = "hermes/referrals-sent/CREATE_REFERRAL";
const CREATE_REFERRAL_SUCCESS = "hermes/referrals-sent/CREATE_REFERRAL_SUCCESS";
const CREATE_REFERRAL_FAILURE = "hermes/referrals-sent/CREATE_REFERRAL_FAILURE";
const CANCEL_REFERRAL = "hermes/referrals-sent/CANCEL_REFERRAL";
const CANCEL_REFERRAL_SUCCESS = "hermes/referrals-sent/CANCEL_REFERRAL_SUCCESS";
const CANCEL_REFERRAL_FAILURE = "hermes/referrals-sent/CANCEL_REFERRAL_FAILURE";
const CLONE_REFERRAL = "hermes/referrals-sent/CLONE_REFERRAL";
const CLONE_REFERRAL_SUCCESS = "hermes/referrals-sent/CLONE_REFERRAL_SUCCESS";
const TOGGLE_REFERRAL_FLAG = "hermes/referrals-sent/TOGGLE_REFERRAL_FLAG";
const TOGGLE_REFERRAL_FLAG_SUCCESS =
  "hermes/referrals-sent/TOGGLE_REFERRAL_FLAG_SUCCESS";
const TOGGLE_REFERRAL_FLAG_FAILURE =
  "hermes/referrals-sent/TOGGLE_REFERRAL_FLAG_FAILURE";
const TOGGLE_REFERRAL_FLAG_RESET =
  "hermes/referrals-sent/TOGGLE_REFERRAL_FLAG_RESET";
const EDIT_REFERRAL = "hermes/referrals-sent/EDIT_REFERRAL";
export const UPDATE_SENT_REFERRAL_PATIENT =
  "hermes/referrals-sent/UPDATE_SENT_REFERRAL_PATIENT";
const EDIT_REFERRAL_SUCCESS = "hermes/referrals-sent/EDIT_REFERRAL_SUCCESS";
const EDIT_REFERRAL_FAILURE = "hermes/referrals-sent/EDIT_REFERRAL_FAILURE";
const GET_SENT_REFERRALS = "hermes/referrals-sent/GET_SENT_REFERRALS";
const GET_SENT_REFERRALS_SUCCESS =
  "hermes/referrals-sent/GET_SENT_REFERRALS_SUCCESS";
const GET_SENT_REFERRAL = "hermes/referrals-sent/GET_SENT_REFERRAL";
const GET_SENT_REFERRAL_SUCCESS =
  "hermes/referrals-sent/GET_SENT_REFERRAL_SUCCESS";
const SELECT_ASSIGNEE = "hermes/referrals-sent/SELECT_ASSIGNEE";
const SELECT_ASSIGNEE_SUCCESS = "hermes/referrals-sent/SELECT_ASSIGNEE_SUCCESS";
const SELECT_ASSIGNEE_FAILURE = "hermes/referrals-sent/SELECT_ASSIGNEE_FAILURE";
const BEGIN_PERIODIC_FETCH = "hermes/referrals-sent/BEGIN_PERIODIC_FETCH";
const PATCH_REFERRAL = "hermes/referrals-sent/PATCH_REFERRAL";
const REFERRAL_REDIRECT_URL = "hermes/referrals-sent/REFERRAL_REDIRECT_URL";
const LOADING_START = "hermes/referrals-sent/LOADING_START";
const LOADING_FINISH = "hermes/referrals-sent/LOADING_FINISH";
const RESET_REFRESH_FLAG = "hermes/referrals-sent/RESET_REFRESH_FLAG";

const initialState = {
  list: [],
  details: [],
  loaded: false,
  redirectUrl: null,
  referralFlagToggledId: null,
  refreshReferralSearch: false,
  loading: false,
};

export function determineIfAbleToFlag(referral) {
  return (
    referral &&
    referral.status !== "Done" &&
    referral.status !== "Closed" &&
    referral.status !== "Declined"
  );
}

export function determineIfAbleToClone(referral) {
  return referral && referral.type === "SENT";
}

export function determineIfAbleToCancel(referral) {
  return (
    referral &&
    referral.type === "SENT" &&
    referral.status !== "Consulted" &&
    referral.status !== "Declined" &&
    referral.status !== "Closed" &&
    referral.status !== "Done"
  );
}

function* prepReferralForSubmission(
  authToken,
  clientId,
  action,
  userId,
  isReceivedReferral
) {
  let recipientContactType, recipientContactNumber;
  if (
    action.formValues.recipientPractice &&
    determineIfOnline(action.formValues.recipientPractice)
  ) {
    recipientContactType = "online";
    recipientContactNumber = findProviderClientId(
      action.formValues.recipientPractice
    );
  } else if (action.formValues.recipientPractice) {
    recipientContactType = "offline";
    recipientContactNumber = action.formValues.recipientPractice.faxPhone;
  }

  let patientId = action.formValues.patient && action.formValues.patient.id;

  let docList =
    action.documents && action.documents.length > 0
      ? action.documents
      : undefined;
  if (docList && docList.length > 0) {
    docList = yield call(
      upgradeDocsToPerm,
      authToken,
      clientId,
      action.documents.filter((doc) => doc.temporary)
    );
    docList = [
      ...action.documents.filter((doc) => !doc.temporary && Boolean(doc.uuid)),
      ...docList,
    ];
    yield put(
      removeDocumentsFromList(action.documents.filter((doc) => doc.temporary))
    );
  }

  if (isReceivedReferral && action.formValues.senderPractice.orgName) {
    action.formValues.senderPractice = action.formValues.senderPractice.orgName;
  }

  let formValues = {
    ...action.formValues,
    documents: docList || [],
    userId,
    recipientContactType,
    recipientContactNumber,
    patientId,
  };
  delete formValues.assignee;
  delete formValues.type;

  if (action.formValues.recipientPractice) {
    formValues.networkId = action.formValues.recipientPractice.networkId;
  }
  return formValues;
}

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_SENT_REFERRALS_SUCCESS:
      return {
        ...state,
        list: action.response,
        loaded: true,
      };
    case GET_SENT_REFERRAL_SUCCESS:
      const getSentDetails = state.details;
      const detailExists = state.details.findIndex(
        (referral) =>
          action.processInstanceId === referral.id ||
          action.response?.id === referral.id
      );
      if (detailExists !== -1) {
        getSentDetails[detailExists] = action.response;
      } else {
        getSentDetails.push(action.response);
      }
      return {
        ...state,
        details: getSentDetails,
      };
    case CHANGE_REFERRAL_STATE:
      const changeStateOptDetail = state.details;
      const changeStateOptDetailExists = state.details.findIndex(
        (referral) =>
          action.processInstanceId === referral.id ||
          action.referral?.id === referral.id
      );
      if (changeStateOptDetailExists !== -1) {
        changeStateOptDetail[changeStateOptDetailExists] = {
          ...changeStateOptDetail[changeStateOptDetailExists],
          status: action.newState,
        };
      }
      return {
        ...state,
        list: [
          ...state.list.map((referral) => {
            if (action.referralId === referral.id) {
              return {
                ...referral,
                status: action.newState,
              };
            }
            return referral;
          }),
        ],
        details: changeStateOptDetail,
      };
    case CHANGE_REFERRAL_STATE_SUCCESS:
      const changeStateDetail = state.details;
      const changeStateDetailExists = state.details.findIndex(
        (referral) =>
          action.processInstanceId === referral.id ||
          action.referral?.id === referral.id
      );
      if (changeStateDetailExists !== -1) {
        changeStateDetail[changeStateDetailExists] = action.referral;
      } else {
        changeStateDetail.push(action.referral);
      }
      return {
        ...state,
        list: [
          ...state.list.map((referral) => {
            if (action.referral.id === referral.id) {
              return action.referral;
            }
            return referral;
          }),
        ],
        details: changeStateDetail,
        refreshReferralSearch: true,
      };
    case CHANGE_REFERRAL_STATE_FAILURE:
      const changeStateFailDetail = state.details;
      const changeStateFailDetailExists = state.details.findIndex(
        (referral) =>
          action.processInstanceId === referral.id ||
          action.referral?.id === referral.id
      );
      if (changeStateFailDetailExists !== -1) {
        changeStateFailDetail[changeStateFailDetailExists] = action.referral;
      } else {
        changeStateFailDetail.push(action.referral);
      }
      return {
        ...state,
        list: [
          ...state.list.map((referral) => {
            if (action.referral.id === referral.id) {
              return action.referral;
            }
            return referral;
          }),
        ],
        details: changeStateFailDetail,
      };
    case UPDATE_SENT_REFERRAL_PATIENT:
      return {
        ...state,
        details: [
          ...state.details.map((referral) =>
            referral.patientId === action.patient.id
              ? { ...referral, patient: action.patient }
              : { ...referral }
          ),
        ],
      };
    case EDIT_REFERRAL_SUCCESS:
      const editDetail = state.details;
      const editDetailExists = state.details.findIndex(
        (referral) =>
          action.processInstanceId === referral.id ||
          action.referral?.id === referral.id
      );
      if (editDetailExists !== -1) {
        editDetail[editDetailExists] = {
          ...(editDetail[editDetailExists].patientId ===
            action.referral.patientId && {
            patient: editDetail[editDetailExists].patient,
          }),
          ...action.referral,
        };
      } else {
        editDetail.push(action.referral);
      }
      return {
        ...state,
        list: state.list.map((ref) => {
          if (ref.id === action.referral.id) {
            return {
              ...(ref.patientId === action.referral.patientId && {
                patient: ref.patient,
              }),
              ...action.referral,
            };
          }
          return ref;
        }),
        details: editDetail,
      };
    case CREATE_REFERRAL_SUCCESS:
      const newReferral = {
        ...action,
        type: "SENT",
      };
      return {
        ...state,
        list: [...state.list, newReferral],
      };
    case CLONE_REFERRAL_SUCCESS:
      return {
        ...state,
        list: [...state.list, action.response],
      };
    case CREATE_REFERRAL_FAILURE:
      return {
        ...state,
      };
    case CANCEL_REFERRAL_SUCCESS:
      return {
        ...state,
        list: state.list.map((ref) =>
          ref.id === action.id ? { ...ref, status: "Done" } : ref
        ),
      };
    case CANCEL_REFERRAL_FAILURE:
      console.warn("Cancelling is not yet supported.");
      return state;
    case TOGGLE_REFERRAL_FLAG_SUCCESS:
      return {
        ...state,
        referralFlagToggledId: action.referralId,
        list: state.list.map((ref) => {
          if (ref.id === action.id) {
            return { ...ref, flagged: !ref.flagged };
          }
          return ref;
        }),
      };
    case TOGGLE_REFERRAL_FLAG_RESET:
      return {
        ...state,
        referralFlagToggledId: null,
      };
    case REFERRAL_REDIRECT_URL:
      return {
        ...state,
        redirectUrl: action.redirectUrl,
      };
    case LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case LOADING_FINISH:
      return {
        ...state,
        loading: false,
      };
    case RESET_REFRESH_FLAG:
      return {
        ...state,
        refreshReferralSearch: false,
      };
    default:
      return state;
  }
}

// Action Creators

export function fetchSentReferralsApollo(data) {
  return {
    type: GET_SENT_REFERRALS_SUCCESS,
    response: data,
  };
}

export function startApolloLoading() {
  return { type: LOADING_START };
}

export function finishApolloLoading() {
  return { type: LOADING_FINISH };
}

export function changeReferralState(
  referralId,
  displayableId,
  taskId,
  intendedState,
  userSelection,
  formValues,
  documents,
  updateTarget
) {
  return {
    type: CHANGE_REFERRAL_STATE,
    referralId: referralId,
    taskId,
    newState: intendedState,
    userSelection,
    formValues,
    displayableId,
    updateTarget,
  };
}

export function resetSentRefresh() {
  return { type: RESET_REFRESH_FLAG };
}

export function getSentReferral(referral, options = {}) {
  const { inspectReferral, cardDetail } = options;
  return {
    type: GET_SENT_REFERRAL,
    referral,
    inspectReferral,
    cardDetail,
  };
}

export function getSentReferrals(referralId) {
  return {
    type: GET_SENT_REFERRALS,
    referralId,
  };
}

export function posCreateReferral(referral, isReceivedReferral, keepOpen) {
  return {
    type: CREATE_REFERRAL,
    referral,
    isReceivedReferral,
    keepOpen,
  };
}

export function cancelReferral(referralId, displayableId) {
  return {
    type: CANCEL_REFERRAL,
    referralId,
    displayableId,
  };
}

export function cloneReferral(referralId, displayableId, stayInPlace, history) {
  return {
    type: CLONE_REFERRAL,
    referralId,
    displayableId,
    stayInPlace,
    history,
  };
}

export function toggleSentReferralFlag(
  referralId,
  displayableId,
  isFlagged,
  comment,
  fullScreen
) {
  return {
    type: TOGGLE_REFERRAL_FLAG,
    referralId,
    displayableId,
    isFlagged,
    comment,
    fullScreen,
  };
}

export function resetSentReferralFlag() {
  return {
    type: TOGGLE_REFERRAL_FLAG_RESET,
  };
}

export function editSentReferral(
  referralId,
  displayableId,
  formValues,
  documents,
  skipPrepopulate,
  updateTarget
) {
  return {
    type: EDIT_REFERRAL,
    referralId,
    displayableId,
    formValues,
    documents,
    skipPrepopulate,
    updateTarget,
  };
}

export function selectAssignee(referralId, displayableId, assignee) {
  return {
    type: SELECT_ASSIGNEE,
    referralId,
    displayableId,
    assignee,
  };
}

export function fetchSentReferralsPeriodically() {
  return {
    type: BEGIN_PERIODIC_FETCH,
  };
}

export function patchReferral(referralId, formValues) {
  return {
    type: PATCH_REFERRAL,
    referralId,
    formValues,
  };
}

// Sagas
function* handleReferralStateChange({
  displayableId,
  newState,
  referralId,
  userSelection,
  formValues = {},
  updateTarget,
}) {
  let clientId;
  let authToken;
  let userId;

  try {
    clientId = yield call(getClientId);
    authToken = yield call(getAuthToken);
    userId = yield call(getUserId);

    const getMessage = {
      Sent: moveToSent,
      "In review": moveToInReview,
      Scheduled: moveToScheduled,
      Consulted: moveToConsulted,
      Done: moveToDone,
      Declined: moveToDeclined,
      Closed: moveToClosed,
    };

    const referral = yield call(
      fetchSentReferral,
      authToken,
      clientId,
      referralId
    );

    if (
      !(formValues.senderPractice || referral.senderPractice) &&
      "Sent" === newState
    ) {
      throw new Error("Missing senderPractice");
    }
    if (
      !(formValues.senderName || referral.senderName) &&
      "Sent" === newState
    ) {
      throw new Error("Missing senderName");
    }
    if (
      !(formValues.senderProvider || referral.senderProvider) &&
      "Sent" === newState
    ) {
      throw new Error("Missing senderProvider");
    }
    if (
      !(formValues.oonReason || referral.oonReason) &&
      "Sent" === newState &&
      !determineIfInNetwork(
        formValues?.recipientPractice,
        formValues?.networkId
          ? [formValues?.networkId]
          : [formValues?.sentReferralNetworkId]
      )
    ) {
      throw new Error("Missing out of network reason");
    }

    if (newState === "Declined") {
      yield call(getMessage[newState], authToken, clientId, referralId, {
        userId,
      });
    } else {
      userSelection === "Reverse"
        ? yield call(reverseState, authToken, referralId, newState, clientId)
        : yield call(getMessage[newState], authToken, referralId, clientId);
    }

    yield put(closeEditDialog());
    yield put(closeCardDetailModal());

    yield put({
      referral: {
        ...referral,
        status: newState,
      },
      type: CHANGE_REFERRAL_STATE_SUCCESS,
    });
    if (updateTarget) {
      yield put(setInspectedReferral(referral, newState));
    }
    yield put(resetSavedStates());
    yield put(resetReferralForm());
    yield put(
      enqueueSnackbar({
        message: `Referral ${displayableId} moved to ${newState}!`,
        options: {
          variant: "success",
        },
      })
    );
    yield delay(5000);
    yield put(getSentReferrals());
  } catch (e) {
    let errorLogging = {};
    if (!_.includes(e.message, "401")) {
      errorLogging = { error: true, payload: e };
    }
    let referral = yield call(
      fetchSentReferral,
      authToken,
      clientId,
      referralId
    );
    yield put({
      type: CHANGE_REFERRAL_STATE_FAILURE,
      referral,
      ...errorLogging,
    });
    yield put(checkAuthState());
    if (
      errorLogging.payload.response &&
      errorLogging.payload.response.data &&
      errorLogging.payload.response.data.exception
    ) {
      yield put(
        enqueueSnackbar({
          message:
            "The referral could not be sent. The fax number for this practice is invalid",
          options: {
            variant: "error",
          },
        })
      );
    } else if (
      e.message === "Missing senderName" ||
      e.message === "Missing out of network reason" ||
      e.message === "Missing senderProvider" ||
      e.message === "Missing senderPractice"
    ) {
      yield put(
        enqueueSnackbar({
          message: `Please complete all required fields.`,
          options: {
            variant: "error",
          },
        })
      );
    } else {
      yield put(
        enqueueSnackbar({
          message: `Error moving referral.`,
          options: {
            variant: "error",
          },
        })
      );
    }
  }
}

// Temporal function to support non refactored methods
function* handleGetSentReferralTmp(referralId) {
  let authToken = yield call(getAuthToken);
  let clientId = yield call(getClientId);
  return yield call(fetchSentReferral, authToken, clientId, referralId);
}

function* handleGetSentReferral(action) {
  try {
    let response = action?.referral;

    // Temporal condition to support non refactored methods
    /*
     * TODO: Remove this condition when EX-3669, EX-3653
     * */
    if (typeof response !== "object") {
      response = yield call(handleGetSentReferralTmp, response);
    }

    yield put({
      response: response,
      type: GET_SENT_REFERRAL_SUCCESS,
    });
    if (action.inspectReferral) {
      yield put(setInspectedReferral(response));
    }
    if (action.cardDetail) {
      yield put(openCardDetailModal(response));
    }
  } catch (e) {
    if (action.inspectReferral) {
      yield put(setInspectedReferral(null));
    }
    yield put(
      enqueueSnackbar({
        message: `Unable to fetch requested referral.`,
        options: {
          variant: "error",
        },
      })
    );
  }
}

function* handlePosCreateReferral(action) {
  try {
    const newReferral = action.referral;
    yield put(closeDetail());
    yield put({ ...newReferral, type: CREATE_REFERRAL_SUCCESS });

    if (action.keepOpen) {
      let newUrl;
      if (action.isReceivedReferral) {
        // view detail for received
        newUrl = "/referrals/received/" + newReferral.id;
      } else {
        // view detail for sent
        newUrl = "/referrals/sent/" + newReferral.id;
      }
      yield delay(5000);
      yield put({
        type: REFERRAL_REDIRECT_URL,
        redirectUrl: newUrl,
      });
    } else {
      const clientId = yield call(getClientId);
      yield put(retrieveProviderStaff(clientId));
      yield put(closeDialog());
      yield put(resetReferralForm());
      yield put(hideProcessingSpinner());
      yield put(resetForm());
      yield put(resetSavedStates());
      yield delay(5000);
      yield put(
        action.isReceivedReferral ? getReceivedReferrals() : getSentReferrals()
      );
    }
  } catch (e) {
    console.error(e);
    let errorLogging = {};
    if (!_.includes(e.message, "401")) {
      errorLogging = { error: true, payload: e };
    }
    yield put(
      enqueueSnackbar({
        message: `Error creating referral.`,
        options: {
          variant: "error",
        },
      })
    );

    yield put(hideProcessingSpinner());
    yield put({ type: CREATE_REFERRAL_FAILURE, ...errorLogging });
  }
}

function* handleCancelReferral(action) {
  try {
    let authToken = yield call(getAuthToken);
    let clientId = yield call(getClientId);
    let cancelResponse = yield call(
      cancelSentReferral,
      authToken,
      action.referralId,
      clientId
    );
    let response = yield call(
      fetchSentReferral,
      authToken,
      clientId,
      action.referralId
    );
    yield put({
      response,
      type: GET_SENT_REFERRAL_SUCCESS,
    });
    yield put({ ...cancelResponse, type: CANCEL_REFERRAL_SUCCESS });
    yield put(removeReferralFromSearch(action.referralId));
    yield put(
      enqueueSnackbar({
        message: `Referral ${action.displayableId} cancelled!`,
        options: {
          variant: "success",
        },
      })
    );
    yield delay(5000);
    yield put(getSentReferrals());
  } catch (e) {
    let errorLogging = {};
    if (!_.includes(e.message, "401")) {
      errorLogging = { error: true, payload: e };
    }
    yield put(
      enqueueSnackbar({
        message: `Error canceling referral.`,
        options: {
          variant: "error",
        },
      })
    );

    yield put(hideProcessingSpinner());
    yield put({ type: CANCEL_REFERRAL_FAILURE, ...errorLogging });
  }
}

function* handleCloneReferral(action) {
  try {
    let authToken = yield call(getAuthToken);
    let clientId = yield call(getClientId);
    let response = yield call(
      cloneSentReferral,
      authToken,
      clientId,
      action.referralId
    );
    yield put({ response, type: CLONE_REFERRAL_SUCCESS });
    yield put(
      enqueueSnackbar({
        message: `Referral ${action.displayableId} cloned as Referral ${response.displayableId}!`,
        options: {
          variant: "success",
        },
      })
    );
    if (action.stayInPlace) {
      yield put(openCardDetailModal(response));
    } else {
      yield put(resetForm());
      action?.history?.replace(`/referrals/sent/${response.id}`);
      yield put(setInspectedReferral(response));
    }
    yield delay(5000);
    yield put(getSentReferrals());
  } catch (e) {
    console.error(e);
    let errorLogging = {};
    if (!_.includes(e.message, "401")) {
      errorLogging = { error: true, payload: e };
    }
    yield put(
      enqueueSnackbar({
        message: `Error cloning referral.`,
        options: {
          variant: "error",
        },
      })
    );

    yield put(hideProcessingSpinner());
    yield put({ type: CANCEL_REFERRAL_FAILURE, ...errorLogging });
  }
}

function* handleToggleSentReferralFlag(action) {
  try {
    let authToken = yield call(getAuthToken);
    let clientId = yield call(getClientId);
    let userId = yield call(getUserId);
    let response = yield call(
      flagReferral,
      authToken,
      clientId,
      action.referralId,
      { comment: action.comment, userId }
    );
    yield put({
      ...response,
      type: TOGGLE_REFERRAL_FLAG_SUCCESS,
      referralId: `${action.referralId}`,
    });
    yield put(resetFormCache("flagComment"));

    if (action.fullScreen) {
      /***
     TODO: this refactor should be handled in EX-3661 after EX-3643
     */
      yield put(getSentReferral(action.referralId, { inspectReferral: true }));
    }
    yield put(modifyFlagOnSearch(action.referralId));
    yield put(
      enqueueSnackbar({
        message: `Referral ${action.displayableId} ${
          action.isFlagged ? "un" : ""
        }flagged!`,
        options: {
          variant: "success",
        },
      })
    );
    yield delay(5000);
    yield put(getSentReferrals());
  } catch (e) {
    let errorLogging = {};
    if (!_.includes(e.message, "401")) {
      errorLogging = { error: true, payload: e };
    }
    yield put(
      enqueueSnackbar({
        message: `Error flagging referral.`,
        options: {
          variant: "error",
        },
      })
    );

    yield put(hideProcessingSpinner());
    yield put({
      ...action,
      type: TOGGLE_REFERRAL_FLAG_FAILURE,
      ...errorLogging,
    });
  }
}

function* handleEditSentReferral(action) {
  try {
    yield put({ type: LOADING_START });
    yield put(showProcessingSpinner());
    let authToken = yield call(getAuthToken);
    let clientId = yield call(getClientId);
    const formValues = yield call(
      prepReferralForSubmission,
      authToken,
      clientId,
      action
    );
    let response = yield call(
      updateReferral,
      authToken,
      clientId,
      action.referralId,
      formValues
    );
    yield put({ referral: response, type: EDIT_REFERRAL_SUCCESS });
    yield put(
      enqueueSnackbar({
        message: `Referral ${action.displayableId} saved!`,
        options: {
          variant: "success",
        },
      })
    );
    yield put(retrieveProviderStaff(formValues.recipientContactNumber));
    yield put(hideProcessingSpinner());
    if (action.updateTarget) {
      yield put(setInspectedReferral(response));
    }
    if (!action.skipPrepopulate) {
      yield put(prepopulateReferralForm(response));
    }
    yield put({ type: LOADING_FINISH });
  } catch (e) {
    let errorLogging = {};
    if (!_.includes(e.message, "401")) {
      errorLogging = { error: true, payload: e };
    }
    yield put(
      enqueueSnackbar({
        message: `Error updating referral.`,
        options: {
          variant: "error",
        },
      })
    );

    yield put(hideProcessingSpinner());
    yield put({ ...action, type: EDIT_REFERRAL_FAILURE, ...errorLogging });
    yield put({ type: LOADING_FINISH });
  }
}

function* handlePatchReferral({ referralId, formValues }) {
  try {
    let authToken = yield call(getAuthToken);
    let clientId = yield call(getClientId);
    let response = yield call(
      updateReferral,
      authToken,
      clientId,
      referralId,
      formValues
    );
    yield put(
      response.type === "SENT"
        ? { referral: response, type: EDIT_REFERRAL_SUCCESS }
        : { referral: response, type: EDIT_RECEIVED_REFERRAL_SUCCESS }
    );
    yield put(setInspectedReferral(response));
    yield put(
      enqueueSnackbar({
        message: `Referral ${response.displayableId} saved!`,
        options: {
          variant: "success",
        },
      })
    );
  } catch (e) {
    let errorLogging = {};
    if (!_.includes(e.message, "401")) {
      errorLogging = { error: true, payload: e };
    }
    yield put(
      enqueueSnackbar({
        message: `Error updating referral.`,
        options: {
          variant: "error",
        },
      })
    );

    yield put({ type: EDIT_REFERRAL_FAILURE, ...errorLogging });
  }
}

function* handleSelectAssignee(action) {
  let authToken = yield call(getAuthToken);
  let clientId = yield call(getClientId);
  yield put({ type: LOADING_START });

  try {
    let response = yield call(
      updateReferral,
      authToken,
      clientId,
      action.referralId,
      {
        assignee: {
          ...action.assignee,
          userId: action.assignee.userId || action.assignee.id,
        },
      }
    );
    yield put({ ...response, type: SELECT_ASSIGNEE_SUCCESS });
    yield put(
      enqueueSnackbar({
        message: `${
          action.assignee &&
          action.assignee.firstName &&
          action.assignee.lastName
            ? `${action.assignee.firstName} ${action.assignee.lastName}`
            : "Unknown user"
        } assigned to ${action.displayableId}!`,
        options: {
          variant: "success",
        },
      })
    );

    /***
   TODO: this refactor should be handled in EX-3665 after EX-3643
   */
    yield put(getSentReferral(action.referralId, { inspectReferral: true }));
    yield delay(5000);
    yield put(getSentReferrals());
    yield put({ type: LOADING_FINISH });
  } catch (e) {
    let errorLogging = {};
    if (!_.includes(e.message, "401")) {
      errorLogging = { error: true, payload: e };
    }
    yield put(checkAuthState());
    yield put(
      enqueueSnackbar({
        message: `Error assigning referral.`,
        options: {
          variant: "error",
        },
      })
    );
    yield put({ ...action, type: SELECT_ASSIGNEE_FAILURE, ...errorLogging });
    yield put({ type: LOADING_FINISH });
  }
}

function* handleFetchSentReferralsPeriodically() {
  yield delay(60000);

  while (true) {
    yield put(getSentReferrals());
    yield delay(30000);
  }
}

export function* getSentReferralSaga() {
  yield takeLatest(GET_SENT_REFERRAL, handleGetSentReferral);
}

export function* changeSentReferralSaga() {
  yield takeEvery(CHANGE_REFERRAL_STATE, handleReferralStateChange);
}

export function* createSentReferralSaga() {
  yield takeEvery(CREATE_REFERRAL, handlePosCreateReferral);
}

export function* cancelSentReferralSaga() {
  yield takeEvery(CANCEL_REFERRAL, handleCancelReferral);
}

export function* cloneSentReferralSaga() {
  yield takeEvery(CLONE_REFERRAL, handleCloneReferral);
}

export function* toggleSentReferralFlagSaga() {
  yield takeEvery(TOGGLE_REFERRAL_FLAG, handleToggleSentReferralFlag);
}

export function* editSentReferralSaga() {
  yield takeEvery(EDIT_REFERRAL, handleEditSentReferral);
}

export function* fetchSentReferralsPeriodicallySaga() {
  yield takeEvery(BEGIN_PERIODIC_FETCH, handleFetchSentReferralsPeriodically);
}

export function* selectSentReferralAssigneeSaga() {
  yield takeEvery(SELECT_ASSIGNEE, handleSelectAssignee);
}

export function* patchReferralSaga() {
  yield takeEvery(PATCH_REFERRAL, handlePatchReferral);
}
