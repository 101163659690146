import axios from "axios";
import { getApiHost } from "../state/ducks/api";

export async function searchTenantDidService(
  bearerToken,
  clientId,
  unmappedOnly
) {
  const apiHost = await getApiHost();
  const response = await axios.get(
    `${apiHost}/api/clients/${clientId}/tenant-dids`,
    {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
      },
      params: {
        clientId: unmappedOnly === false && clientId ? clientId : null,
        unmappedOnly: unmappedOnly,
      },
    }
  );
  return response.data ? response.data : [];
}
