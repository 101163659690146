import { gql } from "@apollo/client";

export const GET_DASHBOARD_DETAIL = gql`
  query ClientDashboards($clientId: Int!, $dashboardId: Int!) {
    clientDashboard(clientId: $clientId, dashboardId: $dashboardId) {
      description
      id
      isMemberOnly
      name
      networkId
      qsDashboardId
      qsDashboardUrl
    }
  }
`;

export const GET_NETWORK_DASHBOARD_LIST = gql`
  query NetworkDashboards($networkId: String!) {
    networkDashboards(networkId: $networkId) {
      id
      isMemberOnly
      name
      networkId
      qsDashboardId
    }
  }
`;

export const GET_NETWORK_DASHBOARD_DETAIL = gql`
  query NetworkDashboard($networkId: String!, $dashboardId: Int!) {
    networkDashboard(networkId: $networkId, dashboardId: $dashboardId) {
      description
      id
      isMemberOnly
      name
      networkId
      qsDashboardId
      qsDashboardUrl
    }
  }
`;
