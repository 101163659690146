export const DOCUMENT_TAB = [
  { label: "Live", value: "live" },
  { label: "Tagged", value: "tagged" },
  { label: "Deleted", value: "deleted" },
];

export const DOCUMENT_SOURCE_TYPES = {
  RECEIVED_FAX: "Received Fax",
  TRANSFERRED: "Transfer",
  UPLOAD: "Upload",
};
