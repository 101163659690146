import { makeStyles } from "@material-ui/core";

const useTextColorStyles = makeStyles((theme) => {
  return {
    primary: {
      color: theme.palette.primary.main,
    },
    primaryLight: {
      color: theme.palette.primary.light,
    },
    primaryDark: {
      color: theme.palette.primary.dark,
    },
    secondary: {
      color: theme.palette.secondary.main,
    },
    secondaryLight: {
      color: theme.palette.secondary.light,
    },
    secondaryDark: {
      color: theme.palette.secondary.dark,
    },
    primaryBlack: {
      color: theme.palette.black.main,
    },
    grayLight: {
      color: theme.palette.gray.gray2,
    },
    white: {
      color: theme.palette.white.main,
    },
    error: {
      color: "red",
    },
    disabled: {
      color: "#BDBDBD",
    },
  };
});

export default useTextColorStyles;
