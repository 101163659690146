import { Home, styles } from "./Home";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { getUserActivity, getAllActivity } from "../../state/ducks/activity";
import { setCreateButton } from "../../state/ducks/sidemenu";
import { withOptimizely } from "@optimizely/react-sdk";

function mapStateToProps(state) {
  return {
    userFirstName: state.auth.firstName,
    userId: state.auth.userId,
    authenticated: state.auth.authenticated,
    userActivity:
      state.activity.userList.map((userActivity) => ({
        ...userActivity,
        username: state.auth.username,
        userFirstName: state.auth.firstName,
        userLastName: state.auth.lastName,
      })) || [],
    allActivity: state.activity.allList || [],
    errorMessage: state.auth.errorMessage,
  };
}

export default connect(mapStateToProps, {
  getUserActivity,
  getAllActivity,
  setCreateButton,
})(withOptimizely(withStyles(styles)(Home)));
