import React, { useState, useEffect } from "react";
import { EuiTitle, EuiComboBox } from "@elastic/eui";
import { useSearchkit } from "@searchkit/client";
import PropTypes from "prop-types";

export const OverridableComboFacet = ({ facet, loading }) => {
  const api = useSearchkit();

  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelected] = useState([]);

  useEffect(() => {
    if (facet?.entries) {
      setOptions(
        facet?.entries.map((entry) => ({
          label: entry.overrideLabel || entry.label,
          value: entry.value,
        }))
      );

      let appliedOptions = [];
      if (facet?.usesCommaSeparatedList) {
        //We need to get all the options selected for the filter
        const selectedFilter = api
          .getFilters()
          .find((filter) => filter?.identifier === facet?.identifier);
        const selectedOptions = selectedFilter?.value?.split(",");
        facet?.entries?.forEach((entry) => {
          if (selectedOptions?.includes(entry.value)) {
            appliedOptions.push({
              label: entry.overrideLabel || entry.label,
              value: entry.value,
            });
          }
        });
      } else {
        facet?.entries.forEach((entry) => {
          if (
            api.isFilterSelected({
              identifier: facet.identifier,
              value: entry.value,
            })
          ) {
            appliedOptions.push({
              label: entry.overrideLabel || entry.label,
              value: entry.value,
            });
          }
        });
      }

      setSelected(appliedOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facet?.entries]);

  const onChange = (selectedOptions) => {
    if (selectedOptions.length) {
      setSelected(selectedOptions);
      if (facet?.usesCommaSeparatedList) {
        api.removeFiltersByIdentifier(facet.identifier);
        api.toggleFilter({
          identifier: facet.identifier,
          value: selectedOptions.map((option) => option.value).join(),
        });
      } else {
        api.toggleFilter({
          identifier: facet.identifier,
          value: selectedOptions.at(-1).value,
        });
      }

      api.setPage({ from: 0 });
      api.search();
    } else {
      api.removeFiltersByIdentifier(facet.identifier);
      api.setPage({ from: 0 });
      api.search();
    }
  };

  return (
    <>
      <EuiTitle size="xxs">
        <h3>{facet.label}</h3>
      </EuiTitle>
      <div id={facet.id}>
        <EuiComboBox
          placeholder={facet.label}
          options={options}
          selectedOptions={selectedOptions}
          onChange={onChange}
          isClearable={true}
          data-test-subj="specialties_combobox"
        />
      </div>
    </>
  );
};

OverridableComboFacet.DISPLAY = "OverridableComboFacet";

OverridableComboFacet.propTypes = {
  facet: PropTypes.shape({
    identifier: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    entries: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        overrideLabel: PropTypes.string,
      })
    ),
    usesCommaSeparatedList: PropTypes.bool,
  }),
  loading: PropTypes.bool,
};
