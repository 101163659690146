import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    formLabelText: {
      fontSize: "14px",
      color: "#828282",
      fontWeight: 700,
    },

    formLabelRequired: {
      color: "#FF0000",
    },

    textAreaFontSize: {
      fontSize: "11px",
    },
  };
});

export { useStyles };
