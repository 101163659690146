export const formatNotifcationFilter = ({
  users,
  referralType,
  dateRangeFilter,
  notficationSetting,
}) => {
  const defaultFilters = [
    {
      identifier: "objectItemTypeNameFilter",
      value: referralType,
    },
    {
      identifier: "excludeUsers",
      value: users?.map((user) => user.id).join(","),
    },
    { ...dateRangeFilter },
  ];
  if (notficationSetting?.comments) {
    defaultFilters.push({
      identifier: "eventType",
      value: "ADD_COMMENT",
    });
  }
  if (notficationSetting?.flagged) {
    defaultFilters.push({
      identifier: "eventType",
      value: "REFERRAL_FLAGGED",
    });
  }
  if (notficationSetting?.documentsAdded) {
    defaultFilters.push({
      identifier: "eventType",
      value: "DOCUMENT_ADDED_TO_PROCESS",
    });
  }
  if (notficationSetting?.unflagged) {
    defaultFilters.push({
      identifier: "eventType",
      value: "REFERRAL_UNFLAGGED",
    });
  }
  return defaultFilters;
};
