import axios from "axios";
import { getApiHost } from "../state/ducks/api";

export async function fetchUserActivity(bearerToken, clientId, userId) {
  const apiHost = await getApiHost();
  const response = await axios.get(
    `${apiHost}/api/clients/${clientId}/activities/`,
    {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
      },
      params: {
        userId,
      },
    }
  );
  return response.data.filter(
    (activity) => "user authentication" !== activity.category
  );
}

export async function fetchReferralActivity(bearerToken, clientId, referralId) {
  const apiHost = await getApiHost();
  const response = await axios.get(
    `${apiHost}/api/clients/${clientId}/process-instances/${referralId}/comments`,
    {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
      },
    }
  );
  return response.data;
}

export async function postCommentToReferral(
  bearerToken,
  clientId,
  userId,
  referral,
  commentText,
  isPrivate,
  username
) {
  const apiHost = await getApiHost();
  const commentEvent = {
    clientId,
    createTime: new Date(),
    createdByDisplayName: username || "",
    createdByUserId: userId,
    description: commentText,
    isPrivate: Boolean(isPrivate),
    processInstanceId: referral.id,
  };

  const response = await axios.post(
    `${apiHost}/api/clients/${clientId}/process-instances/${referral.id}/comments`,
    commentEvent,
    {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
      },
    }
  );
  return response.data;
}
