// Actions
export const OPEN = "hermes/card-menu/OPEN";
const CLOSE = "hermes/card-menu/CLOSE";
const HIDE = "hermes/card-menu/HIDE";
const OPEN_FLAG = "hermes/card-menu/OPEN_FLAG";
const OPEN_CANCEL = "hermes/card-menu/OPEN_CANCEL";

const initialState = {
  cardMenu: null,
  hideCardMenu: false,
  enableResetForm: true,
  refType: undefined,
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case OPEN:
      return {
        ...state,
        hideCardMenu: false,
        cardMenu: action.cardMenu,
        cardRefId: action.cardRefId,
        refDisplayableId: action.refDisplayableId,
        refFlagged: action.refFlagged,
        refType: action.refType,
      };
    case CLOSE:
      return {
        ...state,
        hideCardMenu: false,
        cardMenu: null,
        cardRefId: undefined,
        refDisplayableId: undefined,
        refFlagged: undefined,
        openFlag: false,
        openCancel: false,
      };
    case HIDE:
      return {
        ...state,
      };
    case OPEN_FLAG:
      return {
        ...state,
        openFlag: true,
        enableResetForm: false,
        refType: action.refType,
      };
    case OPEN_CANCEL:
      return {
        ...state,
        openCancel: true,
        enableResetForm: true,
      };
    default:
      return state;
  }
}

// Action Creators
export function openCardMenu(
  cardMenu,
  cardRefId,
  refDisplayableId,
  refFlagged,
  refType
) {
  return {
    type: OPEN,
    cardMenu,
    cardRefId,
    refDisplayableId,
    refFlagged,
    refType,
  };
}

export function closeCardMenu() {
  return {
    type: CLOSE,
  };
}

export function hideCardMenu() {
  return {
    type: HIDE,
  };
}

export function openFlagMenu(refType) {
  return {
    type: OPEN_FLAG,
    refType,
  };
}

export function openCancelMenu() {
  return {
    type: OPEN_CANCEL,
  };
}
