import MainUploadButton from "./MainUploadButton";
import React, { useCallback, useState } from "react";
import { enqueueSnackbar } from "../../state/ducks/notifiers";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import MultiTenantFileUploadModal from "../../pages/NewElasticDocument/components/MultiTenantFileUploadModal";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_CLIENT_NAME } from "../../queries/clients";
import { CREATE_DOCUMENT } from "../../mutations";
import { documentTriageShouldRefresh } from "../../state/ducks/docserver";
import { useAppSetting } from "../../context/useAppSettingContext";

export const MainUploadButtonContainer = () => {
  const dispatch = useDispatch();

  /* Global state */
  const multiTenant = useSelector(({ auth, users }) => {
    const userId = auth?.id;
    const user = users?.list.find((user) => user.id === userId);
    return user?.multiTenant;
  });

  const associatedTenantsIds = useSelector(
    (state) => state?.auth?.associatedTenantUsers
  );

  /* Local state */
  const [uploading, setUploading] = useState(false);
  const [associatedTenants, setAssociatedTenants] = useState([]);
  const [multiTenantFileUploadOpen, setMultiTenantFileUploadOpen] =
    useState(false);

  /* Custom hooks */
  const { isReadOnlyUser } = useAppSetting();

  /* Queries */
  const [getClientName] = useLazyQuery(GET_CLIENT_NAME, {
    onCompleted: (data) => {
      return data;
    },
  });

  /* Mutations */
  const [createDoc] = useMutation(CREATE_DOCUMENT);

  /* Methods */
  const fetchAssociatedTenantsInfo = useCallback(async () => {
    let tenants = [];
    for (const tenantId of associatedTenantsIds) {
      const response = await getClientName({
        variables: { clientId: tenantId?.tenantId },
      });
      tenants = [...tenants, { ...response?.data?.client }];
    }
    setMultiTenantFileUploadOpen(true);
    setAssociatedTenants(tenants);
  }, [associatedTenantsIds, getClientName]);

  const uploadDocs = useCallback(
    async (files, tenantId = null) => {
      const filesWithName = [...files].filter(
        (file) => !!file?.name && !!file?.size
      );

      if (!filesWithName?.length) {
        dispatch(
          enqueueSnackbar({
            message: "Failed to upload document",
            options: {
              variant: "error",
            },
          })
        );
        return;
      }

      try {
        if (multiTenant && associatedTenantsIds?.length === 1) {
          tenantId = associatedTenantsIds[0]?.tenantId; // Upload to child by default
        }

        setUploading(true);
        const promises = filesWithName.map((file) =>
          createDoc({
            variables: {
              clientId: tenantId,
              filename: file.name,
              mimetype: file?.type,
              fileSize: file?.size,
            },
          })
            .then((result) => result?.data?.createDocument?.preSignedUrl)
            .catch(() => {
              dispatch(
                enqueueSnackbar({
                  message: `Failed to upload ${file.name}`,
                  options: {
                    variant: "error",
                  },
                })
              );
              return undefined;
            })
        );
        const preSignedUrls = await Promise.all(promises);
        const newFiles = preSignedUrls.reduce(
          (prev, curr, index) =>
            curr
              ? [...prev, { file: filesWithName[index], path: curr }]
              : [...prev],
          []
        );
        await Promise.all(
          newFiles.map((doc) =>
            axios
              .put(doc.path, doc.file, {
                headers: {
                  "Content-Type": doc.file?.type,
                },
              })
              .then(() => {
                dispatch(
                  enqueueSnackbar({
                    message: `Uploaded ${doc.file.name}!`,
                    options: {
                      variant: "success",
                    },
                  })
                );
              })
              .catch((err) => {
                dispatch(
                  enqueueSnackbar({
                    message: `Failed to upload ${doc.file.name}`,
                    options: {
                      variant: "error",
                    },
                  })
                );
              })
          )
        );
      } catch (err) {
        console.log(err);
        dispatch(
          enqueueSnackbar({
            message: "Failed to upload",
            options: {
              variant: "error",
            },
          })
        );
      }
      if (multiTenant) {
        setMultiTenantFileUploadOpen(false);
      }
      await new Promise((resolve) => setTimeout(resolve, 3000));
      dispatch(documentTriageShouldRefresh(true)); // Send signal to refresh document triage
      setUploading(false);
    },
    [createDoc, dispatch, associatedTenantsIds, multiTenant]
  );

  return (
    <>
      <MainUploadButton
        uploadDocs={uploadDocs}
        uploading={uploading}
        fetchAssociatedTenantsInfo={fetchAssociatedTenantsInfo}
        associatedTenantsIds={associatedTenantsIds}
        isReadOnlyUser={isReadOnlyUser}
        multiTenant={multiTenant}
      />

      {multiTenant && (
        <MultiTenantFileUploadModal
          uploading={uploading}
          uploadDocs={uploadDocs}
          tenantList={associatedTenants}
          open={multiTenantFileUploadOpen}
          handleClose={() => setMultiTenantFileUploadOpen(false)}
        />
      )}
    </>
  );
};
