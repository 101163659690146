import React from "react";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { useStyles } from "./styles";
const Textarea = ({
  id,
  disabled,
  required,
  multiline,
  rows,
  placeholder,
  defaultValue = "",
  value,
  onChange,
  label,
}) => {
  const classes = useStyles();
  return (
    <FormControl fullWidth>
      <FormLabel component="legend">
        <span className={classes.formLabelText}>{label}</span>{" "}
        {required && <span className={classes.formLabelRequired}>*</span>}
      </FormLabel>
      <TextField
        id={id}
        disabled={disabled}
        multiline={multiline}
        rows={rows}
        placeholder={placeholder}
        defaultValue={defaultValue}
        variant="outlined"
        className={classes.textAreaFontSize}
        value={value}
        onChange={onChange}
      />
    </FormControl>
  );
};

export default Textarea;
