import { Select, FormControl, MenuItem, Typography } from "@material-ui/core";
import { useStyles } from "./style";

const SelectBox = ({
  label = "",
  options,
  placeHolderValue = "",
  handleChange,
  required = false,
  testId,
  disabled = false,
  className = "",
  id,
  ...props
}) => {
  const classes = useStyles();
  return (
    <FormControl
      variant="outlined"
      className={`${classes.formControl} ${className}`}
      data-testid={testId}
      id={id}
    >
      {label && (
        <Typography variant="subtitle2" className={classes.inputLabel}>
          {label}
          {required && <span style={{ color: "red" }}>*</span>}
        </Typography>
      )}
      <Select
        className={classes.select}
        value={placeHolderValue}
        onChange={handleChange}
        disabled={disabled}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        }}
        {...props}
      >
        {options.map(({ label, value }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectBox;
