import {
  fetchUserActivity,
  fetchReferralActivity,
  postCommentToReferral,
} from "../../services/ActivityService";
import { getAuthToken, getClientId, getUserId } from "./auth";
import { resetFormCache } from "./form";
import { put, call, takeEvery } from "redux-saga/effects";
import { enqueueSnackbar } from "./notifiers";
import _ from "lodash";

// Actions
const GET_USER_ACTIVITY = "hermes/activity/GET_USER_ACTIVITY";
const GET_USER_ACTIVITY_SUCCESS = "hermes/activity/GET_USER_ACTIVITY_SUCCESS";
const GET_USER_ACTIVITY_FAILURE = "hermes/activity/GET_USER_ACTIVITY_FAILURE";
const GET_ALL_ACTIVITY = "hermes/activity/GET_ALL_ACTIVITY";
const GET_REFERRAL_ACTIVITY = "hermes/activity/GET_REFERRAL_ACTIVITY";
const GET_REFERRAL_ACTIVITY_SUCCESS =
  "hermes/activity/GET_REFERRAL_ACTIVITY_SUCCESS";
const GET_REFERRAL_ACTIVITY_FAILURE =
  "hermes/activity/GET_REFERRAL_ACTIVITY_FAILURE";
const GET_CLIENT_ACTIVITY_REFERRAL =
  "hermes/activity/GET_CLIENT_ACTIVITY_REFERRAL";
const ADD_COMMENT_TO_REFERRAL = "hermes/activity/ADD_COMMENT_TO_REFERRAL";
const COMMENT_ADD_SUCCESS = "hermes/activity/COMMENT_ADD_SUCCESS";
const CLEAR_ADDED_COMMENTS = "hermes/activity/CLEAR_ADDED_COMMENTS";
const SAVE_VARIABLES = "hermes/activity/SAVE_VARIABLES";
const PERSIST_VARIABLES = "hermes/activity/PERSIST_VARIABLES";
const CLEAR_VARIABLES = "hermes/activity/CLEAR_VARIABLES";

const initialState = {
  userList: [],
  allList: [],
  addedComments: [],
  savedSearchVariables: undefined,
  useSaved: false,
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_USER_ACTIVITY_SUCCESS:
      return {
        ...state,
        userList: action.userList,
      };
    case GET_REFERRAL_ACTIVITY_SUCCESS:
      return {
        ...state,
        refLists: {
          ...state.refLists,
          [action.referralId]: action.refList,
        },
      };
    case COMMENT_ADD_SUCCESS:
      return {
        ...state,
        addedComments: [...state.addedComments, action.comment],
      };
    case CLEAR_ADDED_COMMENTS:
      return {
        ...state,
        addedComments: [],
      };
    case SAVE_VARIABLES:
      return {
        ...state,
        savedSearchVariables: action.variables,
      };
    case PERSIST_VARIABLES:
      return {
        ...state,
        useSaved: true,
      };
    case CLEAR_VARIABLES:
      return {
        ...state,
        savedSearchVariables: undefined,
        useSaved: false,
      };
    default:
      return state;
  }
}

// Action Creators
export function saveVariables(variables) {
  return {
    type: SAVE_VARIABLES,
    variables,
  };
}

export function persistVariables() {
  return {
    type: PERSIST_VARIABLES,
  };
}

export function clearVariables() {
  return {
    type: CLEAR_VARIABLES,
  };
}

export function getUserActivity() {
  return {
    type: GET_USER_ACTIVITY,
  };
}

export function getAllActivity() {
  return {
    type: GET_ALL_ACTIVITY,
  };
}

export function getReferralActivity(referralId) {
  return {
    type: GET_REFERRAL_ACTIVITY,
    referralId,
  };
}

export function getClientActivityForReferral(referralId) {
  return {
    type: GET_CLIENT_ACTIVITY_REFERRAL,
    referralId,
  };
}

export function addCommentToReferral(
  referral,
  commentText,
  isPrivate,
  username
) {
  return {
    type: ADD_COMMENT_TO_REFERRAL,
    referral,
    commentText,
    isPrivate,
    username,
  };
}

export function clearAddedComments() {
  return {
    type: CLEAR_ADDED_COMMENTS,
  };
}

// Sagas
function* handleGetUserActivity(/* action */) {
  try {
    let authToken = yield call(getAuthToken);
    let clientId = yield call(getClientId);
    let userId = yield call(getUserId);
    let response = yield call(fetchUserActivity, authToken, clientId, userId);
    yield put({ userList: response, type: GET_USER_ACTIVITY_SUCCESS });
  } catch (e) {
    let errorLogging = {};
    if (!_.includes(e.message, "401")) {
      errorLogging = { error: true, payload: e };
    }
    yield put({ type: GET_USER_ACTIVITY_FAILURE, ...errorLogging });
  }
}

function* handleGetReferralActivity(action) {
  try {
    let authToken = yield call(getAuthToken);
    let clientId = yield call(getClientId);
    let response = yield call(
      fetchReferralActivity,
      authToken,
      clientId,
      action.referralId
    );
    yield put({
      referralId: action.referralId,
      refList: response,
      type: GET_REFERRAL_ACTIVITY_SUCCESS,
    });
  } catch (e) {
    let errorLogging = {};
    if (!_.includes(e.message, "401")) {
      errorLogging = { error: true, payload: e };
    }
    yield put({ type: GET_REFERRAL_ACTIVITY_FAILURE, ...errorLogging });
  }
}

function* handleAddCommentToReferral(action) {
  if (action.commentText && action.commentText.trim()) {
    try {
      let authToken = yield call(getAuthToken);
      let clientId = yield call(getClientId);
      let userId = yield call(getUserId);
      const comment = yield call(
        postCommentToReferral,
        authToken,
        clientId,
        userId,
        action.referral,
        action.commentText,
        action.isPrivate,
        action.username
      );
      //yield put(getReferralActivity(action.referral.id));
      //yield put(getAllActivity());
      yield put(resetFormCache("comment"));
      yield put(
        enqueueSnackbar({
          message: `Comment saved!`,
          options: {
            variant: "success",
          },
        })
      );
      yield put({ type: COMMENT_ADD_SUCCESS, comment });
    } catch (e) {
      yield put(
        enqueueSnackbar({
          message: `There was an error posting your comment`,
          options: {
            variant: "error",
          },
        })
      );
    }
  } else {
    yield put(
      enqueueSnackbar({
        message: `Your comment cannot be blank.`,
        options: {
          variant: "warning",
        },
      })
    );
  }
}

export function* getUserActivitySaga() {
  yield takeEvery(GET_USER_ACTIVITY, handleGetUserActivity);
}

export function* getReferralActivitySaga() {
  yield takeEvery(GET_REFERRAL_ACTIVITY, handleGetReferralActivity);
}

export function* addCommentToReferralSaga() {
  yield takeEvery(ADD_COMMENT_TO_REFERRAL, handleAddCommentToReferral);
}
