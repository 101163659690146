import SchedulingDialog from "./SchedulingDialog";
import RejectDialog from "./RejectDialog";
import FlagDialog from "./FlagDialog";

export default function Dialogs() {
  return (
    <>
      <SchedulingDialog />
      <RejectDialog />
      <FlagDialog />
    </>
  );
}
