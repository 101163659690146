import { useSearchkit } from "@searchkit/client";
import { EuiButton, EuiFlexItem } from "@elastic/eui";
import React from "react";
import PropTypes from "prop-types";
import {
  CREATED_BY_IDENTIFIER,
  RECEIVED_NUMBER_IDENTIFIER,
} from "../../constants";

const facetIdentifiers = [RECEIVED_NUMBER_IDENTIFIER, CREATED_BY_IDENTIFIER];

export const OverridableValueFilter = ({ filter, loading, onClick = null }) => {
  const api = useSearchkit();
  return React.createElement(
    EuiFlexItem,
    { grow: false },
    React.createElement(
      EuiButton,
      {
        onClick: () => {
          if (filter?.onClick) {
            return filter.onClick();
          }
          if (facetIdentifiers.includes(filter?.identifier)) {
            localStorage.removeItem(filter.identifier);
          }
          api.removeFilter(filter);
          api.setPage({ from: 0 });
          api.search();
        },
        iconSide: "right",
        iconType: "cross",
        isLoading: loading,
      },
      filter.label,
      ": ",
      filter.overrideValue || filter.facetLabel || filter.value
    )
  );
};

OverridableValueFilter.propTypes = {
  filter: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
};
