import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    primary: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.white.main,
      textTransform: "none !important",
      fontWeight: "500",
      fontSize: "11px",
      lineHeight: "14px",
      borderRadius: "4px",
      "&.Mui-disabled": {
        backgroundColor: theme.palette.gray.gray5,
        color: theme.palette.white.main,
      },
      "&:hover": {
        backgroundColor: theme.palette.primary.dark,
        boxShadow: "none",
      },
      "&:active": {
        boxShadow: theme.palette.primary.highlighted,
        backgroundColor: "#3c52b2",
      },
    },
    secondary: {
      backgroundColor: theme.palette.white.main,
      textTransform: "none !important",
      color: theme.palette.primary.main,
      fontWeight: "500",
      fontSize: "11px",
      lineHeight: "14px",
      borderRadius: "4px",
      border: "1px solid",
      borderColor: theme.palette.primary.main,
      "&.Mui-disabled": {
        color: theme.palette.gray.gray5,
        backgroundColor: theme.palette.white.main,
      },
    },
  };
});

export { useStyles };
