const SEARCH_PATIENTS_SUCCESS = "hermes/organizations/SEARCH_PATIENTS_SUCCESS";
const NEW_SEARCH_PATIENTS_SUCCESS =
  "hermes/organizations/NEW_SEARCH_PATIENTS_SUCCESS";
const PATIENT_MAYBE_CHANGED = "hermes/organizations/PATIENT_MAYBE_CHANGED";

const initialState = {
  patients: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SEARCH_PATIENTS_SUCCESS:
      return {
        ...state,
        patients: action.patients,
      };
    case NEW_SEARCH_PATIENTS_SUCCESS:
      return {
        ...state,
        patients: action.patients,
      };
    case PATIENT_MAYBE_CHANGED:
      return {
        ...state,
        patients: state.patients.some((p) => p.id === action.patient.id)
          ? [
              ...state.patients.filter((p) => p.id !== action.patient?.id),
              action.patient,
            ]
          : state.patients,
      };
    default:
      return state;
  }
}

// Action Creators

export function patientMaybeChanged(patient) {
  return {
    type: PATIENT_MAYBE_CHANGED,
    patient,
  };
}
