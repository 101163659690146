import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    radioFilled: {
      color: "#828282",
    },

    radioMinPadding: {
      padding: "1px",
    },

    radioGroupMargins: {
      marginLeft: "8px",
      marginTop: "6px",
    },

    formLabelText: {
      fontSize: "14px",
      color: "#828282",
      fontWeight: 700,
    },

    formLabelRequired: {
      color: "#FF0000",
    },

    radioLabel: {
      fontSize: "11px",
      color: "#828282",
      fontWeight: 700,
    },

    horizontalAlign: {
      flexDirection: "row !important",
    },
  };
});

export { useStyles };
