import React, { useState, useEffect } from "react";
import { EuiTitle, EuiDatePickerRange, EuiDatePicker } from "@elastic/eui";
import { useSearchkit } from "@searchkit/client";

export const OverridableDateRangeFacet = ({ facet, loading }) => {
  const api = useSearchkit();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const selectedOptions = api.getFiltersByIdentifier(facet.identifier);
  const selectedOption = selectedOptions && selectedOptions[0];
  useEffect(() => {
    if (startDate && endDate) {
      if (selectedOption) {
        api.removeFilter(selectedOption);
      }

      const startUTC = new Date(
        startDate.clone().startOf("day").format("YYYY-MM-DDTHH:mm:ss")
      ).toISOString();
      const endUTC = new Date(
        endDate.clone().endOf("day").format("YYYY-MM-DDTHH:mm:ss")
      ).toISOString();

      api.addFilter({
        identifier: facet.identifier,
        dateMin: startUTC,
        dateMax: endUTC,
      });
      api.search();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);
  return (
    <div data-testid="overridable-date-range-facet">
      <EuiTitle size="xxs">
        <h3>{facet.label}</h3>
      </EuiTitle>
      <EuiDatePickerRange
        id={facet.id}
        startDateControl={
          <EuiDatePicker
            isLoading={loading}
            selected={startDate}
            onChange={setStartDate}
            startDate={startDate}
            value={selectedOption && selectedOption.dateMin}
            endDate={endDate}
            adjustDateOnChange={false}
            placeholder="from"
            isInvalid={startDate > endDate}
            aria-label="Start date"
          />
        }
        endDateControl={
          <EuiDatePicker
            isLoading={loading}
            selected={endDate}
            onChange={setEndDate}
            startDate={startDate}
            value={selectedOption && selectedOption.dateMax}
            endDate={endDate}
            adjustDateOnChange={false}
            isInvalid={startDate > endDate}
            aria-label="End date"
            placeholder="to"
          />
        }
      ></EuiDatePickerRange>
    </div>
  );
};

OverridableDateRangeFacet.DISPLAY = "OverridableDateRangeFacet";
