import React, { useEffect } from "react";

const Logout = () => {
  useEffect(() => {
    const searchParams = new URLSearchParams(document.location.search);
    const redirect = searchParams.get("redirect");
    const callback = searchParams.get("callback");
    if (callback) {
      localStorage.setItem("logout_callback", callback);
    }
    window.location.assign(redirect);
  });
  return <></>;
};

export default Logout;
