// Actions
const OPEN = "hermes/card-detail/OPEN";
const CLOSE = "hermes/card-detail/CLOSE";
const CLOSE_TEMPORARY = "hermes/card-detail/CLOSE_TEMPORARY";
const OPEN_SUSPENDED = "hermes/card-detail/OPEN_SUSPENDED";

const initialState = {
  cardDetail: undefined,
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case OPEN:
      return {
        ...state,
        showCardDetail: true,
        cardDetail: action.cardDetail,
      };
    case CLOSE:
      return {
        ...state,
        showCardDetail: false,
        cardDetail: undefined,
      };
    case CLOSE_TEMPORARY:
      return {
        ...state,
        showCardDetail: false,
      };
    case OPEN_SUSPENDED:
      return {
        ...state,
        showCardDetail: true,
      };
    default:
      return state;
  }
}

// Action Creators
export function openCardDetailModal(referral) {
  return {
    type: OPEN,
    cardDetail: referral,
  };
}

export function closeCardDetailModal() {
  return {
    type: CLOSE,
  };
}

export function temporarilyCloseCardDetailModal() {
  return {
    type: CLOSE_TEMPORARY,
  };
}

export function resumeSuspendedReferralUpdate() {
  return {
    type: OPEN_SUSPENDED,
  };
}
