import { put, call, takeLatest } from "redux-saga/effects";
import { getLatestApiHost } from "../../services/ApiService";

// Actions
const GET_API_HOST = "hermes/api/GET_API_HOST";
const GET_API_HOST_SUCCESS = "hermes/api/GET_API_HOST_SUCCESS";
const API_GATEWAY_HOST = "api_gateway_host";

const initialState = {
  host: "",
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_API_HOST_SUCCESS:
      return {
        ...state,
        host: action.host,
      };
    default:
      return state;
  }
}

function setApiHost(hostname) {
  sessionStorage.setItem(API_GATEWAY_HOST, hostname);
}

export async function getApiHost() {
  return sessionStorage.getItem(API_GATEWAY_HOST);
}

// Action Creators
export function initApiHost() {
  return {
    type: GET_API_HOST,
  };
}

// Sagas

function* handleGetApiHost(action) {
  try {
    const apiHost = yield call(getLatestApiHost);
    console.info("apiHost", apiHost);
    setApiHost(apiHost);
    yield put({
      type: GET_API_HOST_SUCCESS,
      host: apiHost,
    });
  } catch (e) {
    console.error(e);
  }
}

export function* getApiHostSaga() {
  yield takeLatest(GET_API_HOST, handleGetApiHost);
}
