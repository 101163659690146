import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  Button,
  TextField,
} from "@mui/material";
import { generatePatientDisplayName } from "../TaskCard/TaskCard";
import { closeDialog } from "../../state/ducks/dialogs";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { enqueueSnackbar } from "../../state/ducks/notifiers";
import { changeReferralState } from "../../state/ducks/referralsReceived";
import { Autocomplete } from "@material-ui/lab";
import useFetchReferral from "../../hooks/useFetchReferral";

const rejectReasons = [
  "Documentation not Attached",
  "Duplicate Referral",
  "Not taking new patients",
  "Other",
  "Patient Declined",
  "Patient went elsewhere",
  "Patient was No Show",
  "Patient did not respond",
  "Reschedule",
  "Unapproved Service",
];

export default function RejectDialog() {
  const dispatch = useDispatch();
  const rejectDialogIsOpen = useSelector((state) => state.dialogs.reject?.open);
  const referral = useSelector((state) => state.dialogs.reject?.referral);
  const { handleFetchReferral } = useFetchReferral();

  useEffect(() => {
    if (referral?.id) {
      handleFetchReferral(referral?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referral?.id, referral?.type, dispatch]);

  const action = useSelector((state) => state.dialogs.reject?.action);
  const { handleSubmit, setValue, register, unregister } = useForm({
    defaultValues: {
      rejectionReason: null,
      rejectionComment: "",
    },
  });
  const [rejectionReason, setRejectionReason] = useState(null);
  const [rejectionComment, setRejectionComment] = useState("");

  useEffect(() => {
    register("rejectionReason", { required: true });
    register("rejectionComment", {
      validate: (value) => value === "" || (value && value.trim().length >= 1),
    });

    return () => {
      if (unregister) {
        unregister("rejectionReason");
        unregister("rejectionComment");
      }
      setRejectionReason(null);
      setRejectionComment("");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register]);

  const onSubmit = (formValues) => {
    dispatch(
      changeReferralState(
        referral.id,
        referral.displayableId,
        referral.taskId,
        action.targetState,
        action.name,
        formValues
      )
    );
    closeRejectDialogAndReinit();
  };
  const onError = (error) => {
    dispatch(
      enqueueSnackbar({
        message: `Please complete all required form fields`,
        options: {
          variant: "error",
        },
      })
    );
  };

  const setRejectionReasonSelect = (data) => {
    setValue("rejectionReason", data);
    setRejectionReason(data);
  };
  const setRejectionCommentForm = (data) => {
    setValue("rejectionComment", data);
    setRejectionComment(data);
  };

  const closeRejectDialogAndReinit = () => {
    dispatch(closeDialog("reject"));
    setValue("rejectionReason", null);
    setValue("rejectionComment", "");
    setRejectionReason(null);
    setRejectionComment("");
  };

  return rejectDialogIsOpen ? (
    <Dialog
      key={`${referral.id}_${action?.name}_dialog`}
      open={true}
      onClose={closeRejectDialogAndReinit}
      aria-labelledby="form-dialog-title"
      scroll="body"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle id="form-dialog-title" disableTypography>
        <Typography component="h2" variant="h6">
          {action?.name}
        </Typography>
        <Typography variant="subtitle1">{`${generatePatientDisplayName(
          referral.patient || referral.patientSnapshot
        )} (REF-${referral.displayableId})`}</Typography>
      </DialogTitle>
      <DialogContent>
        <form
          id="reject-action-dialog"
          onSubmit={handleSubmit(onSubmit, onError)}
        >
          <Autocomplete
            autoHighlight
            id="rejectionReason"
            options={rejectReasons}
            name={"rejectionReason"}
            style={{ width: "100%" }}
            value={rejectionReason}
            onChange={(e, val) => setRejectionReasonSelect(val)}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                label="Reject Reason *"
                variant="standard"
              />
            )}
          />
          <TextField
            id="rejectionComment"
            name={rejectionComment}
            key="rejectionComment"
            value={rejectionComment || ""}
            onChange={(e) => setRejectionCommentForm(e?.target?.value)}
            required={rejectionReason === "Other"}
            label={"Description"}
            fullWidth
            multiline={true}
            rows={2}
            variant="standard"
            margin="normal"
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          id="cancel"
          key={`cancel_${action.name}`}
          type="cancel"
          color="primary"
          onClick={closeRejectDialogAndReinit}
        >
          Cancel
        </Button>
        <Button
          id="success"
          key={`button_${action.name}`}
          type="submit"
          form="reject-action-dialog"
          color="primary"
        >
          Reject
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;
}
