import axios from "axios";
import { getApiHost } from "../state/ducks/api";

export async function sendFaxWithPages(
  clientId,
  auth,
  docId,
  sendFaxParams,
  sendFaxDocumentResource
) {
  const apiHost = await getApiHost();

  const response = await axios.post(
    `${apiHost}/api/clients/${clientId}/documents/${docId}/sendfax`,
    sendFaxDocumentResource,
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
      params: {
        ...sendFaxParams,
      },
    }
  );

  return response.data;
}
