import { useLazyQuery } from "@apollo/client";
import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";

import { GET_REFERRALS_FOR_KANBAN } from "../queries/referral";
import {
  fetchSentReferralsApollo,
  startApolloLoading,
  finishApolloLoading,
} from "../state/ducks/referralsSent";
import {
  getReceivedReferralsApollo,
  startApolloLoading as startReceivedLoading,
  finishApolloLoading as finishReceivedLoading,
} from "../state/ducks/referralsReceived";

const PAGE_SIZE = 5000;

const defaultFilters = [
  {
    identifier: "referral.status",
    value: "Scheduling",
  },
  {
    identifier: "referral.status",
    value: "Send Failed",
  },
  {
    identifier: "referral.status",
    value: "Consulted",
  },
  {
    identifier: "referral.status",
    value: "Done",
  },
  {
    identifier: "referral.status",
    value: "Scheduled",
  },
  {
    identifier: "referral.status",
    value: "In review",
  },
  {
    identifier: "referral.status",
    value: "Declined",
  },
  {
    identifier: "referral.status",
    value: "Sent",
  },
  {
    identifier: "referral.status",
    value: "New",
  },
];

const useSearchReferrals = ({
  query = "",
  filters = [],
  referralType = "",
}) => {
  const dispatch = useDispatch();
  const [fetchReferrals, { data: referralData, loading, refetch }] =
    useLazyQuery(GET_REFERRALS_FOR_KANBAN, {
      variables: {
        query,
        sortBy: query === "" ? "newestFirst" : "relevance",
        page: {
          from: 0,
          size: PAGE_SIZE,
        },
        filters: [...defaultFilters, ...filters],
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
    });

  useEffect(() => {
    fetchReferrals();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const referrals = useMemo(() => {
    if (!referralData) return [];
    return referralData?.referrals?.results?.hits?.items.map((ref) => ({
      ...ref.fields,
      displayableId: ref.fields.displayableId,
      assignee: {
        firstName: ref.fields.assigneeFirstName,
        lastName: ref.fields.assigneeLastName,
        userId: ref.fields.assigneeUserId,
        id: ref.fields.assigneeUserId,
      },
      patient: {
        firstName: ref.fields.composedPatientFirstName,
        lastName: ref.fields.composedPatientLastName,
        dob: ref.fields.composedPatientDob,
      },
      recipientPractice: {
        referenceUrns: ref.fields.recipientPracticeProviderReferenceUrns,
        orgName: ref.fields.recipientPracticeOrgName,
        networks: ref.fields.recipientPracticeProviderNetworks,
      },
      networkId: ref.fields.recipientPracticeProviderNetworkId,
    }));
  }, [referralData]);

  useEffect(() => {
    if (referralType === "SENT" && loading) {
      dispatch(startApolloLoading());
    }
    if (referralType === "RECEIVED" && loading) {
      dispatch(startReceivedLoading());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    if (referralType === "SENT" && !loading) {
      dispatch(fetchSentReferralsApollo(referrals));
      dispatch(finishApolloLoading());
    } else if (referralType === "RECEIVED" && !loading) {
      dispatch(getReceivedReferralsApollo(referrals));
      dispatch(finishReceivedLoading());
    } else return;
  }, [referrals]); // eslint-disable-line react-hooks/exhaustive-deps
  return { referralData, loading, refetch, referrals };
};

export default useSearchReferrals;
