import { Chip as MuChip } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  customChip: {
    backgroundColor: "#DCE1EA",
    color: "#465065",
    fontSize: "10px",
    fontWeight: "500",
    textTransform: "uppercase",
    "&:focus": {
      backgroundColor: "#DCE1EA",
    },
    "& .MuiChip-label": {
      overflow: "inherit",
      whiteSpace: "normal",
      textOverflow: "unset",
      overflowWrap: "anywhere",
      padding: "9px 12px",
    },
    overflow: "hidden",
    maxWidth: "100%",
    height: "auto",
    minHeight: "32px",
  },
  deleteIcon: {
    width: "12px",
    fontSize: "12px",
    color: "#516794",
    height: "12px",
    marginTop: "-3.5px",
    marginRight: "10px",
    "& path": {
      strokeWidth: 2,
    },
  },
}));

const Chip = ({ label, handleDelete, id }) => {
  const classes = useStyles();
  return (
    <>
      <MuChip
        className={classes.customChip}
        label={label}
        deleteIcon={
          <DeleteIcon
            data-testid={"delete-icon"}
            className={classes.deleteIcon}
          />
        }
        onDelete={handleDelete ? () => handleDelete(id) : undefined}
      />
    </>
  );
};

export default Chip;
