import axios from "axios";
import { getCurrentEnvironment } from "./VersionService";

let foundEnv;
const platformHosts = JSON.parse(process.env.REACT_APP_PLATFORM_API);

async function getPlatformHost() {
  if (!foundEnv) {
    foundEnv = await getCurrentEnvironment();
  }
  return platformHosts[foundEnv];
}

export async function getUserTotpConfig(bearerToken) {
  const platformHost = await getPlatformHost();
  const response = await axios.get(`${platformHost}/user`, {
    headers: {
      Authorization: `Bearer ${bearerToken}`,
    },
  });
  return response.data;
}

export async function getUserTotpSecret(bearerToken) {
  const platformHost = await getPlatformHost();
  const response = await axios.get(`${platformHost}/user/token`, {
    headers: {
      Authorization: `Bearer ${bearerToken}`,
    },
  });
  return response.data?.secret;
}

export async function verifyUserTotp(bearerToken, code) {
  const platformHost = await getPlatformHost();
  const response = await axios.post(
    `${platformHost}/user/token`,
    {
      code,
    },
    {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
      },
    }
  );
  return response.data;
}

export async function deleteUserTotp(bearerToken) {
  const platformHost = await getPlatformHost();
  const response = await axios.delete(`${platformHost}/user/token`, {
    headers: {
      Authorization: `Bearer ${bearerToken}`,
    },
  });
  return response.data;
}
