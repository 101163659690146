import React from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { closeAboutDialog } from "../../state/ducks/settingsDialog";

export default function AboutDialog() {
  const aboutDialogOpen = useSelector(
    (state) => state.settingsDialog.aboutDialogOpen
  );
  const dispatch = useDispatch();
  const currentYear = new Date().getFullYear();
  return (
    <Dialog
      open={aboutDialogOpen}
      onClose={() => dispatch(closeAboutDialog())}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title" fontWeight={500}>
        About Proficient Health
      </DialogTitle>
      <DialogContent>
        <Box
          component="img"
          sx={{
            display: "block",
            width: "100%",
            marginTop: 1,
          }}
          src={`${
            process.env.REACT_APP_BASENAME === "/"
              ? "/exchange"
              : process.env.REACT_APP_BASENAME
          }/branding_hit.png`}
          alt="Proficient Health"
        />
        <DialogContentText>
          © 2018-{currentYear} Proficient, Inc. All rights reserved.
        </DialogContentText>
        <DialogContentText marginTop="12px">
          PDF technology powered by PDFTron WebViewer SDK copyright © PDFTron™
          Systems Inc., 2001-2020, and distributed by Proficient, Inc under
          license. All rights reserved.
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}
