export function determineIfOnline(provider) {
  return Boolean(
    provider &&
      provider.referenceUrns &&
      provider.referenceUrns.find((urn) => urn.includes("clientId"))
  );
}

export function newDetermineIfOnline(referenceUrn) {
  return Boolean(referenceUrn?.find((urn) => urn.includes("clientId")));
}
export function determineIfMe(provider, clientId) {
  return Boolean(
    provider &&
      provider.referenceUrns &&
      provider.referenceUrns.find((urn) => urn.includes("clientId")) &&
      provider.referenceUrns.find((urn) => urn.includes(clientId))
  );
}
export function determineIfLocation(provider) {
  return Boolean(
    provider &&
      provider.referenceUrns &&
      provider.referenceUrns.find((urn) => urn.includes("locationId"))
  );
}

let prevInNetworkId;
export function determineIfInNetwork(provider, inNetworkId) {
  prevInNetworkId = inNetworkId || prevInNetworkId;
  return Boolean(
    provider?.networks?.some((network) => inNetworkId.includes(network)) ||
      provider?.networkId === prevInNetworkId
  );
}

export function newDetermineIfInNetwork(
  providerNetworks = [],
  inNetworkId = []
) {
  return Boolean(
    providerNetworks?.some((network) => inNetworkId.includes(network))
  );
}

export function findProviderClientId(provider) {
  if (provider?.referenceUrns) {
    let clientIdUrn = provider?.referenceUrns?.find((urn) =>
      urn?.includes("clientId")
    );
    return (
      provider?.referenceUrns &&
      clientIdUrn?.slice(clientIdUrn?.lastIndexOf(":") + 1)
    );
  }
  return undefined;
}
