import axios from "axios";
import { getUserName } from "./UserService";
import { getApiHost } from "../state/ducks/api";

const reflect = (p) =>
  p.then(
    (v) => ({ v, status: "fulfilled" }),
    (e) => ({ v: {}, e, status: "rejected" })
  );

export async function identifyClientDocuments(bearerToken, clientId, docList) {
  let usernameList = [...new Set(docList.map((doc) => doc.createdByUserId))];

  usernameList = await Promise.all(
    usernameList.map(async (user) => {
      let response = await reflect(getUserName(bearerToken, clientId, user));
      return response.v;
    })
  );
  let updateDocumentList = docList.map((doc) => {
    const user = usernameList.find((user) => user.id === doc.createdByUserId);
    if (user) {
      return {
        ...doc,
        username: user.username,
        userFirstName: user.firstName,
        userLastName: user.lastName,
      };
    } else {
      return {
        ...doc,
        username: "unknown",
        userFirstName: "Unknown",
        userLastName: "User",
      };
    }
  });

  return updateDocumentList;
}

export async function updateDocument(
  bearerToken,
  clientId,
  documentId,
  doc,
  versionId
) {
  const apiHost = await getApiHost();
  const response = await axios.put(
    `${apiHost}/api/clients/${clientId}/documents/${documentId}`,
    doc,
    {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
      },
      params: {
        ...{ restoreVersion: versionId },
      },
    }
  );

  return response.data;
}
/**
 * The uploadDocument function that's being called
 * from the docserver duck.
 * @param  {String} bearerToken the token used for authenticating the user for the api calls.
 * @param  {String} clientId the clientId used for identifying which practice is accessing the application.
 * @param {Array} documentList the list of documents that needs to be uploaded
 * @param {String} from the parameter used to identify, if the document should be stored in permanent bucket or temporary
 * @return {Object} Returns list of document with the new document
 */
// TODO we can remove this and related functions after changing DocumentUploadeer to ApolloDocument UPloader
export async function uploadDocument(
  bearerToken,
  clientId,
  documentList,
  from
) {
  const responseList = Promise.all(
    documentList.map(async (doc) => {
      const formData = new FormData();
      formData.append("file-data", doc);
      const apiHost = await getApiHost();
      const document = await axios.post(
        `${apiHost}/api/clients/${clientId}/documents/`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
          },
          params: {
            isTemporary: from !== "REFERRAL",
          },
        }
      );
      const response = await assignUploadTag(bearerToken, clientId, document);
      let usernameResponse = await getUserName(
        bearerToken,
        clientId,
        response.data.createdByUserId
      );
      response.data.username = usernameResponse.username;
      return response.data;
    })
  );

  return responseList;
}

export async function assignUploadTag(bearerToken, clientId, document) {
  const apiHost = await getApiHost();
  const tags = [
    {
      key: "urn:tag:system:ChannelDetail:sourceChannel",
      value: "Upload",
    },
    {
      key: "urn:tag:system:ChannelDetail:receivedTime",
      value: document?.data?.createTime,
    },
  ];
  const response = await axios.put(
    `${apiHost}/api/clients/${clientId}/documents/${document?.data?.uuid}`,
    { ...document?.data, tags },
    {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
      },
    }
  );
  return response;
}

export async function getDocumentBytesNew(url) {
  const response = await axios.get(url, {
    responseType: "blob",
  });
  return response.data;
}

export async function updateDocumentTag(
  bearerToken,
  clientId,
  documentId,
  tagsetName,
  tagObject
) {
  const apiHost = await getApiHost();
  const response = await axios.put(
    `${apiHost}/api/document-stores/${clientId}/documents/${documentId}/tagsets/0/${tagsetName}`,
    tagObject,
    {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
      },
    }
  );
  return response;
}

export async function upgradeDocsToPerm(bearerToken, clientId, docList) {
  const responseList = Promise.all(
    docList.map(async (doc) => {
      const apiHost = await getApiHost();
      const response = await axios.put(
        `${apiHost}/api/clients/${clientId}/documents/${doc.uuid}`,
        {
          temporary: false,
        },
        {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
          },
        }
      );
      return response.data;
    })
  );

  return responseList;
}

export async function createPdfFromReferral(
  bearerToken,
  clientId,
  referralId,
  sig
) {
  const apiHost = await getApiHost();
  try {
    const response = await axios.post(
      `${apiHost}/api/clients/${clientId}/referrals/${referralId}/generate-pdf`,
      {
        additionalText: sig,
      },
      {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
        params: {
          signedPdf: true,
        },
      }
    );
    return response.data;
  } catch (e) {
    console.log(e);
  }
}

export async function createReceivedReferralPDF(
  bearerToken,
  clientId,
  referralId
) {
  const apiHost = await getApiHost();
  try {
    const response = await axios.post(
      `${apiHost}/api/clients/${clientId}/referrals/${referralId}/generate-pdf`,
      {
        additionalText: "",
      },
      {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
        params: {
          signPdf: false,
        },
      }
    );
    return response.data;
  } catch (e) {
    console.log(e);
  }
}
