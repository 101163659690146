import { Notifier } from "./Notifier";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import { removeSnackbar } from "../../state/ducks/notifiers";

function mapStateToProps(state) {
  return {
    notifications: state.notifiers.notifications,
  };
}

export default connect(mapStateToProps, { removeSnackbar })(
  withSnackbar(Notifier)
);
