// Actions
const UPDATE_FORM = "hermes/form/UPDATE_FORM";
const ADD_DOCUMENTS = "hermes/form/ADD_DOCUMENTS";
const UPDATE_DOCUMENTS = "hermes/form/UPDATE_DOCUMENTS";
const ADD_DOCUMENT = "hermes/form/ADD_DOCUMENT";
const REMOVE_DOCUMENT = "hermes/form/REMOVE_DOCUMENT";
const REMOVE_USER_DOCUMENT = "hermes/form/REMOVE_USER_DOCUMENT";
const RESET_FORM = "hermes/form/RESET_FORM";
const RESET_FIELD = "hermes/form/RESET_FIELD";
const SHOW_PROCESSING_SPINNER = "hermes/form/SHOW_PROCESSING_SPINNER";
const HIDE_PROCESSING_SPINNER = "hermes/form/HIDE_PROCESSING_SPINNER";
const SAVE_REFERRAL_FORM = "hermes/form/SAVE_REFERRAL_FORM";
const POPULATE_REFERRAL_FORM = "hermes/form/POPULATE_REFERRAL_FORM";
const POPULATE_PATIENT_FORM = "hermes/form/POPULATE_PATIENT_FORM";
const POPULATE_ORGANIZATION_FORM = "hermes/form/POPULATE_ORGANIZATION_FORM";
const ADD_PATIENT = "hermes/form/ADD_PATIENT";
const ADD_ORGANIZATION = "hermes/form/ADD_ORGANIZATION";
const REMOVE_SAVED_STATES = "hermes/form/REMOVE_SAVED_STATES";
const POPULATE_PATIENT_FROM_REFERRAL =
  "hermes/form/POPULATE_PATIENT_FROM_REFERRAL";
const POPULATE_ORGANIZATION_FROM_REFERRAL =
  "hermes/form/POPULATE_ORGANIZATION_FROM_REFERRAL";
const SET_ERROR_FIELDS = "hermes/form/SET_ERROR_FIELDS";
const CLEAR_ERROR_FIELDS = "hermes/form/CLEAR_ERROR_FIELDS";
const RESET_FORM_CACHE = "hermes/form/RESET_FORM_CACHE";

const initialState = {
  formValues: {},
  documents: [],
  errorFields: [],
  selectedDocument: [],
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_FORM:
      return {
        ...state,
        formValues: {
          ...state.formValues,
          [action.formId]: {
            ...state.formValues[action.formId],
            [action.fieldName]: action.targetValue,
          },
        },
      };
    case ADD_DOCUMENTS:
      return {
        ...state,
        documents: {
          ...state.documents,
          [action.formId]: state.documents[action.formId]
            ? [
                ...state.documents[action.formId].filter(
                  (existingDoc) =>
                    !action.docList.find((doc) => existingDoc.uuid === doc.uuid)
                ),
                ...action.docList,
              ]
            : [...action.docList],
        },
      };
    case UPDATE_DOCUMENTS:
      return {
        ...state,
        documents: {
          ...state.documents,
          [action.formId]: action.docList,
        },
      };
    case ADD_DOCUMENT:
      const documentField = action.formId ? action.formId : "selectedDocument";
      return {
        ...state,
        documents: {
          ...state.documents,
          [documentField]: state.documents[action.formId]
            ? [
                ...state.documents[action.formId].filter(
                  (existingDoc) => existingDoc.uuid !== action.selectDoc.uuid
                ),
                action.selectDoc,
              ]
            : [action.selectDoc],
        },
      };
    case REMOVE_DOCUMENT:
      return {
        ...state,
        documents: {
          ...state.documents,
          [action.formId]: [
            ...state.documents[action.formId].filter(
              (doc) => doc.uuid !== action.targetValue.uuid
            ),
          ],
        },
      };
    case REMOVE_USER_DOCUMENT:
      return {
        ...state,
        docs: state.docs.filter((doc) => doc !== action.targetValue),
      };
    case SHOW_PROCESSING_SPINNER:
      return {
        ...state,
        processing: true,
      };
    case HIDE_PROCESSING_SPINNER:
      return {
        ...state,
        processing: false,
      };
    case SAVE_REFERRAL_FORM:
      return {
        ...state,
        formValues: {
          ...state.formValues,
          [action.formId]: {},
        },
        pausedReferralFields: state.formValues[action.formId],
      };
    case POPULATE_REFERRAL_FORM:
      return {
        ...state,
        formValues: {
          ...state.formValues,
          [action.formId]: action.formValues || {},
        },
        documents: {
          ...state.documents,
          [action.formId]:
            action.formValues && action.formValues.documents
              ? action.formValues.documents
              : [],
        },
        pausedReferralFields: undefined,
        reinitializeReferral: false,
      };
    case POPULATE_PATIENT_FORM:
      return {
        ...state,
        formValues: {
          ...state.formValues,
          [action.formId]: action.formValues || {},
        },
        pausedPatientFields: undefined,
      };
    case POPULATE_ORGANIZATION_FORM:
      return {
        ...state,
        formValues: {
          ...state.formValues,
          [action.formId]: action.formValues || {},
        },
        pausedOrganizationFields: undefined,
      };
    case POPULATE_PATIENT_FROM_REFERRAL:
      const insurance =
        state.pausedReferralFields.insurance ||
        (state.pausedReferralFields.patient &&
          state.pausedReferralFields.patient.insurance);
      let insuranceInfo = {};
      if (insurance && insurance.length) {
        insuranceInfo = insurance
          .map((ins) => {
            if (ins.priority === 1) {
              return {
                primaryInsuranceCompanyId: ins.insuranceCompanyId,
                primaryInsuranceGroupNumber: ins.groupNumber,
                primaryInsurancePolicyNumber: ins.policyNumber,
                primaryInsuranceCompany: ins.insuranceCompanyName,
              };
            } else if (ins.priority === 2) {
              return {
                secondaryInsuranceCompanyId: ins.insuranceCompanyId,
                secondaryInsuranceGroupNumber: ins.groupNumber,
                secondaryInsurancePolicyNumber: ins.policyNumber,
                secondaryInsuranceCompany: ins.insuranceCompanyName,
              };
            } else {
              return null;
            }
          })
          .reduce((map, obj) => {
            return {
              ...map,
              ...obj,
            };
          });
      }
      return {
        ...state,
        pausedPatientFields: {
          ...state.pausedReferralFields.patient,
          ...insuranceInfo,
        },
      };
    case POPULATE_ORGANIZATION_FROM_REFERRAL:
      let organization =
        action.referralType === "Received"
          ? { ...state.pausedReferralFields.senderPractice }
          : { ...state.pausedReferralFields.recipientPractice };

      organization.specialty = organization.specialties[0];

      if (action.referralType === "Received") {
        if (state.pausedReferralFields.senderPractice.address) {
          const { city, zip, street } =
            state.pausedReferralFields.senderPractice.address;

          organization.city = city;
          organization.state =
            state.pausedReferralFields.senderPractice.address.state;
          organization.zip = zip;
          organization.street = street;
        }
      } else {
        if (state.pausedReferralFields.recipientPractice.address) {
          const { city, zip, street } =
            state.pausedReferralFields.recipientPractice.address;

          organization.city = city;
          organization.state =
            state.pausedReferralFields.recipientPractice.address.state;
          organization.zip = zip;
          organization.street = street;
        }
      }

      return {
        ...state,
        pausedOrganizationFields: {
          ...organization,
        },
      };
    case ADD_PATIENT:
      return {
        ...state,
        pausedReferralFields: {
          ...state.pausedReferralFields,
          patient: action.patient,
        },
      };
    case ADD_ORGANIZATION:
      return {
        ...state,
        pausedReferralFields: {
          ...state.pausedReferralFields,
          recipientPractice: action.organization,
        },
      };
    case RESET_FORM:
      return {
        ...state,
        formValues: {},
        documents: [],
        errorFields: [],
        reinitializeReferral: action.reinitializeReferral,
      };
    case RESET_FORM_CACHE:
      return {
        ...state,
        formValues: {
          ...state.formValues,
          [action.formId]: {},
        },
      };
    case RESET_FIELD:
      return {
        ...state,
        formValues: {
          ...state.formValues,
          [action.fieldName]: undefined,
        },
      };
    case REMOVE_SAVED_STATES:
      return {
        ...state,
        pausedReferralFields: undefined,
        pausedPatientFields: undefined,
        pausedOrganizationFields: undefined,
      };
    case SET_ERROR_FIELDS:
      return {
        ...state,
        errorFields: action.errorFields,
      };
    case CLEAR_ERROR_FIELDS:
      return {
        ...state,
        errorFields: [],
      };
    default:
      return state;
  }
}

// Action Creators
export function changeField(event, fieldName, isValue, formId) {
  if (event === null) {
    event = {
      value: undefined,
    };
  }
  if (isValue) {
    return {
      type: UPDATE_FORM,
      targetValue: event,
      fieldName: fieldName,
      formId,
    };
  }
  return {
    type: UPDATE_FORM,
    targetValue: event.target
      ? event.target.value
      : event.value
      ? event.value
      : Array.isArray(event) && event.map((arrayValue) => arrayValue.value),
    fieldName: fieldName,
    formId,
  };
}

export function resetFormCache(formId) {
  return {
    type: RESET_FORM_CACHE,
    formId,
  };
}

export function saveReferralFormState(formId) {
  return {
    type: SAVE_REFERRAL_FORM,
    formId,
  };
}

export function prepopulateReferralForm(formValues, formId) {
  return {
    type: POPULATE_REFERRAL_FORM,
    formValues,
    formId,
  };
}

export function prepopulatePatientForm(formValues, formId) {
  return {
    type: POPULATE_PATIENT_FORM,
    formValues,
    formId,
  };
}

export function prepopulateOrganizationForm(formValues, formId) {
  return {
    type: POPULATE_ORGANIZATION_FORM,
    formValues,
    formId,
  };
}

export function addDocuments(docList, formId) {
  return {
    type: ADD_DOCUMENTS,
    docList,
    formId,
  };
}

export function updateDocuments(docList, formId) {
  return {
    type: UPDATE_DOCUMENTS,
    docList,
    formId,
  };
}

export function selectFromPrint(doc, formId) {
  return {
    type: ADD_DOCUMENT,
    selectDoc: doc,
    formId,
  };
}

export function removeFile(file, formId) {
  return {
    type: REMOVE_DOCUMENT,
    targetValue: file,
    formId,
  };
}

export function removeFileFromPrint(file, formId) {
  return {
    type: REMOVE_USER_DOCUMENT,
    targetValue: file,
    formId,
  };
}

export function resetForm(reinitializeReferral = false, formId) {
  return {
    type: RESET_FORM,
    reinitializeReferral,
    formId,
  };
}

export function resetField(fieldName) {
  return {
    type: RESET_FIELD,
    fieldName,
  };
}

export function resetSavedStates() {
  return {
    type: REMOVE_SAVED_STATES,
  };
}

export function addPatientToReferral(patient) {
  return {
    type: ADD_PATIENT,
    patient,
  };
}

export function addOrganizationToReferral(organization) {
  return {
    type: ADD_ORGANIZATION,
    organization,
  };
}

export function showProcessingSpinner() {
  return {
    type: SHOW_PROCESSING_SPINNER,
  };
}

export function hideProcessingSpinner() {
  return {
    type: HIDE_PROCESSING_SPINNER,
  };
}

export function prepopulateOrganizationFormFromReferralForm(referralType) {
  return {
    type: POPULATE_ORGANIZATION_FROM_REFERRAL,
    referralType,
  };
}

export function setErrorFields(errorFields) {
  return {
    type: SET_ERROR_FIELDS,
    errorFields,
  };
}

export function clearErrorFields() {
  return {
    type: CLEAR_ERROR_FIELDS,
  };
}
