import { put, call, takeLatest } from "redux-saga/effects";
import {
  fetchSentReferral,
  fetchReceivedReferral,
} from "../../services/ReferralService";
import { checkAuthState, getAuthToken, getClientId } from "./auth";
import _ from "lodash";
import { enqueueSnackbar } from "./notifiers";

// Actions
const OPEN = "hermes/edit-dialog/OPEN";
const CLOSE = "hermes/edit-dialog/CLOSE";
const HYDRATE_DIALOG_REFERRAL = "hermes/edit-dialog/HYDRATE_DIALOG_REFERRAL";
const HYDRATE_DIALOG_REFERRAL_SUCCESS =
  "hermes/edit-dialog/HYDRATE_DIALOG_REFERRAL_SUCCESS";
const HYDRATE_DIALOG_REFERRAL_FAILURE =
  "hermes/edit-dialog/HYDRATE_DIALOG_REFERRAL_FAILURE";

const initialState = {
  dialogOpen: false,
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case OPEN:
      return {
        ...state,
        dialogOpen: true,
        dialogReferral: action.dialogReferral,
        dialogAction: action.dialogAction,
      };
    case CLOSE:
      return {
        ...state,
        dialogOpen: false,
        dialogReferral: undefined,
        dialogAction: undefined,
        formValues: undefined,
      };
    case HYDRATE_DIALOG_REFERRAL_SUCCESS:
      return {
        ...state,
        dialogReferral: action.referral,
      };
    case HYDRATE_DIALOG_REFERRAL_FAILURE:
      return {
        ...state,
        dialogReferral: null,
      };
    default:
      return state;
  }
}

// Action Creators
export function openDialog(referral, action) {
  return { type: OPEN, dialogReferral: referral, dialogAction: action };
}

export function closeDialog() {
  return { type: CLOSE };
}

export function hydrateDialogReferral(refId, refType) {
  return {
    type: HYDRATE_DIALOG_REFERRAL,
    refId,
    refType,
  };
}

function* handleHydrateDialogReferral({ refId, refType }) {
  try {
    let authToken = yield call(getAuthToken);
    let clientId = yield call(getClientId);
    let response = yield call(
      refType === "SENT" ? fetchSentReferral : fetchReceivedReferral,
      authToken,
      clientId,
      refId
    );
    yield put({ referral: response, type: HYDRATE_DIALOG_REFERRAL_SUCCESS });
  } catch (e) {
    let errorLogging = {};
    if (!_.includes(e.message, "401")) {
      errorLogging = { error: true, payload: e };
    }
    yield put(checkAuthState());
    yield put(
      enqueueSnackbar({
        message: `Unable to fetch referral.`,
        options: {
          variant: "error",
        },
      })
    );
    yield put({ type: HYDRATE_DIALOG_REFERRAL_FAILURE, ...errorLogging });
  }
}

export function* hydrateDialogReferralSaga() {
  yield takeLatest(HYDRATE_DIALOG_REFERRAL, handleHydrateDialogReferral);
}
