import { gql } from "@apollo/client";

export const documentFieldsFragment = gql`
  fragment DocumentFields on Document {
    uuid
    clientId
    completed
    additionalInfo
    createTime
    createdByUserId
    versions {
      versionId
      lastModifiedTime
      size
      md5sum
    }
    createdByUser {
      userName
      firstName
      lastName
    }
    fileSize
    numPages
    filename
    mimetype
    tags {
      key
      value
    }
    preSignedUrl
    temporary
    deleted
  }
`;
