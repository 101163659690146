import axios from "axios";
import { identifyClientDocuments } from "./DocServerService";
import { getApiHost } from "../state/ducks/api";

const excludedFields = [
  "id",
  "processDefinitionId",
  "processDefinitionName",
  "completed",
  "flagged",
  "processType",
  "startTime",
  "taskId",
  "newComment",
  "status",
  "undefined",
  "documents",
  "patient",
  "actionable",
  "actions",
];

const sentRegExp = /[s|S]ender/;

function determineType(processDefinitionKey) {
  return sentRegExp.test(processDefinitionKey) ? "SENT" : "RECEIVED";
}

function sanitizeReferralFields(referralFields) {
  return Object.keys(referralFields)
    .filter((key) => !excludedFields.includes(key))
    .reduce((prev, next) => ({ ...prev, [next]: referralFields[next] }), {});
}

async function fetchReferral(auth, clientId, processInstanceId) {
  const apiHost = await getApiHost();
  const response = await axios.get(
    `${apiHost}/api/clients/${clientId}/referrals/${processInstanceId}`,
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
      params: {
        includeClosed: true,
      },
    }
  );
  return {
    ...response.data,
    type: determineType(response.data.processDefinitionKey),
    documents: await identifyClientDocuments(
      auth,
      clientId,
      response.data.documents
    ),
  };
}

export async function fetchSentReferral(auth, clientId, processInstanceId) {
  return await fetchReferral(auth, clientId, processInstanceId);
}

export async function fetchReceivedReferral(auth, clientId, processInstanceId) {
  return await fetchReferral(auth, clientId, processInstanceId);
}

export async function reverseState(auth, referralId, newStateName, clientId) {
  newStateName = newStateName.replace(/\b\S/g, (t) => t.toUpperCase());

  return await sendMessageToFlowable(
    auth,
    referralId,
    `Reverse ${newStateName}`,
    clientId
  );
}

async function sendMessageToFlowable(auth, referralId, messageName, clientId) {
  const apiHost = await getApiHost();
  const response = await axios.put(
    `${apiHost}/api/clients/${clientId}/process-instances/${referralId}/message-events/${messageName}`,
    undefined, //Empty Object
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    }
  );
  return { ...response.data };
}

async function sendMessageToFlowableNew(
  auth,
  clientId,
  processInstanceId,
  { comment, messageName, userId }
) {
  const apiHost = await getApiHost();
  const response = await axios.post(
    `${apiHost}/api/clients/${clientId}/process-instances/${processInstanceId}/message-events`,
    {
      ...(comment && {
        additionalInfo: {
          commentDescription: comment,
        },
      }),
      clientId,
      messageName,
      processInstanceId,
      userId,
    },
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    }
  );

  return { ...response.data };
}

export async function moveToSent(auth, referralId, clientId) {
  return await sendMessageToFlowable(
    auth,
    referralId,
    "Send Referral",
    clientId
  );
}

export async function moveToInReview(auth, referralId, clientId) {
  return await sendMessageToFlowable(
    auth,
    referralId,
    "Move Referral to Review",
    clientId
  );
}

export async function moveToScheduled(auth, referralId, clientId) {
  return await sendMessageToFlowable(
    auth,
    referralId,
    "Move Referral to Scheduled",
    clientId
  );
}

export async function moveToConsulted(auth, referralId, clientId) {
  return await sendMessageToFlowable(
    auth,
    referralId,
    "Move Referral to Consulted",
    clientId
  );
}

export async function moveToDone(auth, referralId, clientId) {
  return await sendMessageToFlowable(
    auth,
    referralId,
    "Move Referral to Done",
    clientId
  );
}

export async function moveToDeclined(
  auth,
  clientId,
  referralId,
  { comment, userId }
) {
  return await sendMessageToFlowableNew(auth, clientId, referralId, {
    comment,
    messageName: "Reject Referral",
    userId,
  });
}

export async function moveToClosed(auth, referralId, clientId) {
  return await sendMessageToFlowable(
    auth,
    referralId,
    "Close Referral",
    clientId
  );
}

export async function acceptReferral(auth, referralId, clientId) {
  return await sendMessageToFlowable(
    auth,
    referralId,
    "Accept Referral",
    clientId
  );
}

export async function rejectReferral(
  auth,
  clientId,
  referralId,
  { comment, userId }
) {
  return await sendMessageToFlowableNew(auth, clientId, referralId, {
    comment,
    messageName: "Reject Referral",
    userId,
  });
}

export async function flagReferral(
  auth,
  clientId,
  referralId,
  { comment, userId }
) {
  return await sendMessageToFlowableNew(auth, clientId, referralId, {
    comment,
    messageName: "Flag Referral",
    userId,
  });
}

export async function cancelSentReferral(auth, referralId, clientId) {
  return await sendMessageToFlowable(
    auth,
    referralId,
    "Cancel Referral",
    clientId
  );
}

export async function cloneSentReferral(auth, clientId, referralId) {
  const apiHost = await getApiHost();
  const response = await axios.post(
    `${apiHost}/api/clients/${clientId}/referrals?cloneReferralId=${referralId}`,
    {
      processType: "referralProcess",
    },
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    }
  );
  return { ...response.data };
}

export async function updateReferral(
  auth,
  clientId,
  referralId,
  referralFields
) {
  const apiHost = await getApiHost();

  referralFields = sanitizeReferralFields(referralFields);
  const response = await axios.put(
    `${apiHost}/api/clients/${clientId}/referrals/${referralId}`,
    {
      ...referralFields,
      processType: "referralProcess",
    },
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    }
  );

  return {
    ...response.data,
    type: determineType(response.data.processDefinitionKey),
  };
}

export async function clearConsultDateReferral(auth, clientId, referralId) {
  const apiHost = await getApiHost();
  const response = await axios.put(
    `${apiHost}/api/clients/${clientId}/referrals/${referralId}`,
    {
      processType: "referralProcess",
    },
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
      params: {
        clearConsultDate: true,
      },
    }
  );
  return {
    ...response.data,
    type: determineType(response.data.processDefinitionKey),
  };
}

export async function addDocumentsToReferral(
  auth,
  clientId,
  referralId,
  documents
) {
  const apiHost = await getApiHost();
  const response = await axios.post(
    `${apiHost}/api/clients/${clientId}/process-instances/${referralId}/documents`,
    {
      documents,
    },
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    }
  );

  return response.data;
}

export async function removeDocumentFromReferral(
  auth,
  clientId,
  referralId,
  documentId
) {
  const apiHost = await getApiHost();

  const response = await axios.delete(
    `${apiHost}/api/clients/${clientId}/process-instances/${referralId}/documents/${documentId}`,
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    }
  );

  return response.data;
}

export async function addDocumentsToReceivedReferral(
  auth,
  clientId,
  referralId,
  documents
) {
  const referral = await fetchReceivedReferral(auth, clientId, referralId);
  return await addDocumentsToReferral(auth, clientId, referral.id, documents);
}

export async function addDocumentsToSentReferral(
  auth,
  clientId,
  referralId,
  documents
) {
  const referral = await fetchSentReferral(auth, clientId, referralId);
  return await addDocumentsToReferral(auth, clientId, referral.id, documents);
}

export async function resendFax(auth, clientId, processInstanceId) {
  const apiHost = await getApiHost();

  const response = await axios.post(
    `${apiHost}/api/clients/${clientId}/process-instances/${processInstanceId}/send-fax`,
    {},
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    }
  );

  return response.data;
}
