import {
  DOC_DEPARTMENTS_TAG_URN,
  DOC_DESCRIPTION_TAG_URN,
  DOC_TYPE_TAG_URN,
} from "../constants";

export const getDocTagTypeUrn = (clientId) =>
  DOC_TYPE_TAG_URN.replace("{{clientId}}", clientId);
export const getDocTagDepartmentsUrn = (clientId) =>
  DOC_DEPARTMENTS_TAG_URN.replace("{{clientId}}", clientId);
export const getDocTagDescriptionUrn = (clientId) =>
  DOC_DESCRIPTION_TAG_URN.replace("{{clientId}}", clientId);

/**
 * Hydrates document tag departments.
 * @param clientId
 * @param {Array<{ checked: boolean, value: string }>} departments - An array of objects representing departments.
 */
export const hydratedDocTagDepartmentsUrn = (clientId) => (departments) => {
  const key = getDocTagDepartmentsUrn(clientId);
  return departments
    ?.filter((dept) => dept.checked)
    ?.map((dept) => ({
      key,
      value: dept?.value,
    }));
};

export const hydratedDocumentTagsUrn =
  (clientId) =>
  ({
    currentTags = [],
    departments = [],
    documentDescription = "",
    documentType = "",
  }) => {
    const tagTypeKey = getDocTagTypeUrn(clientId);
    const tagDescriptionKey = getDocTagDescriptionUrn(clientId);
    const tagDepartmentKey = getDocTagDepartmentsUrn(clientId);

    const keys = [tagTypeKey, tagDescriptionKey, tagDepartmentKey];

    const baseTags = currentTags.filter((tag) => !keys.includes(tag?.key));

    baseTags.push({
      key: tagTypeKey,
      value: documentType,
    });
    baseTags.push({
      key: tagDescriptionKey,
      value: documentDescription,
    });

    const departmentTags = hydratedDocTagDepartmentsUrn(clientId)(departments);

    return [...baseTags, ...departmentTags];
  };
