import { put, takeLatest } from "redux-saga/effects";
import { enqueueSnackbar } from "./notifiers";
import _ from "lodash";
import { format } from "date-fns";

// Actions
const SET_FILTERS = "hermes/referral-search/SET_FILTERS";
const SET_FILTER = "hermes/referral-search/SET_FILTER";
const REMOVE_FILTER = "hermes/referral-search/REMOVE_FILTER";
const RESET = "hermes/referral-search/RESET";
const GET_FILTERED_REFERRALS = "hermes/referral-search/GET_FILTERED_REFERRALS";
const START_LOADING_REFERRALS =
  "hermes/referral-search/START_LOADING_REFERRALS";
const MODIFY_FLAG = "hermes/referral-search/MODIFY_FLAG";
const REMOVE_FROM_SEARCH = "hermes/referral-search/REMOVE_FROM_SEARCH";
const GET_LAST_REFERRAL_REFRESH_TIME =
  "hermes/referral-search/GET_LAST_REFERRAL_REFRESH_TIME";
const HYDRATE_SEARCH_REFERRAL =
  "hermes/referral-search/HYDRATE_SEARCH_REFERRAL";
const HYDRATE_SEARCH_REFERRAL_SUCCESS =
  "hermes/referral-search/HYDRATE_SEARCH_REFERRAL_SUCCESS";
const HYDRATE_SEARCH_REFERRAL_FAILURE =
  "hermes/referral-search/HYDRATE_SEARCH_REFERRAL_FAILURE";
const START_SEARCH_HYDRATE = "hermes/referral-search/START_SEARCH_HYDRATE";
const SET_DATE_FLAG = "hermes/referral-search/SET_DATE_FLAG";

const SET_COMING_FROM_REFERRAL_VIEW =
  "hermes/referral-search/SET_COMING_FROM_REFERRAL_VIEW";
const UNSET_COMING_FROM_REFERRAL_VIEW =
  "hermes/referral-search/UNSET_COMING_FROM_REFERRAL_VIEW";

const initialState = {
  list: [],
  filter: [],
  loading: false,
  savedRoute: "/referrals/search-facets",
  dateSet: false,
  comingFromReferralView: false,
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_FILTERS:
      return {
        ...state,
        filter: action.filters,
      };
    case SET_FILTER:
      return {
        ...state,
        filter: [
          ...state.filter.filter((fil) => fil.name !== action.newFilter.name),
          action.newFilter,
        ],
      };
    case REMOVE_FILTER:
      return {
        ...state,
        filter: state.filter.filter(
          (fil) => fil.name !== action.filterToRemove.name
        ),
      };
    case MODIFY_FLAG:
      return {
        ...state,
        list: state.list.map((referral) =>
          referral.id === action.referralId
            ? { ...referral, flagged: !referral.flagged }
            : referral
        ),
      };
    case REMOVE_FROM_SEARCH:
      return {
        ...state,
        list: state.list.filter(
          (referral) => referral.id !== action.referralId
        ),
      };
    case RESET:
      return initialState;
    case START_LOADING_REFERRALS:
      return {
        ...state,
        loading: true,
      };
    case GET_LAST_REFERRAL_REFRESH_TIME:
      return {
        ...state,
        lastReferralRefreshTime: `${format(new Date(), "HH:mm:ss")}`,
      };
    case HYDRATE_SEARCH_REFERRAL_SUCCESS:
      return {
        ...state,
        searchedReferral: action.referral,
        searchedReferralLoading: false,
      };
    case HYDRATE_SEARCH_REFERRAL_FAILURE:
      return {
        ...state,
        searchedReferral: null,
        searchedReferralLoading: false,
      };
    case START_SEARCH_HYDRATE:
      return {
        ...state,
        searchedReferralLoading: true,
      };
    case SET_DATE_FLAG:
      return {
        ...state,
        dateSet: action.flag,
      };
    case SET_COMING_FROM_REFERRAL_VIEW:
      return {
        ...state,
        comingFromReferralView: true,
      };
    case UNSET_COMING_FROM_REFERRAL_VIEW:
      return {
        ...state,
        comingFromReferralView: false,
      };
    default:
      return state;
  }
}

// Action Creators

export function setComingFromReferralView() {
  return {
    type: SET_COMING_FROM_REFERRAL_VIEW,
  };
}

export function unsetComingFromReferralView() {
  return {
    type: UNSET_COMING_FROM_REFERRAL_VIEW,
  };
}

export function hydrateSearchedReferral(referral) {
  return {
    type: HYDRATE_SEARCH_REFERRAL,
    referral,
  };
}

export function getLastReferralRefreshTime() {
  return {
    type: GET_LAST_REFERRAL_REFRESH_TIME,
  };
}

export function setFilters(filters) {
  return {
    type: SET_FILTERS,
    filters,
  };
}

export function setFilter(newFilter) {
  return {
    type: SET_FILTER,
    newFilter,
  };
}

export function removeFilter(filterToRemove) {
  return {
    type: REMOVE_FILTER,
    filterToRemove,
  };
}

export function resetReferralSearch() {
  return {
    type: RESET,
  };
}

export function getMatchingReferrals(filters) {
  return {
    type: GET_FILTERED_REFERRALS,
    filters,
  };
}

export function modifyFlagOnSearch(referralId) {
  return {
    type: MODIFY_FLAG,
    referralId,
  };
}

export function removeReferralFromSearch(referralId) {
  return {
    type: REMOVE_FROM_SEARCH,
    referralId,
  };
}

export function setDateFlag(flag) {
  return {
    type: SET_DATE_FLAG,
    flag,
  };
}

export function startReferralSearch() {
  return {
    type: START_SEARCH_HYDRATE,
  };
}

function* handleHydrateSearchReferral({ referral }) {
  try {
    yield put({ referral: referral, type: HYDRATE_SEARCH_REFERRAL_SUCCESS });
  } catch (e) {
    let errorLogging = {};
    if (!_.includes(e.message, "401")) {
      errorLogging = { error: true, payload: e };
    }
    yield put(
      enqueueSnackbar({
        message: `Unable to fetch referral.`,
        options: {
          variant: "error",
        },
      })
    );
    yield put({ type: HYDRATE_SEARCH_REFERRAL_FAILURE, ...errorLogging });
  }
}

export function* hydrateSearchReferralSaga() {
  yield takeLatest(HYDRATE_SEARCH_REFERRAL, handleHydrateSearchReferral);
}
