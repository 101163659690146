import React, { useCallback, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  Button,
} from "@mui/material";
import { generatePatientDisplayName } from "../TaskCard/TaskCard";
import SchedulingSection from "../Form/CardSections/SchedulingSection";
import { closeDialog } from "../../state/ducks/dialogs";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { patchReferral } from "../../state/ducks/referralsSent";
import { useForm } from "react-hook-form";
import { enqueueSnackbar } from "../../state/ducks/notifiers";
import { changeReferralState } from "../../state/ducks/referralsReceived";
import useFetchReferral from "../../hooks/useFetchReferral";

export const useStyles = makeStyles({
  root: {
    overflowY: "visible",
  },
  cardHeaderRoot: {
    display: "none",
  },
});

export default function SchedulingDialog() {
  const dispatch = useDispatch();
  const schedulingDialogIsOpen = useSelector(
    (state) => state.dialogs.scheduling?.open
  );
  const referral = useSelector((state) => state.dialogs.scheduling?.referral);
  const referralDetail = useSelector((state) =>
    referral?.status === "Closed"
      ? referral
      : referral?.type === "SENT"
      ? state.referralsSent.details.find((ref) => ref.id === referral?.id)
      : state.referralsReceived.details.find((ref) => ref.id === referral?.id)
  );
  const { handleFetchReferral } = useFetchReferral();

  useEffect(() => {
    if (referral?.id) {
      handleFetchReferral(referral?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referral?.id, dispatch, referral?.type]);

  const action = useSelector((state) => state.dialogs.scheduling?.action);
  const classes = useStyles();
  const { handleSubmit, setValue, register, unregister } = useForm({
    defaultValues: {},
  });

  useEffect(() => {
    register("seenAtProvider", { required: true });
    register("consultDate", { required: true });
    register("referralRecipientPhysicianActual");

    return () => {
      if (unregister) {
        unregister("seenAtProvider");
        unregister("consultDate");
        unregister("referralRecipientPhysicianActual");
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register]);

  const validElements = useCallback(
    (data) => {
      Object.keys(data).map((key) => {
        setValue(key, data[key]);
        return null;
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [referral]
  );

  const onSubmit = (formValues) => {
    const requestedServices = referralDetail?.requestedServices?.length
      ? referralDetail?.requestedServices
      : [];
    dispatch(patchReferral(referral.id, { ...formValues, requestedServices }));
    dispatch(
      changeReferralState(
        referral.id,
        referral.displayableId,
        referral.taskId,
        action.targetState,
        action.name,
        formValues
      )
    );
    dispatch(closeDialog("scheduling"));
  };
  const onError = (error) => {
    dispatch(
      enqueueSnackbar({
        message: `Please complete all required form fields`,
        options: {
          variant: "error",
        },
      })
    );
  };

  return schedulingDialogIsOpen ? (
    <Dialog
      key={`${referral.id}_${action?.name}_dialog`}
      open={true}
      onClose={() => {
        dispatch(closeDialog("scheduling"));
      }}
      aria-labelledby="form-dialog-title"
      scroll="body"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle id="form-dialog-title" disableTypography>
        <Typography component="h2" variant="h6">
          {action?.name}
        </Typography>
        <Typography variant="subtitle1">{`${generatePatientDisplayName(
          referral.patient || referral.patientSnapshot
        )} (REF-${referral.displayableId})`}</Typography>
      </DialogTitle>
      <DialogContent sx={{ overflowY: "visible" }}>
        <form
          id="scheduling-action-dialog"
          onSubmit={handleSubmit(onSubmit, onError)}
        >
          <SchedulingSection
            initialize
            {...{ cardDetail: referral, classes, validElements }}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          id="success"
          key={`button_${action.name}`}
          type="submit"
          form="scheduling-action-dialog"
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;
}
