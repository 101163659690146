import React from "react";
import Container from "./components/Container";
import { Provider } from "react-redux";
import { SnackbarProvider } from "notistack";
import {
  OptimizelyProvider,
  createInstance,
  setLogLevel,
} from "@optimizely/react-sdk";
import configureStore from "./state";
import { pdfjs } from "react-pdf";
import { ThemeProvider } from "@material-ui/core/styles";
import { ThemeProvider as V5ThemeProvider } from "@mui/material";
import { getCurrentEnvironment } from "./services/VersionService";
import { getLatestGraphQLHost } from "./services/ApiService";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { createClient } from "graphql-ws";
import {
  ApolloProvider,
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { SearchkitProvider, SearchkitClient } from "@searchkit/client";
import { CookiesProvider } from "react-cookie";
import { EuiProvider } from "@elastic/eui";
import {
  AppSettingProvider,
  NotificationProvider,
  UsersProvider,
} from "./context";
import theme from "./theme";
import v5Theme from "./v5theme";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const store = configureStore();

const optimizelyKeys = JSON.parse(process.env.REACT_APP_OPTIMIZELY_TOKEN);

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = sessionStorage.getItem("hermesUIBearerToken");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: "cache-and-network",
  },
};

const searchkitClient = new SearchkitClient();
var apolloClient;

class App extends React.Component {
  async componentDidMount() {
    const graphqlHost = await getLatestGraphQLHost();
    const wsHost = graphqlHost.replace("https", "wss");

    const httpLink = createHttpLink({
      uri: `${graphqlHost}/graphql`,
    });

    const wsLink = new GraphQLWsLink(
      createClient({
        url: `${wsHost}/graphql`,
        connectionParams: async () => {
          return {
            Authorization: sessionStorage.getItem("hermesUIBearerToken"),
          };
        },
        shouldRetry: true,
      })
    );

    const splitLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === "OperationDefinition" &&
          definition.operation === "subscription"
        );
      },
      wsLink,
      httpLink
    );

    const env = await getCurrentEnvironment();

    const connectToDevTools = env !== "production";
    apolloClient = new ApolloClient({
      cache: new InMemoryCache({
        typePolicies: {
          Providers: {
            merge: true,
          },
        },
      }),
      link: authLink.concat(splitLink),
      defaultOptions,
      connectToDevTools,
    });

    const optimizely = createInstance({
      sdkKey: optimizelyKeys[env],
      datafileOptions: {
        autoUpdate: true,
        updateInterval: 600000,
        urlTemplate:
          env === "local" ? undefined : `/optimizely-config/config.json`,
      },
    });
    env !== "local" && setLogLevel("WARN");
    this.setState({ optimizely });
  }

  render() {
    return this.state && this.state.optimizely ? (
      <Provider store={store}>
        <OptimizelyProvider optimizely={this.state.optimizely}>
          <EuiProvider>
            <ThemeProvider theme={theme}>
              <V5ThemeProvider theme={v5Theme}>
                <SnackbarProvider
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <CookiesProvider>
                    <ApolloProvider client={apolloClient}>
                      <SearchkitProvider client={searchkitClient}>
                        <NotificationProvider>
                          <AppSettingProvider>
                            <UsersProvider>
                              <Container />
                            </UsersProvider>
                          </AppSettingProvider>
                        </NotificationProvider>
                      </SearchkitProvider>
                    </ApolloProvider>
                  </CookiesProvider>
                </SnackbarProvider>
              </V5ThemeProvider>
            </ThemeProvider>
          </EuiProvider>
        </OptimizelyProvider>
      </Provider>
    ) : null;
  }
}

export default App;
