import axios from "axios";

const reflect = (p) =>
  p.then(
    (v) => ({ v, status: "fulfilled" }),
    (e) => ({ v: {}, e, status: "rejected" })
  );

export async function getLatestApiHost() {
  const response = await reflect(
    axios.get(
      `${
        process.env.REACT_APP_BASENAME !== "/"
          ? process.env.REACT_APP_BASENAME
          : ""
      }/api_host.txt`,
      {
        headers: {
          "Cache-Control": "no-cache",
        },
      }
    )
  );

  if (response.status === "fulfilled" && typeof response.v.data === "string") {
    return response.v.data.trim();
  }
  console.warn("Unable to detect API host; using default value.");
  return "https://exchange.phealth-dev.net";
}
export async function getLatestGraphQLHost() {
  const response = await reflect(
    axios.get(
      `${
        process.env.REACT_APP_BASENAME !== "/"
          ? process.env.REACT_APP_BASENAME
          : ""
      }/graphql_host.txt`,
      {
        headers: {
          "Cache-Control": "no-cache",
        },
      }
    )
  );

  if (response.status === "fulfilled" && typeof response.v.data === "string") {
    return response.v.data.trim();
  }
  console.warn("Unable to detect GQL host; using default value.");
  return "https://graphql.phealth-dev.net";
}
