import React from "react";

import { useStyles } from "./styles";
import useTextColorStyles from "./textColor";
import clsx from "clsx";

const Typography = ({
  level,
  children,
  color,
  className,
  bold,
  underlined,
  dataTest,
}) => {
  const classes = useStyles();
  const textColorClasses = useTextColorStyles();

  const getChildren = () => {
    return bold ? <strong>{children}</strong> : children;
  };
  const elements = {
    h1: (
      <h1
        className={clsx(
          classes.h1,
          className,
          textColorClasses[color],
          underlined && classes.underlined
        )}
        data-testid={dataTest}
      >
        {getChildren()}
      </h1>
    ),
    h2: (
      <h2
        className={clsx(
          classes.h2,
          className,
          textColorClasses[color],
          underlined && classes.underlined
        )}
        data-testid={dataTest}
      >
        {getChildren()}
      </h2>
    ),
    h3: (
      <h3
        className={clsx(
          classes.h3,
          className,
          textColorClasses[color],
          underlined && classes.underlined
        )}
        data-testid={dataTest}
      >
        {getChildren()}
      </h3>
    ),
    h4: (
      <h4
        className={clsx(
          classes.h4,
          className,
          textColorClasses[color],
          underlined && classes.underlined
        )}
        data-testid={dataTest}
      >
        {getChildren()}
      </h4>
    ),
    h5: (
      <h5
        className={clsx(
          classes.h5,
          className,
          textColorClasses[color],
          underlined && classes.underlined
        )}
        data-testid={dataTest}
      >
        {getChildren()}
      </h5>
    ),
    h6: (
      <h6
        className={clsx(
          classes.h6,
          className,
          textColorClasses[color],
          underlined && classes.underlined
        )}
        data-testid={dataTest}
      >
        {getChildren()}
      </h6>
    ),
    body: (
      <div
        className={clsx(
          classes.body,
          className,
          textColorClasses[color],
          underlined && classes.underlined
        )}
        data-testid={dataTest}
      >
        {getChildren()}
      </div>
    ),
    large: (
      <div
        className={clsx(
          classes.large,
          className,
          textColorClasses[color],
          underlined && classes.underlined
        )}
        data-testid={dataTest}
      >
        {getChildren()}
      </div>
    ),
    medium: (
      <div
        className={clsx(
          classes.medium,
          className,
          textColorClasses[color],
          underlined && classes.underlined
        )}
        data-testid={dataTest}
      >
        {getChildren()}
      </div>
    ),
    small: (
      <div
        className={clsx(
          classes.small,
          className,
          textColorClasses[color],
          underlined && classes.underlined
        )}
        data-testid={dataTest}
      >
        {getChildren()}
      </div>
    ),
  };
  return children ? elements[level] : null;
};

export default Typography;
