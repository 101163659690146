import axios from "axios";
import { getApiHost } from "../state/ducks/api";

export async function searchProviders(
  auth,
  clientId,
  networkIds,
  specialty,
  searchTerm,
  type,
  orgName,
  size,
  sort,
  urn
) {
  if (!networkIds || !networkIds.length) {
    window.Rollbar.warning("Tried to search directory without any networks");
    return [];
  }
  const apiHost = await getApiHost();

  const response = await axios.get(
    `${apiHost}/api/clients/${clientId}/providerSearch/?${networkIds
      .map((id) => {
        return `networkIds=${id}`;
      })
      .join("&")}`,
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
      params: {
        ...(Boolean(specialty) && { specialty }),
        ...(Boolean(searchTerm) && { searchTerm }),
        ...(Boolean(orgName) && { orgName }),
        ...(Boolean(urn) && { urn }),
        type,
        size,
        sort,
      },
    }
  );

  return response.data || [];
}

export async function getSpecialtiesForClientService(auth, clientId) {
  const apiHost = await getApiHost();
  const response = await axios.get(
    `${apiHost}/api/clients/${clientId}/specialties/`,
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    }
  );

  return response.data;
}

export async function getSpecialties(auth) {
  const apiHost = await getApiHost();
  const response = await axios.get(`${apiHost}/api/specialties/`, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
  return response.data;
}
