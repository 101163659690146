import { gql } from "@apollo/client";

export const assigneeFieldsFragment = gql`
  fragment AssigneeFields on User {
    clientId
    firstName
    id
    lastName
    userName
  }
`;
