import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  pageTitle: {
    marginTop: -12,
  },
  customToolbar: {
    fontSize: "11px",
  },
  headerSection: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "1.57143rem",
    fontWeight: 700,
    color: "#1a1c21",
    lineHeight: "2.28571rem",
  },
  newEuiPageContentHeaderSection: {
    width: "100% !important",
  },
  headerButtons: {
    color: "#516794",
    fontSize: "11px",
    textTransform: "none !important",
  },
  multiTenantFileUploadModal: {
    width: "620px",
    height: "300px",
    backgroundColor: "white",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "relative",
    borderRadius: "8px",
    overflowY: "auto",
  },
  multiTenantFileUploadModalHeader: {
    fontSize: "20px",
    marginTop: "20px",
    fontWeight: "500",
  },
  multiTenantFileUploadModalLeftMargin: {
    marginLeft: "30px",
  },
  multiTenantFileUploadModalInfoText: {
    fontSize: "0.8rem",
    color: "#4F4F4F",
  },

  docTriageSearchBox: {
    backgroundColor: "white",
    width: "100%",
  },

  docTriageSearchBoxLabel: {
    fontWeight: 700,
    fontSize: "11px",
    color: "#828282",
  },

  marginSearchBar: {
    marginTop: "20px",
  },

  radioOptionsMargin: {
    marginTop: "23px",
  },

  resetButtonRightAlign: {
    width: "100%",
    display: "flex",
    gap: 8,
    justifyContent: "flex-end",
    paddingRight: 16,
  },

  rightMargin: {
    marginRight: 12,
  },

  selectedFilterContainer: {
    "& > div": {
      flexWrap: "wrap",
    },
  },

  centerItems: {
    textAlign: "center",
  },

  facetName: {
    fontWeight: 700,
    fontSize: "1rem",
    lineHeight: "1.71429rem",
    color: "#1a1c21",
  },

  noPadding: {
    padding: 0,
  },

  docListPaddings: {
    padding: "30px",
  },
  input: {
    display: "none",
  },
}));
