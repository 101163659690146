import { AppSettingContext } from "./appSettingContext";
import { useContext } from "react";

export const useAppSetting = () => {
  const context = useContext(AppSettingContext);
  if (context === undefined) {
    return new Error("Export config context is undefined");
  }
  return context;
};
