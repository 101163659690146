import React, { useEffect, useState } from "react";
import { Box, Typography, Tooltip } from "@mui/material";
import Select from "react-select";
import { useSearchkit } from "@searchkit/client";
import {
  CREATED_BY_IDENTIFIER,
  RECEIVED_NUMBER_IDENTIFIER,
} from "../../constants";

const getPlaceHolderText = (identifier) => {
  const placeHolderMap = {
    [RECEIVED_NUMBER_IDENTIFIER]: "Received Fax",
    [CREATED_BY_IDENTIFIER]: "Created By",
  };
  return placeHolderMap[identifier] || "Received Fax";
};

const facetIdentifiers = [RECEIVED_NUMBER_IDENTIFIER, CREATED_BY_IDENTIFIER];

export const OverridableSelectFacet = ({ facet }) => {
  const api = useSearchkit();
  const [value, setValue] = useState("");
  const selectedFilter = api
    .getFilters()
    .find((filter) => filter?.identifier === facet?.identifier);

  const options =
    facet?.entries
      ?.map((entry) => ({
        label: entry?.label,
        value: entry?.value,
        extra: entry?.count,
      }))
      .sort((a, b) => b?.extra - a?.extra) || [];

  const formatOptionLabel = ({ label, extra }) => (
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      alignItems="center"
      height="28px"
      fontSize="14px"
    >
      <Tooltip title={label} placement="bottom-start">
        <Box
          sx={{
            whiteSpace: "nowrap",
            width: "90%",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {label}
        </Box>
      </Tooltip>
      <Box
        sx={{
          borderRadius: "4px",
          backgroundColor: "#E4E7F0",
          fontWeight: 500,
          width: "30px",
          textAlign: "center",
        }}
      >
        {extra}
      </Box>
    </Box>
  );
  const onChange = async (option) => {
    localStorage.setItem(facet.identifier, JSON.stringify(option));
    if (option?.value) {
      api.toggleFilter({
        identifier: facet.identifier,
        value: option.value,
      });
    } else {
      api.removeFiltersByIdentifier(facet.identifier);
    }
    api.setPage({ from: 0 });
    api.search();
  };

  useEffect(() => {
    if (selectedFilter?.value) {
      return setValue(options.find((o) => o?.value === selectedFilter?.value));
    }

    const filterValue = JSON.parse(localStorage.getItem(facet.identifier));
    if (facetIdentifiers.includes(facet.identifier) && filterValue) {
      const newValue = options.find((o) => o?.value === filterValue.value);
      setValue(newValue);
    } else {
      setValue(filterValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facet, selectedFilter?.value]);

  useEffect(() => {
    const initialUrl = window.location.href;

    const handleBeforeUnload = (event) => {
      localStorage.removeItem(RECEIVED_NUMBER_IDENTIFIER);
      localStorage.removeItem(CREATED_BY_IDENTIFIER);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);

      if (window.location.href !== initialUrl) {
        localStorage.removeItem(RECEIVED_NUMBER_IDENTIFIER);
        localStorage.removeItem(CREATED_BY_IDENTIFIER);
      }
    };
  }, []);

  return (
    <>
      <Typography fontWeight={700} component={"h3"}>
        {facet.label}
      </Typography>
      <Select
        options={options}
        value={value}
        data-testid="overridable-select-facet"
        formatOptionLabel={formatOptionLabel}
        placeholder={getPlaceHolderText(facet.identifier)}
        styles={{
          option: (base) => ({
            ...base,
            height: "28px",
            padding: "0 8px 0 8px",
            borderBottom: "1px solid #E4E7F0",
          }),
        }}
        isClearable
        onChange={onChange}
      />
    </>
  );
};

OverridableSelectFacet.DISPLAY = "OverridableSelectFacet";
