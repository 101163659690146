const ENQUEUE_SNACKBAR = "hermes/notifiers/ENQUEUE_SNACKBAR";
const REMOVE_SNACKBAR = "hermes/notifiers/REMOVE_SNACKBAR";

const defaultState = {
  notifications: [],
};

const notifiersReducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    case ENQUEUE_SNACKBAR:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            ...action.notification,
          },
        ],
      };

    case REMOVE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.filter(
          (notification) => notification.key !== action.key
        ),
      };

    default:
      return state;
  }
};

export default notifiersReducer;

// Action Creators
export const enqueueSnackbar = (notification) => ({
  type: ENQUEUE_SNACKBAR,
  notification: {
    key: new Date().getTime() + Math.random(),
    ...notification,
  },
});

export const removeSnackbar = (key) => ({
  type: REMOVE_SNACKBAR,
  key,
});
