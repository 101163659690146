import cryptoBrowserify from "crypto-browserify";
import axios from "axios";
import { getCurrentEnvironment } from "../../services/VersionService";
import { setCognitoToken } from "../../state/ducks/auth";

const SCOPE = "aws.cognito.signin.user.admin openid";

//TODO support refresh tokens
// const accessToken = window.localStorage.getItem('accessToken');
// const idToken = window.localStorage.getItem('idToken');
// const refreshToken = window.localStorage.getItem('refreshToken');

const cognitoClientIds = JSON.parse(process.env.REACT_APP_COGNITO_CLIENT_ID);
const fipsUrls = JSON.parse(process.env.REACT_APP_FIPS_URLS);

const getClientId = async () => {
  const env = await getCurrentEnvironment();

  if (env) {
    return cognitoClientIds[env];
  } else {
    throw new Error(`No clientId found for env=${env}`);
  }
};
const getFipsUrl = async () => {
  const env = await getCurrentEnvironment();

  if (env) {
    return fipsUrls[env];
  } else {
    throw new Error(`No fips url found for env=${env}`);
  }
};

export const storeCodeAndGetSubDomain = (code, state) => {
  const url = JSON.parse(window.localStorage.getItem("nonce"));

  if (Boolean(url)) {
    window.localStorage.setItem(
      "nonce",
      JSON.stringify({
        [state]: {
          hostname,
          redirectUrl: url[state]?.redirectUrl,
          subDomain: url[state]?.subDomain,
          code,
        },
      })
    );
  }

  return Boolean(url) ? url[state]?.subDomain : "";
};

export const getSubDomain = (state) => {
  if (state === "localhost:3000") {
    return state;
  }
  const sub = state.split(".")[0];
  return window.location.origin.replace(/https:\/\/[^.]*\./, `https://${sub}.`);
};

//TODO support refresh tokens
// let tokens = accessToken === null ? null : {
//   accessToken,
//   idToken,
//   refreshToken,
// };

const base64URLEncode = (str) => {
  return str
    .toString("base64")
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=/g, "");
};

const sha256 = (buffer) => {
  return cryptoBrowserify.createHash("sha256").update(buffer).digest();
};

let storedVerifier = window.localStorage.getItem("verifier");
let nonce;

if (storedVerifier === null) {
  const newVerifier = base64URLEncode(cryptoBrowserify.randomBytes(32));
  window.localStorage.setItem("verifier", newVerifier);
  storedVerifier = newVerifier;
}
const host =
  window.location.hostname === "localhost"
    ? "phealth-dev"
    : window.location.hostname.split(".")[1];
const verifier = storedVerifier;
const challenge = base64URLEncode(sha256(storedVerifier));

const exchangeUrl = `https://exchange.${host}.net`;

const logoutUri =
  window.location.hostname === "localhost"
    ? "http://localhost:3000/logout/"
    : `https://phealth.${host}.net/logout/`;
//redirect uri has to be phealth subdomain.  cognito does not support wildcard domains for redirect at this point
const redirectUri =
  window.location.hostname === "localhost"
    ? "http://localhost:3000/exchange/"
    : `https://phealth.${host}.net/exchange/`;

const initClientId = getClientId();
const initBaseUrl = getFipsUrl();

const hostname = window.location.hostname;

export const getBaseUrl = async () => {
  return await initBaseUrl;
};
export const getLoginUrl = async () => {
  nonce = base64URLEncode(cryptoBrowserify.randomBytes(32));

  window.localStorage.setItem(
    "nonce",
    JSON.stringify({
      [nonce]: {
        hostname,
        redirectUrl: window.location.pathname + window.location.search,
        subDomain: getSubDomain(hostname),
      },
    })
  );

  const clientId = await initClientId;
  return `${await getBaseUrl()}/oauth2/authorize?scope=${SCOPE}&response_type=code&client_id=${clientId}&code_challenge=${challenge}&code_challenge_method=S256&redirect_uri=${redirectUri}&state=${nonce},${hostname}`;
};

export const getLogoutUrl = async (callback) => {
  const clientId = await initClientId;
  const nonce = window.localStorage.getItem("nonce");
  let nonceId;
  if (nonce) {
    nonceId = Object.keys(JSON.parse(nonce));
  }
  return `${logoutUri}?redirect=${encodeURIComponent(
    `${await getBaseUrl()}/logout?client_id=${clientId}&logout_uri=${redirectUri}&state=${nonceId},${hostname}`
  )}&callback=${encodeURIComponent(callback)}`;
};

export const logout = async (callback) => {
  return await getLogoutUrl(callback);
};

export const login = async (domain, state) => {
  const clientId = await initClientId;
  const url = JSON.parse(window.localStorage.getItem("nonce"));
  const body = `grant_type=authorization_code&client_id=${clientId}&code_verifier=${verifier}&code=${url[state]?.code}&redirect_uri=${redirectUri}`;
  let redirectUrl;

  if (state) {
    redirectUrl = url[state]?.redirectUrl;
  }

  let response = "";
  const tokenUrl = `${await getBaseUrl()}/oauth2/token`;
  try {
    response = await axios.post(tokenUrl, body, {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    });
  } catch (e) {
    //probably refreshed with a code that has already been used.
    //Need a new code
    if (e.response.data.error === "invalid_grant") {
      const loginUrl = await getLoginUrl();
      window.location.replace(loginUrl);
      return;
    } else {
      throw e;
    }
  }

  const { access_token, id_token /* refresh_token */ } = response.data;

  setCognitoToken(access_token);

  //exchange token
  response = await axios.get(
    `${exchangeUrl}/api/auth/token?loginDomain=${domain}`,
    {
      headers: {
        authorization: `Bearer ${id_token}`,
      },
    }
  );
  return {
    ...response.data,
    redirect: redirectUrl,
  };
};
