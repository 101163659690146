import axios from "axios";
import { getApiHost } from "../state/ducks/api";

//GET Insurance Plans

export async function getInsurancePlansFn(auth) {
  const apiHost = await getApiHost();
  const response = await axios.get(`${apiHost}/api/insurance-plans/`, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
  return response.data;
}
