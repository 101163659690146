import React from "react";
import { LinearProgress } from "@material-ui/core";

export const styles = {
  brandHit: {
    display: "block",
    zIndex: "9999",
    width: "25%",
    margin: "170px auto 0 auto",
  },
  brandHitLogo: {
    display: "block",
    width: "100%",
  },
};

export function BrandLoader({ classes, imgStyle }) {
  return (
    <div
      id="brand-loader"
      className={classes.brandHit}
      data-testid="brand-loader"
    >
      <img
        className={classes.brandHitLogo}
        style={imgStyle}
        src={`${
          process.env.REACT_APP_BASENAME === "/"
            ? "/exchange"
            : process.env.REACT_APP_BASENAME
        }/branding_hit.png`}
        alt="Proficient Health"
      />
      <LinearProgress />
    </div>
  );
}
