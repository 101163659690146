import React from "react";
import { Grid, Typography } from "@material-ui/core";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
    const chunkErrorRegEx = RegExp(/Loading chunk \d+ failed/);
    if (chunkErrorRegEx.test(error.message)) {
      window.location.reload(true);
    } else {
      window.Rollbar.error(error, errorInfo);
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Typography style={{ paddingTop: 96 }} variant="h5" color="error">
            Sorry!
          </Typography>
          <Typography color="error">
            An error occurred that is preventing us from showing you the page
            you requested.
          </Typography>
          <Typography style={{ paddingTop: 32 }} color="error">
            Please refresh the page to continue.
          </Typography>
        </Grid>
      );
    }
    return this.props.children;
  }
}
