import { gql } from "@apollo/client";

export const PRACTICE_PROVIDER_SEARCH = gql`
  query providerQuery(
    $query: String
    $filters: [SKFiltersSet]
    $page: SKPageInput
    $sortBy: String
  ) {
    providers {
      results(query: $query, filters: $filters) {
        summary {
          total
          appliedFilters {
            id
            identifier
            display
            label
            ... on ValueSelectedFilter {
              value
            }
          }
          query
        }
        hits(page: $page, sortBy: $sortBy) {
          page {
            total
            totalPages
            pageNumber
            from
            size
            __typename
          }
          items {
            ... on ProviderResultHit {
              fields {
                id
                type
                orgName
                prefixName
                firstName
                lastName
                address {
                  city
                  state
                  zip
                  street
                }
                faxPhone
                workPhone
                networks
                referenceUrns
                specialties
                emrId
              }
            }
          }
        }
        facets {
          identifier
          label
          type
          display
          entries {
            id
            label
            value
            count
          }
        }
      }
    }
  }
`;

export const SEARCH_PROVIDERS_PRACTICES_FOR_REFERRAL = gql`
  query providerPracticeQuery(
    $query: String
    $filters: [SKFiltersSet]
    $page: SKPageInput
    $sortBy: String
  ) {
    providers {
      results(query: $query, filters: $filters) {
        hits(page: $page, sortBy: $sortBy) {
          items {
            ... on ProviderResultHit {
              fields {
                id
                type
                orgName
                prefixName
                firstName
                lastName
                address {
                  city
                  state
                  zip
                  street
                }
                faxPhone
                workPhone
                networks
                referenceUrns
                specialties
                referenceId
                networkId
                contactType
                npi
                insurancePlans {
                  name
                  id
                }
                emrId
              }
            }
          }
        }
      }
    }
  }
`;
