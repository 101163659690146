import { createTheme as createV5Theme } from "@mui/material/styles";

const v5Theme = createV5Theme({
  spacing: 8,
  palette: {
    primary: {
      main: "#516794",
      light: "#97A4BF",
      highlighted: "#53C0A6",
      dark: "#465065",
    },
    text: {
      disabled: "#828282",
    },
    secondary: {
      main: "#F2994A",
      light: "#C6DD5B",
      dark: "#9CB42B",
    },
    accent: {
      main: "53C0A6",
      light: "#87D3C1",
      dark: "#47A38D",
    },
    require: "#EB5757",
    gray: {
      main: "#333333",
      gray2: "#4F4F4F",
      gray3: "#828282",
      gray4: "#BDBDBD",
      gray5: "#E0E0E0",
      gray6: "#F2F2F2",
    },
    black: {
      main: `#4F4F4F`,
    },
    white: {
      main: "white",
    },
    red: {
      red1: "#C23F38",
      red2: "#FF0000",
    },
  },
  typography: {
    //bold label
    subtitle2: {
      fontSize: "1rem",
      lineHeight: "1.7rem",
      fontWeight: 700,
    },
    h4: {
      fontWeight: "400",
      fontSize: "28px",
      lineHeight: "1.45",
      "& div": {
        display: "inline",
      },
    },
    h5: {
      fontSize: "20px",
      fontWeight: 700,
      lineHeight: "1.45rem",
    },
    h6: {
      fontSize: "1.25rem",
      fontWeight: 400,
      lineHeight: 1.6,
    },
    button: {
      textTransform: "none !important",
      cursor: "pointer",
    },
  },
  components: {
    MuiDivider: {
      border: "none",
      height: "2px",
      background: "#516794",
    },
  },
});

export default v5Theme;
