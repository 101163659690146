import { gql } from "@apollo/client";

export const CREATE_REFERRAL = gql`
  mutation CreateReferral($clientId: Int!, $referral: ReferralInput!) {
    createReferral(clientId: $clientId, referral: $referral) {
      id
      displayableId
      sentReferralNetworkId
    }
  }
`;

export const UPDATE_REFERRAL = gql`
  mutation UpdateReferral(
    $clientId: Int!
    $processInstanceId: String!
    $referral: ReferralInput!
  ) {
    updateReferral(
      clientId: $clientId
      processInstanceId: $processInstanceId
      referral: $referral
    ) {
      id
      displayableId
      sentReferralNetworkId
    }
  }
`;

export const ADD_DOCS_TO_REFERRAL = gql`
  mutation AddDocumentsToReferral(
    $clientId: Int!
    $processInstanceId: String!
    $documentIds: [String]!
  ) {
    addDocumentsToReferral(
      clientId: $clientId
      processInstanceId: $processInstanceId
      documentIds: $documentIds
    ) {
      clientId
      filename
    }
  }
`;
