import React from "react";
import { Route } from "react-router-dom";
import { Typography, Box } from "@mui/material";
import { ErrorOutline } from "@material-ui/icons";

function HandleUnauthorized() {
  return (
    <React.Fragment>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        mt={25}
        justifyContent="center"
      >
        <ErrorOutline style={{ fontSize: 40, color: "F44336" }} />
        <Typography variant="h6" align="center" color="error">
          You are not authorized to view this page.
        </Typography>
      </Box>
    </React.Fragment>
  );
}

export default function AuthorizedRoute({
  component: Component,
  permitted,
  path,
  exact,
}) {
  return permitted ? (
    <Route exact={Boolean(exact)} component={Component} path={path} />
  ) : (
    <Route exact={Boolean(exact)} path={path} component={HandleUnauthorized} />
  );
}
