import { gql } from "@apollo/client";

export const CREATE_PATIENT = gql`
  mutation createPatient(
    $clientId: Int!
    $patientPoolId: String!
    $patient: PatientInput
  ) {
    createPatient(
      clientId: $clientId
      patientPoolId: $patientPoolId
      patient: $patient
    ) {
      id
      firstName
      lastName
      mrn
      dob
    }
  }
`;

export const UPDATE_PATIENT = gql`
  mutation updatePatient(
    $clientId: Int!
    $patientPoolId: String!
    $patientId: Int!
    $patient: PatientInput!
  ) {
    updatePatient(
      clientId: $clientId
      patientPoolId: $patientPoolId
      patientId: $patientId
      patient: $patient
    ) {
      id
      firstName
      lastName
      mrn
      dob
    }
  }
`;
