import React from "react";
import { Route } from "react-router-dom";
import AuthorizedRoute from "../AuthorizedRoute";
import { getLoginUrl } from "../Container/auth";

async function handleUnauthenticated() {
  let loginUrl = await getLoginUrl();
  window.location.replace(loginUrl);
  return null;
}

export default function AuthenticatedRoute({
  component: Component,
  authenticated,
  authorized,
  permitted,
  path,
  exact,
}) {
  if (authorized) {
    return authenticated === true ? (
      <AuthorizedRoute
        permitted={permitted}
        path={path}
        component={Component}
        exact={exact}
      />
    ) : (
      <Route render={handleUnauthenticated()} />
    );
  }
  return (
    <Route
      render={(props) =>
        authenticated === true ? (
          <Component {...props} />
        ) : (
          handleUnauthenticated()
        )
      }
    />
  );
}
