import { FormProvider as Form } from "react-hook-form";
import PropTypes from "prop-types";

export default function FormProvider({
  children,
  onSubmit,
  methods,
  id = "default",
}) {
  return (
    <Form {...methods}>
      <form id={id} onSubmit={onSubmit}>
        {children}
      </form>
    </Form>
  );
}

FormProvider.propTypes = {
  children: PropTypes.node.isRequired,
  onSubmit: PropTypes.func.isRequired,
  methods: PropTypes.object.isRequired,
  id: PropTypes.string,
};
