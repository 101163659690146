import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  main: {
    "& .ql-container": {
      borderRadius: "4px",
    },
    borderColor: theme.palette.primary.light,
    "&.ql-container:focus-within": {
      borderColor: theme.palette.primary.main, // Focus border color using theme primary color
      outline: "none", // Remove default outline
    },
    "& .ql-container.ql-container:focus": {
      outline: "none",
      border: "",
      boxShadow: "0px 0px 4px 0px #48A521",
    },
    "& .ql-editor": {
      outlineColor: theme.palette.primary.main,
      minHeight: "80px",
    },
  },
}));

const Editor = React.forwardRef(
  (
    {
      id,
      value,
      onChange,
      error,
      helperText,
      showToolbar = true,
      placeholder,
      ...other
    },
    forwardedRef
  ) => {
    const classes = useStyles();

    const modules = showToolbar
      ? {
          toolbar: [
            [{ header: "1" }, { header: "2" }, { font: [] }],
            [{ size: [] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{ list: "ordered" }, { list: "bullet" }],
            ["link", "image", "video"],
            ["clean"],
          ],
        }
      : {
          toolbar: false,
        };

    return (
      <div ref={forwardedRef}>
        <ReactQuill
          id={id}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          modules={modules}
          style={{ borderRadius: "4px" }}
          className={classes.main}
          formats={
            showToolbar
              ? [
                  "header",
                  "font",
                  "size",
                  "bold",
                  "italic",
                  "underline",
                  "strike",
                  "blockquote",
                  "list",
                  "bullet",
                  "link",
                  "image",
                  "video",
                ]
              : []
          } // Only use formats if the toolbar is shown
          {...other}
        />

        {helperText && (
          <div style={{ color: error ? "red" : "inherit" }}>{helperText}</div>
        )}
      </div>
    );
  }
);

export default Editor;

Editor.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  showToolbar: PropTypes.bool,
  placeholder: PropTypes.string,
  other: PropTypes.object,
};
