const SET_CREATE_FN = "hermes/sidemenu/SET_CREATE_FN";
const SET_HAS_EXTRA_DRAWER = "hermes/sidemenu/SET_HAS_EXTRA_DRAWER";

export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case SET_CREATE_FN:
      return {
        ...state,
        createBtn: action.newBtn,
      };
    case SET_HAS_EXTRA_DRAWER:
      return {
        ...state,
        hasExtraDrawer: action.hasExtraDrawer,
      };
    default:
      return state;
  }
}

export function setCreateButton(newBtn) {
  return {
    type: SET_CREATE_FN,
    newBtn,
  };
}

export function setHasExtraDrawer(hasExtraDrawer) {
  return {
    type: SET_HAS_EXTRA_DRAWER,
    hasExtraDrawer,
  };
}
