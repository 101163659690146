import React, { useMemo } from "react";
import {
  List,
  ListSubheader,
  ListItem,
  ListItemText,
  CircularProgress,
  Typography,
  Divider,
  Paper,
} from "@material-ui/core";
import { Link } from "react-router-dom";

import { useAppSetting } from "../../context";
import useSearchReferrals from "../../hooks/useSearchReferrals";
import { useStyles } from "./style";
import {
  _generateInternalCardContent,
  generateCardActions,
  generateCardHeader,
} from "../TaskCard/TaskCard";
import { useSelector } from "react-redux";
import { _findSpecialtyOptions } from "../TaskCard";
import { getSpecificDateRange } from "../../utils/date";

const createdDateRangeFilter = getSpecificDateRange();
const WorkList = (props) => {
  const { userId } = props;
  const { loading, referrals } = useSearchReferrals({
    query: "",
    filters: [
      {
        identifier: "referral.assignee",
        value: `${userId}`,
      },
      createdDateRangeFilter,
    ],
  });
  const { primaryNetworkId } = useAppSetting();
  const assignedSentList = useMemo(() => {
    if (!referrals || referrals.length === 0) return [];
    return referrals.filter((ref) => ref.type === "SENT");
  }, [referrals]);

  const assignedReceivedList = useMemo(() => {
    if (!referrals || referrals.length === 0) return [];
    return referrals.filter((ref) => ref.type === "RECEIVED");
  }, [referrals]);

  const typeOptions = useSelector(
    (state) =>
      _findSpecialtyOptions(state.createDialog.createReferralTemplate) || []
  );
  const classes = useStyles();

  return (
    <>
      <List style={{ width: "27%" }}>
        {!loading ? (
          <React.Fragment>
            <ListSubheader component="div" id="nested-list-subheader">
              Sent referrals assigned to me
            </ListSubheader>
            <Paper className={classes.listContainer}>
              {assignedSentList.length > 0 &&
                assignedSentList.map((ref) => (
                  <React.Fragment key={`assigned_ref_${ref.id}`}>
                    <ListItem
                      className={classes.padList}
                      button
                      component={Link}
                      to={`/referrals/${ref.type.toLowerCase()}/${ref.id}`}
                    >
                      <ListItemText
                        disableTypography
                        primary={
                          <React.Fragment>
                            {generateCardHeader(ref, classes)}
                            {_generateInternalCardContent(
                              ref,
                              typeOptions,
                              classes
                            )}
                          </React.Fragment>
                        }
                        secondary={generateCardActions(
                          ref,
                          classes,
                          undefined,
                          null,
                          primaryNetworkId
                        )}
                      />
                    </ListItem>
                    <Divider />
                  </React.Fragment>
                ))}
              {assignedSentList.length === 0 && (
                <ListItem key={`nothing_assigned`}>
                  <ListItemText primary="You have nothing assigned to you!" />
                </ListItem>
              )}
            </Paper>
            <ListSubheader component="div" id="nested-list-subheader">
              Received referrals assigned to me
            </ListSubheader>
            <Paper className={classes.listContainer}>
              {assignedReceivedList.length > 0 &&
                assignedReceivedList.map((ref) => (
                  <React.Fragment key={`assigned_ref_${ref.id}`}>
                    <ListItem
                      className={classes.padList}
                      button
                      component={Link}
                      to={`/referrals/${ref.type.toLowerCase()}/${ref.id}`}
                    >
                      <ListItemText
                        disableTypography
                        primary={
                          <React.Fragment>
                            {generateCardHeader(ref, classes)}
                            {_generateInternalCardContent(
                              ref,
                              typeOptions,
                              classes
                            )}
                          </React.Fragment>
                        }
                        secondary={generateCardActions(ref, classes)}
                      />
                    </ListItem>
                    <Divider />
                  </React.Fragment>
                ))}
              {assignedReceivedList.length === 0 && (
                <ListItem key={`nothing_assigned`}>
                  <ListItemText primary="You have nothing assigned to you!" />
                </ListItem>
              )}
            </Paper>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <CircularProgress size={60} className={classes.center} />
            <Typography align="center">Loading referrals</Typography>
          </React.Fragment>
        )}
      </List>
    </>
  );
};

export default WorkList;
